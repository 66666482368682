import { Spinner } from 'presentation/components/base/Spinner';
import { DefaultLayout } from 'presentation/templates';

import logo from 'shared/assets/images/global/logotipo-horizontal-colorido.svg';

import { Container } from './SplashScreen.styles';

export function SplashScreen(): JSX.Element {
  return (
    <DefaultLayout>
      <Container>
        <img
          src={logo}
          alt='VExpenses'
        />

        <Spinner />
      </Container>
    </DefaultLayout>
  );
}
