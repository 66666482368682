import vexpensesLogo from 'shared/assets/icons/notifications/vexpenses.png';
import { Icons } from 'shared/constants/global';

export const notificationIcon = {
  default: <Icons.NotificationBellIcon />,
  form: <Icons.NotificationFormIcon />,
  list: <Icons.NotificationListIcon />,
  cards: <Icons.NotificationCardsIcon />,
  sheet: <Icons.NotificationSheetIcon />,
  'cash-in': <Icons.NotificationCashInIcon />,
  document: <Icons.NotificationDocumentIcon />,
  'user-clock': <Icons.NotificationUserClockIcon />,
  'user-cross': <Icons.NotificationUserCrossIcon />,
  'cash-check': <Icons.NotificationCashCheckIcon />,
  'user-check': <Icons.NotificationUserCheckIcon />,
  'cards-clock': <Icons.NotificationCardsClockIcon />,
  'circle-user-cross': <Icons.NotificationCircleUserCrossIcon />,
  'circle-user-check': <Icons.NotificationCircleUserCheckIcon />,
  vexpenses: (
    <img
      src={vexpensesLogo}
      alt='vexpenses'
    />
  )
};
