import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  color: ${tokens.neutralColorLowDark};
  font-size: ${tokens.fontSizeXxss};
  letter-spacing: 0.75px;
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamiliesPoppins};

  svg {
    color: ${tokens.brandColorSecondaryLight};
  }
`;
