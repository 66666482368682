import { format, subDays } from 'date-fns';
import { create } from 'zustand';

import { type IUseMovementsContext } from './useMovementsContext.types';

export const useMovementsContext = create<IUseMovementsContext>()(set => ({
  movementsFilters: {
    movementType: 'ALL',
    endDate: format(new Date(), 'yyyy-MM-dd'),
    startDate: format(subDays(new Date(), 30), 'yyyy-MM-dd'),
    timezone: '-3:00',
    accounts: ['COMPANY'],
    selectedUsersAccountAggregationIds: []
  },
  movementsSearch: '',
  transactionDetails: null,
  pendencyDetails: undefined,
  dateFilterValidatingStatus: 'OK',
  transactionsToExport: [],
  allUsers: [],
  allPendencies: [],

  handleSetTransactionsToExport: transactionsIds => {
    set({
      transactionsToExport: transactionsIds
    });
  },

  handleSetAllUsers: users => {
    set({
      allUsers: users
    });
  },

  handleSetAllPendencies: pendencies => {
    set({
      allPendencies: pendencies
    });
  },

  handleChangeDateFilterValidatingStatus: status => {
    set({ dateFilterValidatingStatus: status });
  },

  handleSetTransactionDetails: details => {
    set({ transactionDetails: details });
  },

  handleSetPendencyDetails: (pendency, mainTransactionWithPendency) => {
    set({
      pendencyDetails: {
        pendency,
        mainTransactionWithPendency
      }
    });
  },

  handleChangeMovementsSearch: value => {
    set({ movementsSearch: value });
  },

  handleChangeMovementsFilters: filters => {
    set({ movementsFilters: filters });
  }
}));
