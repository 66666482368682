import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ItemContainerStylesType } from './SettingsSidebarItem.types';

export const Container = styled.li<AddPrefixToType<ItemContainerStylesType>>`
  display: flex;
  align-items: center;
  width: 100%;

  ${({ $active }) =>
    $active &&
    css`
      background: ${({ theme }) => theme.colors.gallery};

      a {
        font-weight: 700;
      }
    `}

  > a {
    display: flex;
    width: 100%;
    gap: 1.5rem;
    align-items: center;
    padding: 1.2rem 2.5rem;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.3rem;
    text-decoration: none;
    user-select: none;

    span {
      display: flex;
      align-items: center;
    }

    i {
      font-weight: 500;
    }

    svg {
      margin-left: 1.2rem;
    }
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gallery};

    a {
      font-weight: 700;
    }
  }
`;
