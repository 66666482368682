import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { Container as NotificationIconContainer } from 'presentation/components/base/Notification//NotificationIcon/NotificationIcon.styles';
import { Container as NotificationTitleContainer } from 'presentation/components/base/Notification/NotificationTitle/NotificationTitle.styles';

import { type NotificationCardStyleType } from './NotificationCard.types';

export const Container = styled.li<AddPrefixToType<NotificationCardStyleType>>`
  background: ${({ $disabled, $read }) =>
    $disabled
      ? tokens.neutralColorHighMedium
      : $read
        ? tokens.neutralColorHighPure
        : tokens.brandColorPrimaryLightest};
  border-radius: ${tokens.borderRadiusXs};
  align-self: stretch;
  transition: background-color 0.2s ease-in-out;

  ${({ $disabled, $read }) =>
    $disabled
      ? css`
          pointer-events: none;

          ${NotificationTitleContainer} {
            color: ${tokens.neutralColorLowMediumLight};
          }

          ${NotificationIconContainer} {
            background: ${tokens.neutralColorHighDark};

            svg {
              color: ${tokens.neutralColorLowLight};
            }
          }
        `
      : css`
          &:hover {
            background: ${$read
              ? tokens.neutralColorHighLight
              : tokens.brandColorSecondaryLightest};
          }

          ${NotificationIconContainer} {
            ${!$read &&
            css`
              background: ${tokens.neutralColorHighPure};
            `}
          }
        `}

  a {
    display: flex;
    align-self: stretch;
    padding: ${tokens.spacingXxs};
    gap: ${tokens.spacingXs};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingQuarck};
`;
