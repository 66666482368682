import styled from 'styled-components';

import { type DraggableScrollContainerStyleType } from './DraggableScrollComponent.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<
  AddPrefixToType<DraggableScrollContainerStyleType>
>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  max-width: ${({ $maxWidth }) => $maxWidth ?? 'auto'};
  cursor: ${({ $isDragging }) => ($isDragging ? 'default' : 'grabbing')};
`;
