import { useTheme } from 'styled-components';
import { useShallow } from 'zustand/react/shallow';

import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import {
  DropdownRoot as Container,
  DropdownTrigger
} from 'presentation/ds/DsDropdown';

import { Icons } from 'shared/constants/global';

import { useLanguageSelector } from './useLanguageSelector';

import {
  DropdownContentWrapper,
  DropdownLanguageItem,
  DropdownLanguageScroll,
  Wrapper
} from './LanguageSelector.styles';

export function LanguageSelector(): JSX.Element {
  const [currentLangKey, changeLanguage, options] = useLangContext(
    useShallow(state => [state.currentLangKey, state.changeLang, state.options])
  );

  const { dropdownOpen, setDropdownOpen, langOptions } = useLanguageSelector();

  const theme = useTheme();

  return (
    <Container
      onOpenChange={() => {
        setDropdownOpen(old => !old);
      }}
      open={dropdownOpen}
    >
      <DropdownTrigger>
        <Wrapper $dropdownOpen={dropdownOpen}>
          <Icons.EarthIcon fill={theme.colors.primary} />

          <p>{langOptions[currentLangKey]}</p>

          <BaseIcon
            name='arrow-down-s'
            size={1.8}
            type='line'
          />
        </Wrapper>
      </DropdownTrigger>

      <DropdownContentWrapper withArrow>
        <DropdownLanguageScroll>
          {Object.entries(options).map(
            ([key, language]) =>
              currentLangKey !== key && (
                <DropdownLanguageItem
                  onClick={() => {
                    changeLanguage(language.code);
                  }}
                  key={key}
                >
                  {language.lang}
                </DropdownLanguageItem>
              )
          )}
        </DropdownLanguageScroll>
      </DropdownContentWrapper>
    </Container>
  );
}
