import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type CloseAlertStylesType } from './CloseAlert.types';

export const Container = styled.div<AddPrefixToType<CloseAlertStylesType>>`
  align-items: center;
  background: ${({ theme, $color }) => $color ?? theme.colors.primary};
  display: ${({ $closed }) => ($closed ? 'none' : 'flex')};
  font-size: 1.44rem;
  justify-content: space-between;
  padding: 1.4rem;
  width: 100%;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    cursor: pointer;

    * {
      fill: ${({ theme }) => theme.colors.ghostWhite}55;
    }

    &:hover {
      * {
        fill: ${({ theme }) => theme.colors.ghostWhite};
      }
    }
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const Content = styled.div`
  color: ${({ theme }) => theme.colors.ghostWhite};
  font-size: 1.44rem;
  line-height: 2rem;

  a,
  p,
  span,
  strong {
    font-size: 1.44rem;
  }

  a,
  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.ghostWhite};
  }

  a {
    text-decoration: underline;
  }
`;
