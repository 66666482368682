import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: fit-content;
  height: fit-content;
  border-radius: 40px;
  align-items: flex-start;
  padding: ${tokens.spacingNano};
  background: ${tokens.brandColorPrimaryLightest};

  svg,
  img {
    width: 2rem;
    height: 2rem;
  }

  svg {
    color: ${tokens.brandColorSecondaryPure};
  }
`;
