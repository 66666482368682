import { useMutationCache } from 'data/cache';
import {
  type IReadNotificationPayload,
  type IUseReadNotification,
  NotificationsMutationKeys,
  NotificationsService
} from 'data/modules/notifications';

export function useReadNotification(): IUseReadNotification {
  const {
    mutateAsync: readNotificationAsync,
    isLoading: isReadingNotification,
    isSuccess: isReadNotificationSuccess
  } = useMutationCache({
    mutationFn: async (payload: IReadNotificationPayload) =>
      await NotificationsService.readNotification(payload),
    mutationKey: [NotificationsMutationKeys.READ_NOTIFICATION]
  });

  return {
    readNotification: readNotificationAsync,
    isReadingNotification,
    isReadNotificationSuccess
  };
}
