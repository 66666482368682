import { vexDomain } from 'data/config';
import { useLangContext } from 'data/contexts';
import { useGetSessionUser } from 'data/modules/auth';

import { BaseIcon } from 'presentation/components/base/Icon';
import { Tooltip } from 'presentation/components/base/Tooltip';
import { LanguageSelector } from 'presentation/components/global/LanguageSelector';
import { Notifications } from 'presentation/components/global/Notifications';
import { Outsourced } from 'presentation/components/global/OldOutsourced';
import { Profile } from 'presentation/components/global/OldProfile';
import { TourAction } from 'presentation/components/global/TourAction';

import { ReactComponent as Logo } from 'shared/assets/images/global/logotipo-horizontal-branco.svg';
import { Icons } from 'shared/constants/global/icons.constant';

import { useNavbar } from './useNavbar';

import { type INavbarProps } from './Navbar.types';

import {
  Container,
  IconsContainer,
  LeftContent,
  MobileContainer,
  MobileContent,
  NavbarLabel,
  RightContent,
  UserInfo
} from './Navbar.styles';

export function Navbar({
  onToggleSidebar,
  isTourButtonVisible
}: INavbarProps): JSX.Element {
  const { user, company, outsourcedUser } = useGetSessionUser();
  const { grabAndScrollRef, handleMouseDown } = useNavbar();
  const { currentLangKey, lang } = useLangContext();

  return (
    <>
      <Container
        $outsourced={!!outsourcedUser}
        data-testid='navbar'
      >
        <MobileContent>
          <Icons.MenuIcon onClick={onToggleSidebar} />

          <LeftContent
            ref={grabAndScrollRef}
            onPointerDown={handleMouseDown}
          >
            {/* Aviso do período de testes */}
            {company && company.testDaysRemaining > 0 && (
              <NavbarLabel>
                {lang.navbar.components.remains[currentLangKey] +
                  company?.testDaysRemaining.toString()}
                {lang.navbar.components.days_of_use[currentLangKey]}
              </NavbarLabel>
            )}

            {/* Info de Terceirização */}
            {user?.parameters?.hasOutsourcingAccess && <Outsourced />}
          </LeftContent>
        </MobileContent>

        {/* Informações do usuário */}
        {user && (
          <RightContent>
            <UserInfo>
              <LanguageSelector />

              <IconsContainer>
                {company?.parameters.usesReferrerProgram &&
                  currentLangKey === 'pt' && (
                    <a href={`${vexDomain}/indique-e-ganhe`}>
                      <Tooltip
                        id='Indique & Ganhe'
                        content={lang.global.refer_and_earn[currentLangKey]}
                      >
                        <BaseIcon
                          name='trophy'
                          color='primary'
                          size={2.5}
                        />
                      </Tooltip>
                    </a>
                  )}

                {isTourButtonVisible && <TourAction />}

                <Notifications />
              </IconsContainer>

              <Profile user={user} />
            </UserInfo>
          </RightContent>
        )}
      </Container>

      {/* Navbar mobile com o trigger da sidebar para < 700px */}
      <MobileContainer $outsourced={!!outsourcedUser}>
        <div>
          <Logo />

          <h1>{company?.name}</h1>
        </div>

        <Icons.MenuIcon onClick={onToggleSidebar} />
      </MobileContainer>
    </>
  );
}
