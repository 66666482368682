import { create } from 'zustand';

import { type IUseEditTravelAdminsContext } from './useEditTravelAdminsContext.types';

export const useEditTravelAdminsContext = create<IUseEditTravelAdminsContext>()(
  set => ({
    users: [],
    addedUsers: [],
    removedUsers: [],
    selectedUsers: [],
    hasChangedAtLeastOneAdmin: false,
    setUsers: users => {
      set({ users });
    },
    setAddedUsers: addedUsers => {
      set({ addedUsers });
    },
    setRemovedUsers: removedUsers => {
      set({ removedUsers });
    },
    setSelectedUsers: selectedUsers => {
      set({ selectedUsers });
    },
    setHasChangedAtLeastOneAdmin: hasChanged => {
      set({ hasChangedAtLeastOneAdmin: hasChanged });
    },
    resetState: () => {
      set({
        selectedUsers: [],
        hasChangedAtLeastOneAdmin: false
      });
    }
  })
);
