import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${tokens.spacingXxs};

  > span {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    font-family: ${tokens.fontFamilyPoppins};
    color: ${tokens.neutralColorLowMediumLight};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.8rem;
  }

  > div {
    font-family: ${tokens.fontFamilyPoppins};
    color: ${tokens.neutralColorLowLight};
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.8rem;
  }
`;
