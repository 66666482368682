import React, { Children } from 'react';

import { useDropdown } from './useDropdown';

import { type IDropdownProps } from './Dropdown.types';

import { Button, Container, Content } from './Dropdown.styles';

export function Dropdown({
  children,
  content,
  direction = 'center',
  closeOnContentClick = false,
  ...props
}: IDropdownProps): JSX.Element {
  const { ref, isOpen, toggleIsOpen } = useDropdown();

  const ContentItems = (Children.toArray(content) as JSX.Element[]).map(child =>
    React.cloneElement(child, {
      onClick: () => {
        child?.props?.onClick?.();
        if (closeOnContentClick) toggleIsOpen();
      }
    })
  );

  return (
    <Container ref={ref}>
      <Button onClick={toggleIsOpen}>{children}</Button>
      <Content
        $direction={direction}
        $opened={isOpen}
        {...props}
      >
        {ContentItems}
      </Content>
    </Container>
  );
}
