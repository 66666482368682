import { create } from 'zustand';

import { type IUseAnalysisExpensesListContext } from 'data/contexts/analytics/analysisExpensesList/analysisExpensesListContext.types';

export const useAnalysisExpensesListContext =
  create<IUseAnalysisExpensesListContext>()(set => ({
    expenses: [],
    expenseIndex: -1,
    handleSetExpensesList: expenses => {
      set({
        expenses
      });
    },
    handleChangeExpenseIndex: expenseIndex => {
      set({
        expenseIndex
      });
    }
  }));
