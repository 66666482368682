import { Root as Container } from '@radix-ui/react-dropdown-menu';

import { type IDropdownRootProps } from './DropdownRoot.types';

export function DropdownRoot({
  children,
  ...rest
}: IDropdownRootProps): JSX.Element {
  return (
    <Container
      modal={false}
      {...rest}
    >
      {children}
    </Container>
  );
}
