import { transparentize } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => transparentize(0.5, theme.colors.white)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndex.loader};
  cursor: wait;

  p {
    margin-top: 0.5rem;
    font-size: 2.1rem;
    color: ${({ theme }) => theme.colors.black};
  }
`;
