import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled, { css } from 'styled-components';

import { type PaginationStylesType } from './PaginationPages.types';
import { type AddPrefixToType } from 'ds/types';

export const PerPageSelectMenu = styled.div`
  display: none;
  position: absolute;
  width: 100%;
  margin-bottom: 0.5rem;
  bottom: 100%;
  border-radius: ${tokens.borderRadiusXs};
  border-width: ${tokens.borderWidthThin};
  border-color: ${tokens.neutralColorHighMedium};
  gap: ${tokens.spacingInsetQuarck};
  background-color: ${tokens.neutralColorHighPure};
  z-index: 1;

  box-shadow: ${tokens.shadowLeve4.x} ${tokens.shadowLeve4.y}
    ${tokens.shadowLeve4.blur} ${tokens.shadowLeve4.spread}
    ${tokens.shadowLeve4.color};
`;

export const Container = styled.div<AddPrefixToType<PaginationStylesType>>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacingInsetXxs};
  position: relative;

  ${({ $visible }) =>
    $visible &&
    css`
      ${PerPageSelectMenu} {
        display: block;
      }

      svg {
        transform: rotateZ(3.14rad);
      }
    `}
`;

export const Label = styled.span`
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
`;

export const PerPageSelect = styled.button`
  display: flex;
  border: none;
  outline: none;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  gap: ${tokens.spacingInsetXxs};
  padding: 0.9rem ${tokens.spacingInsetMd};
  background-color: ${tokens.neutralColorHighDark};
  border-radius: ${tokens.borderRadiusXs};
  font-family: ${tokens.fontFamiliesPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightSemibold};

  svg {
    transition: ${transitions.allEaseInOut};
    height: 2.2rem !important;

    * {
      stroke: ${tokens.neutralColorLowMedium};
    }
  }
`;

export const PerPageSelectContainer = styled.div`
  position: relative;
`;

export const PerPageSelectItem = styled.div`
  cursor: pointer;
  font-family: ${tokens.fontFamiliesPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightMedium};
  padding: ${tokens.spacingQuarck} ${tokens.spacingNano};
  line-height: 2.2rem;

  &:last-of-type {
    border-bottom-right-radius: ${tokens.borderRadiusXs};
    border-bottom-left-radius: ${tokens.borderRadiusXs};
  }

  &:first-of-type {
    border-top-right-radius: ${tokens.borderRadiusXs};
    border-top-left-radius: ${tokens.borderRadiusXs};
  }

  &:hover {
    background-color: ${tokens.brandColorPrimaryLightest};
  }
`;
