import { useQueryCache } from 'data/cache';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetOutsourcedUser,
  type IUseGetOutsourcedUserParams
} from 'data/modules/auth';

export function useGetOutsourcedUser({
  userId,
  enabled
}: IUseGetOutsourcedUserParams): IUseGetOutsourcedUser {
  const { data, isFetching } = useQueryCache({
    queryKey: [AuthQueryKeys.GET_OUTSOURCED_USER, userId],
    queryFn: async () => await AuthService.getOutsourcedUser({ userId }),
    enabled,
    staleTime: Infinity
  });

  return {
    isFetchingOutsourcedUser: isFetching,
    outsourcedUser: data
  };
}
