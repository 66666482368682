import { type ISpinnerProps } from './Spinner.types';

import { StyledSpinner } from './Spinner.styles';

export function Spinner({
  size = 3.2,
  color = 'neutral'
}: ISpinnerProps): JSX.Element {
  return (
    <StyledSpinner
      $size={size}
      $color={color}
    />
  );
}
