import { tokens } from 'ds/tokens';
import styled from 'styled-components';

import { type ContainerStylesType } from './ModalHeader.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ $gapDisabled }) =>
    !$gapDisabled &&
    `
    gap: ${tokens.spacingLg};
  `}

  flex-shrink: 0;
  margin-bottom: 1.6rem;
`;
