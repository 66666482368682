import { create } from 'zustand';

import { type IUseCardsContext } from './useCardsContext.types';

export const useCardsContext = create<IUseCardsContext>()((set, get) => ({
  listLayout: 'LIST',
  filters: {
    searchTerm: undefined,
    cardType: undefined,
    order: {
      cardName: 'ASC'
    }
  },
  pagination: {
    page: 1,
    limit: 10,
    total: undefined
  },

  handleChangeListLayout: newListLayout => {
    get().listLayout !== newListLayout && set({ listLayout: newListLayout });
  },
  handleChangeFilters: ({ searchTerm, cardType, order }) => {
    set(state => ({
      filters: {
        ...state.filters,
        ...((searchTerm || searchTerm === null) && { searchTerm }),
        ...(cardType && { cardType }),
        ...(order && { order: { cardName: order.cardName } })
      },
      pagination: {
        ...state.pagination,
        ...(!searchTerm && searchTerm !== null && { page: 1 })
      }
    }));
  },
  handleChangePagination: ({ page, limit, total }) => {
    set(state => ({
      pagination: {
        ...state.pagination,
        ...(page && { page }),
        ...(limit && { limit, page: 1 }),
        ...(total && { total })
      }
    }));
  }
}));
