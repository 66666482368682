import { tokens } from 'ds/tokens';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.li`
  cursor: pointer;
`;

export const CustomLink = styled(Link)`
  color: ${tokens.neutralColorLowMediumLight};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
  text-decoration: none;
  letter-spacing: 0.75px;

  &:hover {
    color: ${tokens.brandColorSecondaryPure};
  }
`;
