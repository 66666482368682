import axios from 'axios';
import Cookies from 'js-cookie';

import { ErrorHandle } from 'shared/utils/errors';

import { env } from './env.config';

if (process.env.NODE_ENV === 'development') {
  Cookies.set('vex_at', env.VITE_AUTH_ACCESS_TOKEN);
  Cookies.set('vex_pt', env.VITE_AUTH_PERSONAL_TOKEN);
}

const personalToken = Cookies.get('vex_pt');

const api = axios.create({
  baseURL: env.VITE_GATEWAY_BASE_URL,
  headers: {
    Accept: 'application/json',
    Authorization: `Bearer ${personalToken}`
  }
});

api.interceptors.response.use(ErrorHandle.apiError);

export default api;
