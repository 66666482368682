import { lazy } from 'react';

const RoomKeyIcon = lazy(
  async () =>
    await import('./room_key.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapHotelPinLocationIcon = lazy(
  async () =>
    await import('./map_hotel_pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelBuildingIcon = lazy(
  async () =>
    await import('./hotel_building.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PlateForkIcon = lazy(
  async () =>
    await import('./plate_fork.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ElevatorIcon = lazy(
  async () =>
    await import('./elevator.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RatingFiveStarIcon = lazy(
  async () =>
    await import('./rating_five_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelSingleBedAddIcon = lazy(
  async () =>
    await import('./hotel_single_bed_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BathroomHangerIcon = lazy(
  async () =>
    await import('./bathroom_hanger.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RoomServiceCleanIcon = lazy(
  async () =>
    await import('./room_service_clean.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MenuRoomServiceIcon = lazy(
  async () =>
    await import('./menu_room_service.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RoomCardIcon = lazy(
  async () =>
    await import('./room_card.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SearchLoupeFavoriteReviewsIcon = lazy(
  async () =>
    await import('./search_loupe_favorite_reviews.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelIcon = lazy(
  async () =>
    await import('./hotel.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BathroomShowerIcon = lazy(
  async () =>
    await import('./bathroom_shower.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatReviewLikeIcon = lazy(
  async () =>
    await import('./chat_review_like.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelSearchIcon = lazy(
  async () =>
    await import('./hotel_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChatStarIcon = lazy(
  async () =>
    await import('./chat_star.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelPinLocationIcon = lazy(
  async () =>
    await import('./hotel_pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DoorRoomAddPlusIcon = lazy(
  async () =>
    await import('./door_room_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KnifeForkRestaurantFoodIcon = lazy(
  async () =>
    await import('./knife_fork_restaurant_food.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ParkingCardIcon = lazy(
  async () =>
    await import('./parking_card.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReceptionHotelIcon = lazy(
  async () =>
    await import('./reception_hotel.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SwimmingPoolIcon = lazy(
  async () =>
    await import('./swimming_pool.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HairDryerIcon = lazy(
  async () =>
    await import('./hair_dryer.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelSquareIcon = lazy(
  async () =>
    await import('./hotel_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelMessageIcon = lazy(
  async () =>
    await import('./hotel_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SlippersIcon = lazy(
  async () =>
    await import('./slippers.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RoomServiceIcon = lazy(
  async () =>
    await import('./room_service.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HotelBookletIcon = lazy(
  async () =>
    await import('./hotel_booklet.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReceptionBellIcon = lazy(
  async () =>
    await import('./reception_bell.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BathroomTowelHangerIcon = lazy(
  async () =>
    await import('./bathroom_towel_hanger.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SmokingSquareIcon = lazy(
  async () =>
    await import('./smoking_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const KitchenIcon = lazy(
  async () =>
    await import('./kitchen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BathBathroomIcon = lazy(
  async () =>
    await import('./bath_bathroom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const BeachUmbrellaIcon = lazy(
  async () =>
    await import('./beach_umbrella.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ReceptionHotelConciergeIcon = lazy(
  async () =>
    await import('./reception_hotel_concierge.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarDatesCheckInCheckOutIcon = lazy(
  async () =>
    await import('./calendar_dates_check_in_check_out.svg').then(module => ({
      default: module.ReactComponent
    }))
);

export const Hotels = {
  RoomKeyIcon,
  MapHotelPinLocationIcon,
  HotelBuildingIcon,
  PlateForkIcon,
  ElevatorIcon,
  RatingFiveStarIcon,
  HotelSingleBedAddIcon,
  BathroomHangerIcon,
  RoomServiceCleanIcon,
  MenuRoomServiceIcon,
  RoomCardIcon,
  SearchLoupeFavoriteReviewsIcon,
  HotelIcon,
  BathroomShowerIcon,
  ChatReviewLikeIcon,
  HotelSearchIcon,
  ChatStarIcon,
  HotelPinLocationIcon,
  DoorRoomAddPlusIcon,
  KnifeForkRestaurantFoodIcon,
  ParkingCardIcon,
  ReceptionHotelIcon,
  SwimmingPoolIcon,
  HairDryerIcon,
  HotelSquareIcon,
  HotelMessageIcon,
  SlippersIcon,
  RoomServiceIcon,
  HotelBookletIcon,
  ReceptionBellIcon,
  BathroomTowelHangerIcon,
  SmokingSquareIcon,
  KitchenIcon,
  BathBathroomIcon,
  BeachUmbrellaIcon,
  ReceptionHotelConciergeIcon,
  CalendarDatesCheckInCheckOutIcon
};
