import { create } from 'zustand';

import { type IHotelRoom } from 'data/modules/travel/productSearch';

import ProductSearchUtil from 'shared/utils/travels/ProductSearch.util';

import {
  HotelOrderBy,
  type IHotelSearchContext,
  type IHotelSearchStates
} from './useHotelSearchContext.types';

export const initialStates: IHotelSearchStates = {
  filters: {
    destination: undefined,
    checkIn: undefined,
    checkOut: undefined,
    breakfastIncluded: undefined
  },
  hotelResultFilters: {
    withinPolicy: undefined,
    minPrice: undefined,
    maxPrice: undefined,
    categories: {
      one: undefined,
      two: undefined,
      three: undefined,
      four: undefined,
      five: undefined
    }
  },
  destinationSelectedOption: {
    value: '',
    label: ''
  },
  hotelOrderBy: HotelOrderBy.LOWER_PRICE,
  hotelList: undefined,
  filteredAndSortedHotelList: undefined,
  selectedHotel: undefined,
  hotelRoomsRecord: {},
  selectedHotelRoom: undefined
};

export const useHotelSearchContext = create<IHotelSearchContext>()(
  (set, get) => ({
    ...initialStates,
    handleChangeFilters: newFilters => {
      set(state => ({
        filters: {
          ...state.filters,
          ...newFilters
        }
      }));
    },
    handleApplyFilters: () => {
      const listToBeFiltered = get().hotelList;
      const hotelResultFilters = get().hotelResultFilters;

      const filteredHotelList = ProductSearchUtil.filterHotelList(
        listToBeFiltered,
        hotelResultFilters
      );

      set({
        filteredAndSortedHotelList: filteredHotelList
      });
    },
    handleChangeHotelOrderBy: hotelOrderBy => {
      set({
        hotelOrderBy
      });
    },
    handleApplyHotelOrderBy: () => {
      const listToBeSorted = get().filteredAndSortedHotelList;
      const hotelOrderBy = get().hotelOrderBy;

      const sortedHotelList = ProductSearchUtil.sortHotelList(
        listToBeSorted,
        hotelOrderBy
      );

      set({
        filteredAndSortedHotelList: sortedHotelList
      });
    },
    handleChangeHotelResultFilters: newFilters => {
      set(state => ({
        hotelResultFilters: {
          ...state.hotelResultFilters,
          ...newFilters,
          categories: {
            ...state.hotelResultFilters.categories,
            ...newFilters.categories
          }
        }
      }));
    },
    handleChangeHotelList: hotelList => {
      set({
        hotelList,
        filteredAndSortedHotelList: hotelList
      });
    },
    handleClearHotelResultFilter: () => {
      set({
        hotelResultFilters: initialStates.hotelResultFilters
      });
    },
    handleClearContext: () => {
      set(initialStates);
    },
    handleChangeDestinationSelectedOption: option => {
      set({ destinationSelectedOption: option });
    },
    handleSelectHotel: hotel => {
      const hotelRoomsRecord: Record<string, IHotelRoom> = {};

      hotel.rooms.forEach(room => {
        hotelRoomsRecord[room.identifier] = room;
      });

      set({ selectedHotel: hotel, hotelRoomsRecord });
    },
    handleSelectHotelRoom: hotelRoom => {
      set({ selectedHotelRoom: hotelRoom });
    }
  })
);
