import { type IDefaultIconProps } from 'data/modules/global';

export function Emotion1({
  type,
  color,
  size
}: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20ZM7 13H9C9 13.7956 9.31607 14.5587 9.87868 15.1213C10.4413 15.6839 11.2044 16 12 16C12.7956 16 13.5587 15.6839 14.1213 15.1213C14.6839 14.5587 15 13.7956 15 13H17C17 14.3261 16.4732 15.5979 15.5355 16.5355C14.5979 17.4732 13.3261 18 12 18C10.6739 18 9.40215 17.4732 8.46447 16.5355C7.52678 15.5979 7 14.3261 7 13ZM8 11C7.60218 11 7.22064 10.842 6.93934 10.5607C6.65804 10.2794 6.5 9.89782 6.5 9.5C6.5 9.10218 6.65804 8.72064 6.93934 8.43934C7.22064 8.15804 7.60218 8 8 8C8.39782 8 8.77936 8.15804 9.06066 8.43934C9.34196 8.72064 9.5 9.10218 9.5 9.5C9.5 9.89782 9.34196 10.2794 9.06066 10.5607C8.77936 10.842 8.39782 11 8 11ZM16 11C15.6022 11 15.2206 10.842 14.9393 10.5607C14.658 10.2794 14.5 9.89782 14.5 9.5C14.5 9.10218 14.658 8.72064 14.9393 8.43934C15.2206 8.15804 15.6022 8 16 8C16.3978 8 16.7794 8.15804 17.0607 8.43934C17.342 8.72064 17.5 9.10218 17.5 9.5C17.5 9.89782 17.342 10.2794 17.0607 10.5607C16.7794 10.842 16.3978 11 16 11Z' />
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22ZM7 13C7 14.3261 7.52678 15.5979 8.46447 16.5355C9.40215 17.4732 10.6739 18 12 18C13.3261 18 14.5979 17.4732 15.5355 16.5355C16.4732 15.5979 17 14.3261 17 13H15C15 13.7956 14.6839 14.5587 14.1213 15.1213C13.5587 15.6839 12.7956 16 12 16C11.2044 16 10.4413 15.6839 9.87868 15.1213C9.31607 14.5587 9 13.7956 9 13H7ZM8 11C8.39782 11 8.77936 10.842 9.06066 10.5607C9.34196 10.2794 9.5 9.89782 9.5 9.5C9.5 9.10218 9.34196 8.72064 9.06066 8.43934C8.77936 8.15804 8.39782 8 8 8C7.60218 8 7.22064 8.15804 6.93934 8.43934C6.65804 8.72064 6.5 9.10218 6.5 9.5C6.5 9.89782 6.65804 10.2794 6.93934 10.5607C7.22064 10.842 7.60218 11 8 11ZM16 11C16.3978 11 16.7794 10.842 17.0607 10.5607C17.342 10.2794 17.5 9.89782 17.5 9.5C17.5 9.10218 17.342 8.72064 17.0607 8.43934C16.7794 8.15804 16.3978 8 16 8C15.6022 8 15.2206 8.15804 14.9393 8.43934C14.658 8.72064 14.5 9.10218 14.5 9.5C14.5 9.89782 14.658 10.2794 14.9393 10.5607C15.2206 10.842 15.6022 11 16 11Z' />
    </svg>
  );
}
