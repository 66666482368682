import { type IDefaultIconProps } from 'data/modules/global';

export function FileWarning({
  color,
  size,
  type
}: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M15 4H5V20H19V8H15V4ZM3 2.9918C3 2.44405 3.44749 2 3.9985 2H16L20.9997 7L21 20.9925C21 21.5489 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918ZM11 15H13V17H11V15ZM11 7H13V13H11V7Z'></path>
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M16 2L21 7V21.0082C21 21.556 20.5551 22 20.0066 22H3.9934C3.44476 22 3 21.5447 3 21.0082V2.9918C3 2.44405 3.44495 2 3.9934 2H16ZM11 15V17H13V15H11ZM11 7V13H13V7H11Z'></path>
    </svg>
  );
}
