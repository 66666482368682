import { useInfiniteQueryCache } from 'data/cache';
import {
  type IUseGetNotifications,
  type IUseGetNotificationsParams,
  NotificationsQueryKeys,
  NotificationsService
} from 'data/modules/notifications';

export function useGetNotifications({
  enabled,
  ...params
}: IUseGetNotificationsParams): IUseGetNotifications {
  const {
    data: notificationsPages,
    isError: isGetNotificationsError,
    isPending,
    isFetching: isFetchingNotifications,
    hasNextPage: hasNextPageNotifications,
    fetchNextPage: fetchNotificationsNextPage,
    isFetchingNextPage: isFetchingNotificationsNextPage
  } = useInfiniteQueryCache({
    queryKey: [NotificationsQueryKeys.GET_NOTIFICATIONS, params],
    queryFn: async ({ pageParam = 1 }) => {
      return await NotificationsService.getNotifications({
        ...params,
        page: pageParam
      });
    },
    enabled,
    getNextPageParam: lastPage =>
      lastPage.headers.currentPage < lastPage.headers.totalPages
        ? lastPage.headers.currentPage + 1
        : undefined,
    initialPageParam: 1
  });

  return {
    isLoadingNotifications: isPending,
    isFetchingNotifications,
    isGetNotificationsError,
    fetchNotificationsNextPage,
    isFetchingNotificationsNextPage,
    hasNextPageNotifications: hasNextPageNotifications ?? false,
    countUnread: notificationsPages?.pages?.[0]?.headers?.countUnread ?? 0,
    notifications: notificationsPages?.pages?.flatMap(page => page.data) ?? []
  };
}
