export const routesPathPrefix = {
  cards: '/cartoes',
  settings: '/settings',
  store: '/store',
  expenses: '/despesas',
  reports: '/relatorios',
  support: '/suporte',
  analytics: '/analises',
  travel: '/viagens',
  admin: '/admin'
};
