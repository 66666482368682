import {
  type INotification,
  type IPersistenceNotification
} from 'data/modules/notifications';

class GetNotificationsMapper {
  toDomain(
    persistenceNotifications: IPersistenceNotification[]
  ): INotification[] {
    return persistenceNotifications.map(persistenceNotification => ({
      id: persistenceNotification.id,
      type: persistenceNotification.type,
      readAt: persistenceNotification.read_at,
      recipient: persistenceNotification.recipient,
      requestId: persistenceNotification.request_id,
      createdAt: persistenceNotification.created_at,
      isRead: Boolean(persistenceNotification.is_read),
      communicationChannel: persistenceNotification.communication_channel,
      content: {
        type: persistenceNotification.content?.type,
        userId: persistenceNotification.content?.userId,
        notification: {
          url: persistenceNotification.content?.notification?.url,
          icon: persistenceNotification.content?.notification?.icon,
          title: persistenceNotification.content?.notification?.title,
          message: persistenceNotification.content?.notification?.message,
          subMessage: persistenceNotification.content?.notification?.submessage
        }
      }
    }));
  }
}

export default new GetNotificationsMapper();
