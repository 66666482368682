import { type IDropdownTriggerProps } from './DropdownTrigger.types';

import { Container } from './DropdownTrigger.styles';

export function DropdownTrigger({
  children,
  ...rest
}: IDropdownTriggerProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
