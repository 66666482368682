import analyticsJSON from './analytics.lang.json';
import cardsJSON from './cards.lang.json';
import companyOnboardingJSON from './companyOnboarding.lang.json';
import errorsJSON from './errors.lang.json';
import expensesJSON from './expenses.lang.json';
import formsJSON from './forms.lang.json';
import fuelJSON from './fuel.lang.json';
import globalJSON from './global.lang.json';
import notificationsJSON from './notifications.lang.json';
import reportsJSON from './reports.lang.json';
import schemasJSON from './schemas.lang.json';
import settingsJSON from './settings.lang.json';
import sidebarJSON from './sidebar.lang.json';
import spendingLimitsJSON from './spendingLimits.lang.json';
import storeJSON from './store.lang.json';
import supportJSON from './support.lang.json';
import travelJSON from './travel.lang.json';
import travelNewTranslationJSON from './travelNewTranslation.lang.json';
import userOnboardingJSON from './userOnboarding.lang.json';

export const en = {
  analytics: analyticsJSON,
  expenses: expensesJSON,
  cards: cardsJSON,
  reports: reportsJSON,
  global: globalJSON,
  schemas: schemasJSON,
  notifications: notificationsJSON,
  store: storeJSON,
  support: supportJSON,
  sidebar: sidebarJSON,
  travel: travelJSON,
  travelNewTranslation: travelNewTranslationJSON,
  fuel: fuelJSON,
  errors: errorsJSON,
  settings: settingsJSON,
  companyOnboarding: companyOnboardingJSON,
  forms: formsJSON,
  spendingLimits: spendingLimitsJSON,
  userOnboarding: userOnboardingJSON
};
