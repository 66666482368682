import { type ISortTable } from 'data/contexts/cards/chargeCards/useChargeCardsContext.types';

export enum ChargeCardsSteps {
  'CHARGE_CARDS',
  'CONFIRM_OPERATIONS'
}

export interface IValueAllocationCardItem {
  name: string;
  type: 'INDIVIDUAL' | 'BUSINESS';
  number: string | null;
  currentValue: number | undefined;
  balanceId: string;
  status: 'ACTIVE' | 'BLOCKED' | null;
  accountId: string;
  userId: string;
}

export interface IUseChargeCards {
  currentStep: ChargeCardsSteps;
}

export interface IGetPaginatedListParams {
  list?: IValueAllocationCardItem[];
  pageSize: number;
}

export interface IGetSortedListParams {
  list?: IValueAllocationCardItem[];
  sortTable: ISortTable;
}

export interface IGetFilteredList {
  list: IValueAllocationCardItem[] | undefined;
  searchTerm: string;
  cardType: 'BUSINESS' | 'INDIVIDUAL' | 'ALL';
}
