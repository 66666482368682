import { Icons } from 'shared/constants/global';

import { useCloseAlert } from './useCloseAlert';

import { type ICloseAlertProps } from './CloseAlert.types';

import { Container, Content } from './CloseAlert.styles';

export function CloseAlert({ children, color }: ICloseAlertProps): JSX.Element {
  const { closed, setClosed } = useCloseAlert();

  return (
    <Container
      $closed={closed}
      data-testid='alert'
      $color={color}
    >
      <Content>{children}</Content>

      {/* Botão de Fechar o Alerta */}
      <Icons.CloseIcon
        onClick={() => {
          setClosed(true);
        }}
      />
    </Container>
  );
}
