import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.img`
  width: 40rem;
  height: 45.2rem;
  max-width: 40rem;
  max-height: 45.2rem;
  border-radius: ${tokens.borderRadiusSm};
`;
