import { useLocation } from 'react-router-dom';

import { env } from 'data/config';
import { useGetAuthUser } from 'data/modules/auth';

import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

import { routeContexts } from 'shared/constants/global';

export function Redirect(): JSX.Element {
  const { pathname, search, hash } = useLocation();

  const { company, isFetchingAuthUser } = useGetAuthUser();

  if (isFetchingAuthUser) {
    return <SplashScreen />;
  }

  if (company?.id === 333393) {
    if (routeContexts.filter(route => pathname.includes(route)).length > 0) {
      return <FallbackPage />;
    }

    const url = env.VITE_VEX_BASE_DOMAIN + pathname + hash + search;
    window.location.href = url;
    return <SplashScreen />;
  }

  return <FallbackPage />;
}
