import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import Select, { components } from 'react-select';
import styled, { css } from 'styled-components';

import { type MultiSelectDefaultStylesType } from './MultiSelectDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
`;

export const Wrapper = styled.div<
  AddPrefixToType<MultiSelectDefaultStylesType>
>`
  width: 100%;
  position: relative;
  min-height: 5.2rem;
  max-height: 5.2rem;

  ${({ $large }) =>
    $large &&
    `
    min-height: 6.4rem;
    max-height: 6.4rem;
  `}

  ${({ $small }) =>
    $small &&
    `
    min-height: 4.1rem;
    max-height: 4.1rem;
  `}
`;

export const Legend = styled.legend`
  opacity: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
  max-width: 0.001rem;
  white-space: nowrap;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  letter-spacing: 0.075rem;

  span {
    opacity: 0;
    visibility: visible;
  }
`;

export const Fieldset = styled.fieldset`
  text-align: left;
  position: absolute;
  inset: -5px 0 0;
  pointer-events: none;
  overflow: hidden;
  z-index: 1;
  min-width: 0%;
  padding: 0 ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusXs};
  border-color: ${tokens.neutralColorLowLight};
  border-width: ${tokens.borderWidthHairline};
  border-style: solid;
  top: -1rem;
  transition: border 100ms ease-in-out;
`;

export const StyledOption = styled.div`
  cursor: pointer;
  display: flex;

  align-items: center;
  background-color: transparent;
  padding: ${tokens.spacingXxs} ${tokens.spacingXs};
  gap: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowDarkest};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  transition: background 100ms ease-in-out;
  border-radius: ${tokens.borderRadiusNano};

  &:hover {
    background-color: ${tokens.neutralColorHighDark} !important;
  }

  .checkbox-container {
    padding: 0 ${tokens.spacingNano};

    > div {
      padding: 0.7rem;
    }

    input:checked + div {
      padding: 0.3rem;
    }
  }
`;

export const MenuInformation = styled.p`
  text-align: left;
  line-height: 1.8rem;
  letter-spacing: 0.075rem;
  padding: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxss};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  color: ${tokens.neutralColorLowMediumLight};
`;

export const StyledMultiSelect = styled(Select)<
  AddPrefixToType<MultiSelectDefaultStylesType>
>`
  ~ span {
    color: ${tokens.neutralColorLowLight};

    ${({ $error }) =>
      $error &&
      css`
        color: ${tokens.feedbackColorErrorPure} !important;
        opacity: 1;
      `}

    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXxs};
      `}
  }

  &:focus-within {
    ~ ${Fieldset} {
      border-color: ${tokens.brandColorSecondaryPure};
      border-width: ${tokens.borderWidthThin};

      ${Legend} {
        opacity: 1;
        visibility: visible;
        max-width: unset;
        overflow: visible;
        padding: 0 ${tokens.spacingQuarck};
      }
    }

    ~ span {
      color: ${tokens.brandColorSecondaryPure};
      opacity: 1;
    }

    input,
    .react-multiSelect-custom__single-value {
      color: ${tokens.neutralColorLowDarkest};
    }

    #chevron {
      opacity: 1;
      color: ${tokens.brandColorSecondaryPure};
    }

    .react-multiSelect-custom__multi-value {
      font-size: ${tokens.fontSizeXxss};
      padding: 0.3rem ${tokens.spacingNano};
      border-radius: ${tokens.borderRadiusXs};
      gap: ${tokens.spacingQuarck};
      background-color: ${tokens.brandColorSecondaryPure};

      * {
        color: ${tokens.neutralColorHighPure};
        font-family: ${tokens.fontFamilyPoppins}, sans-serif;
      }
    }
  }

  * {
    line-height: ${tokens.spacingInsetMd};
    font-size: ${tokens.fontSizeXxs};
  }

  ${({ $isTouched }) =>
    $isTouched &&
    css`
      .react-multiSelect-custom__value-container {
        padding: 0;
      }

      .react-multiSelect-custom__indicator-separator {
        opacity: 1 !important;
      }

      &:not(:focus-within) {
        ~ ${Fieldset} {
          border-color: ${tokens.neutralColorLowMedium};
          border-width: ${tokens.borderWidthHairline};

          ${Legend} {
            opacity: 1;
            visibility: visible;
            max-width: unset;
            overflow: visible;
            padding: 0 ${tokens.spacingQuarck};
          }
        }

        * {
          color: ${tokens.neutralColorLowDarkest};
        }

        ~ span {
          opacity: 1;
          color: ${tokens.neutralColorLowDarkest};
        }
      }
    `}

  ${({ $large }) =>
    $large &&
    css`
      * {
        font-size: ${tokens.fontSizeXs} !important;
      }
    `}

  ${({ $small }) =>
    $small &&
    css`
      * {
        font-size: ${tokens.fontSizeXxss} !important;
        line-height: 1.7rem;
        letter-spacing: 0.075rem;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowLightest} !important;
        border-width: ${tokens.borderWidthHairline} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      ~ span {
        opacity: 1;
        background: linear-gradient(
          180deg,
          ${tokens.deepColorWhite} 53%,
          ${tokens.neutralColorHighLight} 50%
        );
      }
    `}


    ${({ $error }) =>
    $error &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.feedbackColorErrorPure} !important;
        border-width: ${tokens.borderWidthThin} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      .react-multiSelect-custom__indicators svg * {
        color: ${tokens.feedbackColorErrorPure} !important;
      }
    `}

  .react-multiSelect-custom__placeholder {
    font-family: ${tokens.fontFamilyPoppins};
    font-weight: ${tokens.fontWeightRegular};
    letter-spacing: 0.075rem;
  }

  .react-multiSelect-custom__control {
    background-color: ${tokens.neutralColorHighPure};
    position: relative;
    box-shadow: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    transition: border 100ms ease-in-out;
    min-width: 32.2rem;
    gap: ${tokens.spacingInsetNano};
    padding: ${tokens.spacingQuarck} ${tokens.spacingXs};
    border: none;
    min-height: 5.2rem;
    max-height: 5.2rem;

    & > div:first-child {
      flex-shrink: 1;
      overflow: auto;
      max-width: unset;
    }

    ${({ $large }) =>
      $large &&
      `
      min-height: 6.4rem;
      max-height: 6.4rem;
    `}

    &:hover {
      &:not(:focus-within) {
        border-color: ${tokens.neutralColorLowDarkest};
        border-width: ${tokens.borderWidthHairline};

        #chevron {
          opacity: 1;
        }
      }
    }

    #chevron {
      width: 2.2rem;
      height: 2.2rem;
      opacity: 0.5;
      transition: ${transitions.allEaseInOut};
    }

    input,
    .react-multiSelect-custom__single-value {
      background-color: transparent !important;
      color: ${tokens.neutralColorLowLight};
      font-family: ${tokens.fontFamilyPoppins} !important;
      font-weight: ${tokens.fontWeightRegular} !important;

      ${({ $error }) =>
        $error &&
        css`
          color: ${tokens.feedbackColorErrorPure} !important;
        `}
    }

    ${({ $error }) =>
      $error &&
      css`
        #chevron {
          color: ${tokens.feedbackColorErrorPure} !important;
        }
      `}

    ${({ $large }) =>
      $large &&
      css`
        padding-top: ${tokens.spacingXxs} !important;
        padding-bottom: ${tokens.spacingXxs} !important;
        min-width: 32.2rem;
      `}

    ${({ $small }) =>
      $small &&
      css`
        padding-top: ${tokens.spacingQuarck} !important;
        padding-bottom: ${tokens.spacingQuarck} !important;
        min-height: 4.1rem;
        max-height: 4.1rem;
      `}
  }

  .react-multiSelect-custom__value-container {
    flex-wrap: nowrap;
    overflow: hidden;
    display: -webkit-inline-box;
    padding: 0;

    &::-webkit-scrollbar {
      height: 2rem;
    }

    &::-webkit-scrollbar-thumb {
      cursor: pointer;
      background-color: ${tokens.neutralColorLowLight};
      border: 6px solid transparent;
      background-clip: padding-box;
      border-radius: 12px;
    }
  }

  .react-multiSelect-custom__control--is-disabled {
    pointer-events: auto;
    cursor: not-allowed;

    * {
      pointer-events: none;
    }

    background-color: ${tokens.neutralColorHighLight} !important;

    color: ${tokens.neutralColorLowLight} !important;
  }

  .react-multiSelect-custom__control--menu-is-open {
    input,
    .react-multiSelect-custom__single-value {
      color: ${tokens.neutralColorLowDarkest} !important;
    }

    #chevron {
      opacity: 1;

      color: ${tokens.brandColorSecondaryPure};
      transform: rotateZ(3.14rad);
    }

    #clear-icon {
      color: ${tokens.brandColorSecondaryPure};
    }
  }

  .react-multiSelect-custom__dropdown-indicator {
    display: ${({ $hasArrowDownIndicator }) =>
      $hasArrowDownIndicator ? `` : 'none'};
    cursor: pointer !important;
    padding: 0;
    padding-left: 0.8rem;
  }

  .react-multiSelect-custom__indicators {
    cursor: pointer !important;

    #clear-icon {
      display: ${({ $allowClearValues }) => ($allowClearValues ? '' : 'none')};
      margin-top: 0.4rem;
    }
  }

  .react-multiSelect-custom__indicator-separator {
    display: none;
  }

  .react-multiSelect-custom__multi-value__remove:hover {
    background-color: transparent !important;
    cursor: pointer;
  }

  .react-multiSelect-custom__multi-value__remove {
    padding: 0;
    display: ${({ $allowClearValues }) => ($allowClearValues ? '' : 'none')};
  }

  .react-multiSelect-custom__multi-value {
    padding: 0.3rem ${tokens.spacingNano};
    border-radius: ${tokens.borderRadiusXs};
    gap: ${tokens.spacingQuarck};
    background-color: ${tokens.neutralColorHighMedium};
    transition: ${transitions.allEaseInOut};
    width: fit-content;

    * {
      text-overflow: unset !important;
      font-size: ${tokens.fontSizeXxss};
      color: ${tokens.neutralColorLowDark};
      font-family: ${tokens.fontFamilyPoppins}, sans-serif;
      font-weight: ${tokens.fontWeightMedium};
    }

    ${({ $small }) =>
      $small &&
      `
        padding: 0 ${tokens.spacingQuarck} !important;
    `}
  }

  .react-multiSelect-custom__menu {
    margin-top: 0;

    z-index: 5;
    border-radius: ${tokens.borderRadiusXs};
    box-shadow: ${tokens.shadowLeve4.x} ${tokens.shadowLeve4.y}
      ${tokens.shadowLeve4.blur} ${tokens.shadowLeve4.spread}
      ${tokens.shadowLeve4.color};
    padding: ${tokens.spacingXxs} ${tokens.spacingNano};
    border: 2px solid ${tokens.neutralColorHighMedium};

    &:has(.react-multiSelect-custom__menu-notice--no-options)
      > ${MenuInformation} {
      display: none;
    }
  }

  .react-multiSelect-custom__menu-list {
    max-height: ${({ $hasInformation }) =>
      $hasInformation ? '19rem' : '25rem'};
    padding: 0;
    scroll-behavior: unset;
  }

  .react-multiSelect-custom__group-heading {
    text-align: left;
    line-height: 1.7rem;
    text-transform: none;
    letter-spacing: 0.025rem;
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowDarkest};
    font-weight: ${tokens.fontWeightRegular};
    font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  }

  ${StyledOption} {
    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXs} !important;
      `}
  }

  .react-multiSelect-custom__input-container {
    font-family: ${tokens.fontFamilyPoppins} !important;
  }
`;

export const Label = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  top: -1.5rem;
  left: 1.7rem;
  z-index: 2;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  letter-spacing: 0.075rem;
  padding: ${tokens.spacingQuarck};
`;

export const Caption = styled.span<
  AddPrefixToType<MultiSelectDefaultStylesType>
>`
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  margin-top: ${tokens.spacingInsetNano};

  ${({ $error }) =>
    $error &&
    css`
      color: ${tokens.feedbackColorErrorPure} !important;
    `}
`;

export const MenuPortalContainer = styled(components.MenuPortal)<
  AddPrefixToType<MultiSelectDefaultStylesType>
>`
  * {
    line-height: ${tokens.spacingInsetMd};
    font-size: ${tokens.fontSizeXxs};
  }

  ${({ $large }) =>
    $large &&
    css`
      * {
        font-size: ${tokens.fontSizeXs} !important;
      }
    `}

  ${({ $small }) =>
    $small &&
    css`
      * {
        font-size: ${tokens.fontSizeXxss} !important;
        line-height: 1.7rem;
        letter-spacing: 0.075rem;
      }
    `}


  .react-multiSelect-custom__menu {
    margin-top: 0;
    z-index: 5;
    border-radius: ${tokens.borderRadiusXs};
    box-shadow: ${tokens.shadowLeve4.x} ${tokens.shadowLeve4.y}
      ${tokens.shadowLeve4.blur} ${tokens.shadowLeve4.spread}
      ${tokens.shadowLeve4.color};
    padding: ${tokens.spacingXxs} ${tokens.spacingNano};
    border: 2px solid ${tokens.neutralColorHighMedium};

    &:has(.react-multiSelect-custom__menu-notice--no-options)
      > ${MenuInformation} {
      display: none;
    }
  }

  .react-multiSelect-custom__menu-list {
    max-height: ${({ $hasInformation }) =>
      $hasInformation ? '19rem' : '25rem'};
    padding: 0;
    scroll-behavior: unset;
  }

  .react-multiSelect-custom__group-heading {
    text-align: left;
    line-height: 1.7rem;
    text-transform: none;
    letter-spacing: 0.025rem;
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowDarkest};
    font-weight: ${tokens.fontWeightRegular};
    font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  }

  ${StyledOption} {
    ${({ $large }) =>
      $large &&
      css`
        font-size: ${tokens.fontSizeXs} !important;
      `}
  }
`;
