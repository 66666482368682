import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type RadioDefaultStylesType } from './RadioDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const CheckIndicator = styled.div`
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  width: 10px;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
`;

export const RadioComponent = styled.div`
  border-radius: 50%;
  border: solid 2.5px ${tokens.neutralColorLowLight};
  display: flex;
  justify-content: center;
  align-items: center;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  width: 20px;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  height: 20px;

  &:hover {
    border-color: ${tokens.brandColorSecondaryPure};
  }
`;

export const InputComponent = styled.input`
  scale: 0;
  position: absolute;

  &:disabled {
    cursor: not-allowed;
  }
`;

export const RadioWrapper = styled.div`
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus-within {
    background-color: ${tokens.brandColorPrimaryLightest};
  }

  input:checked + ${RadioComponent} {
    border-color: ${tokens.brandColorSecondaryPure};

    ${CheckIndicator} {
      background-color: ${tokens.brandColorSecondaryPure};
    }
  }
`;

export const Container = styled.label<AddPrefixToType<RadioDefaultStylesType>>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  gap: ${tokens.spacingInsetQuarckk};
  padding: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXs};
  position: relative;

  &:hover div {
    border-color: ${tokens.brandColorSecondaryPure};
  }

  ${({ $small }) =>
    $small &&
    css`
      font-size: ${tokens.fontSizeXxss};

      ${RadioWrapper} {
        width: 2.8rem;
        height: 2.8rem;
      }

      ${RadioComponent} {
        /* stylelint-disable-next-line declaration-property-unit-allowed-list */
        width: 16.67px;

        /* stylelint-disable-next-line declaration-property-unit-allowed-list */
        height: 16.67px;

        /* stylelint-disable-next-line declaration-property-unit-allowed-list */
        padding: 2px;
      }

      ${CheckIndicator} {
        width: 100%;
        height: 100%;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed;

      div {
        border-color: ${tokens.neutralColorHighDark};
      }

      span {
        color: ${tokens.neutralColorHighDark};
      }

      &:hover {
        div {
          border-color: ${tokens.neutralColorHighDark};
        }

        span {
          color: ${tokens.neutralColorHighDark};
        }
      }
    `}
`;

export const Label = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  line-height: ${tokens.spacingInsetMd};
  color: ${tokens.neutralColorLowDark};
`;
