import { useLangContext } from 'data/contexts';

import { Portal } from 'presentation/components/base/Portal';
import { Spinner } from 'presentation/components/base/Spinner';

import { type ILoaderProps } from './Loader.types';

import { Container } from './Loader.styles';

export function Loader({
  isLoading = false,
  showText = true,
  message
}: ILoaderProps): JSX.Element | null {
  const [lang, currentLangKey] = useLangContext(state => [
    state.lang,
    state.currentLangKey
  ]);

  if (!isLoading) {
    return null;
  }

  return (
    <Portal containerId='loader-root'>
      <Container>
        <Spinner />

        {showText && <p>{message ?? lang.global.loading[currentLangKey]}</p>}
      </Container>
    </Portal>
  );
}
