import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8rem;
  color: ${tokens.neutralColorLowMedium};
`;
