import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type DefaultTabContainerStylesType } from './DefaultTabContainer.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.nav`
  width: fit-content;
  background-color: transparent;
  position: relative;
`;

export const TabsList = styled.ul<
  AddPrefixToType<DefaultTabContainerStylesType>
>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  list-style: none;
  gap: ${tokens.spacingInsetXs};

  ${({ $hasTrack }) =>
    $hasTrack &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: -0.6rem;
        left: 0;
        height: 0.4rem;
        width: 100%;
        background-color: ${tokens.neutralColorHighDark};
      }
    `}
`;
