import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.2rem;

  > p {
    font-weight: 600;
    color: ${tokens.neutralColorLowMedium};
  }

  > span {
    flex-shrink: 0;
    letter-spacing: 0.025rem;
    display: flex;
    line-height: normal;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    color: ${tokens.neutralColorLowLight};
  }
`;
