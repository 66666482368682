import { type ISkeletonLoaderProps } from './SkeletonLoader.types';

import { Loader, MultipleLoadersContainer } from './SkeletonLoader.styles';

export function SkeletonLoader({
  width = '100%',
  maxWidth = '100%',
  height = '1.6rem',
  rows = 1,
  margin = '0',
  color = 'brandColorPrimaryLightest',
  circle = false,
  loaderClassName,
  multipleLoadersContainerClassName
}: ISkeletonLoaderProps): JSX.Element {
  const loaders = Array.from({ length: rows }, () => (
    <Loader
      key={Math.random()}
      $width={rows > 1 ? '100%' : width}
      $maxWidth={rows > 1 ? '100%' : maxWidth}
      $height={height}
      $margin={margin}
      $color={color}
      $circle={circle}
      className={loaderClassName}
    />
  ));

  if (rows === 1) {
    return <>{loaders}</>;
  }

  return (
    <MultipleLoadersContainer
      $width={width}
      $maxWidth={maxWidth}
      className={multipleLoadersContainerClassName}
    >
      {loaders}
    </MultipleLoadersContainer>
  );
}
