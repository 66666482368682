import { create } from 'zustand';

import {
  type IErrorMessage,
  type IUseModalContext
} from './useModalContext.types';

export const useModalContext = create<IUseModalContext>()((set, get) => ({
  visibleModal: 'closed',
  errorMessage: undefined,
  modalStack: [],
  handleOpenModal: (modal, clearStack) => {
    const lastStack = clearStack ? [] : get()?.modalStack;

    set({
      visibleModal: modal,
      modalStack: [...lastStack, get()?.visibleModal]
    });
  },
  handleCloseModal: () => {
    set({
      visibleModal: 'closed',
      modalStack: []
    });
  },
  handleOpenLastModal: () => {
    const lastModal = get()?.modalStack.at(-1);
    const newStack = get()?.modalStack.slice(0, -1);

    set({
      visibleModal: lastModal || 'closed',
      modalStack: newStack || []
    });
  },
  handleChangeErrorMessage: (errorMessage?: IErrorMessage) => {
    set({ errorMessage });
  }
}));
