import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type FilterPillStylesType } from './FilterPill.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.span<AddPrefixToType<FilterPillStylesType>>`
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  border-radius: ${tokens.borderRadiusXxl};
  padding: ${tokens.spacingNano} ${tokens.spacingXxs};
  border: 1px solid ${tokens.neutralColorHighMedium};
  background: ${tokens.neutralColorHighPure};
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightMedium};
  user-select: none;
  gap: ${tokens.spacingNano};

  &:hover {
    border-color: ${tokens.brandColorPrimaryLight};
    color: ${tokens.brandColorPrimaryMedium};
  }

  &:active {
    border-color: ${tokens.brandColorPrimaryLight};
    background: ${tokens.brandColorPrimaryLightest};
    color: ${tokens.brandColorSecondaryPure};
    font-weight: ${tokens.fontWeightBold};
  }

  ${({ $selected }) =>
    $selected &&
    css`
      border-color: ${tokens.brandColorPrimaryLight} !important;
      background: ${tokens.brandColorPrimaryLightest} !important;
      color: ${tokens.brandColorSecondaryPure} !important;
      font-weight: ${tokens.fontWeightBold} !important;
    `}

  ${({ $isFilter }) =>
    $isFilter &&
    css`
      height: 4rem;
      line-height: 2.4rem;
      font-size: ${tokens.fontSizeXxss};
      cursor: pointer;
      color: ${tokens.neutralColorLowMedium};
      font-weight: ${tokens.fontWeightMedium};
      border: 1px solid ${tokens.neutralColorLowLightest};
      background: ${tokens.brandColorPrimaryLightest};
      padding: ${tokens.spacingNano} ${tokens.spacingXs};

      &:hover,
      &:active {
        color: ${tokens.neutralColorLowMedium};
        font-weight: ${tokens.fontWeightMedium};
        border-color: ${tokens.neutralColorLowLightest};
        background: ${tokens.brandColorPrimaryLightest};
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      border-color: ${tokens.neutralColorHighMedium} !important;
      background: ${tokens.neutralColorHighLight} !important;
      color: ${tokens.neutralColorLowLight} !important;
      font-weight: ${tokens.fontWeightMedium} !important;
    `}
`;
