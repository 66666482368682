import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  > svg {
    width: 2.8rem;
    height: 2.8rem;
    stroke: ${tokens.brandColorSecondaryPure};

    &:last-of-type {
      visibility: hidden;
      max-height: 0;
      opacity: 0;
    }
  }

  &:hover > svg {
    stroke: ${tokens.brandColorSecondaryMedium};

    &:first-of-type {
      visibility: hidden;
      max-height: 0;
      opacity: 0;
    }

    &:last-of-type {
      visibility: visible;
      max-height: unset;
      opacity: 1;
    }
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    min-width: 3.45rem;
  }
`;
