import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
  max-height: calc(100vh - 20rem);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-height: calc(100vh - ${tokens.spacingXxxl});
  }
`;
