import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type DefaultButtonStylesType } from './DefaultButton.types';

export const Container = styled.button<
  AddPrefixToType<DefaultButtonStylesType>
>`
  background: ${({ $color, theme }) => theme.colors[$color]};
  color: ${({ theme }) => theme.colors.white};
  border: 0;
  border-radius: 3px;
  font-size: ${({ $size, theme }) => theme.buttons.defaultButtonSizes[$size]};
  line-height: ${({ $size, theme }) => theme.buttons.defaultButtonSizes[$size]};
  width: 100%;
  transition: all 0.3s ease-in;

  ${({ theme, $size }) => css`
    padding: calc(${theme.buttons.defaultButtonSizes[$size]} / 2);
  `}

  &:hover {
    background: ${({ $color, theme }) => lighten(0.04, theme.colors[$color])};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ $outlined, theme, $color }) =>
    $outlined &&
    css`
      background: transparent;
      color: ${theme.colors[$color]};
      border-color: ${theme.colors[$color]};
      border-style: solid;
      border-width: 1px;

      &:hover {
        background: ${theme.colors[$color]};
        color: ${({ theme }) => theme.colors.white};
      }
    `}
`;
