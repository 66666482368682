import { create } from 'zustand';

import { type ApprovalFlowMembersOrderByColumnType } from 'data/modules/travel/approvalFlows';

import {
  type IUseApprovalFlowUsersContext,
  type TabsNewApprovalFlowType
} from './useApprovalFlowUsersContext.types';

export const useApprovalFlowUsersContext =
  create<IUseApprovalFlowUsersContext>()(set => ({
    searchValue: '',
    sortBy: 'name',
    sortDirection: 'asc',
    page: 1,
    perPage: 10,
    usersFailedToEdit: [],
    tabs: 'approvers',
    setSearchValue: (searchValue: string) => {
      set({ searchValue, page: 1 });
    },
    setPage: (page: number) => {
      set({ page });
    },
    setPerPage: (perPage: number) => {
      set({ perPage, page: 1 });
    },
    setSortColumn: (column: ApprovalFlowMembersOrderByColumnType) => {
      set(({ sortBy, sortDirection }) => {
        const direction =
          sortBy === column && sortDirection === 'asc' ? 'desc' : 'asc';
        return {
          sortBy: column,
          sortDirection: direction,
          page: 1
        };
      });
    },
    setUsersFailedToEdit: (usersFailedToEdit: string[]) => {
      set({ usersFailedToEdit });
    },
    setTabs: (tabs: TabsNewApprovalFlowType) => {
      set({ tabs });
    },
    resetFilters: () => {
      set({
        searchValue: '',
        sortBy: 'name',
        sortDirection: 'asc',
        page: 1,
        perPage: 10,
        usersFailedToEdit: [],
        tabs: 'approvers'
      });
    }
  }));
