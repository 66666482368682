import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type TitleStylesType } from './Title.types';

export const Container = styled.div<AddPrefixToType<TitleStylesType>>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5rem;
  padding: 1.5rem 2rem;
  background: ${({ theme, $backgroundColor }) =>
    theme.colors[$backgroundColor]};
  font-weight: ${({ $boldText }) => ($boldText ? '500' : '300')};
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutralColorHighMedium};
  border-right: 1px solid ${({ theme }) => theme.colors.neutralColorHighMedium};

  > p {
    color: ${({ theme, $color }) => theme.colors[$color]};
    font-size: 1.7rem;
    line-height: 2rem;
  }

  > a,
  button {
    width: auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    justify-content: ${({ $hasButton }) => ($hasButton ? 'center' : 'left')};
    flex-direction: ${({ $hasButton }) => ($hasButton ? 'column' : 'row')};
    height: auto;

    > a,
    button {
      margin-top: 0.3rem;
    }
  }
`;
