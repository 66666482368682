import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type ContainerStylesType } from './StepperItem.types';
import { type AddPrefixToType } from 'ds/types';

export const NumberContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
  align-self: stretch;

  > span {
    font-size: 1.4rem;
    line-height: 1.4rem;
    font-family: ${tokens.fontFamilyPoppins};
    color: ${tokens.deepColorWhite};
    font-weight: ${tokens.fontWeightRegular};
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  &::before {
    content: '';
    height: 0.1rem;
    flex-grow: 1;
  }

  &::after {
    content: '';
    height: 0.1rem;
    flex-grow: 1;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  > span {
    font-size: 1.4rem;
    font-family: ${tokens.fontFamilyPoppins};
    width: 15rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ClickArea = styled.div`
  z-index: 1;
  content: '';
  position: absolute;
  margin: auto;
  height: 100%;
  width: 14rem;
  opacity: 0;
  cursor: pointer;
`;

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  gap: 1.6rem;
  position: relative;

  ${({ $alignText }) =>
    $alignText === 'left' &&
    css`
      ${TextContainer} span {
        text-align: left;
      }

      ${NumberContainer}::before {
        content: none;
      }
    `}

  ${({ $alignText }) =>
    $alignText === 'right' &&
    css`
      ${TextContainer} span {
        text-align: right;
      }

      ${NumberContainer}::after {
        content: none;
      }
    `}

  ${({ $active }) =>
    $active &&
    css`
      ${TextContainer} > span {
        font-weight: ${tokens.fontWeightSemibold};
        color: ${tokens.brandColorSecondaryPure};
      }

      ${NumberContainer} > span {
        background-color: ${tokens.brandColorSecondaryPure};
      }

      ${NumberContainer}::before {
        background-color: #0d69d433;
      }

      ${NumberContainer}::after {
        background-color: #0d69d433;
      }
    `}

  ${({ $checked }) =>
    $checked &&
    css`
      ${TextContainer} > span {
        font-weight: ${tokens.fontWeightRegular};
        color: ${tokens.neutralColorLowLight};
      }

      ${NumberContainer} > span {
        background-color: ${tokens.neutralColorLowLight};
      }

      ${NumberContainer}::before {
        background-color: #919eab3d;
      }

      ${NumberContainer}::after {
        background-color: #919eab3d;
      }
    `}

  ${({ $checked, $active }) =>
    !$checked &&
    !$active &&
    css`
      ${TextContainer} > span {
        font-weight: ${tokens.fontWeightRegular};
        color: ${tokens.neutralColorHighDark};
      }

      ${NumberContainer} > span {
        background-color: ${tokens.neutralColorHighDark};
      }

      ${NumberContainer}::before {
        background-color: #919eab3d;
      }

      ${NumberContainer}::after {
        background-color: #919eab3d;
      }
    `}
`;
