import styled from 'styled-components';

export const Container = styled.ul`
  height: 100%;
  overflow: auto;
  padding-bottom: 10rem;
  min-width: fit-content;
  background: ${({ theme }) => theme.colors.alabaster};
  border-right: 1px solid ${({ theme }) => theme.colors.neutralColorHighMedium};
`;
