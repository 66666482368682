import { create } from 'zustand';

import { type IUseTeamMembersContext } from './useTeamMembersContext.types';

export const useTeamMembersContext = create<IUseTeamMembersContext>()(set => ({
  totalPage: 0,
  perPage: 10,
  isDirty: false,
  selectedTeamMember: undefined,
  handleChangePagination: ({ perPage, totalPage }) => {
    set({
      ...(perPage && { perPage }),
      ...(totalPage !== undefined && { totalPage })
    });
  },
  handleChangeSelectedTeamMember: selectedTeamMember => {
    set({ selectedTeamMember });
  },
  handleChangeIsDirty: isDirty => {
    set({ isDirty });
  }
}));
