import { useDsGa4 } from 'ds/hooks/globals';

import { type IBreadcrumbsLinkProps } from './BreadcrumbsLink.types';

import { Container, CustomLink } from './BreadcrumbsLink.styles';

export function BreadcrumbsLink({
  children,
  to,
  ...rest
}: IBreadcrumbsLinkProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      onClick={() => {
        sendDsGaEvent('components', 'breadcrumb', {
          eventLabel: typeof children === 'string' ? children : to
        });
      }}
    >
      <CustomLink
        to={to}
        {...rest}
      >
        {children}
      </CustomLink>
    </Container>
  );
}
