import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type LegendItemStylesType } from 'ds/components/Chart/DonutChart/DonutChart.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: ${tokens.spacingLg};

  path[fill='transparent'] {
    pointer-events: none;
  }

  svg {
    z-index: 2;
  }
`;

export const ChartWrapper = styled.div`
  position: relative;
`;

export const ChartContent = styled.div`
  inset: 0;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
`;

export const DonutChartHeader = styled.div`
  flex: 1 0 0;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingQuarck};
`;

export const Title = styled.h4`
  flex: 1 0 0;
  font-style: normal;
  line-height: 2.8rem;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamiliesPoppins};
`;

export const Subtitle = styled.h6`
  font-size: 1.2rem;
  font-style: normal;
  line-height: normal;
  color: ${tokens.neutralColorLowLight};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamiliesPoppins};
`;

export const LegendContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingXs};
`;

export const LegendItem = styled.div<AddPrefixToType<LegendItemStylesType>>`
  display: flex;
  cursor: pointer;
  flex-wrap: wrap;
  align-items: center;
  column-gap: ${tokens.spacingXs};
  row-gap: ${tokens.spacingNano};

  ${({ $disableClick }) =>
    $disableClick
      ? css`
          cursor: default;
          pointer-events: none;
        `
      : css`
          cursor: pointer;
        `};

  span {
    background: ${({ $background }) => $background};
  }

  p {
    text-decoration: ${({ $visible }) => ($visible ? '' : 'line-through')};
  }
`;

export const LegendSymbol = styled.span`
  border-radius: 32px;
  width: ${tokens.spacingXs};
  height: ${tokens.spacingXs};
  margin-right: ${tokens.spacingNano};
`;

export const LegendName = styled.p`
  flex: 1 0 10rem;
  overflow: hidden;
  font-style: normal;
  white-space: nowrap;
  line-height: 2.2rem;
  text-overflow: ellipsis;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamiliesPoppins};
  color: ${tokens.neutralColorLowMediumLight};
`;

export const LegendLabel = styled.p`
  overflow: hidden;
  font-style: normal;
  line-height: 2.4rem;
  text-overflow: ellipsis;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDarkest};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamiliesPoppins};
`;

export const Caption = styled.p`
  flex: 1 0 0;
  font-style: normal;
  align-self: stretch;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamiliesPoppins};
  color: ${tokens.neutralColorLowMediumLight};
`;
