import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled, { css } from 'styled-components';

import { type ContainerStylesType } from './AccordionItem.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.details<AddPrefixToType<ContainerStylesType>>`
  width: 100%;
  height: fit-content;
  max-width: 156.9rem;
  border-radius: ${tokens.borderRadiusMd};
  transition: ${transitions.allEaseInOut};
  background: ${tokens.neutralColorHighPure};

  &:hover {
    transition: ${transitions.allEaseInOut};
    background: ${tokens.neutralColorHighLight};
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      background: ${tokens.neutralColorHighDark};

      * {
        color: ${tokens.neutralColorLowMediumLight}!important;
      }

      summary {
        outline: none;
      }
    `}

  &[open] {
    background: ${tokens.neutralColorHighLight};

    summary {
      font-weight: ${tokens.fontWeightSemibold};
      padding-bottom: ${tokens.spacingInsetXs};

      .icon-open {
        display: block;
      }

      .icon-close {
        display: none;
      }
    }
  }
`;
