import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled from 'styled-components';

export const Container = styled.summary`
  width: 100%;
  cursor: pointer;
  align-items: center;
  display: inline-flex;
  letter-spacing: 0.75px;
  box-sizing: border-box;
  overflow-wrap: anywhere;
  gap: ${tokens.spacingXs};
  justify-content: space-between;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  border-radius: ${tokens.borderRadiusMd};
  transition: ${transitions.allEaseInOut};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamiliesPoppins};
  outline-color: ${tokens.brandColorPrimaryPure};
  padding: ${tokens.spacingInsetSm} ${tokens.spacingInsetMdd};

  &::-webkit-details-marker,
  &::marker {
    display: none;
    content: '';
  }

  .icon-open {
    display: none;
  }

  .icon-close {
    display: block;
  }

  &:hover svg {
    transition: ${transitions.allEaseInOut};
    color: ${tokens.brandColorPrimaryPure};
  }

  svg {
    color: ${tokens.neutralColorLowDark};
    transition: ${transitions.allEaseInOut};
    border-width: ${tokens.borderWidthThin};
    width: 2.4rem;
    min-width: 2.4rem;
  }
`;
