import { Link } from 'react-router-dom';

import { type ISidebarSubItemProps } from './SidebarSubItem.types';

import { Container } from './SidebarSubItem.styles';

export function SidebarSubItem({
  isReactRoute,
  isActive,
  isCollapsed,
  outsourced,
  href,
  children
}: ISidebarSubItemProps): JSX.Element {
  return (
    <Container
      $isActive={isActive}
      $isCollapsed={isCollapsed}
      $outsourced={outsourced}
      as={isReactRoute ? Link : 'a'}
      {...(isReactRoute
        ? {
            to: href
          }
        : {
            href
          })}
    >
      {children}
    </Container>
  );
}
