import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.p`
  letter-spacing: 0.75px;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  border-radius: ${tokens.borderRadiusMd};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamiliesPoppins};
  padding: 0 ${tokens.spacingInsetMdd} ${tokens.spacingInsetLg};
  margin: 0;
`;
