import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type ILabelUnityValueStylesType } from './LabelUnityValue.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<
  AddPrefixToType<ILabelUnityValueStylesType>
>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 2px solid ${tokens.brandColorSecondaryPure};
  background-color: ${tokens.brandColorPrimaryLightest}50;
  padding: 0 ${tokens.spacingInsetNano};
  line-height: 3.2rem;
  height: 5.1rem;

  ${({ $active }) =>
    $active &&
    css`
      span {
        color: ${tokens.neutralColorLowMedium};
      }
    `}
`;

export const Label = styled.span`
  color: ${tokens.neutralColorLowMedium};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamiliesPoppins};
  padding-right: ${tokens.spacingInsetNano};
`;

export const UnityContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacingInsetNano};
  padding-left: ${tokens.spacingInsetNano};
`;

export const UnityValue = styled.span`
  color: ${tokens.neutralColorLowLight};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamiliesPoppins};
`;

export const UnityLabel = styled.span`
  color: ${tokens.neutralColorLowLight};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamiliesPoppins};
`;
