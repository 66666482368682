import { driver } from 'driver.js';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { driverJsConfig } from 'data/config';
import { useTourContext } from 'data/contexts';

import { localStorageKeys } from 'shared/constants/global';
import { Zendesk } from 'shared/utils/global';

import { type IUseZoomTour } from '../ImageFullScreen.types';

export function useZoomTour(): IUseZoomTour {
  const { t } = useTranslation('expenses');

  const { t: transGlobal } = useTranslation('global');

  const [handleReset] = useTourContext(
    useShallow(state => [state.handleReset])
  );

  function startTour(): void {
    const zoomTour = driver({
      ...driverJsConfig,
      nextBtnText: transGlobal('next'),
      prevBtnText: transGlobal('previous'),
      doneBtnText: transGlobal('finalize'),
      progressText: `{{current}} ${transGlobal('of')} {{total}}`,
      onDestroyed: () => {
        zoomTour.destroy();
        localStorage.setItem(localStorageKeys.zoomTour, 'true');
        handleReset('');
        Zendesk.show();
      },
      steps: [
        {
          element: '.zoom-menu-container',
          popover: {
            title: t('tours.zoomImageFirstStepTitle'),
            description: t('tours.zoomImageFirstStep')
          }
        },
        {
          element: '.zoom-image',
          popover: {
            title: t('tours.zoomImageSecondStepTitle'),
            description: t('tours.zoomImageSecondStep')
          }
        }
      ]
    });

    zoomTour.drive();
    Zendesk.hide();
  }

  return {
    startTour
  };
}
