import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  max-height: 68vh;
  overflow: auto;
  flex-grow: 1;
  min-height: 10rem;
  padding-right: ${tokens.spacingXs};

  @media screen and (max-width: 768px) {
    max-height: 40vh;
  }

  @media screen and (max-height: 500px) {
    max-height: 30vh;
  }
`;
