import { create } from 'zustand';

import { CustomDate } from 'shared/utils/custom';

import { type IUseClassicModeContext } from './useClassicMode.types';

export const useClassicModeContext = create<IUseClassicModeContext>()(
  (set, get) => ({
    isCardsClassicMode: JSON.parse(
      localStorage.getItem('isCardsClassicMode') ?? 'false'
    ),
    isExpensesClassicMode: JSON.parse(
      localStorage.getItem('isExpensesClassicMode') ?? 'false'
    ),
    isValueSolicitationClassicMode: JSON.parse(
      localStorage.getItem('isValueSolicitationClassicMode') ?? 'false'
    ),
    trialAge: {
      cards: null,
      valueSolicitation: null
    },
    trialEndDate: {
      cards: undefined,
      valueSolicitation: undefined
    },

    handleToggleClassicMode: page => {
      switch (page) {
        case 'CARDS':
          localStorage.setItem(
            'isCardsClassicMode',
            JSON.stringify(!get().isCardsClassicMode)
          );

          break;

        case 'EXPENSES':
          localStorage.setItem(
            'isExpensesClassicMode',
            JSON.stringify(!get().isExpensesClassicMode)
          );

          break;

        case 'VALUE_SOLICITATION':
          localStorage.setItem(
            'isValueSolicitationClassicMode',
            JSON.stringify(!get().isValueSolicitationClassicMode)
          );
      }

      window.location.reload();
    },
    handleSetTrialAge: ({ cardsEnabledAt, valueSolicitationEnabledAt }) => {
      const today = new Date();

      if (cardsEnabledAt) {
        const cardsTrialAge = CustomDate.differenceBetweenDates(
          today,
          new Date(cardsEnabledAt)
        );

        const cardsEnabledAtDate = new Date(cardsEnabledAt);

        const cardsTrialEndDate = CustomDate.getDateAddingDays(
          cardsEnabledAtDate,
          30
        );

        set(state => ({
          trialAge: {
            ...state.trialAge,
            cards: cardsTrialAge
          },
          trialEndDate: {
            ...state.trialEndDate,
            cards: cardsTrialEndDate
          }
        }));
      }

      if (valueSolicitationEnabledAt) {
        const valueSolicitationTrialAge = CustomDate.differenceBetweenDates(
          today,
          new Date(valueSolicitationEnabledAt)
        );

        const valueSolicitationEnabledAtDate = new Date(
          valueSolicitationEnabledAt
        );

        const valueSolicitationTrialEndDate = CustomDate.getDateAddingDays(
          valueSolicitationEnabledAtDate,
          30
        );

        set(state => ({
          trialAge: {
            ...state.trialAge,
            valueSolicitation: valueSolicitationTrialAge
          },
          trialEndDate: {
            ...state.trialEndDate,
            valueSolicitation: valueSolicitationTrialEndDate
          }
        }));
      }
    }
  })
);
