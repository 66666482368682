import styled, { css, keyframes } from 'styled-components';
import type Keyframes from 'styled-components/dist/models/Keyframes';

import { type AddPrefixToType } from 'data/modules/global';

import { DropdownContent, DropdownItem } from 'presentation/ds/DsDropdown';
import { Container as StyledTrigger } from 'presentation/ds/DsDropdown/DropdownTrigger/DropdownTrigger.styles';

import { type SidebarDropdownStylesType } from './Outsourced.types';

export const OutsourcedDropdownWrapper = styled.div<
  AddPrefixToType<SidebarDropdownStylesType>
>`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 1.2rem;
  width: 100%;
  min-width: 20rem;
  box-sizing: border-box;

  div[role='separator'] {
    margin-bottom: 0;
  }

  > button {
    width: 100% !important;
  }

  ${StyledTrigger} div {
    color: #553c39 !important;
    background: #e3e8ee;
    text-overflow: ellipsis;
    font-weight: bold;
    text-align: center;
    letter-spacing: 0.25px;
    padding: 0.3rem 0.8rem;
    border-radius: 8px !important;
    overflow: hidden;
    max-width: 30rem;
    margin: 0 auto;

    ${({ $outsourced }) =>
      $outsourced &&
      css`
        background-color: ${({ theme }) => theme.colors.adamantineBlue};
        color: ${({ theme }) => theme.colors.white} !important;

        span {
          color: ${({ theme }) => theme.colors.white} !important;
        }
      `}
  }
`;

export const DropdownOutsourcedItem = styled(DropdownItem)`
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  column-gap: 1rem;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 1.5rem;
  text-align: left;
  margin: 0;
  cursor: pointer;
  transition: all 200ms ease-in;
  pointer-events: none;

  &:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowDarkTwo};
  }

  p {
    color: ${({ theme }) => theme.colors.independence};
    max-width: 16rem;
  }

  svg {
    fill: ${({ theme }) => theme.colors.black} !important;
  }
`;

export const DropdownOutsourcedScroll = styled.div`
  height: fit-content;
  max-height: 21.3rem;
  overflow: hidden auto;
  width: 100%;
  z-index: 40 !important;

  ${DropdownOutsourcedItem} {
    pointer-events: all;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    border: solid 3px transparent;
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.lightSilver};
  }
`;

const slideUp = (height: number): Keyframes => keyframes`
    0% {
      height: 0;
      overflow: hidden;
    }
    100% {
      height: ${height}rem;
    }
`;

const slideDown = (height: number): Keyframes => keyframes`
    0% {
      height: ${height}rem;
      overflow: hidden;
    }
    100% {
      height: 0;
      overflow: hidden;
    }
`;

export const DropdownOutsourcedContent = styled(DropdownContent)`
  padding: 0;
  max-height: 21.3rem;
  min-width: 25.8rem;
  max-width: 35rem;
  height: fit-content;
  position: relative;
  margin-top: 1rem;

  &[data-state='open'] {
    animation: ${({ height }) => slideUp(height ?? 0)} 250ms ease-in-out;
  }

  &[data-state='closed'] {
    animation: ${({ height }) => slideDown(height ?? 0)} 250ms ease-in-out;
  }

  span {
    left: unset;
    right: 2.4rem;

    svg {
      width: 2rem;
      height: auto;
    }
  }
`;

export const OutsourcedLabel = styled.span`
  font-size: 1.3rem !important;
  line-height: 2.2rem;
  font-weight: 500;
  word-break: break-all;
  white-space: nowrap;
`;
