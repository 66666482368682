import { createContext, type ReactElement, useContext } from 'react';

import { PaginationContainer } from './PaginationContainer.component';

import {
  type IPaginationContext,
  type IPaginationProviderProps
} from './PaginationContainer.types';

const PaginationContext = createContext<IPaginationContext | null>(null);

export function PaginationProvider({
  children,
  currentPage,
  perPage,
  totalPage,
  ...rest
}: IPaginationProviderProps): ReactElement {
  return (
    <PaginationContext.Provider
      value={{
        currentPage,
        totalPage,
        perPage
      }}
    >
      <PaginationContainer {...rest}>{children}</PaginationContainer>
    </PaginationContext.Provider>
  );
}

export function usePagination(): IPaginationContext {
  const pagination = useContext(PaginationContext);

  return pagination as IPaginationContext;
}
