import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 1.8rem 1.6rem;
  height: 8.4rem;
  width: 100%;
  gap: 1.6rem;
  background-color: ${tokens.neutralColorHighLight};
  border-radius: ${tokens.borderRadiusXs};
  transition: ${transitions.allEaseInOut};
  border: 0;
  cursor: pointer;
  outline: none;

  > svg {
    flex-shrink: 0;
    color: ${tokens.brandColorSecondaryPure};
    width: 4.8rem;
    height: 4.8rem;

    > * {
      stroke: ${tokens.brandColorSecondaryPure};
    }
  }

  > span {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxs};
    font-weight: ${tokens.fontWeightSemibold};
    color: ${tokens.neutralColorLowDark};
    text-align: left;
  }

  &:not(:disabled):hover {
    background-color: ${tokens.brandColorSecondaryLightest};
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
