import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.ul`
  display: flex;
  align-items: center;
  gap: 0.4rem;
  padding: 0;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};

  li {
    list-style: none;
  }

  svg {
    flex-shrink: 0;

    &:last-child {
      display: none;
    }
  }
`;
