import styled from 'styled-components';

export const Container = styled.button`
  background: transparent;
  border: 0;
  padding: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
