import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './SidebarSubItem.types';

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  display: flex;
  flex-direction: row;
  font-weight: normal;
  padding: 1.2rem 1.2rem 1.2rem 2.3rem;
  cursor: pointer;
  border-radius: 8px;
  transition: all 100ms ease-in-out;
  font-size: 1.4rem;
  gap: 2rem;
  align-items: center;
  margin-top: 0.5rem;
  line-height: 2.2rem;
  width: 100%;

  svg {
    width: 0.4rem;
    height: 0.4rem;
    margin-left: 0.2rem;
    margin-right: 0.2rem;

    * {
      color: ${({ theme }) =>
        transparentize(0.45, theme.colors.white)} !important;
    }
  }

  ${({ $isActive, $outsourced, theme }) =>
    $isActive &&
    css`
      color: ${$outsourced
        ? theme.colors.outSourced
        : theme.tokens.brandColorSecondaryPure};
    `}

  &:hover {
    background-color: ${({ $outsourced, theme }) =>
      $outsourced
        ? theme.colors.outSourced
        : theme.tokens.brandColorPrimaryMedium};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      font-weight: 600;

      svg {
        width: 0.8rem;
        height: 0.8rem;
        margin: 0;

        * {
          color: ${({ theme }) => theme.colors.white} !important;
        }
      }
    `}
`;
