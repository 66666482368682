import {
  type IPersistenceUserTravel,
  type IUserTravel
} from 'data/modules/auth';

class GetAuthTravelUserMapper {
  toDomain(persistenceUserTravel: IPersistenceUserTravel): IUserTravel {
    return {
      id: persistenceUserTravel.id,
      isApprover: persistenceUserTravel.is_approver,
      type: persistenceUserTravel.type
    };
  }
}
export default new GetAuthTravelUserMapper();
