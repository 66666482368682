import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  > li {
    padding-left: 1.6rem;
  }
`;
