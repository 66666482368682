import { lazy } from 'react';

const BlankFolderIcon = lazy(
  async () =>
    await import('./blank_folder.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBlankIcon = lazy(
  async () =>
    await import('./folder_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEditIcon = lazy(
  async () =>
    await import('./folder_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDownloadArrowIcon = lazy(
  async () =>
    await import('./folder_download_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupIcon = lazy(
  async () =>
    await import('./folder_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersBigCopyIcon = lazy(
  async () =>
    await import('./folders_big_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersCrossRemoveIcon = lazy(
  async () =>
    await import('./folders_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderReadCheckmarkIcon = lazy(
  async () =>
    await import('./folder_read_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDocumentBlankLeftIcon = lazy(
  async () =>
    await import('./folder_document_blank_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMinusDeliteIcon = lazy(
  async () =>
    await import('./folder_minus_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileSmallIcon = lazy(
  async () =>
    await import('./folder_file_small.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersCheckmarkIcon = lazy(
  async () =>
    await import('./folders_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigBookmarkIcon = lazy(
  async () =>
    await import('./folder_big_bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEditPenSquareIcon = lazy(
  async () =>
    await import('./folder_edit_pen_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMoveCopyCheckmarkIcon = lazy(
  async () =>
    await import('./folder_move_copy_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderNotificationSquareIcon = lazy(
  async () =>
    await import('./folder_notification_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEditcircleIcon = lazy(
  async () =>
    await import('./folder_editcircle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileGroupIcon = lazy(
  async () =>
    await import('./folder_file_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupPlusAddIcon = lazy(
  async () =>
    await import('./folder_group_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCheckmarkCopyIcon = lazy(
  async () =>
    await import('./folder_checkmark_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderIcon = lazy(
  async () =>
    await import('./folder.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAddPlusMediumIcon = lazy(
  async () =>
    await import('./folder_add_plus_medium.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCrossRemoveMediumIcon = lazy(
  async () =>
    await import('./folder_cross_remove_medium.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderUploadArrowUpIcon = lazy(
  async () =>
    await import('./folder_upload_arrow_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMoveArrowRightIcon = lazy(
  async () =>
    await import('./folder_move_arrow_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenStyleIcon = lazy(
  async () =>
    await import('./folder_open_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMorednStyleIcon = lazy(
  async () =>
    await import('./folder_moredn_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCopyIcon = lazy(
  async () =>
    await import('./folder_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCopyGroupIcon = lazy(
  async () =>
    await import('./folder_copy_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSignalShareIcon = lazy(
  async () =>
    await import('./folder_signal_share.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFilterLeftIcon = lazy(
  async () =>
    await import('./folder_filter_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderTextDocumentIcon = lazy(
  async () =>
    await import('./folder_text_document.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSyncArrowsIcon = lazy(
  async () =>
    await import('./folder_sync_arrows.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCrossDeleteIcon = lazy(
  async () =>
    await import('./folder_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderPasswordIcon = lazy(
  async () =>
    await import('./folder_password.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDocuementCheckmarkIcon = lazy(
  async () =>
    await import('./folder_docuement_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenMoreIcon = lazy(
  async () =>
    await import('./folder_open_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenDocumentIcon = lazy(
  async () =>
    await import('./folder_open_document.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigCheckmarkSquareIcon = lazy(
  async () =>
    await import('./folder_big_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBrowserIcon = lazy(
  async () =>
    await import('./folder_browser.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCrossDeleteRemoveGroupIcon = lazy(
  async () =>
    await import('./folder_cross_delete_remove_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FilesCopyMoveAddPlusIcon = lazy(
  async () =>
    await import('./files_copy_move_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCrossRemoveDeleteLeftIcon = lazy(
  async () =>
    await import('./folder_cross_remove_delete_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileCopyIcon = lazy(
  async () =>
    await import('./folder_file_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSendCircleIcon = lazy(
  async () =>
    await import('./folder_send_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersBigSmallIcon = lazy(
  async () =>
    await import('./folders_big_small.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpevIcon = lazy(
  async () =>
    await import('./folder_opev.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMinimizeArrowSquareIcon = lazy(
  async () =>
    await import('./folder_minimize_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMobileLeftBottomIcon = lazy(
  async () =>
    await import('./folder_mobile_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderObjectCheckmarkIcon = lazy(
  async () =>
    await import('./folder_object_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderHeartLeftIcon = lazy(
  async () =>
    await import('./folder_heart_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigZipIcon = lazy(
  async () =>
    await import('./folder_big_zip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDocuementAddPlusIcon = lazy(
  async () =>
    await import('./folder_docuement_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenBigCrossRemoveDeleteIcon = lazy(
  async () =>
    await import('./folder_open_big_cross_remove_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMoveIcon = lazy(
  async () =>
    await import('./folder_move.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderStarsShineLeftBottomIcon = lazy(
  async () =>
    await import('./folder_stars_shine_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderImageIcon = lazy(
  async () =>
    await import('./folder_image.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDocuemtnTextRightIcon = lazy(
  async () =>
    await import('./folder_docuemtn_text_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAddPlusLeftIcon = lazy(
  async () =>
    await import('./folder_add_plus_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigShieldIcon = lazy(
  async () =>
    await import('./folder_big_shield.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigKeyIcon = lazy(
  async () =>
    await import('./folder_big_key.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersCopyCrossDeleteRemoveIcon = lazy(
  async () =>
    await import('./folders_copy_cross_delete_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderPinLeftBottomIcon = lazy(
  async () =>
    await import('./folder_pin_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSearchIcon = lazy(
  async () =>
    await import('./folder_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigUploadArrowIcon = lazy(
  async () =>
    await import('./folder_big_upload_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigCutIcon = lazy(
  async () =>
    await import('./folder_big_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSelectIcon = lazy(
  async () =>
    await import('./folder_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenIcon = lazy(
  async () =>
    await import('./folder_open.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderKeyProtectionLeftBottomIcon = lazy(
  async () =>
    await import('./folder_key_protection_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderLineUpIcon = lazy(
  async () =>
    await import('./folder_line_up.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderListIcon = lazy(
  async () =>
    await import('./folder_list.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSendLaptopFastMoveIcon = lazy(
  async () =>
    await import('./folder_send_laptop_fast_move.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBackArrowStyleIcon = lazy(
  async () =>
    await import('./folder_back_arrow_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMoveRightCenterIcon = lazy(
  async () =>
    await import('./folder_move_right_center.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderTextIcon = lazy(
  async () =>
    await import('./folder_text.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersCopyStyleIcon = lazy(
  async () =>
    await import('./folders_copy_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCopyCrossDeleteIcon = lazy(
  async () =>
    await import('./folder_copy_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigPlusAddIcon = lazy(
  async () =>
    await import('./folder_big_plus_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupDeliteIcon = lazy(
  async () =>
    await import('./folder_group_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDocuementRemoveCrossIcon = lazy(
  async () =>
    await import('./folder_docuement_remove_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenBigIcon = lazy(
  async () =>
    await import('./folder_open_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderModernStyleAddPlusIcon = lazy(
  async () =>
    await import('./folder_modern_style_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderModernStyleIcon = lazy(
  async () =>
    await import('./folder_modern_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersIcon = lazy(
  async () =>
    await import('./folders.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSynchronizeIcon = lazy(
  async () =>
    await import('./folder_synchronize.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderLockIcon = lazy(
  async () =>
    await import('./folder_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderTextCopyIcon = lazy(
  async () =>
    await import('./folder_text_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderStarLeftIcon = lazy(
  async () =>
    await import('./folder_star_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSeatchRightIcon = lazy(
  async () =>
    await import('./folder_seatch_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCopyMoveIcon = lazy(
  async () =>
    await import('./folder_copy_move.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAttowRightStyleIcon = lazy(
  async () =>
    await import('./folder_attow_right_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderTimeIcon = lazy(
  async () =>
    await import('./folder_time.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSyncIcon = lazy(
  async () =>
    await import('./folder_sync.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderModernStyleCheckmarkvIcon = lazy(
  async () =>
    await import('./folder_modern_style_checkmarkv.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCrossRemoveDeleteSquareIcon = lazy(
  async () =>
    await import('./folder_cross_remove_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersCopyPlusIcon = lazy(
  async () =>
    await import('./folders_copy_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersSyncMoveCopyIcon = lazy(
  async () =>
    await import('./folders_sync_move_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCursorClickIcon = lazy(
  async () =>
    await import('./folder_cursor_click.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderChatMessageIcon = lazy(
  async () =>
    await import('./folder_chat_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderPinGroupIcon = lazy(
  async () =>
    await import('./folder_pin_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCheckMarkIcon = lazy(
  async () =>
    await import('./folder_check_mark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupPdfIcon = lazy(
  async () =>
    await import('./folder_group_pdf.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderLinkIcon = lazy(
  async () =>
    await import('./folder_link.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMorednStyleLineIcon = lazy(
  async () =>
    await import('./folder_moredn_style_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderIteemIcon = lazy(
  async () =>
    await import('./folder_iteem.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderArchiveBoxIcon = lazy(
  async () =>
    await import('./folder_archive_box.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderItemCopyIcon = lazy(
  async () =>
    await import('./folder_item_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDocumentGroupIcon = lazy(
  async () =>
    await import('./folder_document_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderArchiveCopyIcon = lazy(
  async () =>
    await import('./folder_archive_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDeliteIcon = lazy(
  async () =>
    await import('./folder_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupArrowIcon = lazy(
  async () =>
    await import('./folder_group_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAddPlusCircleIcon = lazy(
  async () =>
    await import('./folder_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigDotsMoreIcon = lazy(
  async () =>
    await import('./folder_big_dots_more.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigModernIcon = lazy(
  async () =>
    await import('./folder_big_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderUserDeliteIcon = lazy(
  async () =>
    await import('./folder_user_delite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileMinimalIcon = lazy(
  async () =>
    await import('./folder_file_minimal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderModernStyleCrossRemoveIcon = lazy(
  async () =>
    await import('./folder_modern_style_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCalendarLeftIcon = lazy(
  async () =>
    await import('./folder_calendar_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupMinusIcon = lazy(
  async () =>
    await import('./folder_group_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCheckmarkIcon = lazy(
  async () =>
    await import('./folder_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigStyleIcon = lazy(
  async () =>
    await import('./folder_big_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAddPlusSquareIcon = lazy(
  async () =>
    await import('./folder_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEditRenameIcon = lazy(
  async () =>
    await import('./folder_edit_rename.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigSendMonitorIcon = lazy(
  async () =>
    await import('./folder_big_send_monitor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersMoveCopySyncIcon = lazy(
  async () =>
    await import('./folders_move_copy_sync.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEditCopyIcon = lazy(
  async () =>
    await import('./folder_edit_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LaptopFolderIcon = lazy(
  async () =>
    await import('./laptop_folder.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigHandCursorIcon = lazy(
  async () =>
    await import('./folder_big_hand_cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCopyCheckmarkIcon = lazy(
  async () =>
    await import('./folder_copy_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCopySelectIcon = lazy(
  async () =>
    await import('./folder_copy_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAlarmWarningIcon = lazy(
  async () =>
    await import('./folder_alarm_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSendCopySquareIcon = lazy(
  async () =>
    await import('./folder_send_copy_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDeleteIcon = lazy(
  async () =>
    await import('./folder_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMobileSendMoveFastIcon = lazy(
  async () =>
    await import('./folder_mobile_send_move_fast.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSmallGroupIcon = lazy(
  async () =>
    await import('./folder_small_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAddPlusGroupIcon = lazy(
  async () =>
    await import('./folder_add_plus_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileIcon = lazy(
  async () =>
    await import('./folder_file.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderAddPlusStyleIcon = lazy(
  async () =>
    await import('./folder_add_plus_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigEditPenIcon = lazy(
  async () =>
    await import('./folder_big_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigClipIcon = lazy(
  async () =>
    await import('./folder_big_clip.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderHandGroupIcon = lazy(
  async () =>
    await import('./folder_hand_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSendCalendarIcon = lazy(
  async () =>
    await import('./folder_send_calendar.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DisplayFolderIcon = lazy(
  async () =>
    await import('./display_folder.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersSelectIcon = lazy(
  async () =>
    await import('./folders_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CalendarFolderIcon = lazy(
  async () =>
    await import('./calendar_folder.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMessageGroupIcon = lazy(
  async () =>
    await import('./folder_message_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFilesDocumentsPaperIcon = lazy(
  async () =>
    await import('./folder_files_documents_paper.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersMoreDotsIcon = lazy(
  async () =>
    await import('./folders_more_dots.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderKeyIcon = lazy(
  async () =>
    await import('./folder_key.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderPlusCircleIcon = lazy(
  async () =>
    await import('./folder_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderUploadArrowUpCenterIcon = lazy(
  async () =>
    await import('./folder_upload_arrow_up_center.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderHeartFavoriteIcon = lazy(
  async () =>
    await import('./folder_heart_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigDocumentIcon = lazy(
  async () =>
    await import('./folder_big_document.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDownloadArrowDownCenterIcon = lazy(
  async () =>
    await import('./folder_download_arrow_down_center.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersSmallFolderIcon = lazy(
  async () =>
    await import('./folders_small_folder.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderTreeConnectIcon = lazy(
  async () =>
    await import('./folder_tree_connect.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderChatMessageCopyIcon = lazy(
  async () =>
    await import('./folder_chat_message_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileCopyCopyIcon = lazy(
  async () =>
    await import('./folder_file_copy_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBrowserLeftBottomIcon = lazy(
  async () =>
    await import('./folder_browser_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSearchRightUpGroupIcon = lazy(
  async () =>
    await import('./folder_search_right_up_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDottedLineIcon = lazy(
  async () =>
    await import('./folder_dotted_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCursorClickCopyIcon = lazy(
  async () =>
    await import('./folder_cursor_click_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupLockIcon = lazy(
  async () =>
    await import('./folder_group_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCheckmarkLeftIcon = lazy(
  async () =>
    await import('./folder_checkmark_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileItmeIcon = lazy(
  async () =>
    await import('./folder_file_itme.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderKeyholeLockIcon = lazy(
  async () =>
    await import('./folder_keyhole_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersStyleIcon = lazy(
  async () =>
    await import('./folders_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBookmarkIcon = lazy(
  async () =>
    await import('./folder_bookmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMoveLeftCenterIcon = lazy(
  async () =>
    await import('./folder_move_left_center.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMenuIcon = lazy(
  async () =>
    await import('./folder_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBackUnderArrowIcon = lazy(
  async () =>
    await import('./folder_back_under_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMessageChatIcon = lazy(
  async () =>
    await import('./folder_message_chat.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupCopyIcon = lazy(
  async () =>
    await import('./folder_group_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSettingLeftDownIcon = lazy(
  async () =>
    await import('./folder_setting_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSettingIcon = lazy(
  async () =>
    await import('./folder_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderArchiveIcon = lazy(
  async () =>
    await import('./folder_archive.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigEyeIcon = lazy(
  async () =>
    await import('./folder_big_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileLeftBottomIcon = lazy(
  async () =>
    await import('./folder_file_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenBigPlusIcon = lazy(
  async () =>
    await import('./folder_open_big_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderPaswordIcon = lazy(
  async () =>
    await import('./folder_pasword.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigIcon = lazy(
  async () =>
    await import('./folder_big.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersAddPlusIcon = lazy(
  async () =>
    await import('./folders_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCursorCircleIcon = lazy(
  async () =>
    await import('./folder_cursor_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigMoveLeftArrowIcon = lazy(
  async () =>
    await import('./folder_big_move_left_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigLineIcon = lazy(
  async () =>
    await import('./folder_big_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderGroupSyncIcon = lazy(
  async () =>
    await import('./folder_group_sync.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderTasklistIcon = lazy(
  async () =>
    await import('./folder_tasklist.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderNotesIcon = lazy(
  async () =>
    await import('./folder_notes.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCrossDeleteCircleIcon = lazy(
  async () =>
    await import('./folder_cross_delete_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEyeIcon = lazy(
  async () =>
    await import('./folder_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenFileIcon = lazy(
  async () =>
    await import('./folder_open_file.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileTextGroupIcon = lazy(
  async () =>
    await import('./folder_file_text_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersGroupCopyIcon = lazy(
  async () =>
    await import('./folders_group_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSettingHorizontalLeftIcon = lazy(
  async () =>
    await import('./folder_setting_horizontal_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSearchLeftDownIcon = lazy(
  async () =>
    await import('./folder_search_left_down.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderShieldCheckmarkIcon = lazy(
  async () =>
    await import('./folder_shield_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderSettingLeftIcon = lazy(
  async () =>
    await import('./folder_setting_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderShieldLeftIcon = lazy(
  async () =>
    await import('./folder_shield_left.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderUserGroupIcon = lazy(
  async () =>
    await import('./folder_user_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderNotesCopyIcon = lazy(
  async () =>
    await import('./folder_notes_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderWarningIcon = lazy(
  async () =>
    await import('./folder_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEnvelopeIcon = lazy(
  async () =>
    await import('./folder_envelope.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FloderTasklistRightIcon = lazy(
  async () =>
    await import('./floder_tasklist_right.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigCrossRemoveIcon = lazy(
  async () =>
    await import('./folder_big_cross_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenCheckmarkIcon = lazy(
  async () =>
    await import('./folder_open_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigDocumentLineIcon = lazy(
  async () =>
    await import('./folder_big_document_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MobileFolderRightBottomIcon = lazy(
  async () =>
    await import('./mobile_folder_right_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigCheckmarkIcon = lazy(
  async () =>
    await import('./folder_big_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderScaleUpLeftBottomIcon = lazy(
  async () =>
    await import('./folder_scale_up_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFingerprintIcon = lazy(
  async () =>
    await import('./folder_fingerprint.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCheckmarkStyleIcon = lazy(
  async () =>
    await import('./folder_checkmark_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMoveLeftBottomIcon = lazy(
  async () =>
    await import('./folder_move_left_bottom.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCrossRemoveStyleIcon = lazy(
  async () =>
    await import('./folder_cross_remove_style.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderStopOctagonIcon = lazy(
  async () =>
    await import('./folder_stop_octagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDeleteCrossIcon = lazy(
  async () =>
    await import('./folder_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderEditPenCircleIcon = lazy(
  async () =>
    await import('./folder_edit_pen_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDottedLineCutIcon = lazy(
  async () =>
    await import('./folder_dotted_line_cut.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderShareArrowIcon = lazy(
  async () =>
    await import('./folder_share_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderMorednIcon = lazy(
  async () =>
    await import('./folder_moredn.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigDownloadIcon = lazy(
  async () =>
    await import('./folder_big_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderBigCropLineIcon = lazy(
  async () =>
    await import('./folder_big_crop_line.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderFileDocumentTextGroupIcon = lazy(
  async () =>
    await import('./folder_file_document_text_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FoldersCopyMinusDeleteIcon = lazy(
  async () =>
    await import('./folders_copy_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderOpenDocumentBlankIcon = lazy(
  async () =>
    await import('./folder_open_document_blank.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderCheckmarkMediumIcon = lazy(
  async () =>
    await import('./folder_checkmark_medium.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FolderDottedLineCopyIcon = lazy(
  async () =>
    await import('./folder_dotted_line_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);

export const Folders = {
  BlankFolderIcon,
  FolderBlankIcon,
  FolderEditIcon,
  FolderDownloadArrowIcon,
  FolderGroupIcon,
  FoldersBigCopyIcon,
  FoldersCrossRemoveIcon,
  FolderReadCheckmarkIcon,
  FolderDocumentBlankLeftIcon,
  FolderMinusDeliteIcon,
  FolderFileSmallIcon,
  FoldersCheckmarkIcon,
  FolderBigBookmarkIcon,
  FolderEditPenSquareIcon,
  FolderMoveCopyCheckmarkIcon,
  FolderNotificationSquareIcon,
  FolderEditcircleIcon,
  FolderFileGroupIcon,
  FolderGroupPlusAddIcon,
  FolderCheckmarkCopyIcon,
  FolderIcon,
  FolderAddPlusMediumIcon,
  FolderCrossRemoveMediumIcon,
  FolderUploadArrowUpIcon,
  FolderMoveArrowRightIcon,
  FolderOpenStyleIcon,
  FolderMorednStyleIcon,
  FolderCopyIcon,
  FolderCopyGroupIcon,
  FolderSignalShareIcon,
  FolderFilterLeftIcon,
  FolderTextDocumentIcon,
  FolderSyncArrowsIcon,
  FolderCrossDeleteIcon,
  FolderPasswordIcon,
  FolderDocuementCheckmarkIcon,
  FolderOpenMoreIcon,
  FolderOpenDocumentIcon,
  FolderBigCheckmarkSquareIcon,
  FolderBrowserIcon,
  FolderCrossDeleteRemoveGroupIcon,
  FilesCopyMoveAddPlusIcon,
  FolderCrossRemoveDeleteLeftIcon,
  FolderFileCopyIcon,
  FolderSendCircleIcon,
  FoldersBigSmallIcon,
  FolderOpevIcon,
  FolderMinimizeArrowSquareIcon,
  FolderMobileLeftBottomIcon,
  FolderObjectCheckmarkIcon,
  FolderHeartLeftIcon,
  FolderBigZipIcon,
  FolderDocuementAddPlusIcon,
  FolderOpenBigCrossRemoveDeleteIcon,
  FolderMoveIcon,
  FolderStarsShineLeftBottomIcon,
  FolderImageIcon,
  FolderDocuemtnTextRightIcon,
  FolderAddPlusLeftIcon,
  FolderBigShieldIcon,
  FolderBigKeyIcon,
  FoldersCopyCrossDeleteRemoveIcon,
  FolderPinLeftBottomIcon,
  FolderSearchIcon,
  FolderBigUploadArrowIcon,
  FolderBigCutIcon,
  FolderSelectIcon,
  FolderOpenIcon,
  FolderKeyProtectionLeftBottomIcon,
  FolderLineUpIcon,
  FolderListIcon,
  FolderSendLaptopFastMoveIcon,
  FolderBackArrowStyleIcon,
  FolderMoveRightCenterIcon,
  FolderTextIcon,
  FoldersCopyStyleIcon,
  FolderCopyCrossDeleteIcon,
  FolderBigPlusAddIcon,
  FolderGroupDeliteIcon,
  FolderDocuementRemoveCrossIcon,
  FolderOpenBigIcon,
  FolderModernStyleAddPlusIcon,
  FolderModernStyleIcon,
  FoldersIcon,
  FolderSynchronizeIcon,
  FolderLockIcon,
  FolderTextCopyIcon,
  FolderStarLeftIcon,
  FolderSeatchRightIcon,
  FolderCopyMoveIcon,
  FolderAttowRightStyleIcon,
  FolderTimeIcon,
  FolderSyncIcon,
  FolderModernStyleCheckmarkvIcon,
  FolderCrossRemoveDeleteSquareIcon,
  FoldersCopyPlusIcon,
  FoldersSyncMoveCopyIcon,
  FolderCursorClickIcon,
  FolderChatMessageIcon,
  FolderPinGroupIcon,
  FolderCheckMarkIcon,
  FolderGroupPdfIcon,
  FolderLinkIcon,
  FolderMorednStyleLineIcon,
  FolderIteemIcon,
  FolderArchiveBoxIcon,
  FolderItemCopyIcon,
  FolderDocumentGroupIcon,
  FolderArchiveCopyIcon,
  FolderDeliteIcon,
  FolderGroupArrowIcon,
  FolderAddPlusCircleIcon,
  FolderBigDotsMoreIcon,
  FolderBigModernIcon,
  FolderUserDeliteIcon,
  FolderFileMinimalIcon,
  FolderModernStyleCrossRemoveIcon,
  FolderCalendarLeftIcon,
  FolderGroupMinusIcon,
  FolderCheckmarkIcon,
  FolderBigStyleIcon,
  FolderAddPlusSquareIcon,
  FolderEditRenameIcon,
  FolderBigSendMonitorIcon,
  FoldersMoveCopySyncIcon,
  FolderEditCopyIcon,
  LaptopFolderIcon,
  FolderBigHandCursorIcon,
  FolderCopyCheckmarkIcon,
  FolderCopySelectIcon,
  FolderAlarmWarningIcon,
  FolderSendCopySquareIcon,
  FolderDeleteIcon,
  FolderMobileSendMoveFastIcon,
  FolderSmallGroupIcon,
  FolderAddPlusGroupIcon,
  FolderFileIcon,
  FolderAddPlusStyleIcon,
  FolderBigEditPenIcon,
  FolderBigClipIcon,
  FolderHandGroupIcon,
  FolderSendCalendarIcon,
  DisplayFolderIcon,
  FoldersSelectIcon,
  CalendarFolderIcon,
  FolderMessageGroupIcon,
  FolderFilesDocumentsPaperIcon,
  FoldersMoreDotsIcon,
  FolderKeyIcon,
  FolderPlusCircleIcon,
  FolderUploadArrowUpCenterIcon,
  FolderHeartFavoriteIcon,
  FolderBigDocumentIcon,
  FolderDownloadArrowDownCenterIcon,
  FoldersSmallFolderIcon,
  FolderTreeConnectIcon,
  FolderChatMessageCopyIcon,
  FolderFileCopyCopyIcon,
  FolderBrowserLeftBottomIcon,
  FolderSearchRightUpGroupIcon,
  FolderDottedLineIcon,
  FolderCursorClickCopyIcon,
  FolderGroupLockIcon,
  FolderCheckmarkLeftIcon,
  FolderFileItmeIcon,
  FolderKeyholeLockIcon,
  FoldersStyleIcon,
  FolderBookmarkIcon,
  FolderMoveLeftCenterIcon,
  FolderMenuIcon,
  FolderBackUnderArrowIcon,
  FolderMessageChatIcon,
  FolderGroupCopyIcon,
  FolderSettingLeftDownIcon,
  FolderSettingIcon,
  FolderArchiveIcon,
  FolderBigEyeIcon,
  FolderFileLeftBottomIcon,
  FolderOpenBigPlusIcon,
  FolderPaswordIcon,
  FolderBigIcon,
  FoldersAddPlusIcon,
  FolderCursorCircleIcon,
  FolderBigMoveLeftArrowIcon,
  FolderBigLineIcon,
  FolderGroupSyncIcon,
  FolderTasklistIcon,
  FolderNotesIcon,
  FolderCrossDeleteCircleIcon,
  FolderEyeIcon,
  FolderOpenFileIcon,
  FolderFileTextGroupIcon,
  FoldersGroupCopyIcon,
  FolderSettingHorizontalLeftIcon,
  FolderSearchLeftDownIcon,
  FolderShieldCheckmarkIcon,
  FolderSettingLeftIcon,
  FolderShieldLeftIcon,
  FolderUserGroupIcon,
  FolderNotesCopyIcon,
  FolderWarningIcon,
  FolderEnvelopeIcon,
  FloderTasklistRightIcon,
  FolderBigCrossRemoveIcon,
  FolderOpenCheckmarkIcon,
  FolderBigDocumentLineIcon,
  MobileFolderRightBottomIcon,
  FolderBigCheckmarkIcon,
  FolderScaleUpLeftBottomIcon,
  FolderFingerprintIcon,
  FolderCheckmarkStyleIcon,
  FolderMoveLeftBottomIcon,
  FolderCrossRemoveStyleIcon,
  FolderStopOctagonIcon,
  FolderDeleteCrossIcon,
  FolderEditPenCircleIcon,
  FolderDottedLineCutIcon,
  FolderShareArrowIcon,
  FolderMorednIcon,
  FolderBigDownloadIcon,
  FolderBigCropLineIcon,
  FolderFileDocumentTextGroupIcon,
  FoldersCopyMinusDeleteIcon,
  FolderOpenDocumentBlankIcon,
  FolderCheckmarkMediumIcon,
  FolderDottedLineCopyIcon
};
