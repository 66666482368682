import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  margin-left: ${tokens.spacingInsetHuge};

  img {
    width: 36rem;
    height: auto;
    margin-top: -5rem;
    margin-bottom: 1rem;
  }
`;
