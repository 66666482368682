import { darken, lighten, transparentize } from 'polished';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type RectButtonStylesType } from './RectButton.types';

export const ContainerIcon = styled.span`
  height: 100%;
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.black)};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerContent = styled.span`
  width: 100%;
  text-align: center;
`;

export const Container = styled.button<AddPrefixToType<RectButtonStylesType>>`
  width: 100%;
  height: ${({ theme, $size }) => theme.buttons.rectButtonSizes[$size].height};
  background: ${({ theme, $color }) => theme.colors[$color]};
  color: ${({ theme }) => theme.colors.white};
  border: 0;
  display: flex;
  align-items: center;
  font-weight: ${({ $fontWeight }) => $fontWeight};
  transition: all 0.3s ease-in;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover:enabled {
    background: ${({ theme, $color }) => lighten(0.04, theme.colors[$color])};
  }

  ${ContainerContent} {
    padding: ${({ theme, $size }) =>
      theme.buttons.rectButtonSizes[$size].padding};
    font-size: ${({ theme, $size }) =>
      theme.buttons.rectButtonSizes[$size].fontSize};
  }

  ${ContainerIcon} {
    padding: ${({ theme, $size }) =>
      theme.buttons.rectButtonSizes[$size].padding};
  }

  ${({ theme, $variation, $color }) =>
    $variation === 'outlined' &&
    css`
      background: transparent;
      color: ${theme.colors[$color]};
      border: 1px solid ${theme.colors[$color]};

      &:hover:enabled {
        background: ${theme.colors[$color]};
        color: ${theme.colors.white};
        border: 1px solid transparent;
      }
    `}

  ${({ theme, $variation, $color }) =>
    $variation === 'borderless' &&
    css`
      background: none;
      color: ${theme.colors[$color]};

      &:hover:enabled {
        background: transparent;
      }

      ${ContainerContent} {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        &:hover {
          color: ${darken(0.1, theme.colors[$color])};
        }
      }
    `}
`;

export const StyledLink = styled(Link)`
  width: 100%;
`;
