export const rectButtonSizes: Record<
  'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl',
  {
    height: string;
    padding: string;
    fontSize: string;
  }
> = {
  xxs: {
    height: '1.9rem',
    padding: '0 0.5rem',
    fontSize: '1.2rem'
  },
  xs: {
    height: '2.1rem',
    padding: '0 0.5rem',
    fontSize: '1.2rem'
  },
  sm: {
    height: '2.6rem',
    padding: '0.3rem 1rem',
    fontSize: '1.3rem'
  },
  md: {
    height: '2.7rem',
    padding: '0 1rem',
    fontSize: '1.2rem'
  },
  lg: {
    height: '2.9rem',
    padding: '0.6rem 1.2rem',
    fontSize: '1.2rem'
  },
  xl: {
    height: '3.1rem',
    padding: '0.6rem 1.2rem',
    fontSize: '1.2rem'
  },
  xxl: {
    height: '3.5rem',
    padding: '0.6rem 1.2rem',
    fontSize: '1.5rem'
  },
  xxxl: {
    height: '4rem',
    padding: '0 1rem',
    fontSize: '1.4rem'
  }
};
