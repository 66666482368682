import { type ISidebarSectionProps } from './SidebarSection.types';

import { Container } from './SidebarSection.styles';

export function SidebarSection({
  title = '',
  children,
  isCollapsed = true
}: ISidebarSectionProps): JSX.Element {
  return (
    <Container $isCollapsed={isCollapsed}>
      {title && <h2>{title}</h2>}
      {children}
    </Container>
  );
}
