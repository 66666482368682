import { useTheme } from 'styled-components';

import { icons } from './constants/icons.constant';

import { type IBaseIconProps } from './BaseIcon.types';

export function BaseIcon({
  name,
  type,
  size,
  color
}: IBaseIconProps): JSX.Element {
  const theme = useTheme();

  return icons[name]({
    type,
    size,
    color:
      color !== undefined && color !== 'inherit' ? theme.colors[color] : color
  });
}
