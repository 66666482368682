import { lazy, Suspense } from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import {
  createBrowserRouter,
  Navigate,
  Route,
  RouterProvider,
  Routes
} from 'react-router-dom';

import { Redirect } from 'presentation/components/global/Redirect';
import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

import { routesPathPrefix } from 'shared/constants/global';
import { AuthMiddleware, FlagMiddleware } from 'shared/middlewares';

import { AuthTest } from './AuthTest';

const AdminRoutes = lazy(async () => await import('./admin.routes'));
const StoreRoutes = lazy(async () => await import('./store.routes'));
const CardsRoutes = lazy(async () => await import('./cards.routes'));
const TravelRoutes = lazy(async () => await import('./travel.routes'));
const SupportRoutes = lazy(async () => await import('./support.routes'));
const ReportsRoutes = lazy(async () => await import('./reports.routes'));
const SettingsRoutes = lazy(async () => await import('./settings.routes'));
const ExpensesRoutes = lazy(async () => await import('./expenses.routes'));
const AnalyticsRoutes = lazy(async () => await import('./analytics.routes'));

function Root(): JSX.Element {
  function getRoutePathPrefix(routeKey: keyof typeof routesPathPrefix): string {
    return `${routesPathPrefix[routeKey]}/*`;
  }

  return (
    <ErrorBoundary FallbackComponent={() => <FallbackPage error='DEFAULT' />}>
      <Suspense fallback={<SplashScreen />}>
        <Routes>
          <Route
            path={getRoutePathPrefix('cards')}
            element={<CardsRoutes />}
          />

          <Route
            path={getRoutePathPrefix('expenses')}
            element={<ExpensesRoutes />}
          />

          <Route
            path={getRoutePathPrefix('reports')}
            element={<ReportsRoutes />}
          />

          <Route
            path={getRoutePathPrefix('admin')}
            element={<AdminRoutes />}
          />

          <Route
            path={getRoutePathPrefix('travel')}
            element={<TravelRoutes />}
          />

          <Route
            path={getRoutePathPrefix('analytics')}
            element={<AnalyticsRoutes />}
          />

          <Route
            path={getRoutePathPrefix('settings')}
            element={<SettingsRoutes />}
          />

          <Route
            path={getRoutePathPrefix('store')}
            element={<StoreRoutes />}
          />

          <Route
            path={getRoutePathPrefix('support')}
            element={<SupportRoutes />}
          />

          {/* Testes de AUTH */}
          <Route element={<AuthMiddleware />}>
            <Route element={<FlagMiddleware />}>
              <Route
                path='/auth'
                element={<AuthTest />}
              />
            </Route>
          </Route>

          <Route
            path='webview/cartoes/*'
            element={<Navigate to='/cartoes/onboarding/usuario' />}
          />

          <Route
            path='*'
            element={<Redirect />}
          />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

export function Router(): JSX.Element {
  return <RouterProvider router={router} />;
}
