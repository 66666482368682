import { useQueryCache } from 'data/cache';
import { api } from 'data/config';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetAuthCardsCompany,
  type IUseGetAuthCardsCompanyParams
} from 'data/modules/auth';

import { CustomCookies } from 'shared/utils/global';

export function useGetAuthCardsCompany({
  enabled
}: IUseGetAuthCardsCompanyParams): IUseGetAuthCardsCompany {
  const {
    isFetching: isFetchingCardsCompany,
    data: cardsCompany,
    isError: cardsCompanyError
  } = useQueryCache({
    queryKey: [AuthQueryKeys.AUTH_CARDS_COMPANY],
    queryFn: async () => {
      const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';

      if (outsourcingUserUuid) {
        api.defaults.headers.common['X-Header-Outsourcing'] =
          outsourcingUserUuid;
      }

      return await AuthService.getAuthCardsCompany();
    },
    enabled,
    staleTime: Infinity
  });

  return {
    cardsCompany,
    cardsCompanyError,
    isFetchingCardsCompany
  };
}
