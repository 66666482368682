import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type CheckboxControlledStylesType } from './CheckboxControlled.types';
import { type AddPrefixToType } from 'ds/types';

export const CheckboxComponent = styled.div<
  AddPrefixToType<CheckboxControlledStylesType>
>`
  padding: 0.9rem;
  border-radius: ${tokens.borderRadiusNano};
  border: solid ${tokens.borderWidthHairline} ${tokens.neutralColorLowLightest};
  color: white;

  svg {
    display: none;
  }

  ${({ $small }) =>
    $small &&
    css`
      padding: 0.8rem;
    `}
`;

export const Container = styled.div<
  AddPrefixToType<CheckboxControlledStylesType>
>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: border 100ms ease-in-out;
  position: relative;
  cursor: pointer;
  user-select: none;
  gap: ${tokens.spacingInsetNano};
  padding: ${tokens.spacingNano};

  svg {
    width: 1rem;
    height: 1rem;
  }

  &:hover div {
    border-color: ${tokens.brandColorSecondaryPure};
  }

  input:checked + ${CheckboxComponent} {
    padding: 0.5rem;
    background-color: ${tokens.brandColorSecondaryPure};
    border: none;

    svg:first-of-type {
      display: block;
    }
  }

  input:indeterminate + ${CheckboxComponent} {
    background-color: ${tokens.brandColorSecondaryPure};
    padding: ${tokens.spacingQuarck};

    svg:last-of-type {
      display: block;
    }
  }

  ${({ $small }) =>
    $small &&
    css`
      div {
        padding: ${tokens.spacingNano};
      }

      span {
        font-size: ${tokens.fontSizeXxs};
      }

      input:checked + ${CheckboxComponent} {
        padding: 0.4rem;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;

      div {
        border-color: ${tokens.neutralColorLowLightest};
      }

      span {
        color: ${tokens.neutralColorLowLightest};
      }

      &:hover {
        div {
          border-color: ${tokens.neutralColorLowLightest};
        }

        span {
          color: ${tokens.neutralColorLowLightest};
        }
      }

      input:checked + ${CheckboxComponent} {
        background-color: ${tokens.neutralColorLowLightest};

        svg:first-of-type {
          display: block;
        }

        span {
          color: ${tokens.neutralColorLowLightest};
        }
      }

      input:indeterminate + ${CheckboxComponent} {
        background-color: ${tokens.neutralColorLowLightest};

        svg:last-of-type {
          display: block;
        }

        span {
          color: ${tokens.neutralColorLowLightest};
        }
      }
    `};
`;

export const Label = styled.span`
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightRegular};
  line-height: ${tokens.spacingInsetMd};
`;

export const InputComponent = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
`;
