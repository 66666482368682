import Cookies from 'js-cookie';

class CustomCookies {
  getDomain(): string | undefined {
    // se for local, não precisa definir o domínio
    if (process.env.NODE_ENV === 'development') {
      return undefined;
    }

    const host = window.location.host;

    // remove o app. ou amp. do início do domínio
    const parts = host.split('.').slice(1);

    // junta o domínio novamente com o ponto inicial
    const domain = '.' + parts.join('.');

    return domain;
  }

  set(
    name: string,
    value: string,
    options?: {
      daysToExpire?: number;
      sharedDomain?: boolean;
    }
  ): void {
    Cookies.set(name, value, {
      expires: options?.daysToExpire,
      domain: options?.sharedDomain === false ? undefined : this.getDomain()
    });
  }

  remove(
    name: string,
    options?: {
      sharedDomain?: boolean;
    }
  ): void {
    Cookies.remove(name, {
      domain: options?.sharedDomain === false ? undefined : this.getDomain()
    });
  }

  get(name: string): string | undefined {
    return Cookies.get(name);
  }
}

export default new CustomCookies();
