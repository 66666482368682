import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type FileInputDefaultStylesType } from './FileInputDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<
  AddPrefixToType<FileInputDefaultStylesType>
>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.6rem;
  height: 6.4rem;
  min-width: 25rem;
  min-height: 4.1rem;
  max-height: 4.1rem;
  width: 100%;
  padding: ${tokens.spacingInsetXxs};
  border-radius: ${tokens.borderRadiusXs};
  border-color: ${tokens.neutralColorLowLight};
  border-width: ${tokens.borderWidthHairline};
  border-style: solid;
  cursor: pointer;
  position: relative;
  background-color: ${tokens.neutralColorHighPure};

  &:focus-within {
    background-color: ${tokens.neutralColorHighPure};
    cursor: pointer;

    span {
      color: ${tokens.neutralColorLowDarkest};
      opacity: 1;
    }

    input {
      color: ${tokens.neutralColorLowDarkest};
    }

    svg {
      opacity: 1;
    }
  }

  ${({ $error }) =>
    $error &&
    css`
      background-color: ${tokens.feedbackColorErrorLight};
      border-color: ${tokens.feedbackColorErrorPure};
      cursor: pointer;
    `}

  ${({ $underlined }) =>
    $underlined &&
    css`
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      border-color: ${tokens.neutralColorLowLight};
      border-radius: ${tokens.borderRadiusNone};
      cursor: pointer;
    `}

  ${({ $error, $underlined }) =>
    $error &&
    $underlined &&
    css`
      background-color: ${tokens.feedbackColorErrorLight};
      border-right: none;
      border-color: ${tokens.feedbackColorErrorPure};
      border-radius: ${tokens.borderRadiusNone};
      cursor: pointer;
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      background-color: ${tokens.neutralColorHighMedium};
      opacity: 0.5;
      pointer-events: none;
      cursor: default;
    `}

  span {
    color: ${tokens.neutralColorLowLight};
    font-size: ${tokens.fontSizeXxss};
    font-family: ${tokens.fontFamilyPoppins};
    cursor: pointer;
    ${({ $error }) =>
      $error &&
      css`
        color: ${tokens.feedbackColorErrorPure};
        cursor: pointer;
      `}
    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${tokens.neutralColorLowLightest};
        cursor: default;
      `}
  }
`;

export const Message = styled.div<AddPrefixToType<FileInputDefaultStylesType>>`
  color: ${tokens.neutralColorLowPure};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightMedium};
  font-size: ${tokens.fontSizeXxss};
  max-width: 30rem;
  margin-top: 0.5rem;
  margin-left: ${({ $underlined }) => ($underlined ? '0' : '0.3rem')};

  ${({ $error }) =>
    $error &&
    css`
      color: ${tokens.feedbackColorErrorPure};
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
    `}
`;

export const CustomFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
`;
