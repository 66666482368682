import { type IDefaultIconProps } from 'data/modules/global';

export function Trophy({ size, color, type }: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        width={size ? `${size}rem` : '1rem'}
        height={size ? `${size}rem` : '1rem'}
        fill='none'
        viewBox='0 0 28 28'
        xmlns='http://www.w3.org/2000/svg'
        id='trophy'
      >
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.33335 4.66667H18.6667C19.311 4.66667 19.8334 5.189 19.8334 5.83333V12.8333C19.8334 16.055 17.2217 18.6667 14 18.6667V18.6667C10.7784 18.6667 8.16669 16.055 8.16669 12.8333V5.83333C8.16669 5.189 8.68902 4.66667 9.33335 4.66667Z'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M19.4521 14.9101L21.6594 13.8064C23.0428 13.1148 23.9166 11.7009 23.9167 10.1542V8.59451C23.9167 8.03072 23.4596 7.57367 22.8958 7.57367H19.8333'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M14 23.3333V18.6667'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M18.6666 23.3333H9.33331'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M8.54788 14.9101L6.34054 13.8064C4.95718 13.1148 4.08333 11.7009 4.08331 10.1542V8.59451C4.08331 8.03072 4.54036 7.57367 5.10415 7.57367H8.16665'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
      </svg>
    );
  }

  return (
    <svg
      width={size ? `${size}rem` : '1rem'}
      height={size ? `${size}rem` : '1rem'}
      fill='none'
      viewBox='0 0 28 28'
      xmlns='http://www.w3.org/2000/svg'
      id='trophy'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.33335 4.66667H18.6667C19.311 4.66667 19.8334 5.189 19.8334 5.83333V12.8333C19.8334 16.055 17.2217 18.6667 14 18.6667V18.6667C10.7784 18.6667 8.16669 16.055 8.16669 12.8333V5.83333C8.16669 5.189 8.68902 4.66667 9.33335 4.66667Z'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
      <path
        d='M19.4521 14.9101L21.6594 13.8064C23.0428 13.1148 23.9166 11.7009 23.9167 10.1542V8.59451C23.9167 8.03072 23.4596 7.57367 22.8958 7.57367H19.8333'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
      <path
        d='M14 23.3333V18.6667'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
      <path
        d='M18.6666 23.3333H9.33331'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
      <path
        d='M8.54788 14.9101L6.34054 13.8064C4.95718 13.1148 4.08333 11.7009 4.08331 10.1542V8.59451C4.08331 8.03072 4.54036 7.57367 5.10415 7.57367H8.16665'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
    </svg>
  );
}
