import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type DefaultTableHeadCellStylesType } from './DefaultTableHeadCell.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.th<
  AddPrefixToType<DefaultTableHeadCellStylesType>
>`
  text-align: left;
  background-color: ${tokens.neutralColorHighMedium};
  padding: ${tokens.spacingXxs} ${tokens.spacingXs};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamiliesPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  border: none;
  transition: background-color 100ms ease-in-out;

  ${({ $width }) =>
    $width &&
    css`
      width: ${$width};
    `}

  ${({ $order }) =>
    $order &&
    css`
      cursor: pointer;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    `}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${tokens.spacingInsetNano};
  align-items: center;
`;
