import { tokens } from 'ds';
import styled, { css, keyframes } from 'styled-components';

import { type SpinnerContainerStyleType } from './Spinner.types';
import { type AddPrefixToType } from 'ds/types';

const spinnerRotate = keyframes`from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }`;

export const Container = styled.span<
  AddPrefixToType<SpinnerContainerStyleType>
>`
  display: block;
  position: relative;
  animation: ${spinnerRotate} 1s linear infinite;

  &::before {
    inset: 0;
    content: '';
    position: absolute;
    border-radius: 50%;
    background-image: ${`conic-gradient(from 180deg at 50% 50%, #ffffff00 0%, ${tokens.brandColorPrimaryMedium} 100%)`};
    mask: radial-gradient(farthest-side, transparent calc(100% - 6px), #fff 0);
  }

  ${({ $size }) =>
    $size === 'small'
      ? css`
          width: 2rem;
          height: 2rem;

          &::before {
            mask: radial-gradient(
              farthest-side,
              transparent calc(100% - 4px),
              #fff 0
            );
          }
        `
      : css`
          width: 3rem;
          height: 3rem;

          &::before {
            mask: radial-gradient(
              farthest-side,
              transparent calc(100% - 6px),
              #fff 0
            );
          }
        `}
`;
