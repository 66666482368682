import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { Container as CloseAlertContainer } from 'presentation/components/base/CloseAlert/CloseAlert.styles';

import { type DefaultLayoutStylesType } from './DefaultLayout.types';

export const DefaultContainer = styled.div`
  display: flex;
  align-items: stretch;
  box-sizing: border-box;
  min-height: 100vh;
`;

export const ContentContainer = styled.div<
  AddPrefixToType<DefaultLayoutStylesType>
>`
  transition: padding 200ms ease-in-out;
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 1048px) {
    > ${CloseAlertContainer} {
      display: none;
    }
  }

  @media screen and (min-width: 1370px) {
    ${({ $isCollapsed }) =>
      $isCollapsed
        ? css`
            padding-left: 8.6rem !important;
          `
        : css`
            padding-left: 27.2rem !important;
          `}
  }

  @media screen and (max-width: 1369px) {
    ${({ $isCollapsed }) =>
      $isCollapsed
        ? css`
            padding-left: 8.6rem;
          `
        : css`
            padding-left: 27.2rem;
          `}
  }

  @media screen and (max-width: 1200px) {
    padding: 0;
  }

  @media screen and (min-width: 1920px) {
    ${({ $isCollapsed }) =>
      $isCollapsed
        ? css`
            padding-left: 8.6rem !important;
          `
        : css`
            padding-left: 27.2rem !important;
          `}
  }
`;

export const Content = styled.div`
  overflow: auto;
  flex-grow: 1;
`;

export const ConfirmEmailButton = styled.button`
  background: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.ghostWhite};
  font-size: 1.44rem;
  line-height: 2rem;
  text-decoration: underline;
`;
