import { type IDropdownLabelProps } from './DropdownLabel.types';

import { Container } from './DropdownLabel.styles';

export function DropdownLabel({
  children,
  subLabel,
  ...rest
}: IDropdownLabelProps): JSX.Element {
  return (
    <Container {...rest}>
      {children} {subLabel && <span>{subLabel}</span>}
    </Container>
  );
}
