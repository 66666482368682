export enum ProductsTab {
  AEREO = 'AEREO',
  HOTEL = 'HOTEL'
}

export interface IProductSearchStates {
  activeTab: ProductsTab;
}

export interface IProductSearchContext extends IProductSearchStates {
  handleChangeActiveTab: (newTab: ProductsTab) => void;
}
