import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.strong`
  font-style: normal;
  line-height: 2.4rem;
  letter-spacing: 0.75px;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;
