import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type SidebarSectionStylesType } from './SidebarSection.types';

import { Container as SidebarItem } from 'src/presentation/components/global/Sidebar/SidebarItem/SidebarItem.styles';

export const Container = styled.div<AddPrefixToType<SidebarSectionStylesType>>`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 0.4rem;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden auto;
  justify-content: space-between;
  align-items: center;
  scroll-behavior: smooth;

  /* width */
  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(255 255 255 / 75%);
    border: 1px solid rgba(0 0 0 / 0%);
    background-clip: padding-box;
    border-radius: 999px;
  }

  h2 {
    margin-left: 1.6rem;
    margin-bottom: 1.2rem;
    text-transform: uppercase;
    font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  }

  ${({ $isCollapsed }) =>
    $isCollapsed &&
    css`
      /* > div {
        gap: 1.6rem !important;
      } */

      h2 {
        display: none;
      }

      ${SidebarItem} a {
        padding: 1.2rem;
      }

      ${SidebarItem}:hover {
        span {
          opacity: 1;
          visibility: 1;
          z-index: 100;
        }
      }

      @media screen and (max-height: 700px) {
        padding: 0 !important;
        padding-right: 0.5rem !important;
      }
    `}
`;
