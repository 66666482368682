import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type DefaultTableBodyCellStylesType } from './DefaultTableBodyCell.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.td<
  AddPrefixToType<DefaultTableBodyCellStylesType>
>`
  background-color: ${tokens.neutralColorHighPure};
  padding: 1rem ${tokens.spacingXs};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamiliesPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  letter-spacing: 0.025rem;
  white-space: nowrap;
  border: none;

  ${({ $truncate }) =>
    $truncate &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `}

  :has(input[type='checkbox']) {
    justify-content: start;
  }
`;
