import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type MenuTabContainerStylesType } from './MenuTabContainer.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.nav`
  width: 100%;
  background-color: transparent;
  position: relative;
`;

export const TabsList = styled.ul<AddPrefixToType<MenuTabContainerStylesType>>`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  list-style: none;
  gap: ${tokens.spacingLg};
  padding: 0 ${tokens.spacingXl};
  width: 100%;

  ${({ $spaced }) =>
    $spaced &&
    css`
      gap: unset;
      justify-content: space-between;
    `}

  ${({ $hasTrack }) =>
    $hasTrack &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: -0.3rem;
        left: 0;
        height: 0.4rem;
        width: 100%;
        background-color: ${tokens.neutralColorHighDark};
      }
    `}
`;
