import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.button`
  border: none;
  text-align: left;
  font-style: normal;
  letter-spacing: 0.25px;
  background: transparent;
  padding: ${tokens.spacingNano};
  font-size: ${tokens.fontSizeXxss};
  line-height: ${tokens.lineHeightXs};
  border-radius: ${tokens.borderRadiusXs};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${tokens.neutralColorHighLight};
  }
`;
