import { Spinner } from 'ds';
import { useTranslation } from 'react-i18next';

import { type INotificationHeaderProps } from './NotificationHeader.types';

import {
  Container,
  CounterTag,
  MarkAllAsReadButton,
  MarkAllAsReadWrapper,
  Title
} from './NotificationHeader.styles';

export function NotificationHeader({
  children,
  unreadCounter,
  isReadingAllNotifications,
  onReadAllNotificationsClick,
  ...props
}: INotificationHeaderProps): JSX.Element {
  const { t } = useTranslation('notifications');

  return (
    <Container {...props}>
      <Title>{t('notificationTitle')}</Title>
      {unreadCounter > 0 && (
        <CounterTag>
          {t('unread', {
            count: unreadCounter,
            counter: unreadCounter
          })}
        </CounterTag>
      )}

      <MarkAllAsReadWrapper>
        {isReadingAllNotifications && <Spinner size='small' />}
        <MarkAllAsReadButton
          $disabled={!unreadCounter}
          onClick={onReadAllNotificationsClick}
        >
          {t('markAllAsReadLabel')}
        </MarkAllAsReadButton>
      </MarkAllAsReadWrapper>
    </Container>
  );
}
