import { useDsGa4 } from 'ds/hooks/globals';
import { DSIcons } from 'ds/icons';
import { toast } from 'react-toastify';

import { toastVariants } from './constants/toastVariants';

import { type IToastProps } from './Toast.types';

import {
  CloseIconContainer,
  Container,
  ContentContainer,
  Description,
  Footer,
  Text,
  ToastIconContainer,
  Wrapper
} from './Toast.styles';

export function Toast({
  message,
  description,
  footer,
  onToastClick,
  variant = 'default',
  dismissAfterClicked = true
}: IToastProps): JSX.Element {
  const Icon = toastVariants[variant].icon;

  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      $variant={variant}
      className={variant === 'notification' ? 'notification' : ''}
      onClick={e => {
        onToastClick?.();
        if (!dismissAfterClicked) e.stopPropagation();

        sendDsGaEvent('components', 'snackbar', {
          eventLabel: 'open_snackbar',
          description: 'click na snackbar'
        });
      }}
    >
      <ContentContainer>
        {Icon && (
          <ToastIconContainer>
            <Icon />
          </ToastIconContainer>
        )}
        <Wrapper>
          <Text>{message}</Text>
          {description && <Description>{description}</Description>}
          {footer && <Footer>{footer}</Footer>}
        </Wrapper>
      </ContentContainer>

      <CloseIconContainer>
        <DSIcons.CloseLargerIcon
          onClick={e => {
            toast.dismiss();

            e.stopPropagation();

            sendDsGaEvent('components', 'snackbar');
          }}
        />
      </CloseIconContainer>
    </Container>
  );
}
