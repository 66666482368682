import { Fragment } from 'react';

import { CustomDate } from 'shared/utils/custom';

import { type INotificationDateProps } from './NotificationDate.types';

import { Container } from './NotificationDate.styles';

export function NotificationDate({
  children,
  ...props
}: INotificationDateProps): JSX.Element {
  const date = new Date(children);

  if (date.toString() === 'Invalid Date') {
    return <Fragment />;
  }

  return (
    <Container {...props}>
      {CustomDate.formatFromISO(CustomDate.formatDateToIso(date))}
    </Container>
  );
}
