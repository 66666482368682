import {
  type IHotel,
  type IHotelRoom
} from 'data/modules/travel/productSearch';

interface IHotelSearchFilters {
  destination?: string;
  checkIn?: string;
  checkOut?: string;
  breakfastIncluded?: boolean;
}

export enum HotelCategory {
  ONE_STAR = 1,
  TWO_STARS,
  THREE_STARS,
  FOUR_STARS,
  FIVE_STARS
}

export enum HotelOrderBy {
  LOWER_PRICE = 'LOWER_PRICE',
  HIGHER_PRICE = 'HIGHER_PRICE',
  WITHIN_POLICY = 'WITHIN_POLICY',
  CATEGORY = 'CATEGORY'
}

export interface IHotelResultFilters {
  withinPolicy?: boolean;
  minPrice?: number | null;
  maxPrice?: number | null;
  categories?: {
    one?: boolean;
    two?: boolean;
    three?: boolean;
    four?: boolean;
    five?: boolean;
  };
}

export interface ISelectOption {
  value: string;
  label: string;
}

export interface ISelectedHotelRoom {
  id: string;
  name: string;
  category: number | null;
  withBreakfast: boolean;
  cancelationDeadline: Date | null;
  rules: string | null;
  nightsAmount: number;
  unitPrice: number;
  taxesPrice: number;
  totalPrice: number;
  hotelCode: string;
  roomSignature: string;
}

export interface IHotelSearchStates {
  filters: IHotelSearchFilters;
  hotelResultFilters: IHotelResultFilters;
  destinationSelectedOption: ISelectOption;
  hotelOrderBy: HotelOrderBy;
  hotelList?: IHotel[];
  filteredAndSortedHotelList?: IHotel[];
  selectedHotel?: IHotel;
  hotelRoomsRecord?: Record<string, IHotelRoom>;
  selectedHotelRoom?: ISelectedHotelRoom;
}

export interface IHotelSearchContext extends IHotelSearchStates {
  handleChangeFilters: (filters: Partial<IHotelSearchFilters>) => void;
  handleApplyFilters: () => void;
  handleChangeHotelOrderBy: (hotelOrderBy: HotelOrderBy) => void;
  handleApplyHotelOrderBy: () => void;
  handleChangeHotelResultFilters: (
    filters: Partial<IHotelResultFilters>
  ) => void;
  handleChangeHotelList: (hotelList: IHotel[]) => void;
  handleClearHotelResultFilter: () => void;
  handleClearContext: () => void;
  handleChangeDestinationSelectedOption: (option: ISelectOption) => void;
  handleSelectHotel: (hotel: IHotel) => void;
  handleSelectHotelRoom: (hotelRoom: ISelectedHotelRoom) => void;
}
