import { tokens } from 'ds';
import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type GroupTitleStylesType,
  type SubItemsContainerStylesType
} from './SettingsSidebarGroup.types';

export const Container = styled.li`
  cursor: pointer;
`;

export const GroupTitle = styled.p<AddPrefixToType<GroupTitleStylesType>>`
  background: ${({ theme }) => theme.colors.alabaster};
  padding: 1rem 2.5rem 1rem 0.5rem;
  display: flex;
  align-items: center;

  span {
    display: block;
    padding: 0 0 0 ${({ $listLevel }) => ($listLevel ? 2.1 : 0.5)}rem;
    color: ${({ theme }) => theme.colors.mineShaft};
    font-size: 1.3rem;
    user-select: none;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.gallery};

    span {
      font-weight: 700;
    }
  }

  svg {
    transition: transform 0.7s;
    transform: ${({ $isOpen }) =>
      $isOpen ? 'rotate(0deg)' : 'rotate(-90deg)'};
  }
`;

export const CircleMark = styled.span`
  width: ${tokens.spacingNano};
  height: ${tokens.spacingNano};
  border-radius: 50%;
  background-color: #fc5555;
  margin-left: calc(${tokens.spacingNano} + 0.7rem);
`;

export const SubItemsContainer = styled.ul<
  AddPrefixToType<SubItemsContainerStylesType>
>`
  overflow: hidden;
  transition: all 0.7s ease-in-out;
  background: ${({ theme }) => theme.colors.alabaster};
  max-height: ${({ $isOpen, $maxHeight }) =>
    $isOpen ? `${$maxHeight}rem` : 0};
`;
