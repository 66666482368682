import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${tokens.deepColorWhite};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 6.4rem;
  gap: 14rem;

  img {
    width: 70rem;
    height: 52.5rem;
  }

  @media screen and (max-width: 1440px) {
    img {
      width: 50rem;
      height: 37.5rem;
    }
  }

  @media screen and (max-width: 834px) {
    flex-direction: column-reverse;
    gap: ${tokens.spacingInsetXxl};

    img {
      width: 40rem;
      height: 30rem;
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column-reverse;
    gap: ${tokens.spacingInsetXxl};

    img {
      width: 35rem;
      height: 25rem;
    }
  }

  @media screen and (max-width: 320px) {
    flex-direction: column-reverse;
    gap: ${tokens.spacingInsetXxl};

    img {
      width: 30rem;
      height: 20rem;
    }
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetMdd};
  width: 68rem;

  h1 {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeDisplay};
    color: ${tokens.neutralColorLowPure};
    line-height: 8.8rem;
    letter-spacing: 0.1rem;
  }

  h2 {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXl};
    color: ${tokens.neutralColorLowDark};
    line-height: 4.8rem;
    letter-spacing: 0.1rem;
    font-weight: ${tokens.fontWeightRegular};
  }

  p {
    font-family: ${tokens.fontFamilyPoppins};
    font-size: ${tokens.fontSizeXxss};
    color: ${tokens.neutralColorLowDark};
    line-height: 2.2rem;
    letter-spacing: 0.025rem;
    font-weight: ${tokens.fontWeightMedium};
  }

  button {
    margin-top: ${tokens.spacingInsetMdd};
    width: fit-content;
    min-width: 20rem;
  }

  @media screen and (max-width: 1440px) {
    h1 {
      font-size: ${tokens.fontSizeXxxl};
      line-height: 7.2rem;
    }

    h2 {
      font-size: ${tokens.fontSizeMd};
    }
  }

  @media screen and (max-width: 834px) {
    align-items: center;
    width: 40rem;

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }

  @media screen and (max-width: 320px) {
    align-items: center;
    width: 30rem;

    h2 {
      text-align: center;
    }

    p {
      text-align: center;
    }
  }
`;
