export const tokens = {
  /** 0 */
  borderRadiusNone: 0,

  /** '0.4rem' */
  borderRadiusNano: '0.4rem',

  /** '0.8rem' */
  borderRadiusXs: '0.8rem',

  /** '1.2rem' */
  borderRadiusSm: '1.2rem',

  /** '1.6rem' */
  borderRadiusMd: '1.6rem',

  /** '2rem' */
  borderRadiusLg: '2rem',

  /** '2.4rem' */
  borderRadiusXl: '2.4rem',

  /** '3.2rem' */
  borderRadiusXxl: '3.2rem',

  /** '4.8rem' */
  borderRadiusPill: '4.8rem',

  /** '20rem' */
  borderRadiusCircular: '20rem',

  /** 0 */
  borderWidthNone: 0,

  /** '0.1rem' */
  borderWidthHairline: '0.1rem',

  /** '0.2rem' */
  borderWidthThin: '0.2rem',

  /** '0.8rem' */
  borderWidthThick: '0.8rem',

  /** '1.00rem' */
  lineHeightDefault: '1.00rem',

  /** '1.16rem' */
  lineHeightXxss: '1.16rem',

  /** '1.28rem' */
  lineHeightXxs: '1.28rem',

  /** '1.32rem' */
  lineHeightXs: '1.32rem',

  /** '1.35rem' */
  lineHeightSm: '1.35rem',

  /** '1.38rem' */
  lineHeightMd: '1.38rem',

  /** '1.40rem' */
  lineHeightLg: '1.40rem',

  /** '1.50rem' */
  lineHeightXl: '1.50rem',

  /** '1.60rem' */
  lineHeightXxl: '1.60rem',

  /** '1.65rem' */
  lineHeightXxxl: '1.65rem',

  /** '1.70rem' */
  lineHeightDisplay: '1.70rem',

  /** 700 */
  fontWeightBold: 700,

  /** 600 */
  fontWeightSemibold: 600,

  /** 500 */
  fontWeightMedium: 500,

  /** 400 */
  fontWeightRegular: 400,

  /** '1.3rem' */
  fontSizeXxss: '1.3rem',

  /** '1.5rem' */
  fontSizeXxs: '1.5rem',

  /** '1.8rem' */
  fontSizeXs: '1.8rem',

  /** '2rem' */
  fontSizeSm: '2rem',

  /** '2.4rem' */
  fontSizeMd: '2.4rem',

  /** '3.2rem' */
  fontSizeLg: '3.2rem',

  /** '3.6rem' */
  fontSizeXl: '3.6rem',

  /** '4.8rem' */
  fontSizeXxl: '4.8rem',

  /** '5.6rem' */
  fontSizeXxxl: '5.6rem',

  /** '6.4rem' */
  fontSizeDisplay: '6.4rem',

  /** '0.2rem' */
  spacingMini: '0.2rem',

  /** '0.4rem' */
  spacingQuarck: '0.4rem',

  /** '0.8rem' */
  spacingNano: '0.8rem',

  /** '1.2rem' */
  spacingXxs: '1.2rem',

  /** '1.6rem' */
  spacingXs: '1.6rem',

  /** '2rem' */
  spacingSm: '2rem',

  /** '2.4rem' */
  spacingMd: '2.4rem',

  /** '3.2rem' */
  spacingLg: '3.2rem',

  /** '4rem' */
  spacingXl: '4rem',

  /** '4.8rem' */
  spacingXxl: '4.8rem',

  /** '5.6rem' */
  spacingXxxl: '5.6rem',

  /** '9.6rem' */
  spacingHuge: '9.6rem',

  /** '12.8rem' */
  spacingGiant: '12.8rem',
  shadowLevel1: {
    color: '#000000',
    type: 'dropShadow',
    x: '0',
    y: '0',
    blur: '16px',
    spread: '0px'
  },
  shadowLevel2: {
    color: '#0000000a',
    type: 'dropShadow',
    x: '0',
    y: '0',
    blur: '32px',
    spread: '0'
  },
  shadowLevel3: {
    color: '#000000',
    type: 'dropShadow',
    x: '0',
    y: '0',
    blur: '72px',
    spread: '0'
  },
  shadowLeve4: {
    x: '0',
    y: '8px',
    blur: '16px',
    spread: '0',
    color: 'rgba(170,170,170,0.16)',
    type: 'dropShadow'
  },
  fontFamilyPoppins: 'Poppins',

  /** 'Roboto' */
  fontFamilyRoboto: 'Roboto',

  /** '#111111' */
  neutralColorLowPure: '#111111',

  /** '#222222' */
  neutralColorLowDarkest: '#222222',

  /** '#444444' */
  neutralColorLowDark: '#444444',

  /** '#666666' */
  neutralColorLowMedium: '#666666',

  /** '#888888' */
  neutralColorLowMediumLight: '#888888',

  /** '#AAAAAA' */
  neutralColorLowLight: '#AAAAAA',

  /** '#BBBBBB' */
  neutralColorLowLightest: '#BBBBBB',

  /** '#CCCCCC' */
  neutralColorHighDarkest: '#CCCCCC',

  /** '#DDDDDD' */
  neutralColorHighDark: '#DDDDDD',

  /** '#EEEEEE' */
  neutralColorHighMedium: '#EEEEEE',

  /** '#F7F7F7' */
  neutralColorHighLight: '#F7F7F7',

  /** '#fcfcfc' */
  neutralColorHighPure: '#fcfcfc',

  /** '#002040' */
  brandColorPrimaryDark: '#002040',

  /** '#0072DF' */
  brandColorPrimaryMedium: '#0072DF',

  /** '#3fa1ff' */
  brandColorPrimaryPure: '#3fa1ff',

  /** '#8CC7FF' */
  brandColorPrimaryLight: '#8CC7FF',

  /** '#ECF6FF' */
  brandColorPrimaryLightest: '#ECF6FF',

  /** '#02192D' */
  brandColorSecondaryDark: '#02192D',

  /** '#07589F' */
  brandColorSecondaryMedium: '#07589F',

  /** '#0a7ee3' */
  brandColorSecondaryPure: '#0a7ee3',

  /** '#62B2F8' */
  brandColorSecondaryLight: '#62B2F8',

  /** '#E5F2FE' */
  brandColorSecondaryLightest: '#E5F2FE',

  /** '#351A02' */
  highlightColorDark: '#351A02',

  /** '#BB5C07' */
  highlightColorMedium: '#BB5C07',

  /** '#F7841E' */
  highlightColorPure: '#F7841E',

  /** '#FAB578' */
  highlightColorMediumLight: '#FAB578',

  /** '#f6ac3c80' */
  highlightColorLight: '#f6ac3c80',

  /** '#FEF3E9' */
  highlightColorLightest: '#FEF3E9',

  /** '#001C06' */
  feedbackColorSuccessDark: '#001C06',

  /** '#006216' */
  feedbackColorSuccessMedium: '#006216',

  /** '#008C1F' */
  feedbackColorSuccessPure: '#008C1F',

  /** '#00D62F' */
  feedbackColorSuccessLight: '#00D62F',

  /** '#DAFFE2' */
  feedbackColorSuccessLightest: '#DAFFE2',

  /** '#3B2403' */
  feedbackColorWarningDark: '#3B2403',

  /** '#CD7F09' */
  feedbackColorWarningMedium: '#CD7F09',

  /** '#844002' */
  feedbackColorWarningMediumDark: '#844002',

  /** '#F6AC3C' */
  feedbackColorWarningPure: '#F6AC3C',

  /** '#FACD8A' */
  feedbackColorWarningLight: '#FACD8A',

  /** '#FEF7EB' */
  feedbackColorWarningLightest: '#FEF7EB',

  /** '#2D0101' */
  feedbackColorErrorDark: '#2D0101',

  /** '#9D0505' */
  feedbackColorErrorMedium: '#9D0505',

  /** '#E00707' */
  feedbackColorErrorPure: '#E00707',

  /** '#FA5D5D' */
  feedbackColorErrorMediumLight: '#FA5D5D',

  /** '#fa5d5db3' */
  feedbackColorErrorLight: '#fa5d5db3',

  /** '#FEE4E4' */
  feedbackColorErrorLightest: '#FEE4E4',

  /** 'Poppins' */
  fontFamiliesPoppins: 'Poppins',

  /** 'none' */
  textCaseNone: 'none',

  /** 'none' */
  textDecorationNone: 'none',

  /** '0.2rem' */
  spacingInsetMini: '0.2rem',

  /** '0.4rem' */
  spacingInsetQuarck: '0.4rem',

  /** '0.5rem' */
  spacingInsetQuarckk: '0.5rem',

  /** '0.8rem' */
  spacingInsetNano: '0.8rem',

  /** '1rem' */
  spacingInsetXxxs: '1rem',

  /** '1.2rem' */
  spacingInsetXxs: '1.2rem',

  /** '1.6rem' */
  spacingInsetXs: '1.6rem',

  /** '1.8rem' */
  spacingInsetX: '1.8rem',

  /** '2rem' */
  spacingInsetSm: '2rem',

  /** '2.2rem' */
  spacingInsetMd: '2.2rem',

  /** '2.4rem' */
  spacingInsetMdd: '2.4rem',

  /** '3.2rem' */
  spacingInsetLg: '3.2rem',

  /** '4rem' */
  spacingInsetXl: '4rem',

  /** '4.8rem' */
  spacingInsetXxl: '4.8rem',

  /** '5.6rem' */
  spacingInsetHuge: '5.6rem',

  /** '7.2rem' */
  spacingInsetGiant: '7.2rem',

  /** '12.8rem' */
  spacingInsetDisplay: '12.8rem',

  /** '4rem' */
  borderRadiusXxxl: '4rem',

  /** '#081018' */
  baseColorBlack: '#081018',

  /** '#F7F7F7' */
  baseColorWhite: '#F7F7F7',

  /** '#000000' */
  deepColorBlack: '#000000',

  /** '#FFFFFF' */
  deepColorWhite: '#FFFFFF',

  /** '0.2rem' */
  borderRadiusThin: '0.2rem',

  /** '#102030' */
  supportsColorLowPure: '#102030',

  /** '#183048' */
  supportsColorLowDakest: '#183048',

  /** '#285078' */
  supportsColorLowDark: '#285078',

  /** '#3870a7' */
  supportsColorLowMedium: '#3870a7',

  /** '#588fc7' */
  supportsColorLowLight: '#588fc7',

  /** '#709FCF' */
  supportsColorLowLightest: '#709FCF',

  /** '#88afd7' */
  supportsColorHighPure: '#88afd7',

  /** '#9FBFDF' */
  supportsColorHighDark: '#9FBFDF',

  /** '#B7CFE7' */
  supportsColorHighMedium: '#B7CFE7',

  /** '#CFDFEF' */
  supportsColorHighLight: '#CFDFEF',

  /** '#E7EFF7 */
  supportsColorHighLightest: '#E7EFF7'
};
