import labelsJson from './labels.lang.json';
import modalJson from './modal.lang.json';
import statusJson from './status.lang.json';
import tableJson from './table.lang.json';
import { tours } from './tours';

export const expenses = {
  status: statusJson,
  labels: labelsJson,
  modal: modalJson,
  table: tableJson,
  tours
};
