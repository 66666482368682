import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type PercentageListStyleType } from './PercentageList.types';
import { type AddPrefixToType } from 'ds/types';

const VariantColors = [
  tokens.brandColorPrimaryPure,
  tokens.brandColorSecondaryMedium,
  tokens.highlightColorPure,
  tokens.supportsColorLowLight,
  tokens.neutralColorLowMediumLight
];

const VariantColorsBackground = [
  tokens.brandColorPrimaryLightest,
  tokens.brandColorSecondaryLightest,
  tokens.highlightColorLightest,
  tokens.supportsColorHighLightest,
  tokens.neutralColorHighDark
];

export const Container = styled.div`
  width: 100%;
  border-radius: ${tokens.borderRadiusMd};
  background-color: ${tokens.neutralColorHighPure};
  border: 1px solid ${tokens.neutralColorHighMedium};
  padding: ${tokens.spacingNano} ${tokens.spacingMd} ${tokens.spacingMd}
    ${tokens.spacingXs};
`;

export const Title = styled.h4`
  margin: 0;
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  padding-bottom: ${tokens.spacingXs};
  letter-spacing: 0.75px;
`;

export const ItemPercentage = styled.div<
  AddPrefixToType<PercentageListStyleType>
>`
  position: relative;
  width: 100%;
  height: 0.4rem;
  border-radius: 2px;
  background-color: ${VariantColorsBackground[0]};

  &::before {
    content: '';
    position: absolute;
    left: 0;
    z-index: 1;
    width: ${({ $percentage }) => $percentage}%;
    height: 0.4rem;
    border-radius: 2px;
    background-color: ${VariantColors[0]};
  }

  ${({ $index }) =>
    $index &&
    css`
      background-color: ${VariantColorsBackground[$index]};

      &::before {
        background-color: ${VariantColors[$index]};
      }
    `}
`;

export const ItemsContainer = styled.div<
  AddPrefixToType<PercentageListStyleType>
>`
  width: 100%;
  display: flex;
  gap: ${tokens.spacingInsetXs};
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  max-height: 24.5rem;
  transition: all 200ms ease-in-out;

  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  &::-webkit-scrollbar-track {
    border: solid 3px transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${tokens.neutralColorLowLight};
  }

  ${({ $active }) =>
    $active &&
    css`
      max-height: 45rem;
      overflow: auto;
      padding-right: ${tokens.spacingInsetNano};
    `}
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.spacingNano};
`;

export const ItemPercentageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${tokens.spacingNano};
`;

export const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const ItemTitle = styled.span`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
`;

export const ItemValue = styled.span`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.2rem;
`;

export const ItemPercentageValue = styled.span`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  line-height: 2.2rem;
`;

export const ItemObservation = styled.span`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  line-height: 2.4rem;
`;

export const ShowMore = styled.button<AddPrefixToType<PercentageListStyleType>>`
  outline: none;
  border: none;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${tokens.spacingInsetNano};
  color: ${tokens.brandColorPrimaryPure};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  padding: ${tokens.spacingNano} ${tokens.spacingSm};
  background-color: transparent;
  cursor: pointer;

  svg {
    transition: all 200ms ease-in-out;

    ${({ $active }) =>
      $active &&
      css`
        transform: rotateZ(3.14rad);
      `}

    * {
      color: ${tokens.brandColorPrimaryPure};
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  gap: ${tokens.spacingInsetXs};
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  max-height: 24rem;
  transition: all 200ms ease-in-out;
  padding: ${tokens.spacingNano} ${tokens.spacingSm} ${tokens.spacingNano}
    ${tokens.spacingSm};
  margin-top: ${tokens.spacingXs};
`;

export const NoItemsMessage = styled.span`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
`;
