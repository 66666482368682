import { useState } from 'react';

import { type IUseProfile } from './Profile.types';

export function useProfile(): IUseProfile {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  return {
    dropdownOpen,
    setDropdownOpen
  };
}
