import { useQueryCache } from 'data/cache';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetOutsourcedUsersAndPermissions,
  type IUseGetOutsourcedUsersAndPermissionsParams
} from 'data/modules/auth';

export function useGetOutsourcedUsersAndPermissions(
  params?: IUseGetOutsourcedUsersAndPermissionsParams
): IUseGetOutsourcedUsersAndPermissions {
  const { data, isFetching } = useQueryCache({
    queryKey: [AuthQueryKeys.GET_OUTSOURCED_USERS_AND_PERMISSIONS],
    queryFn: async () => await AuthService.getOutsourcedUsersAndPermissions(),
    enabled: params?.enabled ?? false,
    staleTime: Infinity
  });

  return {
    outsourcedUsersAndPermissions: data,
    isFetchingOutsourcedUsersAndPermissions: isFetching
  };
}
