import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.h3`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightSemibold};
  letter-spacing: 0.75px;
  line-height: 3.2rem;
  text-align: center;
`;
