import { type IDefaultTabContainerProps } from './DefaultTabContainer.types';

import { Container, TabsList } from './DefaultTabContainer.styles';

export function DefaultTabContainer({
  children,
  hasTrack,
  ...rest
}: IDefaultTabContainerProps): JSX.Element {
  return (
    <Container
      data-testid='container'
      {...rest}
    >
      <TabsList $hasTrack={hasTrack}>{children}</TabsList>
    </Container>
  );
}
