import apiErrorsJSON from './api/errors.lang.json';
import addressJSON from './forms/address.lang.json';
import formsSelectJSON from './forms/select.lang.json';
import formsJSON from './global/forms.lang.json';
import globalJSON from './global/global.lang.json';
import globalPagesJSON from './global/pages.lang.json';
import schemasJSON from './global/schemas.lang.json';
import { cards } from './cards';
import { expenses } from './expenses';
import { navbar } from './navbar';
import { reports } from './reports';
import { settings } from './settings';

export const langJSON = {
  global: globalJSON,
  cards,
  expenses,
  reports,
  api: {
    errors: apiErrorsJSON
  },
  settings,
  forms: {
    ...formsJSON,
    select: formsSelectJSON,
    address: addressJSON
  },
  schemas: schemasJSON,
  globalPages: globalPagesJSON,
  navbar
};
