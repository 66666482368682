import { Breadcrumbs, DSIcons, tokens } from 'ds';
import styled from 'styled-components';

export const GoBackButton = styled(DSIcons.DiagramsArrow)`
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.tokens.spacingNano};
`;

export const PageTitle = styled.h1`
  color: ${tokens.neutralColorLowDarkest} !important;
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeSm};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  white-space: nowrap;
  width: 100%;
  max-width: 50rem;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 834px) {
    max-width: 25rem;
  }
`;

export const TitleAndBreadcrumbsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  white-space: nowrap;
  overflow: hidden;

  ul {
    width: 100%;
    overflow: hidden;
  }
`;

export const CurrentPage = styled(Breadcrumbs.CurrentPage)`
  overflow: hidden;
  max-width: 35rem;
  text-overflow: ellipsis;
`;

export const SkeletonContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${tokens.spacingNano};
`;
