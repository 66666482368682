import * as Switch from '@radix-ui/react-switch';
import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type SwitchDefaultStylesType } from './SwitchDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<SwitchDefaultStylesType>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  &:has([data-disabled]) {
    label {
      opacity: 0.5 !important;
      cursor: not-allowed;
    }
  }

  ${({ $hasError }) =>
    $hasError &&
    css`
      button {
        background-color: ${tokens.feedbackColorErrorPure} !important;

        &:focus {
          outline: ${tokens.feedbackColorErrorPure}55 solid
            ${tokens.borderWidthThick} !important;
        }
      }

      label {
        color: ${tokens.feedbackColorErrorPure};
      }

      &:has([data-disabled]) {
        opacity: 0.5;
      }

      &:has([data-disabled]) ~ p {
        opacity: 0.5 !important;
      }
    `}
`;

export const Label = styled.label`
  font-family: ${tokens.fontFamilyRoboto}, sans-serif;
  font-size: ${tokens.fontSizeSm};
  font-weight: ${tokens.fontWeightMedium};
  color: ${tokens.brandColorSecondaryPure};

  &:hover {
    cursor: pointer;
  }
`;

export const StyledSwitch = styled(Switch.Root)`
  all: unset;
  width: 4.4rem;
  height: 2.4rem;
  background-color: ${tokens.neutralColorLowLight};
  border-radius: ${tokens.borderRadiusXxxl};
  position: relative;
  transition: background-color 0.3s ease-in-out;

  &[data-state='checked'] {
    background-color: ${tokens.brandColorPrimaryPure};
  }

  &:hover {
    background-color: ${tokens.neutralColorLowMedium};
    cursor: pointer;

    &[data-state='checked'] {
      background-color: ${tokens.brandColorPrimaryDark};
    }
  }

  &:focus {
    outline: ${tokens.brandColorPrimaryLight} solid ${tokens.borderWidthThick};

    &[data-state='unchecked'] {
      outline-color: ${tokens.neutralColorHighMedium};
    }
  }

  &[data-disabled] {
    background-color: ${tokens.brandColorPrimaryPure}70;
    cursor: not-allowed;

    &:hover {
      background-color: ${tokens.brandColorPrimaryPure}70;
    }

    &[data-state='unchecked'] {
      opacity: 0.5;
      background-color: ${tokens.neutralColorLowLight};
    }
  }
`;

export const StyledThumb = styled(Switch.Thumb)`
  display: block;
  width: 2rem;
  height: 2rem;
  background-color: ${tokens.neutralColorHighPure};
  border-radius: ${tokens.borderRadiusCircular};
  transition: transform 100ms;
  transform: translateX(0.2rem);
  will-change: transform;

  &[data-state='checked'] {
    transform: translateX(2.2rem);
  }
`;

export const ErrorMessage = styled.p`
  color: ${tokens.feedbackColorErrorPure};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightMedium};
  font-size: ${tokens.fontSizeXxss};
  word-break: break-all;
  margin-top: 0.5rem;
  margin-left: 0.3rem;
`;
