import { type RefObject, useEffect } from 'react';

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: () => void
): void {
  useEffect(() => {
    function handleClick(event: MouseEvent): void {
      const element = ref?.current;

      if (!element || element.contains(event.target as Node)) {
        return;
      }

      handler();
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handler, ref]);
}
