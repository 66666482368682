import { useState } from 'react';

import { type IUseCloseAlert } from './CloseAlert.types';

export function useCloseAlert(): IUseCloseAlert {
  const [closed, setClosed] = useState(false);

  return {
    closed,
    setClosed
  };
}
