import {
  HotelCategory,
  HotelOrderBy,
  type IHotelResultFilters,
  type IHotelSearchStates
} from 'data/contexts';
import { type IHotel } from 'data/modules/travel/productSearch';

class ProductSearch {
  sortHotelList(
    hotelList: IHotel[] | undefined,
    orderBy: HotelOrderBy
  ): IHotel[] {
    if (!hotelList || hotelList.length === 0) {
      return [];
    }

    if (!orderBy) {
      return hotelList;
    }

    switch (orderBy) {
      case HotelOrderBy.LOWER_PRICE:
        return hotelList.sort((a, b) => a.totalPrice - b.totalPrice);

      case HotelOrderBy.HIGHER_PRICE:
        return hotelList.sort((a, b) => b.totalPrice - a.totalPrice);

      case HotelOrderBy.WITHIN_POLICY:
        return hotelList.sort((a, b) => {
          const withinPolicyA = a.withinPolicy;
          const withinPolicyB = b.withinPolicy;

          if (withinPolicyA && !withinPolicyB) {
            return -1;
          }

          if (!withinPolicyA && withinPolicyB) {
            return 1;
          }

          return 0;
        });

      case HotelOrderBy.CATEGORY:
        return hotelList.sort((a, b) => (b.category ?? 0) - (a.category ?? 0));

      default:
        return hotelList;
    }
  }

  getMeetsCategories(
    hotel: IHotel,
    categories?: IHotelResultFilters['categories']
  ): boolean {
    if (!categories || !Object.values(categories).some(category => category)) {
      return true;
    }

    switch (hotel.category) {
      case HotelCategory.ONE_STAR:
        return Boolean(categories.one);
      case HotelCategory.TWO_STARS:
        return Boolean(categories.two);
      case HotelCategory.THREE_STARS:
        return Boolean(categories.three);
      case HotelCategory.FOUR_STARS:
        return Boolean(categories.four);
      case HotelCategory.FIVE_STARS:
        return Boolean(categories.five);
      default:
        return false;
    }
  }

  getMeetsMinPrice(hotel: IHotel, minPrice?: number | null): boolean {
    if (!minPrice) {
      return true;
    }

    return hotel.totalPrice >= minPrice;
  }

  getMeetsMaxPrice(hotel: IHotel, maxPrice?: number | null): boolean {
    if (!maxPrice) {
      return true;
    }

    return hotel.totalPrice <= maxPrice;
  }

  getMeetsWithinPolicy(hotel: IHotel, withinPolicy?: boolean): boolean {
    if (!withinPolicy) {
      return true;
    }

    return hotel.withinPolicy;
  }

  filterHotelList(
    hotelList: IHotel[] | undefined,
    hotelResultFilters: IHotelSearchStates['hotelResultFilters']
  ): IHotel[] {
    if (!hotelList || hotelList.length === 0) return [];

    const { categories, minPrice, maxPrice, withinPolicy } = hotelResultFilters;

    const listToBeFiltered = hotelList;

    const filteredHotelList = listToBeFiltered.filter(hotel => {
      const meetsCategories = this.getMeetsCategories(hotel, categories);
      const meetsMinPrice = this.getMeetsMinPrice(hotel, minPrice);
      const meetsMaxPrice = this.getMeetsMaxPrice(hotel, maxPrice);
      const meetsWithinPolicy = this.getMeetsWithinPolicy(hotel, withinPolicy);

      return (
        meetsCategories && meetsMinPrice && meetsMaxPrice && meetsWithinPolicy
      );
    });

    return filteredHotelList;
  }
}

export default new ProductSearch();
