import { DSIcons } from 'ds';

import { useTourAction } from './useTourAction';

import { Container } from './TourAction.styles';

export function TourAction(): JSX.Element {
  const { handleResetTour } = useTourAction();

  return (
    <Container
      onClick={handleResetTour}
      className='guided-tour'
    >
      <DSIcons.GraduateHatIcon />
    </Container>
  );
}
