import { type IDefaultIconProps } from 'data/modules/global';

export function Edit({ type, color, size }: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M6.414 16L16.556 5.85799L15.142 4.44399L5 14.586V16H6.414ZM7.243 18H3V13.757L14.435 2.32199C14.6225 2.13452 14.8768 2.02921 15.142 2.02921C15.4072 2.02921 15.6615 2.13452 15.849 2.32199L18.678 5.15099C18.8655 5.33852 18.9708 5.59283 18.9708 5.85799C18.9708 6.12316 18.8655 6.37746 18.678 6.56499L7.243 18ZM3 20H21V22H3V20Z' />
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M7.243 18H3V13.757L14.435 2.32199C14.6225 2.13452 14.8768 2.02921 15.142 2.02921C15.4072 2.02921 15.6615 2.13452 15.849 2.32199L18.678 5.15099C18.8655 5.33852 18.9708 5.59283 18.9708 5.85799C18.9708 6.12316 18.8655 6.37746 18.678 6.56499L7.243 18ZM3 20H21V22H3V20Z' />
    </svg>
  );
}
