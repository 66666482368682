import companyJSON from './company.lang.json';
import containerJSON from './container.lang.json';
import onboardingInProgressJSON from './onboardingInProgress.lang.json';
import userJSON from './user.lang.json';

export const onboarding = {
  container: containerJSON,
  company: companyJSON,
  onboardingInProgress: onboardingInProgressJSON,
  user: userJSON
};
