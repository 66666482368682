import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled, { css } from 'styled-components';

import {
  type ContainerPdfStylesType,
  type ContainerStylesType
} from './ViewImagePdf.types';
import { type AddPrefixToType } from 'ds/types';

export const ImageContainer = styled.div<AddPrefixToType<ContainerStylesType>>`
  height: 59.2rem;
  width: 40rem;
  border-radius: ${tokens.borderRadiusMd};
  background: ${({ $url }) =>
    $url !== null ? `url('${$url}')` : tokens.neutralColorHighMedium};
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: ${({ $url }) => ($url !== null ? 'pointer' : 'auto')};

  ${({ $isOpenedImage }) =>
    $isOpenedImage &&
    css`
      z-index: 1;
    `};

  > div {
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: ${tokens.borderRadiusMd};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    background-color: transparent;
    transition: ${transitions.allEaseInOut};

    > * {
      margin-right: 3.2rem;
      margin-left: 3.2rem;
      opacity: ${({ $url }) => ($url !== null ? 0 : 1)};
      transition: ${transitions.allEaseInOut};
    }

    > svg {
      color: ${tokens.neutralColorLowMedium};
    }

    > span {
      text-align: center;
      color: ${tokens.neutralColorLowMedium};
      font-size: ${tokens.fontSizeXxs};
      font-weight: ${({ $url }) =>
        $url !== null ? tokens.fontWeightMedium : tokens.fontWeightRegular};
      font-family: ${tokens.fontFamilyPoppins};
    }
  }

  ${({ $containerHasBorder }) =>
    $containerHasBorder &&
    css`
      border: 1px solid ${tokens.neutralColorLowLightest};
    `}

  ${({ $url }) =>
    $url !== null &&
    css`
      > div {
        &:hover {
          background-color: #ffffffcc;

          > * {
            opacity: 1;
          }
        }
      }
    `};
`;

export const NotImageFileContainer = styled.div<
  AddPrefixToType<ContainerPdfStylesType>
>`
  height: 59.2rem;
  width: 40rem;
  border-radius: ${tokens.borderRadiusMd};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  gap: 1.6rem;
  background-color: ${tokens.neutralColorHighLight};
  padding-left: ${tokens.spacingLg};
  padding-right: ${tokens.spacingLg};
  cursor: pointer;

  > svg {
    color: ${tokens.neutralColorLowLight};
    width: 6.4rem;
    height: 6.4rem;
  }

  > span {
    font-size: ${tokens.fontSizeXxs};
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    white-space: nowrap;
    color: ${tokens.neutralColorLowMedium};
    font-weight: ${tokens.fontWeightRegular};
    font-family: ${tokens.fontFamilyPoppins};
    line-height: 2.4rem;
    letter-spacing: 0.75px;
  }

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    border-radius: ${tokens.borderRadiusMd};
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.6rem;
    background-color: transparent;
    transition: ${transitions.allEaseInOut};

    > * {
      margin-right: 3.2rem;
      margin-left: 3.2rem;
      opacity: 0;
      transition: ${transitions.allEaseInOut};
    }

    > svg {
      color: ${tokens.neutralColorLowMedium};
    }

    > span {
      text-align: center;
      color: ${tokens.neutralColorLowMedium};
      font-size: ${tokens.fontSizeXxs};
      font-weight: ${tokens.fontWeightMedium};
      font-family: ${tokens.fontFamilyPoppins};
    }

    &:hover {
      background-color: #ffffffcc;

      > * {
        opacity: 1;
      }
    }
  }

  ${({ $containerHasBorder }) =>
    $containerHasBorder &&
    css`
      border: 1px solid ${tokens.neutralColorLowLightest};
    `}
`;
