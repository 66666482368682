import { notificationIcon } from './constants/notificationIcon.constant';

import { type INotificationIconProps } from './NotificationIcon.types';

import { Container } from './NotificationIcon.styles';

export function NotificationIcon({
  children,
  icon = 'default',
  ...props
}: INotificationIconProps): JSX.Element {
  return (
    <>
      <Container {...props}>
        {notificationIcon[icon as keyof typeof notificationIcon] ??
          notificationIcon.default}
      </Container>
    </>
  );
}
