import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type PasswordInputControlledStylesType } from './PasswordInputControlled.types';
import { type AddPrefixToType } from 'ds/types';

export const Legend = styled.legend`
  opacity: 0;
  visibility: hidden;
  padding: 0;
  overflow: hidden;
  max-width: 0.001rem;
  white-space: nowrap;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  letter-spacing: 0.075rem;

  span {
    opacity: 0;
    visibility: visible;
  }
`;

export const Fieldset = styled.fieldset<
  AddPrefixToType<PasswordInputControlledStylesType>
>`
  text-align: left;
  position: absolute;
  inset: -5px 0 0;
  pointer-events: none;
  overflow: hidden;
  min-width: 0%;
  padding: 0 ${tokens.spacingXs};
  border-radius: ${tokens.borderRadiusXs};
  border-color: ${tokens.neutralColorLowLight};
  border-width: ${tokens.borderWidthHairline};
  border-style: solid;
  top: -1rem;
  transition: border 100ms ease-in-out;

  ${({ $hasLabel }) =>
    !$hasLabel &&
    css`
      top: 0;

      > legend {
        padding: 0 !important;
      }
    `}
`;

export const Container = styled.div<
  AddPrefixToType<PasswordInputControlledStylesType>
>`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetNano};
  width: 100%;
  position: relative;
  min-height: 5.2rem;
  max-height: 5.2rem;

  ${({ $large }) =>
    $large &&
    `
    min-height: 6.4rem;
    max-height: 6.4rem;
  `}

  ${({ $small }) =>
    $small &&
    `
    justify-content: center;
    min-height: 4.1rem;
    max-height: 4.1rem;
    padding: 0.04rem 1.6rem;
    background-color: ${tokens.neutralColorHighPure};

    div > span {
      top: -1.6rem;
    }
  `}

  ${({ $disabled }) =>
    $disabled &&
    `
    background-color: ${tokens.neutralColorHighLight};
  `}

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px ${tokens.neutralColorHighPure} inset !important;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
`;

export const InputContainer = styled.div<
  AddPrefixToType<PasswordInputControlledStylesType>
>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${tokens.spacingInsetNano};
  padding: ${tokens.spacingNano} ${tokens.spacingXs};
  min-width: 25.2rem;
  width: 100%;
  background-color: ${tokens.neutralColorHighPure};
  height: fit-content;

  * {
    letter-spacing: 0.075rem;
  }

  svg {
    width: 2.2rem;
    height: 2.2rem;
    opacity: 0.3;
    transition: opacity 100ms ease-in-out;
    ${({ $disabled }) =>
      !$disabled &&
      `
      cursor: pointer;
      `};
  }

  div {
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:focus-within {
    background-color: ${tokens.neutralColorHighPure};

    span {
      color: ${tokens.brandColorSecondaryPure};
      opacity: 1;
    }

    input {
      color: ${tokens.neutralColorLowDarkest};
    }

    svg {
      opacity: 1;
    }

    ~ ${Fieldset} {
      border-color: ${tokens.brandColorSecondaryPure};
      border-width: ${tokens.borderWidthThin};

      ${Legend} {
        opacity: 1;
        visibility: visible;
        max-width: unset;
        overflow: visible;
        padding: 0 ${tokens.spacingQuarck};
      }
    }
  }

  ${({ $isTouched }) =>
    $isTouched &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowMedium};
        border-width: ${tokens.borderWidthHairline};

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      input {
        color: ${tokens.neutralColorLowDarkest};
      }

      span,
      svg {
        opacity: 1;
      }
    `}

  ${({ $large }) =>
    $large &&
    css`
      padding-top: ${tokens.spacingXxs} !important;
      padding-bottom: ${tokens.spacingXxs} !important;
      min-width: 25.2rem;

      input {
        font-size: ${tokens.fontSizeXs} !important;
      }

      span {
        font-size: ${tokens.fontSizeXxs};
      }
    `}

    ${({ $small }) =>
    $small &&
    css`
      padding: 0;

      input {
        font-size: ${tokens.fontSizeXxss} !important;
      }
    `}

  ${({ $disabled }) =>
    $disabled &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.neutralColorLowLightest} !important;
        border-width: ${tokens.borderWidthHairline} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      background-color: ${tokens.neutralColorHighLight} !important;

      * {
        color: ${tokens.neutralColorLowMediumLight} !important;
      }

      span {
        opacity: 1;
      }
    `}

  ${({ $hasError }) =>
    $hasError &&
    css`
      ~ ${Fieldset} {
        border-color: ${tokens.feedbackColorErrorPure} !important;
        border-width: ${tokens.borderWidthThin} !important;

        ${Legend} {
          opacity: 1;
          visibility: visible;
          max-width: unset;
          overflow: visible;
          padding: 0 ${tokens.spacingQuarck};
        }
      }

      span,
      svg {
        color: ${tokens.feedbackColorErrorPure} !important;
        opacity: 1;
      }
    `}
`;

export const InputComponent = styled.input`
  border: none !important;
  outline: none !important;
  width: 100%;
  background-color: transparent !important;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightRegular};
  padding: ${tokens.spacingNano};
  line-height: 1.8rem;
  font-size: ${tokens.fontSizeXxs};
`;

export const Label = styled.span`
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  top: -1.5rem;
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  padding: ${tokens.spacingQuarck};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: transparent !important;
`;

export const Caption = styled.span<
  AddPrefixToType<PasswordInputControlledStylesType>
>`
  display: inline-block;
  color: ${tokens.neutralColorLowLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${tokens.feedbackColorErrorPure} !important;
    `}
`;

export const ClearButton = styled(DSIcons.ClearIcon)`
  cursor: pointer;
`;
