import { type IBreadcrumbsCurrentPageProps } from './BreadcrumbsCurrentPage.types';

import { Container } from './BreadcrumbsCurrentPage.styles';

export function BreadcrumbsCurrentPage({
  children,
  ...rest
}: IBreadcrumbsCurrentPageProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
