class Zendesk {
  private zendeskVisible = true;
  private readonly zendesk = document.getElementById(
    'launcher'
  ) as HTMLDivElement;

  private readonly zendeskContainer = this.zendesk
    ? (this.zendesk.parentElement as HTMLDivElement)
    : null;

  toggleVisibility(): void {
    const zendeskCloseButton = document.querySelector<HTMLDivElement>(
      '#zendesk-close-button'
    );

    if (this.zendeskContainer) {
      this.zendeskContainer.style.visibility = this.zendeskVisible
        ? 'hidden'
        : 'visible';
    }

    if (zendeskCloseButton) {
      zendeskCloseButton.style.visibility = this.zendeskVisible
        ? 'hidden'
        : 'visible';
    }

    this.zendeskVisible = !this.zendeskVisible;
  }

  show(): void {
    const zendeskCloseButton = document.querySelector<HTMLDivElement>(
      '#zendesk-close-button'
    );

    if (this.zendeskContainer) {
      this.zendeskContainer.style.visibility = 'visible';
    }

    if (zendeskCloseButton) {
      zendeskCloseButton.style.visibility = 'visible';
    }

    this.zendeskVisible = true;
  }

  hide(): void {
    const zendeskCloseButton = document.querySelector<HTMLDivElement>(
      '#zendesk-close-button'
    );

    if (this.zendeskContainer) {
      this.zendeskContainer.style.visibility = 'hidden';
    }

    if (zendeskCloseButton) {
      zendeskCloseButton.style.visibility = 'hidden';
    }

    this.zendeskVisible = false;
  }

  toggleChat(): void {
    const iframe = document.getElementById('launcher') as HTMLIFrameElement;
    const chatButton =
      iframe?.contentWindow?.document?.getElementsByTagName('button')?.[0];

    chatButton?.click();
  }
}

export default new Zendesk();
