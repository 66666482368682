import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  bottom: 70px;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  right: 70px;

  z-index: 999;
`;

export const Close = styled.div`
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${tokens.baseColorWhite};
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 50%);
`;
