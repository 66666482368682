import { create } from 'zustand';

import { type IBalanceSolicitation } from 'data/modules/cards/balance';

import { type IUseValueSolicitationContext } from './useValueSolicitationContext.types';

export const useValueSolicitationContext =
  create<IUseValueSolicitationContext>()((set, get) => ({
    currentPage: 1,
    pageSize: 10,
    totalPages: 1,
    totalItems: 0,
    listType: 'SENT',
    searchInput: '',
    dateType: null,
    startDate: undefined,
    endDate: undefined,
    step: 'solicitation',
    selectedSolicitationIdsList: [],
    requestedAmount: 0,
    sortByDate: 'default',

    handleSortByDate: () => {
      if (get().sortByDate === 'asc') {
        set({ sortByDate: 'desc' });
      } else if (get().sortByDate === 'desc') {
        set({ sortByDate: 'default' });
      } else {
        set({ sortByDate: 'asc' });
      }
    },

    handleChangeSearch: value => {
      set({ searchInput: value });
    },

    handleChangeDate: (type, value) => {
      if (type === 'start') {
        set({ startDate: value });
        return;
      }

      set({ endDate: value });
    },

    handleChangeDateType: value => {
      set({ dateType: value });
    },

    handleChangeListType: value => {
      set({
        searchInput: '',
        endDate: undefined,
        startDate: undefined,
        dateType: null,
        currentPage: 1,
        listType: value,
        sortByDate: 'default'
      });
    },

    handleChangePagination: ({
      currentPage,
      pageSize,
      totalPages,
      totalItems
    }) => {
      set({
        ...(totalItems && { totalItems }),
        ...(totalPages && { totalPages }),
        ...(currentPage && { currentPage }),
        ...(pageSize && { pageSize })
      });
    },

    handleChangeStep: value => {
      set({
        searchInput: '',
        endDate: undefined,
        startDate: undefined,
        dateType: null,
        currentPage: 1,
        sortByDate: 'default',
        requestedAmount: 0
      });

      set({ step: value });
    },

    handleToggleSelectSolicitationId: solicitation => {
      const hasAlreadySelectedCardId = get().selectedSolicitationIdsList.some(
        valueSolicitation => valueSolicitation.id === solicitation.id
      );

      if (hasAlreadySelectedCardId) {
        const filteredCardsIdsList = get().selectedSolicitationIdsList.filter(
          (item: IBalanceSolicitation) => item.id !== solicitation.id
        );

        set({
          selectedSolicitationIdsList: filteredCardsIdsList
        });

        return;
      }

      set(state => ({
        selectedSolicitationIdsList: [
          ...state.selectedSolicitationIdsList,
          solicitation
        ]
      }));
    },

    handleSetAllSelectSolicitation: (value: IBalanceSolicitation[]) => {
      set({ selectedSolicitationIdsList: value });
    },

    handleClearAllSelectSolicitation: () => {
      set({ selectedSolicitationIdsList: [] });
    },

    handleChangeRequestedAmount: (value: number) => {
      set({ requestedAmount: value });
    },

    handleToggleSelectAllSolicitationActions: solicitations => {
      // Filtra apenas as solicitações com status "SENT"
      const sentSolicitations = solicitations.filter(
        solicitation => solicitation.status === 'SENT'
      );

      if (
        get().selectedSolicitationIdsList.length === sentSolicitations.length
      ) {
        set({ selectedSolicitationIdsList: [] });
        return;
      }

      const selectedSolicitations = sentSolicitations.map(solicitation => ({
        ...solicitation,
        transferAmount: solicitation.requestedAmount
      }));

      set({
        selectedSolicitationIdsList:
          selectedSolicitations as IBalanceSolicitation[]
      });
    },

    handleToggleValueRequestedAmount: (solicitation, value) => {
      const { selectedSolicitationIdsList } = get();

      const updatedList = selectedSolicitationIdsList.map(
        solicitationAggregate => {
          if (solicitationAggregate.id === solicitation.id) {
            return { ...solicitationAggregate, requestedAmount: value };
          } else return solicitationAggregate;
        }
      );

      set({
        selectedSolicitationIdsList: updatedList
      });
    },

    handleToggleManagerJustify: (solicitation, value) => {
      const { selectedSolicitationIdsList } = get();

      const updatedList = selectedSolicitationIdsList.map(
        solicitationAggregate => {
          if (solicitationAggregate.id === solicitation.id) {
            return { ...solicitationAggregate, managerJustify: value };
          } else return solicitationAggregate;
        }
      );

      set({
        selectedSolicitationIdsList: updatedList
      });
    }
  }));
