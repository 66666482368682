import { type IDropdownItemProps } from './DropdownItem.types';

import { Container } from './DropdownItem.styles';

export function DropdownItem({
  children,
  ...rest
}: IDropdownItemProps): JSX.Element {
  return <Container {...rest}>{children}</Container>;
}
