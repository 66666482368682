import { create } from 'zustand';

import { type IUseCardGroupsContext } from './useCardGroups.types';

export const useCardGroupsContext = create<IUseCardGroupsContext>()(
  (set, get) => ({
    hasCardGroups: false,
    filters: {
      name: undefined,
      managers: undefined
    },
    pagination: {
      page: 1,
      limit: 10,
      total: undefined
    },
    ordenationType: 'ASC',
    tableViewMode: 'LIST',
    selectedCardGroup: null,
    groupCardsSearchTerm: '',
    groupManagerSearchTerm: '',
    cardBeingExcluded: null,
    managerBeingExcluded: null,
    currentStepOfGroupCreation: 0,
    cardGroupToBeCreated: null,
    paginationUsers: {
      page: 1,
      limit: 10,
      total: undefined,
      userName: undefined
    },
    paginationManagers: {
      page: 1,
      limit: 10,
      total: undefined,
      userName: undefined
    },
    paginationGroupManagement: {
      page: 1,
      limit: 10,
      total: undefined
    },
    filtersGroupManagement: {
      tableViewMode: 'LIST',
      order: 'ASC',
      name: undefined
    },
    paginationUsersModal: {
      page: 1,
      limit: 10,
      total: undefined
    },
    paginationModalDetails: {
      usersPagination: {
        page: 1,
        limit: 10,
        total: undefined
      },
      managersPagination: {
        page: 1,
        limit: 10,
        total: undefined
      }
    },
    selectedUsersWithoutCardGroup: [],
    selectedAvailableToBeManager: [],
    inconsistentUsersToAdd: [],
    isInCardGroupDetails: false,

    handleSetIsInCardGroupDetails: value => {
      set({ isInCardGroupDetails: value });
    },
    handleSetInconsisentUsersToAdd: users => {
      set({ inconsistentUsersToAdd: users });
    },
    handleChangePaginationModalDetails: ({
      managersPagination,
      usersPagination
    }) => {
      set(state => ({
        ...state,
        paginationModalDetails: {
          ...state.paginationModalDetails,
          managersPagination: {
            ...(state.paginationModalDetails.managersPagination || {}),
            ...(managersPagination && {
              page: managersPagination.page,
              limit: managersPagination.limit,
              total: managersPagination.total
            })
          },
          usersPagination: {
            ...(state.paginationModalDetails.usersPagination || {}),
            ...(usersPagination && {
              page: usersPagination.page,
              limit: usersPagination.limit,
              total: usersPagination.total
            })
          }
        }
      }));
    },

    handleChangePaginationGroupManagement: ({ page, limit, total }) => {
      set(state => ({
        paginationGroupManagement: {
          ...state.paginationGroupManagement,
          ...(page && { page }),
          ...(limit && { limit }),
          ...(total && { total })
        }
      }));
    },

    handleChangeFiltersGroupManagement: ({ tableViewMode, order, name }) => {
      set(state => ({
        filtersGroupManagement: {
          ...state.filtersGroupManagement,
          ...(tableViewMode && { tableViewMode }),
          ...(order && { order }),
          ...((name || name === null) && { name })
        }
      }));
    },

    handleToggleSelectUsersWithoutCardGroup: user => {
      const hasAlreadySelectedCardId = get().selectedUsersWithoutCardGroup.some(
        item => item === user
      );

      if (hasAlreadySelectedCardId) {
        const filteredCardsIdsList = get().selectedUsersWithoutCardGroup.filter(
          item => item !== user
        );

        set({
          selectedUsersWithoutCardGroup: filteredCardsIdsList
        });

        return;
      }

      set(state => ({
        selectedUsersWithoutCardGroup: [
          ...state.selectedUsersWithoutCardGroup,
          user
        ]
      }));
    },

    handleToggleSelectAvailableToBeManager: manager => {
      const hasAlreadySelectedCardId = get().selectedAvailableToBeManager.some(
        item => item === manager
      );

      if (hasAlreadySelectedCardId) {
        const filteredCardsIdsList = get().selectedAvailableToBeManager.filter(
          item => item !== manager
        );

        set({
          selectedAvailableToBeManager: filteredCardsIdsList
        });

        return;
      }

      set(state => ({
        selectedAvailableToBeManager: [
          ...state.selectedAvailableToBeManager,
          manager
        ]
      }));
    },

    handleChangePaginationUsers: ({ page, limit, total, userName }) => {
      set(state => ({
        paginationUsers: {
          ...state.paginationUsers,
          ...(typeof userName !== 'undefined' ? { userName } : ''),
          ...(page && { page }),
          ...(limit && { limit }),
          ...(total && { total })
        }
      }));
    },

    handleCreateCardGroup: cardGroup => {
      set({ cardGroupToBeCreated: cardGroup });
    },

    handleResetAfterCardGroupCreated: () => {
      set({
        hasCardGroups: true,
        currentStepOfGroupCreation: 4,
        cardGroupToBeCreated: null,
        selectedAvailableToBeManager: [],
        selectedUsersWithoutCardGroup: []
      });
    },

    handleResetSelectedUsersWithoutCardGroup: () => {
      set({
        selectedUsersWithoutCardGroup: []
      });
    },

    handleResetSelectedAvailableToBeManager: () => {
      set({
        selectedAvailableToBeManager: []
      });
    },

    handleChangePaginationManagers: ({ page, limit, total, userName }) => {
      set(state => ({
        paginationManagers: {
          ...state.paginationManagers,
          ...(typeof userName !== 'undefined' && { userName }),
          ...(page && { page }),
          ...(limit && { limit }),
          ...(total && { total })
        }
      }));
    },

    addManagersListSearchTerm: '',
    managersToAdd: [],

    handleSelectManager: manager => {
      if (get().managersToAdd.includes(manager)) {
        const newManagers = get().managersToAdd.filter(
          item => item.id !== manager.id
        );

        set({
          managersToAdd: newManagers
        });

        return;
      }

      set(state => ({
        managersToAdd: [...state.managersToAdd, manager]
      }));
    },

    handleSearchManager: searchTerm => {
      set({
        addManagersListSearchTerm: searchTerm
      });
    },

    handleChangeStepOfGroupCreation: type => {
      if (type === 'prev') {
        set({
          currentStepOfGroupCreation: get().currentStepOfGroupCreation - 1
        });

        return;
      }

      set({
        currentStepOfGroupCreation:
          get().currentStepOfGroupCreation === 7
            ? get().currentStepOfGroupCreation
            : get().currentStepOfGroupCreation + 1
      });
    },

    handleChangeFilters: ({ name, managers }) => {
      set(state => ({
        filters: {
          ...state.filters,
          ...((name || name === null) && { name }),
          ...((managers || managers === null) && {
            managers
          })
        }
      }));
    },

    handleChangePagination: ({ page, limit, total }) => {
      set(state => ({
        pagination: {
          ...state.pagination,
          ...(page && { page }),
          ...(limit && { limit }),
          ...(total && { total })
        }
      }));
    },

    handleChangeOrdenation: () => {
      set(state => ({
        ordenationType: state.ordenationType === 'ASC' ? 'DESC' : 'ASC'
      }));
    },

    handleSearchCardInGroup: searchTerm => {
      set({
        groupCardsSearchTerm: searchTerm
      });
    },

    handleSearchManagerInGroup: searchTerm => {
      set({
        groupManagerSearchTerm: searchTerm
      });
    },

    handleSelectCardGroup: cardGroup => {
      set({
        selectedCardGroup: cardGroup
      });
    },

    handleChangeTableViewMode: tableViewMode => {
      set({
        tableViewMode
      });
    },

    handleSetCardBeingExcluded: card => {
      set({
        cardBeingExcluded: card
      });
    },

    handleSetManagerBeingExcluded: manager => {
      set({
        managerBeingExcluded: manager
      });
    },

    handleSetHasCardGroups: hasCardGroups => {
      set({
        hasCardGroups
      });
    },

    handleResetCardGroupDetailsModal: () => {
      set({
        selectedCardGroup: null,
        groupCardsSearchTerm: '',
        groupManagerSearchTerm: '',
        cardBeingExcluded: null,
        managerBeingExcluded: null,
        addManagersListSearchTerm: '',
        cardGroupToBeCreated: null,
        selectedAvailableToBeManager: [],
        selectedUsersWithoutCardGroup: [],
        paginationModalDetails: {
          usersPagination: {
            page: 1,
            limit: 5,
            total: undefined
          },
          managersPagination: {
            page: 1,
            limit: 5,
            total: undefined
          }
        }
      });
    },

    handleSetGroupCreationStep: step => {
      set({
        currentStepOfGroupCreation: step
      });
    }
  })
);
