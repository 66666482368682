import { create } from 'zustand';

import { type IUseApprovalReviewContext } from './useApprovalReviewContext.types';

export const useApprovalReviewContext = create<IUseApprovalReviewContext>()(
  set => ({
    isReproving: false,
    setIsReproving: isReproving => {
      set({ isReproving });
    },
    selectedBookings: [],
    setSelectedBookings: bookings => {
      set({ selectedBookings: bookings });
    },
    approvalOrigin: 'travelRequests',
    setApprovalOrigin: origin => {
      set({ approvalOrigin: origin });
    },
    handleToggleSelectBooking: uuid => {
      set(state => {
        if (state.selectedBookings.includes(uuid)) {
          return {
            selectedBookings: state.selectedBookings.filter(
              bookingUuid => bookingUuid !== uuid
            )
          };
        }

        return {
          selectedBookings: [...state.selectedBookings, uuid]
        };
      });
    },
    resetApprovalReview: () => {
      set({
        selectedBookings: [],
        isReproving: false,
        approvalOrigin: 'travelRequests',
        reviewStep: 1
      });
    },
    approvalBreadcrumbs: {
      tripBreadcrumb: {
        name: '',
        id: ''
      },
      bookingBreadcrumb: {
        name: '',
        id: ''
      }
    },
    setApprovalBreadcrumbs: breadcrumbs => {
      set(state => ({
        approvalBreadcrumbs: {
          ...state.approvalBreadcrumbs,
          ...breadcrumbs
        }
      }));
    },
    reviewStep: 1,
    handleReviewStepChange: reviewStep => {
      set({ reviewStep });
    }
  })
);
