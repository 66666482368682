import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  flex-grow: 1;
  align-self: flex-start;
  margin-bottom: 5rem;

  &::before {
    content: '';
    position: absolute;
    left: -0.8rem;
    border-right: 2px solid ${tokens.brandColorPrimaryLightest};
    border-bottom: 2px solid ${tokens.brandColorPrimaryLightest};
    top: 1.6rem;

    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    width: 15px;

    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    height: 15px;
    border-radius: 0 0 7px;
    transform: rotate(135deg);

    background: linear-gradient(
      -45deg,
      ${tokens.brandColorPrimaryLightest} 50%,
      transparent 50%
    );
  }

  > div {
    padding: ${tokens.spacingNano} ${tokens.spacingXs};
    background: ${tokens.brandColorPrimaryLightest};
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
`;
