import { create } from 'zustand';

import { ChargeCardsSteps } from 'presentation/pages/cards/Management/ChargeCards/ChargeCards.types';

import { type IUseChargeCardsContext } from './useChargeCardsContext.types';

export const useChargeCardsContext = create<IUseChargeCardsContext>()(
  (set, get) => ({
    filters: {
      search: undefined,
      cardType: undefined,
      isCardTypeVisible: true
    },

    pagination: {
      page: 1,
      limit: 10,
      total: undefined
    },

    cardTypeFilterOptions: [],
    chargeCardsMassOperation: {
      operationType: undefined,
      value: 0
    },
    selectedCardsMap: new Map(),
    chargeCardsOperationsMap: new Map(),
    operationsSummary: {
      totalToAdd: 0,
      totalToRemove: 0,
      impactedUsers: 0
    },
    currentStep: ChargeCardsSteps.CHARGE_CARDS,
    sortTable: {
      cardName: 'ASC',
      currentValue: 'DEFAULT'
    },

    handleChangeFilters: ({ search, cardType, isCardTypeVisible }) => {
      set(state => ({
        filters: {
          ...state.filters,
          ...(search && { search }),
          ...(search === null && { search: undefined }),
          ...(cardType && { cardType, search: undefined }),
          ...(isCardTypeVisible !== undefined && { isCardTypeVisible })
        },
        pagination: {
          ...state.pagination,
          ...(!search && search !== null && { page: 1 })
        }
      }));
    },

    handleChangePagination: ({ page, limit, total }) => {
      set(state => ({
        pagination: {
          ...state.pagination,
          ...(page && { page }),
          ...(limit && { limit, page: 1 }),
          ...(total && { total })
        }
      }));
    },

    handleChangeChargeCardsMassOperation: newChargeCardsMassOperation => {
      set(state => ({
        chargeCardsMassOperation: {
          ...state.chargeCardsMassOperation,
          ...newChargeCardsMassOperation
        }
      }));
    },

    handleToggleSelectCard: card => {
      const updatedSelectedCardsMap = new Map(get().selectedCardsMap);

      const hasAlreadySelectedCard = updatedSelectedCardsMap.has(
        `${card.balanceId}-${card.number && card.number}`
      );

      if (hasAlreadySelectedCard) {
        updatedSelectedCardsMap.delete(
          `${card.balanceId}-${card.number && card.number}`
        );

        set({
          selectedCardsMap: updatedSelectedCardsMap
        });

        return;
      }

      updatedSelectedCardsMap.set(
        `${card.balanceId}-${card.number && card.number}`,
        card
      );

      set({
        selectedCardsMap: updatedSelectedCardsMap
      });
    },

    handleToggleSelectAllCards: cardsList => {
      const updatedSelectedCardsMap = new Map();

      if (get().selectedCardsMap.size < cardsList.length) {
        cardsList.forEach(card => {
          updatedSelectedCardsMap.set(
            `${card.balanceId}-${card.number && card.number}`,
            card
          );
        });
      }

      set({ selectedCardsMap: updatedSelectedCardsMap });
    },

    handleChangeChargeCardsOperationsMap: ({ card, value, operationType }) => {
      set(state => {
        const updatedOperationsMap = new Map(state.chargeCardsOperationsMap);

        if (!operationType && !value) {
          updatedOperationsMap.delete(
            `${card.balanceId}-${card.number && card.number}`
          );
        }

        if (operationType) {
          updatedOperationsMap.set(
            `${card.balanceId}-${card.number && card.number}`,
            {
              operationType,
              value:
                updatedOperationsMap.get(
                  `${card.balanceId}-${card.number && card.number}`
                )?.value ?? 0,
              ...card
            }
          );
        }

        if (value) {
          updatedOperationsMap.set(
            `${card.balanceId}-${card.number && card.number}`,
            {
              operationType:
                updatedOperationsMap.get(
                  `${card.balanceId}-${card.number && card.number}`
                )?.operationType ?? 'CREDIT',
              value,
              ...card
            }
          );
        }

        return { chargeCardsOperationsMap: updatedOperationsMap };
      });

      get().handleUpdateOperationsSummary();
    },

    handleApplyMassOperationToSelected: () => {
      const selectedCardsMap = get().selectedCardsMap;
      const { operationType, value } = get().chargeCardsMassOperation;

      if (operationType === 'CHANGE_FINAL_VALUE') {
        selectedCardsMap.forEach(card => {
          if (!card.currentValue && card.currentValue !== 0) return;

          get().handleChangeChargeCardsOperationsMap({
            card,
            operationType:
              card.currentValue > value
                ? 'DEBIT'
                : card.currentValue !== value
                  ? 'CREDIT'
                  : undefined,
            value: Number(Math.abs(value - card.currentValue).toFixed(2))
          });
        });

        set({
          selectedCardsMap: new Map()
        });

        get().handleChangeChargeCardsMassOperation({
          operationType: undefined,
          value: 0
        });

        return;
      }

      selectedCardsMap.forEach(card => {
        get().handleChangeChargeCardsOperationsMap({
          card,
          operationType,
          value
        });
      });

      set({
        selectedCardsMap: new Map()
      });

      get().handleChangeChargeCardsMassOperation({
        operationType: undefined,
        value: 0
      });
    },

    handleUpdateOperationsSummary: () => {
      let totalToAdd: number = 0;
      let totalToRemove: number = 0;

      get().chargeCardsOperationsMap.forEach(operation => {
        if (operation.operationType === 'CREDIT') {
          totalToAdd += operation.value;
        }

        if (operation.operationType === 'DEBIT') {
          totalToRemove += operation.value;
        }
      });

      set({
        operationsSummary: {
          totalToAdd,
          totalToRemove,
          impactedUsers: get().chargeCardsOperationsMap.size
        }
      });
    },

    handleClearValues: () => {
      set({
        selectedCardsMap: new Map(),
        chargeCardsOperationsMap: new Map(),
        chargeCardsMassOperation: {
          operationType: undefined,
          value: 0
        },
        operationsSummary: {
          impactedUsers: 0,
          totalToAdd: 0,
          totalToRemove: 0
        },
        sortTable: {
          cardName: 'ASC',
          currentValue: 'DEFAULT'
        }
      });
    },

    handleChangeCurrentStep: newStep => {
      set({
        currentStep: newStep,
        selectedCardsMap: new Map(),
        filters: {
          search: undefined,
          cardType: undefined,
          isCardTypeVisible: true
        }
      });
    },

    handleRemoveOperation: ({ balanceId, cardNumber }) => {
      set(state => {
        const updatedMap = new Map(state.chargeCardsOperationsMap);

        updatedMap.delete(`${balanceId}-${cardNumber}`);

        return { chargeCardsOperationsMap: updatedMap };
      });

      get().handleUpdateOperationsSummary();
    },

    handleGetOperationsList: () => {
      return Array.from(get().chargeCardsOperationsMap.values()).map(card => ({
        amount: card.value,
        balanceAccountId: card.balanceId,
        cardNumber: card.number,
        type: card.operationType,
        userName: card.name,
        cardType: card.type,
        currentValue: card.currentValue,
        status: card.status
      }));
    },

    handleChangeSortTable: sortBy => {
      set(state => {
        if (sortBy === 'cardName') {
          return {
            sortTable: {
              cardName: state.sortTable.cardName === 'ASC' ? 'DESC' : 'ASC',
              currentValue: 'DEFAULT'
            }
          };
        }

        return {
          sortTable: {
            cardName: 'ASC',
            currentValue:
              state.sortTable.currentValue === 'ASC' ? 'DESC' : 'ASC'
          }
        };
      });

      get().handleChangePagination({ page: 1 });
    },

    loadOperationsWithCachedData: cachedData => {
      if (cachedData) {
        cachedData.forEach(item => {
          get().chargeCardsOperationsMap.set(
            `${item.balanceAccountId}-${item.cardNumber}`,
            {
              value: item.amount,
              operationType: item.type,
              balanceId: item.balanceAccountId,
              currentValue: item.currentValue,
              name: item.userName,
              number: item.cardNumber,
              type: item.cardType!,
              status: item.status!,
              accountId: item.accountId!,
              userId: item.userId!
            }
          );
        });

        get().handleUpdateOperationsSummary();
      }
    }
  })
);
