import { useState } from 'react';

import { ZendeskAPI } from 'react-zendesk';

import { type IUseZendeskCloseAction } from './ZendeskCloseAction.types';

export function useZendeskCloseAction(): IUseZendeskCloseAction {
  const [isOpenCloseActionVisible, setIsOpenCloseActionVisible] =
    useState(true);

  function handleCloseZendesk(): void {
    ZendeskAPI('messenger', 'hide');

    setIsOpenCloseActionVisible(false);
  }

  return {
    handleCloseZendesk,
    isOpenCloseActionVisible
  };
}
