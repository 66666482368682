/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { DefaultButton } from 'ds';

import { api, apiGateway } from 'data/config';
import { type IPersistenceAuthUser } from 'data/modules/auth';
import { type IDefaultResponse } from 'data/modules/global';

export function AuthTest(): JSX.Element {
  async function handleButtonClick(): Promise<void> {
    await requestActualApi();
    await requestApiGateway();
    await requestTravelsApiGateway();
  }

  async function requestActualApi(): Promise<void> {
    const response =
      await api.get<IDefaultResponse<IPersistenceAuthUser>>('/oauth/user');
    console.log({ api: response.data });
  }

  async function requestApiGateway(): Promise<void> {
    const response = await apiGateway.get('/oauth/user');
    console.log({ apiGateway: response.data });
  }

  async function requestTravelsApiGateway(): Promise<void> {
    const response = await apiGateway.get('/travel/travel?per_page=5');
    console.log({ apiGatewayTravels: response.data });
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        columnGap: '2rem',
        height: '100vh'
      }}
    >
      <DefaultButton onClick={handleButtonClick}>All Requests</DefaultButton>
      <DefaultButton onClick={requestActualApi}>Api Request</DefaultButton>
      <DefaultButton onClick={requestApiGateway}>Gateway Request</DefaultButton>
      <DefaultButton onClick={requestTravelsApiGateway}>
        Travel Gateway Request
      </DefaultButton>
    </div>
  );
}
