import { useState } from 'react';

import { toast } from 'ds';
import { useTranslation } from 'react-i18next';

import { useSendEmailConfirmation } from 'data/modules/auth';

import { CustomCookies } from 'shared/utils/global';

import { type IUseDefaultLayout } from './DefaultLayout.types';

export function useDefaultLayout(
  forceSidebarCollapse?: boolean
): IUseDefaultLayout {
  const { t } = useTranslation('global');

  const controlSidebar = CustomCookies.get('sidebar');

  if (window.innerWidth < 1280) {
    CustomCookies.set('sidebar', 'collapsed', {
      daysToExpire: 2
    });
  }

  const [sidebarIsCollapsed, setSidebarIsCollapsed] = useState(
    controlSidebar === 'collapsed' || !!forceSidebarCollapse
  );

  const { isSendingEmailConfirmation, sendEmailConfirmationAsync } =
    useSendEmailConfirmation();

  sidebarIsCollapsed &&
    CustomCookies.set('sidebar', sidebarIsCollapsed ? 'collapsed' : '', {
      daysToExpire: 2
    });

  async function handleConfirmEmailButtonClick(): Promise<void> {
    try {
      await sendEmailConfirmationAsync(null);
      toast.success(t('confirmationEmailSentSuccessfully'));
    } catch (error) {
      toast.error(t('errorOccurredWhileSendingConfirmationEmail'));
    }
  }

  return {
    sidebarIsCollapsed,
    setSidebarIsCollapsed,
    handleConfirmEmailButtonClick,
    isSendingEmailConfirmation
  };
}
