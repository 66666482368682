import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ReversalIconStylesType } from './ReversalIcon.types';

export const Container = styled.div<AddPrefixToType<ReversalIconStylesType>>`
  width: ${({ $size }) => $size}rem;
  height: ${({ $size }) => $size}rem;
  border-radius: 50%;
  background: ${({ $backgroundColor, theme }) =>
    theme.colors[$backgroundColor]};
  display: flex;
  justify-content: center;
  align-items: center;
`;
