import { create } from 'zustand';

import {
  type IProductSearchContext,
  type IProductSearchStates,
  ProductsTab
} from './useProductSearchContext.types';

export const initialStates: IProductSearchStates = {
  activeTab: ProductsTab.HOTEL
};

export const useProductSearchContext = create<IProductSearchContext>()(set => ({
  ...initialStates,
  handleChangeActiveTab: newTab => {
    set({ activeTab: newTab });
  }
}));
