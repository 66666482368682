import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type NotificationDropdownStyleType } from './NotificationDropdown.types';

export const Container = styled.div<
  AddPrefixToType<NotificationDropdownStyleType>
>`
  .notifications-dropdown {
    position: fixed;
    top: ${tokens.spacingXxxl};
    right: ${tokens.spacingLg};
    padding-right: ${tokens.spacingNano};
    width: 60rem;
    background-color: ${tokens.neutralColorHighPure};

    @media screen and (max-width: 800px) {
      max-width: 70vw;
    }

    ${({ $hasOutsourcingAccess }) =>
      $hasOutsourcingAccess &&
      css`
        @media screen and (max-width: 1335px) {
          top: 14rem;
        }
      `}

    @media screen and (max-width: 800px) {
      right: ${tokens.spacingXs};
    }

    @media screen and (max-width: ${({ theme }) =>
        theme.breakpoints.mobile}px) {
      width: auto;
      border: none;
      max-width: unset;
      border-radius: 0;
      inset: ${({ $hasOutsourcingAccess }) =>
          $hasOutsourcingAccess ? '16.5rem' : '7.2rem'}
        0 0 0;
    }
  }
`;
