import { type MotionProps } from 'framer-motion';

export const animations: Record<string, MotionProps> = {
  fadeInOut: {
    animate: { opacity: [0, 1] },
    exit: { opacity: [0] }
  },
  popFadeInOut: {
    animate: { opacity: [0, 1], scale: [0.7, 1] },
    exit: { opacity: [0], scale: [0] }
  },
  slideUpAndDown: {
    initial: { height: 0, opacity: 0 },
    animate: { height: 'auto', opacity: 1 },
    transition: { duration: 0.2 },
    exit: { height: 0, opacity: 0 }
  },
  rotateRightToLeft: {
    initial: { rotateY: -90, opacity: 0 },
    animate: { rotateY: 0, opacity: 1 },
    transition: { duration: 0.5 },
    exit: { rotateY: 90, opacity: 0 }
  },
  rotateLeftToRight: {
    initial: { rotateY: 90, opacity: 0 },
    animate: { rotateY: 0, opacity: 1 },
    transition: { duration: 0.5 },
    exit: { rotateY: -90, opacity: 0 }
  }
};
