import { Icons } from 'shared/constants/global';

import { type INotificationTriggerProps } from './NotificationTrigger.types';

import { Container, Wrapper } from './NotificationTrigger.styles';

export function NotificationTrigger({
  children,
  ...props
}: INotificationTriggerProps): JSX.Element {
  return (
    <Container {...props}>
      <Wrapper>
        <Icons.BellIcon />
        <Icons.BellAltIcon />

        {children}
      </Wrapper>
    </Container>
  );
}
