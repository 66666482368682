import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type ContainerStylesType } from './CircularButton.types';

export const Container = styled.button<AddPrefixToType<ContainerStylesType>>`
  width: ${({ $size, theme }) => theme.buttons.circularButtonSizes[$size]};
  height: ${({ $size, theme }) => theme.buttons.circularButtonSizes[$size]};
  background: ${({ $color, theme }) => theme.colors[$color]};
  color: ${({ theme }) => theme.colors.white};
  border: 0;
  border-radius: 50%;
  transition: all 0.3s ease-in;

  ${({ theme, $size }) => css`
    font-size: calc(${theme.buttons.circularButtonSizes[$size]} / 2);
    line-height: calc(${theme.buttons.circularButtonSizes[$size]} / 2);
  `};

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: ${({ $color, theme }) => lighten(0.04, theme.colors[$color])};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
