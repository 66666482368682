export enum TravelUserType {
  TRAVELER = 'traveler',
  TRAVEL_ADMIN = 'travel_admin',
  USER = 'user'
}

export interface ITravelUser {
  id: string;
  type: TravelUserType;
  name: string;
  active: 0 | 1;
}

export interface IPersistenceTravelUser extends ITravelUser {}
