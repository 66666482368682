import { useLocation } from 'react-router-dom';

import { useQueryCache } from 'data/cache';
import { vexDomain } from 'data/config';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetAuthUser,
  type IUseGetAuthUserParams
} from 'data/modules/auth';

import { publicPaths } from 'shared/constants/global';

export function useGetAuthUser(
  { enabled = true, redirectToLoginOnError = true }: IUseGetAuthUserParams = {
    enabled: true,
    redirectToLoginOnError: true
  }
): IUseGetAuthUser {
  const location = useLocation();

  const {
    data: authUserData,
    isFetching: isFetchingAuthUser,
    isError
  } = useQueryCache({
    queryKey: [AuthQueryKeys.AUTH_USER],
    queryFn: async () => await AuthService.getAuthUser(),
    staleTime: Infinity,
    enabled
  });

  if (
    isError &&
    redirectToLoginOnError &&
    !publicPaths.includes(location.pathname)
  ) {
    window.location.href = `${vexDomain}/login`;

    return {
      company: undefined,
      isFetchingAuthUser: false,
      user: undefined
    };
  }

  return {
    user: authUserData?.user,
    company: authUserData?.company,
    isFetchingAuthUser
  };
}
