import { useState } from 'react';

import { type IUseAlert } from './Alert.types';

export function useAlert(): IUseAlert {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  return {
    isVisible,
    closeAlert: () => {
      setIsVisible(false);
    }
  };
}
