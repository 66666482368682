import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.h1`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeMd};
  font-weight: ${tokens.fontWeightBold};
  letter-spacing: 1px;
`;
