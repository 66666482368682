import { create } from 'zustand';

import { type IUseEditTravelTravelersContext } from './useEditTravelTravelersContext.types';

export const useEditTravelTravelersContext =
  create<IUseEditTravelTravelersContext>()(set => ({
    users: [],
    addedUsers: [],
    removedUsers: [],
    selectedUsers: [],
    hasChangedAtLeastOneTravelers: false,
    setUsers: users => {
      set({ users });
    },
    setAddedUsers: addedUsers => {
      set({ addedUsers });
    },
    setRemovedUsers: removedUsers => {
      set({ removedUsers });
    },
    setSelectedUsers: selectedUsers => {
      set({ selectedUsers });
    },
    setHasChangedAtLeastOneTravelers: hasChanged => {
      set({ hasChangedAtLeastOneTravelers: hasChanged });
    },
    resetState: () => {
      set({
        selectedUsers: [],
        hasChangedAtLeastOneTravelers: false
      });
    }
  }));
