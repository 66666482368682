import styled, { css } from 'styled-components';

import { type ContainerStylesType } from './StepperContainer.types';
import { type AddPrefixToType } from 'ds/types';

import {
  ClickArea as StepperItemClickArea,
  Container as StepperItemContainer,
  NumberContainer as StepperItemNumberContainer,
  TextContainer as StepperItemTextContainer
} from 'ds/components/Stepper/StepperItem/StepperItem.styles';

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  width: 100%;

  ${StepperItemContainer}:first-child {
    ${StepperItemNumberContainer}::before {
      background-color: transparent;

      /*
        metade do tamanho do container (tamanho 14rem)
          - metade do tamanho do número (tamanho 2.4rem)
          - espaço entre os elementos (gap de 0.8rem)
      */
      flex-basis: calc(7rem - 1.2rem - 0.8rem);
      flex-grow: 0;
    }

    ${StepperItemTextContainer} {
      justify-content: start;
    }

    ${StepperItemClickArea} {
      margin: unset;
      left: 0;
    }
  }

  ${StepperItemContainer}:last-child {
    ${StepperItemNumberContainer}::after {
      background-color: transparent;

      /*
        metade do tamanho do container (tamanho 14rem)
          - metade do tamanho do número (tamanho 2.4rem)
          - espaço entre os elementos (gap de 0.8rem)
      */
      flex-basis: calc(7rem - 1.2rem - 0.8rem);
      flex-grow: 0;
    }

    ${StepperItemTextContainer} {
      justify-content: end;
    }

    ${StepperItemClickArea} {
      margin: unset;
      right: 0;
    }
  }

  ${({ $breakpointToBreakIntoColumns }) => css`
    @media screen and (max-width: ${$breakpointToBreakIntoColumns}px) {
      flex-direction: column;

      ${StepperItemContainer} {
        gap: 0.8rem;

        &::before {
          content: '';
          background-color: #919eab3d;
          width: 0.1rem;
          flex-basis: 0.8rem;
        }

        &::after {
          content: '';
          background-color: #919eab3d;
          width: 0.1rem;
          flex-basis: 0.8rem;
        }

        &.active::before {
          background-color: #0d69d433;
        }

        &.active::after {
          background-color: #0d69d433;
        }

        ${StepperItemClickArea} {
          height: calc(100% - 3.2rem);
        }

        ${StepperItemNumberContainer}::after {
          content: none;
        }

        ${StepperItemNumberContainer}::before {
          content: none;
        }
      }

      ${StepperItemContainer}:first-child {
        &::before {
          content: none;
        }

        ${StepperItemClickArea} {
          margin: auto;
          left: unset;
          height: calc(100% - 1.6rem);
          margin-bottom: 1.6rem;
        }
      }

      ${StepperItemContainer}:last-child {
        &::after {
          content: none;
        }

        ${StepperItemClickArea} {
          margin: auto;
          right: unset;
          height: calc(100% - 1.6rem);
          margin-top: 1.6rem;
        }
      }

      ${StepperItemNumberContainer},
      ${StepperItemTextContainer} {
        align-self: auto;
      }
    }
  `}
`;
