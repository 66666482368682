import { type IDefaultIconProps } from 'data/modules/global';

export function Information({
  type,
  color,
  size
}: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 20 20'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 100-16.001A8 8 0 0010 18zM9 5h2v2H9V5zm0 4h2v6H9V9z'></path>
      </svg>
    );
  }
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 20'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zM9 9v6h2V9H9zm0-4v2h2V5H9z'></path>
    </svg>
  );
}
