import i18next from 'i18next';
import { create } from 'zustand';

import { langJSON } from 'shared/lang';
import { CustomCookies } from 'shared/utils/global';

import {
  type FullLanguageType,
  type IUseLangContext,
  type LanguageKeyType
} from './useLangContext.types';

export const useLangContext = create<IUseLangContext>()(set => ({
  lang: langJSON,
  currentLangKey:
    (CustomCookies.get('language')?.substring(0, 2) as LanguageKeyType) ?? 'pt',
  options: {
    pt: {
      code: 'pt-BR',
      lang: 'Português'
    },
    en: {
      code: 'en-US',
      lang: 'English'
    },
    es: {
      code: 'es-ES',
      lang: 'Español'
    }
  },
  changeLang: (key: FullLanguageType) => {
    const currentLangKey = key.substring(0, 2) as LanguageKeyType;

    set({ currentLangKey });

    i18next.changeLanguage(currentLangKey);

    CustomCookies.set('language', key, {
      daysToExpire: 365
    });
  }
}));
