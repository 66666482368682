import { create } from 'zustand';

import {
  AnalysisDateToConsiderFilter,
  AnalysisOrderByFilter,
  AnalysisReimbursableNonReimbursableFilter,
  AnalysisUserFilter
} from 'data/modules/analytics';

import { CustomDate } from 'shared/utils/custom';

import {
  type IUseAnalysisUserFilters,
  type IUseAnalysisUserFiltersContext
} from './useAnalysisUserFiltersContext.types';

export const defaultAnalysisUserFilters: IUseAnalysisUserFilters = {
  projects: [],
  costCenters: [],
  reportCodes: [],
  paymentForms: [],
  expensesTypes: [],
  expensesStatus: [],
  saveFilterPreferences: false,
  users: [AnalysisUserFilter.ACTIVE],
  orderBy: AnalysisOrderByFilter.VALUE_DESC,
  endDate: CustomDate.formatDateToIso(new Date()),
  initialDate: CustomDate.formatDateToIso(
    CustomDate.getDateSubtractingDays(new Date(), 7)
  ),
  dateToConsider: AnalysisDateToConsiderFilter.EXPENSE_CREATED_AT,
  reimbursableNonReimbursable:
    AnalysisReimbursableNonReimbursableFilter.REIMBURSABLE_NON_REIMBURSABLE
};

export const useAnalysisUserFiltersContext =
  create<IUseAnalysisUserFiltersContext>()((set, get) => ({
    defaultFilters: defaultAnalysisUserFilters,
    filters: defaultAnalysisUserFilters,
    isEmptyResults: true,
    currentPage: 1,
    totalPages: 1,
    perPage: 10,
    isListBottomReached: false,
    getSelectedFastDateFilter: () => {
      const initialDate = CustomDate.parseIsoToDate(
        get()?.filters?.initialDate ?? ''
      );

      const endDate = CustomDate.parseIsoToDate(get()?.filters?.endDate ?? '');

      const isToday = CustomDate.isEveryDateSameToday(initialDate, endDate);
      if (isToday) return 'TODAY';

      const isLastWeek = CustomDate.isLastSevenDays(initialDate, endDate);
      if (isLastWeek) return 'LAST_WEEK';

      const isLastMonth = CustomDate.isLastThirtyDays(initialDate, endDate);
      if (isLastMonth) return 'LAST_MONTH';

      const isThisMonth = CustomDate.isThisMonth(initialDate, endDate);
      if (isThisMonth) return 'THIS_MONTH';

      return undefined;
    },
    handleChangeFilters: filters => {
      set(state => ({
        ...state,
        perPage: 10,
        totalPages: 1,
        currentPage: 1,
        filters: {
          ...state?.filters,
          ...filters
        }
      }));
    },
    handleChangePagination: ({ currentPage, perPage, totalPages }) => {
      set({
        ...(perPage && { perPage }),
        ...(totalPages && { totalPages }),
        ...(currentPage && { currentPage })
      });
    },
    handleChangeIsEmptyResults: isEmptyResults => {
      set({
        isEmptyResults
      });
    },
    handleChangeIsListBottomReached: isListBottomReached => {
      set({
        isListBottomReached
      });
    }
  }));
