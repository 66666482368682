import { type IDefaultIconProps } from 'data/modules/global';

export function SkipBack({
  size,
  color,
  type
}: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path
          fill='none'
          d='M0 0h24v24H0z'
        ></path>
        <path d='M8 11.333l10.223-6.815a.5.5 0 01.777.416v14.132a.5.5 0 01-.777.416L8 12.667V19a1 1 0 01-2 0V5a1 1 0 112 0v6.333zm9 4.93V7.737L10.606 12 17 16.263z'></path>
      </svg>
    );
  }
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path
        fill='none'
        d='M0 0h24v24H0z'
      ></path>
      <path d='M8 11.333l10.223-6.815a.5.5 0 01.777.416v14.132a.5.5 0 01-.777.416L8 12.667V19a1 1 0 01-2 0V5a1 1 0 112 0v6.333z'></path>
    </svg>
  );
}
