import {
  type IDefaultResponse,
  type IDomainResponse,
  type IObjectsArray
} from 'data/modules/global';

class ResponseAdapter {
  formatRegularResponse<T>(response: IDefaultResponse<T>): IDomainResponse<T> {
    const {
      success,
      code,
      errors,
      to,
      from,
      current_page: currentPage,
      last_page: lastPage,
      per_page: perPage,
      total,
      data
    } = response;

    return {
      success,
      to,
      from,
      code,
      error: errors ? errors[0] : undefined,
      currentPage,
      lastPage,
      perPage,
      total,
      data
    };
  }

  formatObjectsArrayResponse<T>(
    response: IDefaultResponse<IObjectsArray<T> | null>
  ): IDomainResponse<T[] | null> {
    const {
      success,
      code,
      errors,
      current_page: currentPage,
      last_page: lastPage,
      per_page: perPage,
      total,
      data
    } = response;

    let formattedData: T[] | null = data as T[] | null;

    if (data && (data as IObjectsArray<T>).objects !== undefined) {
      formattedData = (data as IObjectsArray<T>).objects as T[];
    }

    return {
      success,
      code,
      error: errors ? errors[0] : undefined,
      currentPage,
      lastPage,
      perPage,
      total,
      data: formattedData
    };
  }
}

export default new ResponseAdapter();
