import { type RefObject } from 'react';

import html2canvas from 'html2canvas';

class CustomImage extends Image {
  async takeScreenShotAndDowndload(
    element: RefObject<HTMLElement>,
    fileName?: string
  ): Promise<void> {
    const currentElement = element.current;

    if (!currentElement) return;

    const canvas = await html2canvas(currentElement, {
      scale: 3
    });

    const image = canvas.toDataURL('image/png');
    const a = document.createElement('a');

    a.href = image;
    a.download = `${fileName}.png` ?? 'arquivo.png';
    a.click();
    a.remove();
  }
}
export default new CustomImage();
