import { useCallback, useEffect, useState } from 'react';

import { fetchAndActivate } from 'firebase/remote-config';
import { Outlet } from 'react-router-dom';

import { remoteConfig } from 'data/config';
import { useFlagsContext } from 'data/contexts';
import { useGetAuthUser } from 'data/modules/auth';

import { SplashScreen } from 'presentation/components/global/SplashScreen';

// Função para realizar tentativas de fetch com retry
async function tryFetchAndActivate(
  retries: number,
  delay: number
): Promise<void> {
  for (let i = 0; i < retries; i++) {
    try {
      await fetchAndActivate(remoteConfig);
      return;
    } catch (error) {
      if (i >= retries - 1) {
        throw error;
      }
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
}

export function FlagMiddleware(): JSX.Element {
  const { company, isFetchingAuthUser } = useGetAuthUser();
  const getRemoteFlags = useFlagsContext(state => state.getRemoteFlags);
  const [isFetchingFlags, setIsFetchingFlags] = useState(true);

  const retryLimit = 3;
  const retryDelay = 1500;

  const fetchFlags = useCallback(async () => {
    try {
      await tryFetchAndActivate(retryLimit, retryDelay);
    } catch (error) {
      throw new Error(String(error));
    } finally {
      setIsFetchingFlags(false);
      getRemoteFlags(company?.id);
    }
  }, [getRemoteFlags, company?.id]);

  useEffect(() => {
    if (!isFetchingAuthUser) {
      fetchFlags();
    }
  }, [fetchFlags, isFetchingAuthUser]);

  if (isFetchingFlags || isFetchingAuthUser) {
    return <SplashScreen />;
  }

  return <Outlet />;
}
