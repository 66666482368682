import { Group as Container } from '@radix-ui/react-dropdown-menu';

import { type IDropdownGroupProps } from './DropdownGroup.types';

import { StyledSeparator } from './DropdownGroup.styles';

export function DropdownGroup({
  children,
  lastGroup = false,
  ...rest
}: IDropdownGroupProps): JSX.Element {
  return (
    <Container {...rest}>
      {children}

      {!lastGroup && <StyledSeparator />}
    </Container>
  );
}
