import axios from 'axios';

import { env } from 'data/config';
import {
  getNotificationsMock,
  readAllNotificationsMock,
  readNotificationMock,
  unreadNotificationMock
} from 'data/mocks/notifications';

import {
  type IGetNotificationsPayload,
  type INotification,
  type IPersistenceNotification,
  type IReadAllNotificationsPayload,
  type IReadNotificationPayload,
  type IUnreadNotificationPayload,
  type WithNotificationsHeadersType
} from '..';

import { GetNotificationsMapper } from './mappers';

class NotificationService {
  async getNotifications(
    payload: IGetNotificationsPayload
  ): Promise<WithNotificationsHeadersType<INotification[]>> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await getNotificationsMock();
    }

    const { data, headers } = await axios.get<IPersistenceNotification[]>(
      `${env.VITE_NOTIFICATIONS_API}/notifications/${payload.userId}?page=${payload.page}&sort=desc`
    );

    return {
      data: GetNotificationsMapper.toDomain(data),
      headers: {
        count: Number(headers['x-total-count'] ?? 0),
        countUnread: Number(headers['x-total-count-unread'] ?? 0),
        totalPages: Number(headers['x-total-pages'] ?? 0),
        currentPage: Number(headers['x-current-page'] ?? 0)
      }
    };
  }

  async readNotification(payload: IReadNotificationPayload): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await readNotificationMock();
    }

    const { data } = await axios.put<null>(
      `${env.VITE_NOTIFICATIONS_API}/notifications/read/${payload.id}`
    );

    return data;
  }

  async unreadNotification(payload: IUnreadNotificationPayload): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await unreadNotificationMock();
    }

    const { data } = await axios.put<null>(
      `${env.VITE_NOTIFICATIONS_API}/notifications/unread/${payload.id}`
    );

    return data;
  }

  async readAllNotifications(
    payload: IReadAllNotificationsPayload
  ): Promise<null> {
    if (env.VITE_MOCK_API === 'ENABLED') {
      return await readAllNotificationsMock();
    }

    const { data } = await axios.put<null>(
      `${env.VITE_NOTIFICATIONS_API}/notifications/read/all/${payload.userId}`
    );

    return data;
  }
}

export default new NotificationService();
