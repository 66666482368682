import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.label`
  color: ${tokens.neutralColorLowDark};
  font-size: ${tokens.fontSizeXxs};
  letter-spacing: 0.75px;
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
  text-transform: uppercase;
`;
