import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type FloatingMenuStylesType } from './FloatingMenu.types';
import { type AddPrefixToType } from 'ds/types';

export const DropdownContainer = styled.div<
  AddPrefixToType<FloatingMenuStylesType>
>`
  display: flex;
  width: 23rem;
  max-width: 23rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: ${tokens.borderRadiusSm};
  background: ${tokens.deepColorWhite};
  box-shadow: 0 20px 40px -4px rgba(145 158 171 / 16%);
  margin: 1rem;

  &::-webkit-scrollbar-track {
    border: solid 3px transparent;
  }

  &::-webkit-scrollbar {
    width: 2rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${tokens.neutralColorLowLightest};
    border: 6px solid rgba(0 0 0 / 0%);
    background-clip: padding-box;
  }

  ${({ $maxHeight }) =>
    $maxHeight &&
    css`
      max-height: ${$maxHeight}rem;
      overflow-y: auto;
    `}

  ${({ $hasScroll }) =>
    $hasScroll &&
    css`
      width: 25rem;
      max-width: 25rem;
    `}
`;

export const TriggerContainer = styled.div`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  user-select: none;
`;

export const DropdownItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-style: normal;
  line-height: 2.2rem;
  transition: background 200ms ease-in-out;
  width: 100%;
  cursor: pointer;
  padding: ${tokens.spacingXxs} ${tokens.spacingXs};
  gap: ${tokens.spacingNano};
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: 1.4rem;
  font-weight: ${tokens.fontWeightRegular};
  z-index: 2;

  svg {
    flex-shrink: 0;
  }

  &:hover {
    background: ${tokens.neutralColorHighDark};
  }

  &:first-of-type {
    border-top-left-radius: ${tokens.borderRadiusSm};
    border-top-right-radius: ${tokens.borderRadiusSm};
  }

  &:last-of-type {
    border-bottom-left-radius: ${tokens.borderRadiusSm};
    border-bottom-right-radius: ${tokens.borderRadiusSm};
  }
`;

export const Arrow = styled.div<AddPrefixToType<FloatingMenuStylesType>>`
  position: absolute;
  pointer-events: none;
  z-index: 1;

  svg {
    width: 2.5rem;
    height: auto;

    path {
      fill: ${tokens.deepColorWhite};
    }
  }
`;
export const PopperContainer = styled.div`
  &[data-popper-placement*='bottom'] ${Arrow} {
    left: 0;
    top: 0;
    margin-top: 0.2rem;
  }

  &[data-popper-placement*='right'] ${Arrow} {
    left: 0;
    margin-left: -0.3rem;

    svg {
      transform: rotate(270deg);
    }
  }

  &[data-popper-placement*='top'] ${Arrow} {
    bottom: 0;
    left: 0;

    svg {
      transform: rotate(180deg);
    }
  }

  &[data-popper-placement*='left'] ${Arrow} {
    margin-right: -0.3rem;
    right: 0;

    svg {
      transform: rotate(-270deg);
    }
  }
`;
