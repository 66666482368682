import { DSIcons, tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { Container as NotificationList } from 'presentation/components/base/Notification/NotificationList/NotificationList.styles';
import { Wrapper as NotificationTrigger } from 'presentation/components/base/Notification/NotificationTrigger/NotificationTrigger.styles';
import {
  GoBackButton,
  TitleAndBreadcrumbsContainer
} from 'presentation/components/global/Navbar/TitleAndBreadcrumbs/TitleAndBreadcrumbs.styles';
import { StyledArrow } from 'presentation/ds/DsDropdown/DropdownContent/DropdownContent.styles';

import { type ContainerStylesType } from './Navbar.types';

export const NavbarLabel = styled.span`
  min-width: fit-content;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.2rem;
  text-align: center;
  letter-spacing: 0.025rem;
  padding: 0.3rem 1rem;
  border-radius: 8px;
  color: ${({ theme }) => theme.tokens.neutralColorHighPure};
  background: ${({ theme }) => theme.colors.mako};
  white-space: nowrap;
`;

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  width: 100%;
  background: ${({ theme }) => theme.colors.alabaster};
  color: ${({ theme }) => theme.colors.alto};
  box-sizing: border-box;
  display: flex;
  position: sticky;
  top: 0;
  left: 0%;
  z-index: ${({ theme }) => theme.zIndex.topbar};
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  overflow: visible;
  padding: 1.6rem;
  padding-left: 3.2rem;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.tokens.neutralColorHighMedium}`};
  min-height: 8rem;
  box-shadow: 0 0 2px 1px #00000010;

  & > svg {
    display: none;

    * {
      fill: ${({ theme }) => theme.tokens.brandColorPrimaryDarkest} !important;
    }

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }

  ${({ $outsourced }) =>
    $outsourced &&
    css`
      button > div > p {
        color: ${({ theme }) => theme.colors.mineShaft};
      }

      svg
        :not(${NotificationList} svg *):not(${NotificationTrigger} svg *):not(
          ${GoBackButton} *
        ):not(${TitleAndBreadcrumbsContainer} svg *) {
        fill: ${({ theme }) => theme.colors.mineShaft};
        stroke: ${({ theme }) => theme.colors.mineShaft};
      }

      ${NotificationTrigger} svg * {
        stroke: ${({ theme }) => theme.colors.mineShaft};
      }

      ${StyledArrow} * {
        fill: ${({ theme }) => theme.colors.white} !important;
        stroke: ${({ theme }) => theme.colors.white} !important;
      }

      ${NavbarLabel} {
        background-color: ${({ theme }) => theme.colors.adamantineBlue};
        color: ${({ theme }) => theme.colors.white};
      }
    `}

  @media screen and (max-width: 800px) {
    display: none;
  }

  @media screen and (max-width: 1200px) {
    padding-left: 1rem;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;

  svg :not(${NotificationTrigger} svg *) * {
    fill: ${({ theme }) => theme.colors.primary};
  }

  button {
    cursor: pointer;
    background-color: transparent;
    border: none;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 1.6rem;
  align-items: center;
  margin: 0 1.6rem;
  min-width: fit-content;

  svg :not(${NotificationTrigger} svg *) {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const RightContent = styled.div`
  min-width: fit-content;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: end;
  gap: 0.5rem;
`;

export const LeftContent = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  gap: 1.6rem;
  margin-right: 1rem;
  margin-left: auto;
`;

export const MobileContainer = styled(Container)`
  display: none;
  background-color: ${tokens.neutralColorHighLight};
  border: none;
  padding: 1.2rem 2rem 1.2rem 1.6rem;
  height: 8rem;
  box-shadow: 0 0 2px 1px #00000010;
  overflow: hidden;

  > svg {
    flex-shrink: 0;
    max-width: 1.8rem;
    height: 3rem;

    * {
      fill: ${({ theme }) => `${theme.colors.white}!important`};
      stroke: ${({ theme }) => `${theme.colors.white}!important`};
    }
  }

  @media screen and (max-width: 800px) {
    display: flex;
    justify-content: space-between;
  }
`;

export const MobileContent = styled.div`
  display: flex;
  gap: 1.6rem;
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  justify-content: start;

  > svg {
    max-width: 1.8rem;
    height: 3rem;
    display: none;

    * {
      fill: ${({ theme }) => theme.colors.primary} !important;
    }

    @media screen and (max-width: 1200px) {
      display: block;
    }
  }

  @media screen and (max-width: 1200px) {
    padding: 1rem;
    justify-content: flex-start;
  }
`;

export const MobileRightContent = styled.div`
  gap: ${tokens.spacingXs};
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  svg {
    stroke: ${tokens.brandColorSecondaryPure};
  }
`;

export const MobileLeftContent = styled.div`
  display: flex;
  align-items: center;
  gap: 1.5rem;
  width: fit-content;
  overflow: hidden;
`;

export const MenuIcon = styled(DSIcons.MenuBurger)`
  min-width: fit-content;
  display: none;

  * {
    stroke: ${tokens.brandColorSecondaryPure};
    fill: ${tokens.brandColorSecondaryPure};
  }

  @media screen and (max-width: 800px) {
    display: block;
  }
`;

export const OldOutsourcedContainer = styled.div`
  display: none;

  @media screen and (min-width: 1201px) {
    display: block;
  }
`;
