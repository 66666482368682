import { useState } from 'react';

import { type IUseLanguageSelector } from './LanguageSelector.types';

export function useLanguageSelector(): IUseLanguageSelector {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const langOptions = {
    pt: 'PT (BR)',
    en: 'EN (US)',
    es: 'ES (ES)'
  };

  return {
    dropdownOpen,
    setDropdownOpen,
    langOptions
  };
}
