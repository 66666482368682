import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled, { css, keyframes } from 'styled-components';

import { type DefaultTabItemPropsStylesType } from './DefaultTabItem.types';
import { type AddPrefixToType } from 'ds/types';

const widthAnimation = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`;

export const Container = styled.li<
  AddPrefixToType<DefaultTabItemPropsStylesType>
>`
  display: flex;
  width: fit-content;
  flex-direction: row;
  gap: ${tokens.spacingInsetNano};
  cursor: pointer;
  position: relative;
  align-items: center;
  padding: ${tokens.spacingQuarck} ${tokens.spacingXs};

  ${({ $badgePosition }) =>
    $badgePosition === 'left' &&
    css`
      flex-direction: row-reverse;
    `}

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      height: 5.2rem;

      button,
      span {
        font-size: ${tokens.fontSizeXxs};
      }

      span {
        line-height: 2.376rem;
      }
    `}

    ${({ $size }) =>
    $size === 'small' &&
    css`
      button,
      span {
        font-size: ${tokens.fontSizeXxss};
      }

      span {
        line-height: 1.98rem;
      }
    `}

    ${({ $active }) =>
    $active &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: -0.6rem;
        height: 0.4rem;
        left: 50%;
        width: 100%;
        background-color: ${tokens.brandColorPrimaryMedium};
        border-radius: ${tokens.borderRadiusXl};
        animation: ${widthAnimation} 200ms ease-in-out;
        transform: translate(-50%, 0);
      }

      button {
        color: ${tokens.brandColorPrimaryMedium} !important;
      }

      span {
        background-color: ${tokens.brandColorSecondaryPure};
        color: ${tokens.neutralColorHighPure};
      }
    `};
`;

export const TabButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
  color: ${tokens.neutralColorLowMedium};
  transition: ${transitions.allEaseInOut};
  letter-spacing: 0.075rem;
  line-height: 2.43rem;

  &:hover {
    color: ${tokens.neutralColorLowDarkest};
  }
`;

export const Badge = styled.span`
  border-radius: ${tokens.borderRadiusXs};
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightBold};
  padding: ${tokens.spacingMini} ${tokens.spacingNano};
  font-family: ${tokens.fontFamilyPoppins};
  line-height: 2.376rem;
  background-color: ${tokens.neutralColorHighDark};
  color: ${tokens.neutralColorLowMedium};
  height: fit-content;
`;
