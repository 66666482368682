import styled from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type TooltipStylesType } from './Tooltip.types';

export const Container = styled.div<AddPrefixToType<TooltipStylesType>>`
  display: ${({ $display }) => $display};
  margin-left: ${({ $marginLeft }) => `${$marginLeft}rem`};
  text-align: center;

  > span {
    color: ${({ $color, theme }) => theme.colors[$color]};
    font-size: ${({ $fontSize }) => `${$fontSize}rem`};
    display: flex;
    align-items: center;
  }

  > div {
    max-width: ${({ $maxWidth }) => $maxWidth}rem;
    border-radius: 0;
    padding: 0.313rem 0.5rem;
    font-size: ${({ $tooltipFontSize }) => $tooltipFontSize}rem;
  }
`;
