import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { toastVariants } from './constants/toastVariants';

import { type ToastStylesType, type ToastType } from './Toast.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<ToastStylesType>>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 27.4rem;
  border-radius: ${tokens.borderRadiusXs};
  padding: ${tokens.spacingXxs};
  padding-right: ${tokens.spacingNano};
  gap: ${tokens.spacingInsetXxs};
  background-color: ${tokens.neutralColorLowDark};
  box-shadow: ${tokens.shadowLeve4.x}px ${tokens.shadowLeve4.y}px
    ${tokens.shadowLeve4.blur}px ${tokens.shadowLeve4.spread}px
    ${tokens.shadowLeve4.color};

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }

  > svg:last-of-type {
    cursor: pointer;
    width: 0.938rem;
    height: 0.938rem;
    display: block;
    opacity: 1;
    visibility: visible;

    * {
      stroke: ${tokens.neutralColorHighLight};
    }
  }

  ${({ $variant }) =>
    $variant !== 'default' &&
    css`
      background-color: ${tokens.deepColorWhite};
      min-width: 27.6rem;

      > svg:last-of-type {
        * {
          stroke: ${tokens.neutralColorLowLight};
        }
      }

      span:first-of-type {
        color: ${tokens.neutralColorLowDarkest};
        font-weight: ${tokens.fontWeightMedium};
      }
    `}

  ${({ $variant }) =>
    $variant !== 'default' &&
    css`
      div > div:first-of-type {
        background-color: ${toastVariants[$variant as ToastType].background};
        padding: 1rem 1.1rem;
        border-radius: ${tokens.borderRadiusSm};
        max-width: 80%;
      }
    `};
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: ${tokens.spacingInsetXxs};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Text = styled.span`
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: ${tokens.spacingInsetMd};
  color: ${tokens.neutralColorHighPure};
`;

export const CloseIconContainer = styled.div`
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
`;

export const ToastIconContainer = styled.div`
  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;

export const Description = styled.span`
  font-size: ${tokens.fontSizeXxs};
  line-height: ${tokens.spacingInsetMd};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
`;

export const Footer = styled.span`
  font-size: ${tokens.fontSizeXxss};
  line-height: ${tokens.spacingInsetMd};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
`;
