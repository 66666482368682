import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import { transparentize } from 'polished';
import styled, { css } from 'styled-components';

import {
  type DragPropertiesStylesType,
  type OverlayStylesType,
  type TitleStylesType
} from './DragAndDropFileDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  height: 45.2rem;
  width: 40rem;
`;

export const InputFile = styled.input`
  display: none;
`;

export const FileActions = styled.div`
  position: absolute;
  z-index: 2;
  height: 100%;
  width: 100%;
  display: flex;
  padding: ${tokens.spacingLg};
  border-radius: ${tokens.borderRadiusSm};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  background-color: transparent;
  transition: ${transitions.allEaseInOut};
  opacity: 0;

  > button {
    width: 100%;
  }
`;

export const ImageFile = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: ${tokens.borderRadiusMd};
  object-fit: cover;
  border: 1px solid ${tokens.neutralColorHighDark};
`;

export const LabelFile = styled.label<
  AddPrefixToType<DragPropertiesStylesType>
>`
  position: relative;
  text-align: center;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: 1px dashed;
  padding: ${tokens.spacingLg};
  outline-color: ${tokens.brandColorSecondaryLight};
  border-radius: ${tokens.borderRadiusMd};
  background-color: ${tokens.brandColorPrimaryLightest};
  transition: ${transitions.allEaseInOut};
  cursor: pointer;

  ${({ $selectedFile, $selectedFileIsImage }) =>
    $selectedFile &&
    css`
      outline: transparent;
      cursor: auto;
      background: ${tokens.neutralColorHighMedium};

      &:hover {
        border-radius: ${tokens.borderRadiusSm};
        outline: 1px dashed;
        outline-color: ${tokens.brandColorSecondaryLight};

        ${FileActions} {
          opacity: 1;
          background: ${$selectedFileIsImage
            ? transparentize(0.1, tokens.neutralColorHighPure)
            : tokens.neutralColorHighLight};
        }
      }

      ${FileActions}:focus,
      ${FileActions}:focus-within {
        opacity: 1;
        background: ${$selectedFileIsImage
          ? transparentize(0.1, tokens.neutralColorHighPure)
          : tokens.neutralColorHighLight};
      }
    `}

  ${({ $documentDragActive }) =>
    $documentDragActive &&
    css`
      outline: 1px dashed;
      outline-color: ${tokens.brandColorSecondaryLight};
      border-radius: ${tokens.borderRadiusMd};
      background: ${tokens.supportsColorHighLight};
    `}

  ${({ $elementDragActive }) =>
    $elementDragActive &&
    css`
      outline: 1px dashed;
      border-radius: ${tokens.borderRadiusMd};
      background: ${tokens.brandColorPrimaryLight};
      outline-color: ${tokens.brandColorSecondaryPure};
    `}
`;

export const Title = styled.p<AddPrefixToType<TitleStylesType>>`
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamilyPoppins};
  margin-top: 2.4rem;

  ${({ $error }) =>
    $error &&
    css`
      color: ${tokens.feedbackColorErrorMedium};
    `}

  ${({ $documentDragActive }) =>
    $documentDragActive &&
    css`
      margin-top: 0;
      color: ${tokens.brandColorSecondaryPure};
    `}

  ${({ $elementDragActive }) =>
    $elementDragActive &&
    css`
      margin-top: 0;
      color: ${tokens.brandColorSecondaryMedium};
    `}
`;

export const Text = styled.p`
  font-size: ${tokens.fontSizeXxs};
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightRegular};
  font-family: ${tokens.fontFamilyPoppins};
  margin-top: 4rem;
`;

export const FilenameText = styled.p`
  font-size: ${tokens.fontSizeXxss};
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  color: ${tokens.neutralColorLowMedium};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
  margin-top: 2.4rem;
`;

export const IncompatibleFileMessage = styled.p`
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.feedbackColorErrorPure};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
  line-height: 2.4rem;
  letter-spacing: 0.075rem;
  margin-top: 0.8rem;
`;

export const Overlay = styled.div<AddPrefixToType<OverlayStylesType>>`
  position: absolute;
  height: calc(100% + 0.5rem);
  width: calc(100% + 0.5rem);
  opacity: 0;
  z-index: ${({ $highZIndex }) => ($highZIndex ? 3 : 1)};
`;

export const StyledIconFile = styled(DSIcons.FileIcon)`
  color: ${tokens.neutralColorLowLight};
  width: 8rem;
  height: 8rem;
`;
