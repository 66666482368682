import { type INotificationGroupTitleProps } from './NotificationGroupTitle.types';

import { Container } from './NotificationGroupTitle.styles';

export function NotificationGroupTitle({
  children,
  ...props
}: INotificationGroupTitleProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
