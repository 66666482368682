import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.div`
  margin-left: auto;

  .notification-menu-dropdown {
    top: 0;
    right: 100%;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${tokens.spacingMini};

  svg {
    transition: color 0.5s ease;
    color: ${tokens.neutralColorLowMediumLight};

    &:hover {
      color: ${tokens.brandColorSecondaryPure};
    }

    &:active {
      color: ${tokens.brandColorSecondaryMedium};
    }
  }
`;

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingMini};
`;
