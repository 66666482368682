import { useQueryCache } from 'data/cache';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetAuthCardsUser,
  type IUseGetAuthCardsUserParams
} from 'data/modules/auth';

export function useGetAuthCardsUser({
  enabled
}: IUseGetAuthCardsUserParams): IUseGetAuthCardsUser {
  const {
    isFetching: isFetchingCardsUser,
    data: cardsUser,
    isError: cardsUserError
  } = useQueryCache({
    queryKey: [AuthQueryKeys.AUTH_CARDS_USER],
    queryFn: async () => await AuthService.getAuthCardsUser(),
    enabled,
    staleTime: Infinity
  });

  return {
    cardsUser,
    cardsUserError,
    isFetchingCardsUser
  };
}
