import { DefaultTabs } from 'ds';
import styled from 'styled-components';

export const TabsContainer = styled(DefaultTabs.Container)`
  margin-left: 1.6rem;
  max-width: 100%;

  li {
    white-space: nowrap;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: 90%;
    overflow-x: auto;
    overflow-y: hidden;
    height: 12rem;
  }
`;
