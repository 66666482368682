import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled, { css } from 'styled-components';

import { type ButtonStylesType } from './WarningButton.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.button<AddPrefixToType<ButtonStylesType>>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  transition: ${transitions.allEaseInOut};
  line-height: 2.1rem;
  gap: ${tokens.spacingInsetNano};
  padding: ${tokens.spacingSm} ${tokens.spacingLg};
  background-color: ${tokens.feedbackColorWarningLightest};
  color: ${tokens.feedbackColorWarningMedium};
  font-family: ${tokens.fontFamilyPoppins}, sans-serif;
  font-size: ${tokens.fontSizeXxs};
  font-weight: ${tokens.fontWeightSemibold};
  border-radius: ${tokens.borderRadiusSm};

  * {
    transition: ${transitions.allEaseInOut};
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;

    color: ${tokens.feedbackColorWarningMedium} !important;
  }

  &:hover {
    background-color: ${tokens.feedbackColorWarningPure};
    color: ${tokens.neutralColorHighPure};

    svg * {
      color: ${tokens.neutralColorHighPure};
    }
  }

  &:focus-visible {
    outline: ${tokens.borderWidthThick} solid
      ${tokens.feedbackColorWarningPure}B2;
    background-color: ${tokens.feedbackColorWarningLightest};
    color: ${tokens.feedbackColorWarningPure};

    svg * {
      color: ${tokens.feedbackColorWarningPure} !important;
    }
  }

  &:active {
    background-color: ${tokens.feedbackColorWarningMedium};
    color: ${tokens.neutralColorHighPure};
    outline: none;

    svg * {
      color: ${tokens.neutralColorHighPure} !important;
    }
  }

  &:disabled {
    background-color: ${tokens.neutralColorHighMedium};
    cursor: not-allowed;

    color: ${tokens.neutralColorLowMediumLight} !important;

    svg * {
      color: ${tokens.neutralColorLowMediumLight} !important;
    }
  }

  ${({ $variant }) =>
    $variant === 'pill' &&
    css`
      border-radius: ${tokens.borderRadiusPill};
    `}

  ${({ $size }) =>
    $size === 'huge' &&
    css`
      padding: ${tokens.spacingMd} ${tokens.spacingLg};
      font-size: ${tokens.fontSizeXs};
      border-radius: ${tokens.borderRadiusMd};
    `}

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      padding: ${tokens.spacingXs} ${tokens.spacingMd};
    `}

  ${({ $size }) =>
    $size === 'small' &&
    css`
      border-radius: ${tokens.borderRadiusXs};
      padding: ${tokens.spacingNano} calc(${tokens.spacingMd} - 0.4rem);
      font-size: ${tokens.fontSizeXxss};
      line-height: 1.82rem;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    `}
`;
