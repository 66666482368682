import { type IDefaultIconProps } from 'data/modules/global';

export function Coins({ type, color, size }: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M14.0049 2.00293C18.4232 2.00293 22.0049 5.58465 22.0049 10.0029C22.0049 13.2475 20.0733 16.041 17.2973 17.2962C16.0422 20.0719 13.249 22.0029 10.0049 22.0029C5.5866 22.0029 2.00488 18.4212 2.00488 14.0029C2.00488 10.7588 3.9359 7.96566 6.71122 6.71024C7.96681 3.9345 10.7603 2.00293 14.0049 2.00293ZM10.0049 8.00293C6.69117 8.00293 4.00488 10.6892 4.00488 14.0029C4.00488 17.3166 6.69117 20.0029 10.0049 20.0029C13.3186 20.0029 16.0049 17.3166 16.0049 14.0029C16.0049 10.6892 13.3186 8.00293 10.0049 8.00293ZM11.0049 9.00293V10.0029H13.0049V12.0029H9.00488C8.72874 12.0029 8.50488 12.2268 8.50488 12.5029C8.50488 12.7484 8.68176 12.9525 8.91501 12.9949L9.00488 13.0029H11.0049C12.3856 13.0029 13.5049 14.1222 13.5049 15.5029C13.5049 16.8836 12.3856 18.0029 11.0049 18.0029V19.0029H9.00488V18.0029H7.00488V16.0029H11.0049C11.281 16.0029 11.5049 15.7791 11.5049 15.5029C11.5049 15.2575 11.328 15.0533 11.0948 15.011L11.0049 15.0029H9.00488C7.62417 15.0029 6.50488 13.8836 6.50488 12.5029C6.50488 11.1222 7.62417 10.0029 9.00488 10.0029V9.00293H11.0049ZM14.0049 4.00293C12.2214 4.00293 10.6196 4.78109 9.52064 6.01642C9.68133 6.00776 9.84254 6.00293 10.0049 6.00293C14.4232 6.00293 18.0049 9.58465 18.0049 14.0029C18.0049 14.1656 18 14.3272 17.9905 14.4874C19.2265 13.3887 20.0049 11.7867 20.0049 10.0029C20.0049 6.68922 17.3186 4.00293 14.0049 4.00293Z'></path>
      </svg>
    );
  }
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M14.0049 2.00293C18.4232 2.00293 22.0049 5.58465 22.0049 10.0029C22.0049 13.2475 20.0733 16.041 17.2973 17.2962C16.0422 20.0719 13.249 22.0029 10.0049 22.0029C5.5866 22.0029 2.00488 18.4212 2.00488 14.0029C2.00488 10.7588 3.9359 7.96566 6.71122 6.71024C7.96681 3.9345 10.7603 2.00293 14.0049 2.00293ZM11.0049 9.00293H9.00488V10.0029C7.62417 10.0029 6.50488 11.1222 6.50488 12.5029C6.50488 13.8284 7.53642 14.913 8.84051 14.9976L9.00488 15.0029H11.0049L11.0948 15.011C11.328 15.0533 11.5049 15.2575 11.5049 15.5029C11.5049 15.7484 11.328 15.9525 11.0948 15.9949L11.0049 16.0029H7.00488V18.0029H9.00488V19.0029H11.0049V18.0029C12.3856 18.0029 13.5049 16.8836 13.5049 15.5029C13.5049 14.1774 12.4733 13.0929 11.1693 13.0082L11.0049 13.0029H9.00488L8.91501 12.9949C8.68176 12.9525 8.50488 12.7484 8.50488 12.5029C8.50488 12.2575 8.68176 12.0533 8.91501 12.011L9.00488 12.0029H13.0049V10.0029H11.0049V9.00293ZM14.0049 4.00293C12.2214 4.00293 10.6196 4.78109 9.52064 6.01642C9.68133 6.00776 9.84254 6.00293 10.0049 6.00293C14.4232 6.00293 18.0049 9.58465 18.0049 14.0029C18.0049 14.1656 18 14.3272 17.9905 14.4874C19.2265 13.3887 20.0049 11.7867 20.0049 10.0029C20.0049 6.68922 17.3186 4.00293 14.0049 4.00293Z'></path>
    </svg>
  );
}
