import { useMutationCache } from 'data/cache';
import {
  type IUnreadNotificationPayload,
  type IUseUnreadNotification,
  NotificationsMutationKeys,
  NotificationsService
} from 'data/modules/notifications';

export function useUnreadNotification(): IUseUnreadNotification {
  const {
    mutateAsync: unreadNotificationAsync,
    isLoading: isUnreadingNotification,
    isSuccess: isUnreadNotificationSuccess
  } = useMutationCache({
    mutationFn: async (payload: IUnreadNotificationPayload) =>
      await NotificationsService.unreadNotification(payload),
    mutationKey: [NotificationsMutationKeys.UNREAD_NOTIFICATION]
  });

  return {
    unreadNotification: unreadNotificationAsync,
    isUnreadingNotification,
    isUnreadNotificationSuccess
  };
}
