import { useEffect } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ToastContainer as DsToastContainer } from 'ds';
import GA4 from 'react-ga4';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';

import { env } from 'data/config';

import { ZendeskCloseAction } from 'presentation/components/global/ZendeskCloseAction';

import { Router } from 'shared/routes/Router';
import GlobalStyles from 'shared/styles/GlobalStyles';
import { defaultTheme } from 'shared/styles/themes/default.theme';

function App(): JSX.Element {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5 // 5 minutos
      }
    }
  });

  useEffect(() => {
    if (env.VITE_GA_ID) {
      GA4.initialize(env.VITE_GA_ID);
    }

    if (env.VITE_GTM_ID) {
      TagManager.initialize({
        gtmId: env.VITE_GTM_ID
      });
    }
  }, []);

  return (
    <>
      <ZendeskCloseAction />

      <QueryClientProvider client={queryClient}>
        <div style={{ fontSize: '16px' }}>
          <ReactQueryDevtools
            initialIsOpen={false}
            buttonPosition='bottom-left'
          />
        </div>

        <ThemeProvider theme={defaultTheme}>
          <GlobalStyles />

          <DsToastContainer />

          <Router />
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
