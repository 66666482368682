import { type MutableRefObject } from 'react';

class Elements {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isInTable(elementRef: MutableRefObject<any>): boolean {
    const maxIteration = 100;
    let currentElement = elementRef.current;
    let iterations = 0;

    while (currentElement && iterations < maxIteration) {
      if (currentElement.nodeName.toLowerCase() === 'table') {
        return true;
      }

      currentElement = currentElement.parentElement;
      iterations++;
    }

    return false;
  }
}

export default new Elements();
