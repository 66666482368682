import { type IDefaultIconProps } from 'data/modules/global';

export function BuildingBusinessOffice({
  color,
  size
}: IDefaultIconProps): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M1 4L0.790471 3.0222L0 3.19159V4H1ZM15 23V24V23ZM1 23H0V24H1V23ZM12.5809 1.51837L12.7905 2.49617L12.5809 1.51837ZM6 19V18H5V19H6ZM10 19H11V18H10V19ZM10 23V24H11V23H10ZM6 23H5V24H6V23ZM15 5V4C14.4477 4 14 4.44772 14 5H15ZM23 5H24C24 4.44772 23.5523 4 23 4V5ZM23 23V24C23.5523 24 24 23.5523 24 23H23ZM1.20953 4.97781L12.7905 2.49617L12.3714 0.540569L0.790471 3.0222L1.20953 4.97781ZM14 3.47398V23H16V3.47398H14ZM15 22H1V24H15V22ZM2 23V4H0V23H2ZM12.7905 2.49617C13.413 2.36278 14 2.83733 14 3.47398H16C16 1.56404 14.2389 0.140382 12.3714 0.540569L12.7905 2.49617ZM6 20H10V18H6V20ZM9 19V23H11V19H9ZM10 22H6V24H10V22ZM7 23V19H5V23H7ZM19 15H23V13H19V15ZM19 11H23V9H19V11ZM19 19H23V17H19V19ZM15 6H23V4H15V6ZM22 5V23H24V5H22ZM23 22L15 22V24L23 24V22ZM16 23V5H14V23H16ZM18 0V5H20V0H18ZM4 16H7V14H4V16ZM9 16H12V14H9V16ZM9 8H12V6H9V8ZM4 8H7V6H4V8ZM4 12H7V10H4V12ZM9 12H12V10H9V12Z' />
    </svg>
  );
}
