import { Children } from 'react';

import { Link } from 'react-router-dom';

import { NotificationIcon } from 'presentation/components/base/Notification/NotificationIcon/NotificationIcon.component';
import { NotificationMenu } from 'presentation/components/base/Notification/NotificationMenu/NotificationMenu.component';

import { type INotificationCardProps } from './NotificationCard.types';

import { Container, Content } from './NotificationCard.styles';

export function NotificationCard({
  url,
  onClick,
  children,
  read = false,
  disabled = false,
  ...props
}: INotificationCardProps): JSX.Element {
  const Elements = Children.toArray(children) as JSX.Element[];

  const Icon = Elements.find(
    child => child.type.name === NotificationIcon.name
  );
  const Menu = Elements.find(
    child => child.type.name === NotificationMenu.name
  );
  const ContentElements = Elements.filter(
    child =>
      child.type.name !== NotificationIcon.name &&
      child.type.name !== NotificationMenu.name
  );

  return (
    <Container
      {...props}
      $read={read}
      data-read={read}
      $disabled={disabled}
      data-disabled={disabled}
    >
      <Link
        to={url}
        onClick={e => {
          e.preventDefault();
          onClick?.();
        }}
      >
        {Icon && <Icon.type {...Icon.props} />}
        <Content>{...ContentElements}</Content>
        {Menu && <Menu.type {...Menu.props} />}
      </Link>
    </Container>
  );
}
