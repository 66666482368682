import { type IDefaultIconProps } from 'data/modules/global';

export function ExpandUpDown({
  color,
  size,
  type
}: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M18.207 9.043L12 2.836 5.793 9.043l1.414 1.414L12 5.664l4.793 4.793 1.414-1.414zM5.793 14.957L12 21.165l6.207-6.208-1.414-1.414L12 18.336l-4.793-4.793-1.414 1.414z'></path>
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M18 9l-6-6-6 6h12zm0 6l-6 6-6-6h12z'></path>
    </svg>
  );
}
