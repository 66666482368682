import { Toast as ToastComponent } from 'ds';
import { toast as reactToastify } from 'react-toastify';

class Toast {
  static errorToastId: string | number | null = null;
  static warningToastId: string | number | null = null;

  success(message: string, description?: string): void {
    reactToastify(
      <ToastComponent
        message={message}
        variant='success'
        description={description}
      />,
      {
        containerId: 'toast'
      }
    );
  }

  error(message: string, toastId?: string, description?: string): void {
    if (!Toast.errorToastId) {
      Toast.errorToastId = reactToastify(
        <ToastComponent
          message={message}
          variant='error'
          description={description}
        />,
        {
          containerId: 'toast',
          toastId,
          onClose: () => {
            Toast.errorToastId = null;
          }
        }
      );
    }
  }

  errorWithoutToastId(message: string, description?: string): void {
    reactToastify(
      <ToastComponent
        message={message}
        variant='error'
        description={description}
      />,
      {
        containerId: 'toast'
      }
    );
  }

  warning(message: string, description?: string, unique?: boolean): void {
    if (unique) {
      if (!Toast.warningToastId) {
        Toast.warningToastId = reactToastify(
          <ToastComponent
            message={message}
            variant='warning'
            description={description}
          />,
          {
            containerId: 'toast',
            onClose: () => {
              Toast.warningToastId = null;
            }
          }
        );
      }

      return;
    }

    reactToastify(
      <ToastComponent
        message={message}
        variant='warning'
        description={description}
      />,
      {
        containerId: 'toast'
      }
    );
  }

  info(message: string, description?: string): void {
    reactToastify(
      <ToastComponent
        message={message}
        variant='info'
        description={description}
      />,
      {
        containerId: 'toast'
      }
    );
  }

  default(message: string, description?: string): void {
    reactToastify(
      <ToastComponent
        message={message}
        variant='default'
        description={description}
      />,
      {
        containerId: 'toast'
      }
    );
  }

  notification(
    message: string,
    description: string,
    footer?: string,
    id?: string,
    onToastClick?: () => void
  ): void {
    reactToastify(
      <ToastComponent
        footer={footer}
        message={message}
        variant='notification'
        description={description}
        dismissAfterClicked={false}
        onToastClick={onToastClick}
      />,
      {
        containerId: 'toast',
        toastId: id
      }
    );
  }
}

export const toast = new Toast();
