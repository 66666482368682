import analyticsJSON from './analytics.ga4.json';
import cardsJSON from './cards.ga4.json';
import expensesJSON from './expenses.ga4.json';
import notificationsJSON from './notifications.ga4.json';
import reportsJSON from './reports.ga4.json';
import storeJSON from './store.ga4.json';
import { travelJSON } from './travel';

export const ga4 = {
  expenses: expensesJSON,
  cards: cardsJSON,
  store: storeJSON,
  analytics: analyticsJSON,
  notifications: notificationsJSON,
  travel: travelJSON,
  reports: reportsJSON
};
