import { type INotificationActionProps } from './NotificationAction.types';

import { Container } from './NotificationAction.styles';

export function NotificationAction({
  children,
  ...props
}: INotificationActionProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
