import { create } from 'zustand';

import { type IUseTravelRequestsContext } from './useTravelRequestsContext.types';

export const useTravelRequestsContext = create<IUseTravelRequestsContext>()(
  set => ({
    hasBookingsAboutToExpire: false,
    hasExpiredBookings: false,
    setHasBookingsAboutToExpire: hasBookingsAboutToExpire => {
      set({ hasBookingsAboutToExpire });
    },
    setHasExpiredBookings: hasExpiredBookings => {
      set({ hasExpiredBookings });
    },
    filters: {
      status: null,
      requestedDate: null,
      users: [],
      tripDates: null,
      policy: 'all',
      bookingType: 'all',
      page: 1,
      perPage: 10,
      orderBy: null,
      order: 'default'
    },
    handleChangeFilter: newFilters => {
      set(state => ({
        filters: {
          ...state.filters,
          ...newFilters,
          page: newFilters.page ?? 1
        }
      }));
    },
    totalOfTravelRequests: 0,
    setTotalOfTravelRequests: total => {
      set({ totalOfTravelRequests: total });
    }
  })
);
