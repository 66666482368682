import { type IMenuTabContainerProps } from './MenuTabContainer.types';

import { Container, TabsList } from './MenuTabContainer.styles';

export function MenuTabContainer({
  children,
  hasTrack,
  spaced,
  ...rest
}: IMenuTabContainerProps): JSX.Element {
  return (
    <Container
      data-testid='container'
      {...rest}
    >
      <TabsList
        $spaced={spaced}
        $hasTrack={hasTrack}
      >
        {children}
      </TabsList>
    </Container>
  );
}
