import { DefaultTabs } from 'ds';
import { useTranslation } from 'react-i18next';

import { useGetSessionUser } from 'data/modules/auth';

import { Notification } from 'presentation/components/base/Notification';

import { useGa4 } from 'shared/hooks/global';

import { useNotifications } from './useNotifications';

import { TabsContainer } from './Notifications.styles';

export function Notifications(): JSX.Element {
  const { t } = useTranslation(['notifications', 'global']);

  const { user } = useGetSessionUser();

  const {
    countUnread,
    notifications,
    getMenuIsLoading,
    loadingMoreStatus,
    newNotificationArrived,
    handleReadNotification,
    handleNotificationClick,
    handleUnreadNotification,
    isReadingAllNotifications,
    handleReadAllNotificationsClick,
    handleGetNextNotificationsPageClick,
    notificationTab,
    setNotificationTab
  } = useNotifications();

  const { sendGaEvent } = useGa4();

  /**
   * O componente não é renderizado enquanto não tiver os dados do user
   */
  if (!user) return <></>;

  return (
    <Notification.Dropdown>
      {/* Componente que abre/fecha o dropdown (Trigger) */}
      <Notification.Trigger>
        <Notification.Counter
          onClick={() => {
            sendGaEvent('notifications', 'vex_home_notif_icon_exibir');
          }}
          counter={countUnread}
          totalCount={notifications.total}
          newNotificationArrived={newNotificationArrived}
        />
      </Notification.Trigger>

      <Notification.Container>
        <Notification.Header
          unreadCounter={countUnread}
          isReadingAllNotifications={isReadingAllNotifications}
          onReadAllNotificationsClick={() => {
            const items = Object.values(notifications.items).flat();

            if (items.length > 0 && notificationTab !== 'all') {
              handleReadAllNotificationsClick(items);
              return;
            }

            handleReadAllNotificationsClick();
          }}
        />

        <TabsContainer hasTrack>
          <DefaultTabs.Item
            badge={
              notificationTab === 'all' ? notifications.totalActive : undefined
            }
            size='small'
            active={notificationTab === 'all'}
            onClick={() => {
              setNotificationTab('all');
              sendGaEvent('notifications', 'vex_home_notif_todas');
            }}
          >
            {t('global:all_female_other')}
          </DefaultTabs.Item>

          <DefaultTabs.Item
            size='small'
            badge={
              notificationTab === 'actions'
                ? notifications.totalActive
                : undefined
            }
            active={notificationTab === 'actions'}
            onClick={() => {
              setNotificationTab('actions');
              sendGaEvent('notifications', 'vex_home_notif_acoes');
            }}
          >
            {t('notifications:actions')}
          </DefaultTabs.Item>

          <DefaultTabs.Item
            size='small'
            badge={
              notificationTab === 'my_expenses'
                ? notifications.totalActive
                : undefined
            }
            active={notificationTab === 'my_expenses'}
            onClick={() => {
              setNotificationTab('my_expenses');
              sendGaEvent('notifications', 'vex_home_notif_despesas');
            }}
          >
            {t('notifications:myExpenses')}
          </DefaultTabs.Item>

          <DefaultTabs.Item
            size='small'
            badge={
              notificationTab === 'others'
                ? notifications.totalActive
                : undefined
            }
            active={notificationTab === 'others'}
            onClick={() => {
              setNotificationTab('others');
              sendGaEvent('notifications', 'vex_home_notif_outras');
            }}
          >
            {t('notifications:others')}
          </DefaultTabs.Item>
        </TabsContainer>

        {/* Componente que mostra as notificações (Central) */}
        <Notification.List
          loadingStatus={loadingMoreStatus}
          onLoadMoreClick={handleGetNextNotificationsPageClick}
        >
          {notifications.items.today &&
            notifications.items.today?.length > 0 && (
              <>
                <Notification.GroupTitle>
                  {t('global:today')}
                </Notification.GroupTitle>

                {notifications.items.today.map(notification => (
                  <Notification.Card
                    key={notification.id}
                    url={notification.url}
                    read={notification.isRead}
                    onClick={handleNotificationClick(notification.id)}
                  >
                    <Notification.Icon icon={notification.icon} />
                    <Notification.Title>
                      {notification.title}
                    </Notification.Title>
                    <Notification.Body>
                      {notification.message}
                    </Notification.Body>

                    <Notification.Body>
                      {notification.subMessage}
                    </Notification.Body>
                    {notification.createdAt && (
                      <Notification.Date>
                        {notification.createdAt}
                      </Notification.Date>
                    )}

                    <Notification.Menu
                      isLoading={getMenuIsLoading(notification.id)}
                    >
                      {notification.isRead ? (
                        <Notification.Action
                          onClick={() => {
                            handleUnreadNotification(notification.id);
                          }}
                        >
                          {t('notifications:notificationUnreadAction')}
                        </Notification.Action>
                      ) : (
                        <Notification.Action
                          onClick={() => {
                            handleReadNotification(notification.id);
                          }}
                        >
                          {t('notifications:notificationReadAction')}
                        </Notification.Action>
                      )}
                    </Notification.Menu>
                  </Notification.Card>
                ))}
              </>
            )}

          {notifications.items.yesterday &&
            notifications.items.yesterday?.length > 0 && (
              <>
                <Notification.GroupTitle>
                  {t('global:yesterday')}
                </Notification.GroupTitle>

                {notifications.items.yesterday.map(notification => (
                  <Notification.Card
                    key={notification.id}
                    url={notification.url}
                    read={notification.isRead}
                    onClick={handleNotificationClick(notification.id)}
                  >
                    <Notification.Icon icon={notification.icon} />
                    <Notification.Title>
                      {notification.title}
                    </Notification.Title>
                    <Notification.Body>
                      {notification.message}
                    </Notification.Body>

                    <Notification.Body>
                      {notification.subMessage}
                    </Notification.Body>
                    {notification.createdAt && (
                      <Notification.Date>
                        {notification.createdAt}
                      </Notification.Date>
                    )}

                    <Notification.Menu
                      isLoading={getMenuIsLoading(notification.id)}
                    >
                      {notification.isRead ? (
                        <Notification.Action
                          onClick={() => {
                            handleUnreadNotification(notification.id);
                          }}
                        >
                          {t('notifications:notificationUnreadAction')}
                        </Notification.Action>
                      ) : (
                        <Notification.Action
                          onClick={() => {
                            handleReadNotification(notification.id);
                          }}
                        >
                          {t('notifications:notificationReadAction')}
                        </Notification.Action>
                      )}
                    </Notification.Menu>
                  </Notification.Card>
                ))}
              </>
            )}

          {notifications.items.older &&
            notifications.items.older?.length > 0 && (
              <>
                <Notification.GroupTitle>
                  {t('notifications:olders')}
                </Notification.GroupTitle>

                {notifications.items.older.map(notification => (
                  <Notification.Card
                    key={notification.id}
                    url={notification.url}
                    read={notification.isRead}
                    onClick={handleNotificationClick(notification.id)}
                  >
                    <Notification.Icon icon={notification.icon} />
                    <Notification.Title>
                      {notification.title}
                    </Notification.Title>
                    <Notification.Body>
                      {notification.message}
                    </Notification.Body>

                    <Notification.Body>
                      {notification.subMessage}
                    </Notification.Body>
                    {notification.createdAt && (
                      <Notification.Date>
                        {notification.createdAt}
                      </Notification.Date>
                    )}

                    <Notification.Menu
                      isLoading={getMenuIsLoading(notification.id)}
                    >
                      {notification.isRead ? (
                        <Notification.Action
                          onClick={() => {
                            handleUnreadNotification(notification.id);
                          }}
                        >
                          {t('notifications:notificationUnreadAction')}
                        </Notification.Action>
                      ) : (
                        <Notification.Action
                          onClick={() => {
                            handleReadNotification(notification.id);
                          }}
                        >
                          {t('notifications:notificationReadAction')}
                        </Notification.Action>
                      )}
                    </Notification.Menu>
                  </Notification.Card>
                ))}
              </>
            )}
        </Notification.List>
      </Notification.Container>
    </Notification.Dropdown>
  );
}
