import styled, { css, keyframes } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { DropdownContent, DropdownItem } from 'presentation/ds/DsDropdown';

import { type WrapperStylesType } from './LanguageSelector.types';

export const Wrapper = styled.div<AddPrefixToType<WrapperStylesType>>`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
  cursor: pointer;
  min-width: 100%;

  > p {
    margin-left: 1rem;
    font-weight: 400;
    min-width: fit-content;
    font-size: 1.6rem;
    margin-right: 0.5rem;
  }

  > svg:first-child {
    width: 2.4rem;
    height: 2.4rem;
  }

  > svg:last-child {
    transition: all 200ms ease-in-out;
  }

  ${({ $dropdownOpen }) =>
    $dropdownOpen &&
    css`
      > svg:last-child {
        transform: rotateZ(3.14rad);
      }
    `}
`;

const slideUp = keyframes({
  from: { height: 0, overflow: 'hidden' },
  to: { height: '10.4rem', overflow: 'hidden' }
});

const slideDown = keyframes({
  from: {
    height: '10.4rem',
    overflow: 'hidden'
  },
  to: { height: 0, overflow: 'hidden' }
});

export const DropdownContentWrapper = styled(DropdownContent)`
  padding: 0;
  max-height: 10.4rem;
  position: relative;
  margin-top: 1rem;

  &[data-state='open'] {
    animation: ${slideUp} 300ms ease-in-out;
  }

  &[data-state='closed'] {
    animation: ${slideDown} 300ms ease-in-out;
  }

  span {
    left: unset;
    right: 2.4rem;

    svg {
      width: 2rem;
      height: auto;
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const DropdownLanguageItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  column-gap: 1rem;
  font-weight: 500;
  font-size: 1.4rem;
  padding: 1.5rem;
  text-align: left;
  margin: 0;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.independence};
  transition: all 200ms ease-in;

  &:hover {
    background-color: #4e4b6625;
    color: ${({ theme }) => theme.colors.independence};
  }

  &:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  &:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

export const DropdownLanguageScroll = styled.div`
  height: fit-content;
  max-height: 19.3rem;
  overflow-y: auto;
  position: relative;
  width: 100%;
  z-index: 40 !important;

  /* width */
  &::-webkit-scrollbar {
    width: 0.6rem;
  }

  ::-webkit-scrollbar-track {
    border: solid 3px transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: ${({ theme }) => theme.colors.gray85};
  }
`;
