import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type MarkAllAsReadButtonStyleType } from './NotificationHeader.types';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  gap: ${tokens.spacingNano};
  padding: ${tokens.spacingNano};
  padding-right: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-wrap: wrap;

    > div {
      margin-left: unset;
    }
  }
`;

export const Title = styled.strong`
  font-style: normal;
  line-height: 3.2rem;
  letter-spacing: 0.75px;
  font-size: ${tokens.fontSizeXs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
`;

export const CounterTag = styled.span`
  display: flex;
  line-height: 2rem;
  text-align: center;
  font-style: normal;
  align-items: center;
  white-space: nowrap;
  padding: 0.1rem 0.8rem;
  justify-content: center;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightBold};
  border-radius: ${tokens.borderRadiusXs};
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamiliesPoppins};
  background: ${tokens.neutralColorHighMedium};
`;

export const MarkAllAsReadWrapper = styled.div`
  display: flex;
  margin-left: auto;
  gap: ${tokens.spacingNano};
`;

export const MarkAllAsReadButton = styled.button<
  AddPrefixToType<MarkAllAsReadButtonStyleType>
>`
  border: none;
  text-align: center;
  font-style: normal;
  line-height: 2.2rem;
  letter-spacing: 0.25px;
  background: transparent;
  transition: all 0.3s ease-in-out;
  font-size: ${tokens.fontSizeXxss};
  color: ${tokens.brandColorPrimaryPure};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamiliesPoppins};

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: not-allowed !important;
      opacity: 0.3;
      pointer-events: none;
    `}
`;
