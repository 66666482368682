import { type IAccordionContainerProps } from './AccordionContainer.types';

import { Container } from './AccordionContainer.styles';

export function AccordionContainer({
  children,
  ...props
}: IAccordionContainerProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
