import { type IDefaultIconProps } from 'data/modules/global';

export function Bell({ size, color, type }: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        width={size ? `${size}rem` : '1rem'}
        height={size ? `${size}rem` : '1rem'}
        fill='none'
        viewBox='0 0 18 24'
        xmlns='http://www.w3.org/2000/svg'
        id='notification'
      >
        <path
          d='M6.32605 19.4013V19.8272C6.32605 21.303 7.52305 22.5 9.00005 22.5V22.5C10.477 22.5 11.674 21.303 11.674 19.826V19.4002'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M11.1385 4.7095V3.6385C11.1385 2.45783 10.1818 1.5 9.00001 1.5V1.5C7.81818 1.5 6.86151 2.45783 6.86151 3.6385V4.7095'
          stroke={color}
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M2.65685 9.886C2.65685 6.96584 5.02402 4.59984 7.94302 4.59984H10.0582C12.9784 4.59984 15.3444 6.967 15.3444 9.886V13.1492C15.3444 13.7675 15.5905 14.3613 16.028 14.7988L16.7759 15.5467C17.2134 15.9842 17.4595 16.578 17.4595 17.1963C17.4595 18.4143 16.4725 19.4013 15.2545 19.4013H2.74669C1.52869 19.4013 0.541687 18.4143 0.541687 17.1963C0.541687 16.578 0.787854 15.9842 1.22535 15.5467L1.97319 14.7988C2.41069 14.3613 2.65685 13.7675 2.65685 13.1492V9.886Z'
          stroke={color}
          fill={color}
        ></path>
      </svg>
    );
  }

  return (
    <svg
      width={size ? `${size}rem` : '1rem'}
      height={size ? `${size}rem` : '1rem'}
      fill='none'
      viewBox='0 0 18 24'
      xmlns='http://www.w3.org/2000/svg'
      id='notification'
    >
      <path
        d='M6.32605 19.4013V19.8272C6.32605 21.303 7.52305 22.5 9.00005 22.5V22.5C10.477 22.5 11.674 21.303 11.674 19.826V19.4002'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
      <path
        d='M11.1385 4.7095V3.6385C11.1385 2.45783 10.1818 1.5 9.00001 1.5V1.5C7.81818 1.5 6.86151 2.45783 6.86151 3.6385V4.7095'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      ></path>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.65685 9.886C2.65685 6.96584 5.02402 4.59984 7.94302 4.59984H10.0582C12.9784 4.59984 15.3444 6.967 15.3444 9.886V13.1492C15.3444 13.7675 15.5905 14.3613 16.028 14.7988L16.7759 15.5467C17.2134 15.9842 17.4595 16.578 17.4595 17.1963C17.4595 18.4143 16.4725 19.4013 15.2545 19.4013H2.74669C1.52869 19.4013 0.541687 18.4143 0.541687 17.1963C0.541687 16.578 0.787854 15.9842 1.22535 15.5467L1.97319 14.7988C2.41069 14.3613 2.65685 13.7675 2.65685 13.1492V9.886Z'
        stroke={color}
        fill={color}
      ></path>
    </svg>
  );
}
