import {
  addDays,
  differenceInDays,
  differenceInMinutes,
  format,
  formatDistance,
  type FormatDistanceOptions,
  formatDistanceStrict,
  formatDistanceToNow,
  formatDistanceToNowStrict,
  formatRelative,
  isBefore,
  isDate,
  isSameDay,
  isToday,
  type Locale,
  parse,
  parseISO,
  startOfMonth,
  sub
} from 'date-fns';
import { enUS, es, ptBR } from 'date-fns/locale';
import i18n from 'i18next';

import { type LanguageKeyType } from 'data/contexts/lang/useLangContext.types';

class CustomDate extends Date {
  private readonly locales = {
    pt: ptBR,
    en: enUS,
    es
  };

  formatFromISO(date: string | undefined, outFormat = 'dd/MM/yyyy'): string {
    if (!date) return '';

    return format(parseISO(date), outFormat);
  }

  getDayAndMonth(
    date: Date | string,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    return format(date, 'd MMM', {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  getTimezone(): string {
    const timezoneInt = -this.getTimezoneOffset() / 60;
    const timezoneHours = Math.trunc(timezoneInt);
    const timezoneMinutes = Math.abs((timezoneInt % 1) * 60)
      .toString()
      .padStart(2, '0');

    return `${timezoneHours}:${timezoneMinutes}`;
  }

  parseIsoToDate(date: string): Date {
    return parseISO(date);
  }

  formatToIso(date: string, formatString = 'dd/MM/yyyy'): string {
    const parsed = parse(date, formatString, new Date());

    return format(parsed, 'yyyy-MM-dd');
  }

  formatDateToIso(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }

  differenceBetweenDates(from: Date, to: Date): number {
    return differenceInDays(from, to);
  }

  isDateOlderThanHours(date?: Date | string, hours: number = 24): boolean {
    const parsed = new Date(date ?? '');

    return (
      isDate(parsed) && differenceInMinutes(new Date(), parsed) / 60 >= hours
    );
  }

  formatRelativeDateLocale(
    currentLangKey: LanguageKeyType,
    date: Date
  ): string {
    const currentLocale =
      currentLangKey === 'en' ? enUS : currentLangKey === 'es' ? es : ptBR;

    const formatRelativeLocale: Record<string, string> = {
      other: 'dd MMMM',
      lastWeek: 'dd MMMM'
    };

    const locale: Locale = {
      ...currentLocale,
      formatRelative: (token, ...args) =>
        formatRelativeLocale[token] ??
        currentLocale.formatRelative?.(token, ...args)?.split(' ')?.[0]
    };

    return formatRelative(date, new Date(), {
      locale
    });
  }

  getDateAddingDays(currentDate: Date, daysToAdd: number): Date {
    return addDays(currentDate, daysToAdd);
  }

  /**
   * Retorna a data subtraindo um número específico de dias da data fornecida.
   * @param date A data inicial.
   * @param days O número de dias a subtrair da data inicial.
   * @returns Uma nova data resultante da subtração do número especificado de dias da data inicial.
   */
  getDateSubtractingDays(date: Date, days: number): Date {
    return sub(date, { days });
  }

  getDateMonthName(
    date: Date | undefined,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    if (!date) return '';

    return format(date, 'MMMM', {
      locale: this.locales[currentLangKey]
    });
  }

  /**
   * Extrai o nome do dia da semana a partir de uma data: new Date() -> seg | segunda
   * @param date Data a ser extraída o dia da semana
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @param abbreviated Se deve retornar o nome abreviado ou não. Padrão: true
   * @returns O nome do dia da semana, abreviado ou não.
   */
  getWeekdayName(
    date: Date | string,
    currentLangKey: 'pt' | 'en' | 'es',
    abbreviated: boolean = true
  ): string {
    let formatString = currentLangKey === 'pt' ? 'eee' : 'eeee';

    if (abbreviated) {
      formatString = currentLangKey === 'pt' ? 'eeeeee.' : 'eee.';
    }

    return format(date, formatString, {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Formata uma data para o seguinte formato: new Date() -> 14 de junho | june 14th
   * @param date A data a ser formatada
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @returns A data formatada
   */
  getDayAndLongMonthName(
    date: Date,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    const formatString = currentLangKey === 'en' ? 'MMMM do' : "d 'de' MMMM";

    return format(date, formatString, {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Formata uma data para o seguinte formato: new Date() -> 14 jun. 2024
   * @param date A data a ser formatada
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @returns A data formatada
   */
  getDayMonthYearName(date: Date, currentLangKey: 'pt' | 'en' | 'es'): string {
    return format(date, 'd MMM. yyyy', {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Formata uma data para o seguinte formato: new Date() -> 27 set. às 18h
   * @param date A data a ser formatada
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @returns `string` - A data formatada
   */
  getDateWithMonthAndHour(
    date: Date,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    const formatString = {
      pt: "d MMM. 'às' H'h'",
      en: "d MMM. 'at' H'h'",
      es: "d MMM. 'a las' H'h'"
    };

    return format(date, formatString[currentLangKey], {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Formata uma data para o seguinte formato: new Date() -> seg, 14 jun
   * @param date A data a ser formatada
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @returns string A data formatada
   */
  getDateWithWeekAndMonthName(
    date: Date | string,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    const formatString =
      currentLangKey === 'pt' ? 'eeeeee, d MMM' : 'eee, d MMM';

    return format(date, formatString, {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Exemplo de retorno: 14 de jun
   */
  getDateWithDayAndMonthName(
    date: Date | string,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    const formatString = currentLangKey === 'pt' ? `d 'de' MMM` : 'd MMM';

    return format(date, formatString, {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Formata uma data para o seguinte formato: new Date() -> segunda-feira, 14 de junho
   * @param date A data a ser formatada
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @returns string A data formatada
   */
  getDateWithWeekAndMonthNameLong(
    date: Date | string,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    const formatString =
      currentLangKey === 'en' ? 'eeee, MMMM do' : "eeee, d 'de' MMMM";

    return format(date, formatString, {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Formata uma data para o seguinte formato: new Date() -> seg, 14 jun. 2024
   * @param date A data a ser formatada
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @returns string A data formatada
   */
  getDateWithWeekMonthAndYearName(
    date: Date,
    currentLangKey: 'pt' | 'en' | 'es'
  ): string {
    const formatString =
      currentLangKey === 'pt' ? 'eeeeee, d MMM. yyyy' : 'eee, d MMM. yyyy';

    return format(date, formatString, {
      locale: this.locales[currentLangKey]
    }).toLowerCase();
  }

  /**
   * Retorna a diferença de uma data até a data atual, exemplo: 5 dias | 10 meses | cerca de 2 horas
   *
   * @param date A data a ser usada no calculo de diferença
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @param addSuffix Se deve adicionar o sufixo no retorno (há cerca de, ago, hace)
   * @param strict Retorna a distância entre as datas, usando unidades estritas: 2 horas
   * @returns string A diferença de data formatada
   */
  getFormattedDistanceToNow(
    date: Date,
    currentLangKey: 'pt' | 'en' | 'es',
    addSuffix = false,
    strict = false
  ): string {
    const format = strict ? formatDistanceToNowStrict : formatDistanceToNow;

    return format(date, {
      locale: this.locales[currentLangKey],
      addSuffix
    });
  }

  /**
   * Retorna texto com a diferença entre duas datas, exemplo: cerca de 2 horas
   *
   * @param earlierDate A menor data a ser usada no calculo de diferença
   * @param laterDate A maiordata a ser usada no calculo de diferença
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @param strict Retorna a distância entre as datas, usando unidades estritas: 2 horas
   * @param options Objeto com opções de formatação do date-fns
   * @returns string A diferença de data formatada
   */
  getFormattedDistance(
    earlierDate: Date | string,
    laterDate: Date | string,
    currentLangKey: LanguageKeyType,
    strict = false,
    options?: FormatDistanceOptions
  ): string {
    const format = strict ? formatDistanceStrict : formatDistance;

    return format(laterDate, earlierDate, {
      locale: this.locales[currentLangKey],
      ...(options || {})
    });
  }

  /**
   * Represente a data de 6 dias atrás em palavras relativas à data de hoje
   * Exemplo: hoje às 17:11, ontem às 17:11
   *
   * @param date A data a ser usada
   * @param currentLangKey Idioma da aplicação utilizado no momento
   * @param isShortDate Se remove o prefixo da data e deixa ela mais curta
   * @returns string A data formatada
   */
  getFormattedRelativeToNow(
    date: Date,
    currentLangKey: LanguageKeyType,
    isShortDate = false
  ): string {
    let formattedDate = formatRelative(date, new Date(), {
      locale: this.locales[currentLangKey]
    });

    if (isShortDate) {
      formattedDate = formattedDate.replace(
        /(última|último|próxima|last|next|pasado|próximo|el) /gi,
        ''
      );
    }

    return formattedDate;
  }

  /**
   * Retorna a data do primeiro dia do mês da data fornecida no formato 'yyyy-MM-01'.
   * @param date A data para a qual se deseja obter o primeiro dia do mês.
   * @returns Uma string representando o primeiro dia do mês no formato 'yyyy-MM-01'.
   */
  getFistDayOfMonth(date: Date): string {
    return format(date, 'yyyy-MM-01');
  }

  /**
   * Retorna a data no dia do ano passado 'yyyy-01-01'.
   * @param date A data para a qual se deseja obter o primeiro dia do mês.
   * @returns Uma string representando o primeiro dia do mês no formato 'yyyy-01-01'.
   */
  getDateAYearAgo(date: Date): string {
    const lastYearDate = new Date(date);
    lastYearDate.setFullYear(lastYearDate.getFullYear() - 1);

    return format(lastYearDate, 'yyyy-MM-dd');
  }

  /**
   * Verifica se todas as datas fornecidas têm o mesmo dia.
   * @param dates Um array de objetos Date a serem verificados.
   * @returns true se todas as datas tiverem o mesmo dia, false caso contrário.
   */
  isEveryDateSameDay(...dates: Date[]): boolean {
    if (dates.length === 0) return false;

    const firstDate = dates[0];
    return dates.every(date => isSameDay(date, firstDate));
  }

  /**
   * Verifica se todas as datas fornecidas são do mesmo dia que o dia atual.
   * @param dates Um array de objetos Date a serem verificados.
   * @returns true se todas as datas forem do mesmo dia que o dia atual, false caso contrário.
   */
  isEveryDateSameToday(...dates: Date[]): boolean {
    if (dates.length === 0) return false;

    return dates.every(date => isSameDay(date, new Date()));
  }

  /**
   * Verifica se o intervalo entre duas datas é exatamente os últimos sete dias, a contar do dia atual.
   * @param initialDate A data inicial do intervalo.
   * @param endDate A data final do intervalo.
   * @returns Um valor booleano indicando se o intervalo de datas é exatamente os últimos sete dias, a contar do dia atual.
   */
  isLastSevenDays(initialDate: Date, endDate: Date): boolean {
    return isToday(endDate) && differenceInDays(endDate, initialDate) === 7;
  }

  /**
   * Verifica se o intervalo entre duas datas é exatamente o os últimos trinta dias, a contar do dia atual.
   * @param initialDate A data inicial do intervalo.
   * @param endDate A data final do intervalo.
   * @returns Um valor booleano indicando se o intervalo de datas é exatamente o os últimos trinta dias, a contar do dia atual.
   */
  isLastThirtyDays(initialDate: Date, endDate: Date): boolean {
    return isToday(endDate) && differenceInDays(endDate, initialDate) === 30;
  }

  /**
   * Verifica se o intervalo entre duas datas está dentro do mês atual, a contar do dia atual.
   * @param initialDate A data inicial do intervalo.
   * @param endDate A data final do intervalo.
   * @returns Um valor booleano indicando se o intervalo de datas está dentro do mês atual, a contar do dia atual.
   */
  isThisMonth(initialDate: Date, endDate: Date): boolean {
    return isToday(endDate) && isSameDay(initialDate, startOfMonth(endDate));
  }

  /**
   * Verifica se o intervalo entre duas datas está em um intervalo de um ano da data atual.
   * @param initialDate A data inicial do intervalo.
   * @param endDate A data final do intervalo.
   * @returns Um valor booleano indicando se o intervalo de datas está dentro do mês atual, a contar do dia atual.
   */
  isThisAYearAgo(startDate: Date, endDate: Date): boolean {
    const lastYearDate = new Date(startDate);
    lastYearDate.setFullYear(lastYearDate.getFullYear() + 1);

    return isToday(endDate) && isSameDay(lastYearDate, endDate);
  }

  /**
   * Formata uma data extraindo o horário e o minuto e separando-os com um separador definido.
   * @param date A data a ser formatada.
   * @param separator Separador entre as horas e os minutos. O padrão é ':'.
   * @returns Horário formatado com o separador definido.
   */
  getFormattedHour(date: Date, separator: string = ':'): string {
    return format(date, `HH'${separator}'mm`);
  }

  /**
   * Retorno: boolean
   */
  isPastOrToday(date: Date): boolean {
    const today = new Date();
    return isToday(date) || isBefore(date, today);
  }

  /**
   * Retorna uma data informada formatada com data e hora.
   * @param datetime A data que será formatada.
   * @returns A data formatada seguindo esse padrão: 17/03/2001 às 02h58.
   */
  getDateAndHour(datetime: string): string {
    return `${format(datetime, 'dd/MM/yyyy')} ${i18n.t('global:at')} ${format(datetime, 'HH')}h${format(datetime, 'mm')}`;
  }
}

export default new CustomDate();
