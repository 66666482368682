import { lazy } from 'react';

const SingleUserInfoIcon = lazy(
  async () =>
    await import('./single_user_info.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserPinSquareIcon = lazy(
  async () =>
    await import('./user_pin_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserEditPenCircleIcon = lazy(
  async () =>
    await import('./user_edit_pen_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileCircleIcon = lazy(
  async () =>
    await import('./user_profile_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupUserIcon = lazy(
  async () =>
    await import('./group_user.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersFriendsIcon = lazy(
  async () =>
    await import('./users_friends.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileWindowIcon = lazy(
  async () =>
    await import('./user_profile_window.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserLightningIcon = lazy(
  async () =>
    await import('./single_user_lightning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserMenuIcon = lazy(
  async () =>
    await import('./user_menu.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserEnterIcon = lazy(
  async () =>
    await import('./single_user_enter.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserSignaSqaureIcon = lazy(
  async () =>
    await import('./user_signa_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserCrossDeleteIcon = lazy(
  async () =>
    await import('./user_cross_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserRemoveIcon = lazy(
  async () =>
    await import('./single_user_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileCardsIcon = lazy(
  async () =>
    await import('./user_profile_cards.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserCheckmarkCircleIcon = lazy(
  async () =>
    await import('./single_user_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserMessageChatIcon = lazy(
  async () =>
    await import('./single_user_message_chat.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersMessageChatIcon = lazy(
  async () =>
    await import('./users_message_chat.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserRotateIcon = lazy(
  async () =>
    await import('./user_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileLoadingCircleIcon = lazy(
  async () =>
    await import('./user_profile_loading_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersAddPlusSquareIcon = lazy(
  async () =>
    await import('./users_add_plus_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserCheckIcon = lazy(
  async () =>
    await import('./single_user_check.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserDocumentIcon = lazy(
  async () =>
    await import('./user_document.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserMinusIcon = lazy(
  async () =>
    await import('./single_user_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserDeleteCrossIcon = lazy(
  async () =>
    await import('./single_user_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserSelectCursorSquareIcon = lazy(
  async () =>
    await import('./user_select_cursor_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserStopIcon = lazy(
  async () =>
    await import('./user_stop.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsenEnterLoginIcon = lazy(
  async () =>
    await import('./usen_enter_login.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileReplyIcon = lazy(
  async () =>
    await import('./user_profile_reply.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserFavoriteIcon = lazy(
  async () =>
    await import('./single_user_favorite.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileRefreshChangeIcon = lazy(
  async () =>
    await import('./user_profile_refresh_change.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserClockIcon = lazy(
  async () =>
    await import('./user_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileMinusIcon = lazy(
  async () =>
    await import('./user_profile_minus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersCheckmarkSquareIcon = lazy(
  async () =>
    await import('./users_checkmark_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileSyncIcon = lazy(
  async () =>
    await import('./user_profile_sync.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserCheckmarkIcon = lazy(
  async () =>
    await import('./user_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserSelectIcon = lazy(
  async () =>
    await import('./single_user_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersProfileGroupDeleteIcon = lazy(
  async () =>
    await import('./users_profile_group_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileChangeRefreshIcon = lazy(
  async () =>
    await import('./user_profile_change_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserSearchSquareIcon = lazy(
  async () =>
    await import('./user_search_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserMinusDeleteIcon = lazy(
  async () =>
    await import('./user_minus_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserEditSquareIcon = lazy(
  async () =>
    await import('./user_edit_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileDeleteIcon = lazy(
  async () =>
    await import('./user_profile_delete.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileSearchCheckmarkIcon = lazy(
  async () =>
    await import('./user_profile_search_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserSearchIcon = lazy(
  async () =>
    await import('./single_user_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileWarningIcon = lazy(
  async () =>
    await import('./user_profile_warning.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserUploadIcon = lazy(
  async () =>
    await import('./single_user_upload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserEditPenIcon = lazy(
  async () =>
    await import('./user_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersCopyIcon = lazy(
  async () =>
    await import('./users_copy.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserKeyProtectionIcon = lazy(
  async () =>
    await import('./single_user_key_protection.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserMinusDeleteRemoveIcon = lazy(
  async () =>
    await import('./single_user_minus_delete_remove.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileGroupIcon = lazy(
  async () =>
    await import('./user_profile_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileBackIcon = lazy(
  async () =>
    await import('./user_profile_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileCheckmarkIcon = lazy(
  async () =>
    await import('./user_profile_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UsersProfileGroupIcon = lazy(
  async () =>
    await import('./users_profile_group.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserSignalCircleIcon = lazy(
  async () =>
    await import('./user_signal_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserSquareIcon = lazy(
  async () =>
    await import('./single_user_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserLockIcon = lazy(
  async () =>
    await import('./single_user_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserLoadingIcon = lazy(
  async () =>
    await import('./single_user_loading.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserAddSquareIcon = lazy(
  async () =>
    await import('./user_add_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileMessageIcon = lazy(
  async () =>
    await import('./user_profile_message.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserIcon = lazy(
  async () =>
    await import('./single_user.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserAddPlusIcon = lazy(
  async () =>
    await import('./single_user_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserLoginCircleIcon = lazy(
  async () =>
    await import('./user_login_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserHeartUserIcon = lazy(
  async () =>
    await import('./single_user_heart_user.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserUploadSquareIcon = lazy(
  async () =>
    await import('./user_upload_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileEditIcon = lazy(
  async () =>
    await import('./user_profile_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserCrossDeleteSquareIcon = lazy(
  async () =>
    await import('./user_cross_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserMoreOptionIcon = lazy(
  async () =>
    await import('./user_more_option.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const HomeUserIcon = lazy(
  async () =>
    await import('./home_user.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserPinIcon = lazy(
  async () =>
    await import('./user_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileHexagonIcon = lazy(
  async () =>
    await import('./user_profile_hexagon.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserCursorCircleIcon = lazy(
  async () =>
    await import('./user_cursor_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GroupUserAddIcon = lazy(
  async () =>
    await import('./group_user_add.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserCheckmarkIcon = lazy(
  async () =>
    await import('./single_user_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserAlertIcon = lazy(
  async () =>
    await import('./single_user_alert.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileVoiceIcon = lazy(
  async () =>
    await import('./user_profile_voice.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserDeleteCrossIcon = lazy(
  async () =>
    await import('./user_delete_cross.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileRefreshIcon = lazy(
  async () =>
    await import('./user_profile_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserNotificationCirleIcon = lazy(
  async () =>
    await import('./user_notification_cirle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserBackIcon = lazy(
  async () =>
    await import('./single_user_back.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserClockIcon = lazy(
  async () =>
    await import('./single_user_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileSignalWifiIcon = lazy(
  async () =>
    await import('./user_profile_signal_wifi.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileAddCircleIcon = lazy(
  async () =>
    await import('./user_profile_add_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserDownloadIcon = lazy(
  async () =>
    await import('./single_user_download.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileEyeIcon = lazy(
  async () =>
    await import('./user_profile_eye.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileTagIcon = lazy(
  async () =>
    await import('./user_profile_tag.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserOneIcon = lazy(
  async () =>
    await import('./single_user_one.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserDownloadSquareIcon = lazy(
  async () =>
    await import('./user_download_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserEditIcon = lazy(
  async () =>
    await import('./single_user_edit.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileTimeClockIcon = lazy(
  async () =>
    await import('./user_profile_time_clock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileProfileIcon = lazy(
  async () =>
    await import('./user_profile_profile.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserEnterArrowSquareIcon = lazy(
  async () =>
    await import('./user_enter_arrow_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserNotificationSqaureIcon = lazy(
  async () =>
    await import('./user_notification_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserAddPlusIcon = lazy(
  async () =>
    await import('./user_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserEditPenIcon = lazy(
  async () =>
    await import('./single_user_edit_pen.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const UserProfileLockIcon = lazy(
  async () =>
    await import('./user_profile_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserSettingIcon = lazy(
  async () =>
    await import('./single_user_setting.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const SingleUserDiscountIcon = lazy(
  async () =>
    await import('./single_user_discount.svg').then(module => ({
      default: module.ReactComponent
    }))
);

export const Users = {
  SingleUserInfoIcon,
  UserPinSquareIcon,
  UserEditPenCircleIcon,
  UserProfileCircleIcon,
  GroupUserIcon,
  UsersFriendsIcon,
  UserProfileWindowIcon,
  SingleUserLightningIcon,
  UserMenuIcon,
  SingleUserEnterIcon,
  UserSignaSqaureIcon,
  UserCrossDeleteIcon,
  SingleUserRemoveIcon,
  UserProfileCardsIcon,
  SingleUserCheckmarkCircleIcon,
  SingleUserMessageChatIcon,
  UsersMessageChatIcon,
  UserRotateIcon,
  UserProfileLoadingCircleIcon,
  UsersAddPlusSquareIcon,
  SingleUserCheckIcon,
  UserDocumentIcon,
  SingleUserMinusIcon,
  SingleUserDeleteCrossIcon,
  UserSelectCursorSquareIcon,
  UserStopIcon,
  UsenEnterLoginIcon,
  UserProfileReplyIcon,
  SingleUserFavoriteIcon,
  UserProfileRefreshChangeIcon,
  UserClockIcon,
  UserProfileMinusIcon,
  UsersCheckmarkSquareIcon,
  UserProfileSyncIcon,
  UserCheckmarkIcon,
  SingleUserSelectIcon,
  UsersProfileGroupDeleteIcon,
  UserProfileChangeRefreshIcon,
  UserSearchSquareIcon,
  UserMinusDeleteIcon,
  UserEditSquareIcon,
  UserProfileDeleteIcon,
  UserProfileSearchCheckmarkIcon,
  SingleUserSearchIcon,
  UserProfileWarningIcon,
  SingleUserUploadIcon,
  UserEditPenIcon,
  UsersCopyIcon,
  SingleUserKeyProtectionIcon,
  SingleUserMinusDeleteRemoveIcon,
  UserProfileGroupIcon,
  UserProfileBackIcon,
  UserProfileCheckmarkIcon,
  UsersProfileGroupIcon,
  UserSignalCircleIcon,
  SingleUserSquareIcon,
  SingleUserLockIcon,
  SingleUserLoadingIcon,
  UserAddSquareIcon,
  UserProfileMessageIcon,
  SingleUserIcon,
  SingleUserAddPlusIcon,
  UserLoginCircleIcon,
  SingleUserHeartUserIcon,
  UserUploadSquareIcon,
  UserProfileEditIcon,
  UserCrossDeleteSquareIcon,
  UserMoreOptionIcon,
  HomeUserIcon,
  UserPinIcon,
  UserProfileHexagonIcon,
  UserCursorCircleIcon,
  GroupUserAddIcon,
  SingleUserCheckmarkIcon,
  SingleUserAlertIcon,
  UserProfileVoiceIcon,
  UserDeleteCrossIcon,
  UserProfileRefreshIcon,
  UserNotificationCirleIcon,
  SingleUserBackIcon,
  SingleUserClockIcon,
  UserProfileSignalWifiIcon,
  UserProfileAddCircleIcon,
  SingleUserDownloadIcon,
  UserProfileEyeIcon,
  UserProfileTagIcon,
  SingleUserOneIcon,
  UserDownloadSquareIcon,
  SingleUserEditIcon,
  UserProfileTimeClockIcon,
  UserProfileProfileIcon,
  UserEnterArrowSquareIcon,
  UserNotificationSqaureIcon,
  UserAddPlusIcon,
  SingleUserEditPenIcon,
  UserProfileLockIcon,
  SingleUserSettingIcon,
  SingleUserDiscountIcon
};
