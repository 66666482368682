import { tokens } from 'ds/tokens';

import {
  type BadgeStatusType,
  type IBadgeStatusVariants
} from 'ds/components/BadgeStatus/BadgeStatus.types';

export const badgeStatusProps: Record<BadgeStatusType, IBadgeStatusVariants> = {
  default: {
    background: tokens.brandColorPrimaryLightest,
    font: tokens.brandColorPrimaryPure
  },
  secondary: {
    background: tokens.brandColorSecondaryPure,
    font: tokens.neutralColorHighPure
  },
  general: {
    background: tokens.neutralColorHighDark,
    font: tokens.neutralColorLowDark
  },
  success: {
    background: tokens.feedbackColorSuccessLightest,
    font: tokens.feedbackColorSuccessPure
  },
  warning: {
    background: tokens.feedbackColorWarningLightest,
    font: tokens.feedbackColorWarningMedium
  },
  error: {
    background: tokens.feedbackColorErrorLightest,
    font: tokens.feedbackColorErrorMedium
  }
};

export const badgeStatusOutlinedProps: Record<
  BadgeStatusType,
  IBadgeStatusVariants
> = {
  default: {
    background: tokens.neutralColorHighPure,
    font: tokens.brandColorPrimaryPure,
    border: tokens.brandColorPrimaryPure
  },
  secondary: {
    background: tokens.neutralColorHighPure,
    font: tokens.brandColorSecondaryPure,
    border: tokens.brandColorSecondaryPure
  },
  general: {
    background: tokens.neutralColorHighPure,
    font: tokens.neutralColorLowDark,
    border: tokens.neutralColorLowMedium
  },
  success: {
    background: tokens.neutralColorHighPure,
    font: tokens.feedbackColorSuccessMedium,
    border: tokens.feedbackColorSuccessPure
  },
  warning: {
    background: tokens.neutralColorHighPure,
    font: tokens.feedbackColorWarningMedium,
    border: tokens.feedbackColorWarningPure
  },
  error: {
    background: tokens.neutralColorHighPure,
    font: tokens.feedbackColorErrorPure,
    border: tokens.feedbackColorErrorPure
  }
};
