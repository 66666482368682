import { type IToastContainerProps } from './ToastContainer.types';

import { Container } from './ToastContainer.styles';

export function ToastContainer({
  duration = 5000,
  hideProgressBar = true,
  position = 'top-right'
}: IToastContainerProps): JSX.Element {
  return (
    <Container
      containerId={'toast'}
      position={position}
      autoClose={duration}
      hideProgressBar={hideProgressBar}
      pauseOnHover
      closeButton={false}
      limit={5}
    />
  );
}
