import { useEffect, useState } from 'react';

import {
  type IUseGetSessionUser,
  type IUseGetSessionUserParams
} from 'data/modules/auth';
import {
  useGetAuthCardsCompany,
  useGetAuthCardsUser,
  useGetAuthTravelUser,
  useGetAuthUser,
  useGetOutsourcedUser
} from 'data/modules/auth/useCases';

import { CustomCookies } from 'shared/utils/global';

export function useGetSessionUser(
  { enabled }: IUseGetSessionUserParams = {
    enabled: true
  }
): IUseGetSessionUser {
  const outsourcingUserUuid = CustomCookies.get('outsourcing') ?? '';
  const [outsourcedUserWithoutCard, setOutsourcedUserWithoutCard] =
    useState(false);

  const { user, company, isFetchingAuthUser } = useGetAuthUser({
    enabled
  });

  const { travelUser, isFetchingTravelUser } = useGetAuthTravelUser(
    !!company?.parameters.usesTravels
  );

  const isGetOutsourcedUserRequestEnabled =
    !!outsourcingUserUuid && !!user?.parameters.hasOutsourcingAccess;

  const { outsourcedUser, isFetchingOutsourcedUser } = useGetOutsourcedUser({
    userId: outsourcingUserUuid,
    enabled: enabled && isGetOutsourcedUserRequestEnabled
  });

  const { cardsCompany, cardsCompanyError, isFetchingCardsCompany } =
    useGetAuthCardsCompany({
      enabled:
        enabled &&
        !isFetchingAuthUser &&
        !!company?.parameters.usesCards &&
        !window.location.pathname.includes('store') &&
        !window.location.pathname.includes('suporte') &&
        !window.location.pathname.includes('despesas') &&
        !window.location.pathname.includes('analises') &&
        !window.location.pathname.includes('combustivel') &&
        !window.location.pathname.includes('viagens')
    });

  const { cardsUser, cardsUserError, isFetchingCardsUser } =
    useGetAuthCardsUser({
      enabled:
        enabled &&
        !isFetchingAuthUser &&
        !!company?.parameters.usesCards &&
        !!cardsCompany?.isOnboardingFinished &&
        !window.location.pathname.includes('store') &&
        !window.location.pathname.includes('suporte') &&
        !window.location.pathname.includes('despesas') &&
        !window.location.pathname.includes('analises') &&
        !window.location.pathname.includes('combustivel') &&
        !window.location.pathname.includes('viagens')
    });

  useEffect(() => {
    if (
      !isFetchingAuthUser &&
      !isGetOutsourcedUserRequestEnabled &&
      !outsourcedUser &&
      !user?.parameters.usesCards &&
      !user?.parameters.cardsUserType
    ) {
      CustomCookies.remove('outsourcing');
      sessionStorage.removeItem('idUsuarioTerceirizado');

      setOutsourcedUserWithoutCard(true);
    }
  }, [
    isFetchingAuthUser,
    isGetOutsourcedUserRequestEnabled,
    outsourcedUser,
    user
  ]);

  return {
    user,
    company,
    isFetchingAuthUser,
    travelUser,
    isFetchingTravelUser,
    outsourcedUser,
    isFetchingOutsourcedUser,
    cardsCompany,
    cardsCompanyError,
    isFetchingCardsCompany,
    cardsUser,
    cardsUserError,
    isFetchingCardsUser,
    isGetOutsourcedUserRequestEnabled,
    outsourcedUserWithoutCard
  };
}
