import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type ContainerStyleType } from './CardInfoTitle.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.h1<AddPrefixToType<ContainerStyleType>>`
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.2rem;
  letter-spacing: 0.075rem;
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};

  ${({ $variant }) =>
    $variant === 'small'
      ? css`
          font-size: ${tokens.fontSizeXxss};
        `
      : $variant === 'medium'
        ? css`
            font-size: ${tokens.fontSizeXxs};
          `
        : $variant === 'large' &&
          css`
            font-size: ${tokens.fontSizeXs};
          `}
`;
