import { type INotificationCounterProps } from './NotificationCounter.types';

import { Container } from './NotificationCounter.styles';

export function NotificationCounter({
  newNotificationArrived,
  totalCount,
  children,
  counter,
  ...props
}: INotificationCounterProps): JSX.Element {
  return (
    <Container
      {...props}
      className={`${totalCount === 0 ? 'start-empty' : counter === 0 ? 'empty' : ''} ${
        counter === 1 ? 'first' : ''
      } ${newNotificationArrived ? 'new-arrived' : ''}`}
    >
      {newNotificationArrived && <span>+1</span>}
      <span>{counter <= 10 ? counter : '10+'}</span>
    </Container>
  );
}
