import cardsBalanceSolicitationJSON from './balanceSolicitations.lang.json';
import depositJSON from './deposit.lang.json';
import globalJSON from './global.lang.json';
import kycStatusJSON from './kycStatus.lang.json';
import managementJSON from './management.lang.json';
import { onboarding } from './onboarding';
import requestCardsJSON from './requestCards.lang.json';
import statementJSON from './statement.lang.json';
import { tours } from './tours';
import valueAllocationJSON from './valueAllocation.lang.json';
import valueSolicitationJSON from './valueSolicitation.lang.json';
import withdrawalJSON from './withdrawal.lang.json';

export const cards = {
  global: globalJSON,
  management: managementJSON,
  valueAllocation: valueAllocationJSON,
  valueSolicitation: valueSolicitationJSON,
  balanceSolicitation: cardsBalanceSolicitationJSON,
  statement: statementJSON,
  withdrawal: withdrawalJSON,
  requestCards: requestCardsJSON,
  deposit: depositJSON,
  onboarding,
  kycStatus: kycStatusJSON,
  tours
};
