import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: ${tokens.spacingXxs} ${tokens.spacingXs};
  border-bottom-left-radius: ${tokens.borderRadiusMd};
  border-bottom-right-radius: ${tokens.borderRadiusMd};
  background-color: ${tokens.neutralColorHighPure};
`;
