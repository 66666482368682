import { Label } from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export const Container = styled(Label)`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.4rem;
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  font-weight: ${({ theme }) => theme.tokens.fontWeightSemibold};
  color: ${({ theme }) => theme.colors.primary};
  padding: 0 1.6rem;
  gap: 1rem;

  span {
    font-weight: ${({ theme }) => theme.tokens.fontWeightRegular};
    font-size: ${({ theme }) => theme.tokens.fontSizeXxss};
    color: ${({ theme }) => theme.tokens.neutralColorLowDark};
  }
`;
