import { Tooltip as ReactTooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';

import { type ITooltipProps } from './Tooltip.types';

import { Container } from './Tooltip.styles';

export function Tooltip({
  id,
  content,
  children,
  display = 'block',
  marginLeft = 0,
  direction = 'bottom',
  tooltipFontSize = 1.2,
  maxWidth = 15,
  fontSize = 1.2,
  color = 'mineShaft',
  noFontSize = false
}: ITooltipProps): JSX.Element {
  return (
    <Container
      $maxWidth={maxWidth}
      $fontSize={noFontSize ? 0 : fontSize}
      $color={color}
      $tooltipFontSize={tooltipFontSize}
      $display={display}
      $marginLeft={marginLeft}
    >
      <span data-tooltip-id={id}>{children}</span>
      <ReactTooltip
        id={id}
        place={direction}
      >
        {content}
      </ReactTooltip>
    </Container>
  );
}
