import { useTranslation } from 'react-i18next';

import { vexDomain } from 'data/config';
import {
  useGetOutsourcedUsersAndPermissions,
  useGetSessionUser
} from 'data/modules/auth';

import { CustomCookies } from 'shared/utils/global';

import {
  type IUseOutsourced,
  type OutsourcingOptionType
} from './Outsourced.types';

export function useOutsourced(): IUseOutsourced {
  const { user, outsourcedUser, isFetchingOutsourcedUser } =
    useGetSessionUser();

  const { t } = useTranslation('global');

  const {
    isFetchingOutsourcedUsersAndPermissions,
    outsourcedUsersAndPermissions
  } = useGetOutsourcedUsersAndPermissions({
    enabled: user?.parameters.hasOutsourcingAccess ?? false
  });

  let outsourcingOptions: OutsourcingOptionType[] =
    outsourcedUsersAndPermissions?.map(({ directorUuid, directorName }) => ({
      name: directorName,
      uuid: directorUuid
    })) ?? [];

  // Caso o usuário logado esteja em terceirização, ele deve aparecer como primeira opção da lista
  // e o usuário sendo terceirizado deve ser removido das opções
  outsourcingOptions =
    outsourcedUser !== undefined
      ? [
          {
            name: `${user?.name} (${t('you')})`,
            uuid: user?.uuid as string
          },
          ...outsourcingOptions.filter(
            ({ uuid }) => uuid !== outsourcedUser.user.uuid
          )
        ]
      : outsourcingOptions;

  function handleChangeOutsourcingOption(userUuid: string): void {
    if (userUuid === user?.uuid) {
      CustomCookies.remove('outsourcing');
    } else {
      CustomCookies.set('outsourcing', userUuid, {
        daysToExpire: 1
      });
    }

    if (
      outsourcedUser?.user.parameters.usesCards &&
      window.location.pathname.includes('cartoes')
    ) {
      window.location.href = `${vexDomain}/cartoes/inicio`;
      return;
    }

    window.location.href = `${vexDomain}/despesas`;
  }

  const nameOfTheUserWhoIsPerformingActions = (
    outsourcedUser !== undefined ? outsourcedUser?.user.name : user?.name
  ) as string;

  return {
    outsourcingOptions,
    isFetchingOutsourcingOptions: isFetchingOutsourcedUsersAndPermissions,
    outsourcedUser,
    isFetchingOutsourcedUser,
    handleChangeOutsourcingOption,
    nameOfTheUserWhoIsPerformingActions
  };
}
