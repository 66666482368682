import { Item } from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export const Container = styled(Item)`
  color: ${({ theme }) => theme.tokens.neutralColorLowDark};
  font-size: ${({ theme }) => theme.tokens.fontSizeXxs};
  padding: 0 2.4rem;
  margin-bottom: 1.6rem;
  transition: color 0.3s ease-in-out;
  cursor: pointer;
`;
