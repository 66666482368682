import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const StyledIcon = styled(DSIcons.CloseBigIcon)`
  color: ${tokens.brandColorSecondaryPure};
  width: 1.3rem;
  height: 1.3rem;
  cursor: pointer;
  flex-shrink: 0;
`;
