import { type IAuthUser, type IPersistenceAuthUser } from 'data/modules/auth';

import { Mask } from 'shared/utils/format';

class AuthUserMapper {
  toDomain(persistenceUser: IPersistenceAuthUser): IAuthUser {
    return {
      user: {
        id: persistenceUser.user.id,
        uuid: persistenceUser.user.uuid,
        companyId: persistenceUser.user?.company_id,
        companyUuid: persistenceUser.user?.company_uuid,
        userType: persistenceUser.user?.user_type,
        name: persistenceUser.user.name,
        confirmed: persistenceUser.user?.confirmed,
        email: persistenceUser.user.email,
        userApproves: persistenceUser.user?.user_approves,
        userCompanies:
          persistenceUser.user.user_companies.length > 0
            ? persistenceUser.user.user_companies.map(companies => ({
                userId: companies.user_id,
                companyId: companies.company_id,
                companyName: companies.company_name
              }))
            : [],
        parameters: {
          shouldAnswerUmuxSurveyOnFuelDashboard: persistenceUser.user.parameters
            .should_answer_surveys
            ? persistenceUser.user.parameters.should_answer_surveys.includes(
                'UMUXLITE_GESTOR_COMBUSTIVEL_DASH'
              )
            : false,
          shouldAnswerUmuxSurveyOnSendReportToApproval: persistenceUser.user
            .parameters.should_answer_surveys
            ? persistenceUser.user.parameters.should_answer_surveys.includes(
                'UMUXLITE_COLAB_RELATORIO_ENVIO'
              )
            : false,
          shouldAnswerUmuxSurveyOnExportMovementsPdf: persistenceUser.user
            .parameters.should_answer_surveys
            ? persistenceUser.user.parameters.should_answer_surveys.includes(
                'UMUXLITE_EXTRATO_GESTOR_PDF'
              )
            : false,
          shouldAnswerUmuxSurveyOnExportMovementsExcel: persistenceUser.user
            .parameters.should_answer_surveys
            ? persistenceUser.user.parameters.should_answer_surveys.includes(
                'UMUXLITE_EXTRATO_GESTOR_EXCEL'
              )
            : false,
          shouldAnswerRecurringNps:
            persistenceUser.user.parameters.should_answer_recurring_nps,
          usesCards: persistenceUser.user.parameters.uses_cards,
          cardsUserType: persistenceUser.user.parameters.cards_user_type,
          allowAccessTeamMember:
            persistenceUser.user.parameters.allow_access_team_member,
          receivesManagementSummaryEmail:
            persistenceUser.user.parameters.receives_management_summary_email,
          allowsManagementAccess:
            persistenceUser.user.parameters.allows_management_access,
          hasOutsourcingAccess:
            persistenceUser.user.parameters.has_outsourcing_access,
          sharedAccountsCards:
            persistenceUser.user.parameters.shared_accounts_cards
        }
      },
      company: {
        id: persistenceUser.company.id,
        uuid: persistenceUser.company.uuid,
        companyType: persistenceUser.company.company_type,
        name: persistenceUser.company.name,
        testPeriod: persistenceUser.company.test_period,
        planDaysRemaining: persistenceUser.company.plan_days_remaining,
        cnpj: Mask.apply('cnpj', persistenceUser.company.cnpj),
        testDaysRemaining: persistenceUser.company.test_days_remaining,
        logoUrl: persistenceUser.company.logo_url,
        fuelingTypeId: persistenceUser.company.fueling_type_id,
        routeTypeId: persistenceUser.company.route_type_id,
        isCompanyCsm: persistenceUser.company.is_company_csm,
        parameters: {
          defaultCurrencyId:
            persistenceUser.company.parameters.default_currency_id,
          allowsGps: persistenceUser.company.parameters.allows_gps,
          allowsMap: persistenceUser.company.parameters.allows_map,
          allowsChangeKmValue:
            persistenceUser.company.parameters.allows_change_km_value,
          usesCards: persistenceUser.company.parameters.uses_cards,
          usesTravels: persistenceUser.company.parameters.uses_travels,
          kilometerValue: persistenceUser.company.kilometer_value,
          usesReferrerProgram:
            persistenceUser.company.parameters.uses_referrer_program,
          usesActiveDirectory:
            persistenceUser.company.parameters.uses_active_directory,
          usesManagerAccessTeamMember:
            persistenceUser.company.parameters.uses_manager_access_team_member,
          showCustomFields:
            persistenceUser.company.parameters.show_custom_fields,
          showCostCenterSettings:
            persistenceUser.company.parameters.show_cost_center_settings,
          showProjectSettings:
            persistenceUser.company.parameters.show_project_settings,
          showExpenseTypeSettings:
            persistenceUser.company.parameters.show_expense_type_settings,
          showPaymentMethodSettings:
            persistenceUser.company.parameters.show_payment_method_settings,
          showApprovalFlowSettings:
            persistenceUser.company.parameters.show_approval_flow_settings,
          usesHorus: persistenceUser.company.parameters.uses_horus,
          usesCheckingAccount:
            persistenceUser.company.parameters.uses_checking_account,
          usesInvoiceConciliation:
            persistenceUser.company.parameters.uses_invoice_conciliation,
          usesRedesignExpenses:
            persistenceUser.company.parameters.uses_redesign_expenses,
          usesExchangeToInsertExpenses:
            persistenceUser.company.parameters.uses_exchange_to_insert_expenses,
          usesLogoOnInterface:
            persistenceUser.company.parameters.uses_logo_on_interface,
          isExpensesOldVersionAllowed:
            persistenceUser.company.parameters.is_expenses_old_version_allowed,
          showRouteTime: persistenceUser.company.parameters.show_route_time,
          usesFuelsModule: persistenceUser.company.parameters.uses_fuels_module,
          blockChangePasswordTeamMember:
            persistenceUser.company.parameters
              .block_change_password_team_member,
          usesAdditionalFields:
            persistenceUser.company.parameters.uses_adittional_fields,
          usesNewTeamMembersScreen:
            !!persistenceUser.company.parameters.uses_new_team_members_screen,
          usesSendToApprovalSapConflictingDatesCheck:
            persistenceUser.company.parameters
              .uses_send_to_approval_sap_conflicting_dates_check,
          usesRedesignReports:
            persistenceUser.company.parameters.uses_redesign_reports,
          isReportsOldVersionAllowed:
            persistenceUser.company.parameters.is_reports_old_version_allowed
        }
      }
    };
  }
}

export default new AuthUserMapper();
