import { type LanguageKeyType } from 'data/contexts/lang/useLangContext.types';
import { TravelProducts } from 'data/modules/travel/global';
import {
  type ITravelCart,
  type TravelCartItemType
} from 'data/modules/travel/travelCart';

import { type ISummaryCardInfo } from 'presentation/pages/travel/Travels/TravelCheckout/components/SummaryCard/SummaryCard.types';
import { type IJustificationCardData } from 'presentation/pages/travel/Travels/TravelCheckout/steps/Justification/components/JustificationCard/JustificationCard.types';
import {
  type IGetJustificationCardDataLists,
  type JustificationCardDataListRecordType
} from 'presentation/pages/travel/Travels/TravelCheckout/steps/Justification/Justification.types';

import { CustomDate } from '../custom';
import CurrencyUtil from '../format/Currency.util';

class TravelCheckout {
  getSummaryCardInfo(travelCart: ITravelCart | null): ISummaryCardInfo {
    let flightsAmount = 0;
    let flightsTotalPrice = 0;
    let hotelsAmount = 0;
    let hotelsTotalPrice = 0;
    let totalPrice = 0;

    if (!travelCart) {
      return {
        flightsAmount,
        flightsTotalPrice,
        hotelsAmount,
        hotelsTotalPrice,
        totalPrice
      };
    }

    travelCart.items.forEach(travelCartItem => {
      switch (travelCartItem.itemType) {
        case TravelProducts.AEREO:
          flightsAmount += 1;
          flightsTotalPrice += travelCartItem.totalPrice ?? 0;
          break;

        case TravelProducts.HOTEL:
          hotelsAmount += 1;
          hotelsTotalPrice += travelCartItem.totalPrice ?? 0;
      }

      totalPrice += travelCartItem.totalPrice ?? 0;
    });

    return {
      flightsAmount,
      flightsTotalPrice,
      hotelsAmount,
      hotelsTotalPrice,
      totalPrice
    };
  }

  getJustificationCardData(
    item: TravelCartItemType,
    currentLangKey: LanguageKeyType
  ): IJustificationCardData {
    switch (item.itemType) {
      case TravelProducts.AEREO: {
        const parsedDepartureDate = CustomDate.getDateWithDayAndMonthName(
          item.departureDate,
          currentLangKey
        );
        const parsedReturnDate = CustomDate.getDateWithDayAndMonthName(
          item.arrivalDate,
          currentLangKey
        );
        return {
          id: item.bookingId,
          productType: item.itemType,
          totalValue: CurrencyUtil.format('BRL', item.totalPrice, true),
          datesPeriod: `${parsedDepartureDate} - ${parsedReturnDate}`,
          policyRules: item.brokenRules,
          origin: `(${item.originIata}) ${item.originCity}`,
          destination: `(${item.destinationIata}) ${item.destinationCity}`
        };
      }
      case TravelProducts.HOTEL: {
        const parsedCheckinDate = CustomDate.getDateWithDayAndMonthName(
          item.checkIn,
          currentLangKey
        );
        const parsedCheckoutDate = CustomDate.getDateWithDayAndMonthName(
          item.checkOut,
          currentLangKey
        );

        return {
          id: item.bookingId,
          productType: item.itemType,
          totalValue: CurrencyUtil.format('BRL', item.totalPrice, true),
          datesPeriod: `${parsedCheckinDate} - ${parsedCheckoutDate}`,
          policyRules: item.brokenRules,
          hotelName: item.hotelName
        };
      }
    }
  }

  getJustificationCardDataLists(
    travelCart: ITravelCart | null | undefined,
    currentLangKey: LanguageKeyType
  ): IGetJustificationCardDataLists {
    const justificationCardDataListRecord: JustificationCardDataListRecordType =
      {
        AEREO: [],
        HOTEL: [],
        ONIBUS: [],
        VEICULO: []
      };

    const outOfPolicyIds: string[] = [];

    if (!travelCart || travelCart.items.length === 0) {
      return {
        justificationCardDataListRecord,
        outOfPolicyIds
      };
    }

    travelCart.items.forEach(cartItem => {
      if (cartItem.flagPolicyRules) return;

      outOfPolicyIds.push(cartItem.bookingId);

      justificationCardDataListRecord[cartItem.itemType].push(
        this.getJustificationCardData(cartItem, currentLangKey)
      );
    });

    return {
      justificationCardDataListRecord,
      outOfPolicyIds
    };
  }

  validateField(value?: string): boolean {
    if (!value || value.trim().length === 0) return false; // Campo obrigatório e não pode ser apenas espaços
    if (value.trim().length < 5) return false; // Mínimo de 5 caracteres
    if (/^\d+$/.test(value)) return false; // Não pode ser apenas números

    return true;
  }
}

export default new TravelCheckout();
