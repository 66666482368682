import { create } from 'zustand';

import { cookiesKeys } from 'shared/constants/global';
import { CustomCookies } from 'shared/utils/global';

import { type IUseFuelContext } from './useFuelContext.types';

const initialValueShowCircleMarkOnMenu =
  CustomCookies.get(cookiesKeys.userAlreadyAccessFuelPoliciesPage) !== 'true' ||
  CustomCookies.get(cookiesKeys.userAlreadyAccessFuelVehiclesPage) !== 'true';

export const useFuelContext = create<IUseFuelContext>()(set => ({
  showCircleMarkOnMenu: initialValueShowCircleMarkOnMenu,
  setCookieAccessFuelVehiclesPage: () => {
    CustomCookies.set(cookiesKeys.userAlreadyAccessFuelVehiclesPage, 'true', {
      sharedDomain: true,
      daysToExpire: 365
    });

    if (
      CustomCookies.get(cookiesKeys.userAlreadyAccessFuelPoliciesPage) ===
      'true'
    ) {
      set({ showCircleMarkOnMenu: false });
    }
  },
  setCookieAccessFuelPoliciesPage: () => {
    CustomCookies.set(cookiesKeys.userAlreadyAccessFuelPoliciesPage, 'true', {
      sharedDomain: true,
      daysToExpire: 365
    });

    if (
      CustomCookies.get(cookiesKeys.userAlreadyAccessFuelVehiclesPage) ===
      'true'
    ) {
      set({ showCircleMarkOnMenu: false });
    }
  }
}));
