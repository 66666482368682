import { tokens } from 'ds/tokens';
import { transparentize } from 'polished';
import styled, { css, keyframes } from 'styled-components';

import {
  type MultipleLoadersContainerStylesType,
  type SkeletonLoaderStylesType
} from './SkeletonLoader.types';
import { type AddPrefixToType } from 'ds/types';

const highlightProgress = keyframes`
  0% {
    left: -50rem;
  }
  100% {
    left: 100%;
  }
`;

export const Loader = styled.span<AddPrefixToType<SkeletonLoaderStylesType>>`
  position: relative;
  display: inline-block;
  width: ${({ $width }) => $width};
  max-width: ${({ $maxWidth }) => $maxWidth};
  height: ${({ $height }) => $height};
  margin: ${({ $margin }) => $margin};
  background: ${({ $color }) => tokens[$color]};
  border-radius: ${({ $circle }) => ($circle ? '50%' : tokens.borderRadiusXs)};
  overflow: hidden;
  flex-shrink: ${({ $circle }) => ($circle ? 0 : 1)};

  &::before {
    content: '';
    position: absolute;
    width: 50rem;
    height: 100%;
    top: 0;
    left: -50rem;
    ${css`
      background-image: linear-gradient(
        90deg,
        ${transparentize(1, tokens.neutralColorHighPure)},
        ${transparentize(0.4, tokens.neutralColorHighPure)},
        ${transparentize(1, tokens.neutralColorHighPure)}
      );
    `}
    animation: ${highlightProgress} 1.2s ease-in-out infinite;
  }
`;

export const MultipleLoadersContainer = styled.div<
  AddPrefixToType<MultipleLoadersContainerStylesType>
>`
  width: ${({ $width }) => $width};
  max-width: ${({ $maxWidth }) => $maxWidth};
`;
