import { DefaultButton } from 'ds';
import { useTranslation } from 'react-i18next';

import { vexDomain } from 'data/config';

import { DefaultLayout } from 'presentation/templates';

import unauthorizedError from 'shared/assets/images/global/401-error.png';
import notFoundError from 'shared/assets/images/global/404-error.png';
import defaultError from 'shared/assets/images/global/default-error.png';

import { type IFallbackPageProps } from './FallbackPage.types';

import { Container, InfoContainer } from './FallbackPage.styles';

export function FallbackPage({
  error = '404'
}: IFallbackPageProps): JSX.Element {
  const { t } = useTranslation(['errors', 'global']);

  return (
    <DefaultLayout>
      <Container>
        {error === 'DEFAULT' && (
          <>
            <InfoContainer>
              <h1>Ops!</h1>

              <h2>{t('errors:defaultErrorPageTitle')}</h2>

              <p>{t('errors:defaultErrorPageDescription')}</p>

              <DefaultButton
                size='small'
                onClick={() => (window.location.href = '/')}
              >
                {t('global:goBackInitialPage')}
              </DefaultButton>
            </InfoContainer>

            <img
              src={defaultError}
              alt='Erro'
            />
          </>
        )}

        {error === '404' && (
          <>
            <InfoContainer>
              <h1>Ops!</h1>

              <h2>{t('errors:pageNotFoundTitle')}</h2>

              <p>{t('errors:pageNotFoundDescription')}</p>

              <DefaultButton
                size='small'
                onClick={() => (window.location.href = `${vexDomain}/inicio`)}
              >
                {t('global:goBackInitialPage')}
              </DefaultButton>
            </InfoContainer>

            <img
              src={notFoundError}
              alt='Erro'
            />
          </>
        )}

        {error === '401' && (
          <>
            <InfoContainer>
              <h1>Ops!</h1>

              <h2>{t('errors:unauthorizedPageTitle')}</h2>

              <p>{t('errors:unauthorizedPageDescription')}</p>

              <DefaultButton
                size='small'
                onClick={() => (window.location.href = '/')}
              >
                {t('global:goBackInitialPage')}
              </DefaultButton>
            </InfoContainer>

            <img
              src={unauthorizedError}
              alt='Erro'
            />
          </>
        )}
      </Container>
    </DefaultLayout>
  );
}
