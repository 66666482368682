import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.td`
  background-color: ${tokens.neutralColorHighPure};
  padding: ${tokens.spacingXxs} ${tokens.spacingXs};
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};

  div {
    display: flex;
    flex-flow: row wrap;
    color: ${tokens.neutralColorLowDarkest};
    gap: ${tokens.spacingInsetNano};
  }

  svg {
    flex-shrink: 0;
    width: 2.2rem;
    height: 2.2rem;
  }
`;
