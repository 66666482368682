import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.tr`
  width: 100%;

  &:hover > td {
    background-color: ${tokens.brandColorPrimaryLightest};
  }
`;
