import { tokens } from 'ds/tokens';
import styled, { css, keyframes } from 'styled-components';

import { type IContainerStyles, type ITextStyles } from './LoadingBar.types';
import { type Keyframes } from 'styled-components/dist/types';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacingInsetNano};
`;

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${tokens.spacingInsetXs};
`;

export const PercentageContainer = styled.div`
  min-width: 4.4rem;
`;

export const ProgressBarContainer = styled.div<IContainerStyles>`
  overflow: hidden;
  border-radius: ${tokens.borderRadiusXxl};
  background-color: ${tokens.neutralColorHighDark};
  width: 100%;
  height: ${({ $size }) => {
    switch ($size) {
      case 'xsmall':
        return '4px';

      case 'small':
        return '8px';

      case 'medium':
        return '16px';

      case 'large':
        return '24px';
    }
  }};
`;

function getLoadingAnimation(fakeLoadingLimit: number): Keyframes {
  return keyframes`
  from {
    width: 0%;
  }
  to {
    width: ${fakeLoadingLimit}%;
  }
`;
}

export const ProgressBar = styled.div<IContainerStyles>`
  border-radius: inherit;
  background-color: ${({ $variant }) => {
    switch ($variant) {
      case 'default':
        return tokens.brandColorPrimaryLight;

      case 'error':
        return tokens.feedbackColorErrorMediumLight;

      case 'success':
        return tokens.feedbackColorSuccessPure;
    }
  }};
  height: inherit;
  width: ${({ $progressPercentage }) => `${$progressPercentage}%`};
  ${({ $fakeLoadingDuration, $fakeLoadingLimit }) => {
    if ($fakeLoadingDuration && $fakeLoadingLimit) {
      return css`
        animation: ${getLoadingAnimation($fakeLoadingLimit)}
          ${$fakeLoadingDuration}s ease-out forwards;
      `;
    }
  }}
`;

export const Text = styled.p<ITextStyles>`
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${({ $size }) => {
    switch ($size) {
      case 'xsmall':
        return tokens.fontSizeXxss;

      case 'small':
        return tokens.fontSizeXxss;

      case 'medium':
        return tokens.fontSizeXxs;

      case 'large':
        return tokens.fontSizeXs;
    }
  }};
  font-weight: ${tokens.fontWeightRegular};
  letter-spacing: 1px;
  text-align: left;
`;

export const TitlePercentageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
