import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type ContainerStyleType } from './CardInfoCaption.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.p<AddPrefixToType<ContainerStyleType>>`
  color: ${tokens.neutralColorLowMediumLight};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 2.2rem;
  letter-spacing: 0.025rem;
  margin-top: ${tokens.spacingInsetXxxs};

  ${({ $variant }) =>
    $variant === 'small'
      ? css`
          font-size: ${tokens.fontSizeXxss};
          margin-top: 0;
        `
      : $variant === 'medium'
        ? css`
            font-size: ${tokens.fontSizeXxs};
          `
        : $variant === 'large' &&
          css`
            font-size: ${tokens.fontSizeXs};
          `}
`;
