import { tokens } from 'ds/tokens';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { type ImageStyleType } from './ImageFullScreen.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const Overlay = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 6rem;
`;

export const OverlayScroll = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
  padding: 7rem 6rem;

  * {
    scroll-behavior: unset !important;
    user-select: none !important;
  }
`;

export const CloseButton = styled.span`
  color: white;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  font-size: 4rem;
  font-weight: bold;
  opacity: 0.2;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }
`;

export const ImageContainer = styled.div`
  margin: auto;
  padding: 0;
  width: 90%;
  height: 100%;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ImageZoomContainer = styled.div`
  margin: auto;
  padding: 0;
  width: 90%;
  height: 100%;
  text-align: center;
  overflow: hidden;
`;

export const Slide = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Image = styled.img`
  max-height: 100%;
  max-width: 100%;
`;

export const ImageZoom = styled.img<AddPrefixToType<ImageStyleType>>`
  width: calc(60rem * (${({ $zoom }) => $zoom}));
  cursor: grab;
  transform-origin: top;
  max-height: ${({ $zoom }) => ($zoom ?? 1) * 100}vh;
`;

export const MenuContainer = styled.div`
  position: absolute;
  top: 1rem;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  border-radius: 25px;
  background-color: ${tokens.neutralColorHighPure};
  align-items: center;
  justify-content: center;
  gap: 1rem;
  user-select: none;

  svg {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    color: ${tokens.neutralColorLowDark};

    * {
      stroke: ${tokens.neutralColorLowDark};
    }
  }
`;

export const Percentage = styled.span`
  text-align: center;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  font-family: ${tokens.fontFamiliesPoppins};
  letter-spacing: 0.075rem;
`;
