import { create } from 'zustand';

import { type IUseCardDetailsContext } from './useCardDetailsContext.types';

export const useCardDetailsContext = create<IUseCardDetailsContext>()(set => ({
  selectedAccount: null,
  temporaryTransactions: [],

  handleSetTemporaryTransactions: transaction => {
    if (transaction) {
      set(state => ({
        temporaryTransactions: state.temporaryTransactions
          ? [...state.temporaryTransactions, transaction]
          : [transaction]
      }));
    }
  },

  handleSetSelectedAccount: account => {
    set({ selectedAccount: account });
  },

  handleSetAvailableBalance: availableBalance => {
    set(state => ({
      selectedAccount: {
        ...state.selectedAccount,
        availableBalance
      }
    }));
  }
}));
