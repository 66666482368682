import { create } from 'zustand';

import { type ITravelUsersFilter } from 'data/modules/travel/travelUsers';

import { type IUseTravelUsersTravelersContext } from './useTravelUsersTravelersContext.types';

const defaultFilters: ITravelUsersFilter = {
  search: '',
  page: 1,
  perPage: 10,
  orderBy: null,
  order: 'default'
};

export const useTravelUsersTravelersContext =
  create<IUseTravelUsersTravelersContext>()((set, get) => ({
    filters: defaultFilters,
    handleChangeFilter: newFilters => {
      set(state => ({
        filters: {
          ...state.filters,
          ...newFilters,
          page: newFilters.page ?? 1
        }
      }));
    },
    failedUsersUpdate: [],
    setFailedUsersUpdate: failedUsers => {
      set({ failedUsersUpdate: failedUsers });
    }
  }));
