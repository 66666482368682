import { create } from 'zustand';

import { type IUseApportionmentContext } from './useApportionmentContext.types';

export const useApportionmentContext = create<IUseApportionmentContext>()(
  set => ({
    disabledKilometerInput: false,
    requiredReceiptInManualRoute: false,
    handleChangeDisabledKilometerInput: state => {
      set({ disabledKilometerInput: state });
    },
    handleChangeRequiredReceiptInManualRoute: state => {
      set({ requiredReceiptInManualRoute: state });
    }
  })
);
