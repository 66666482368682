import { create } from 'zustand';

import {
  type ITravelCartContext,
  type ITravelCartContextState
} from './useTravelCartContext.types';

const initialState: ITravelCartContextState = {
  selectedCartItem: null,
  isRemoveCartItemModalOpen: false,
  cartItemToRemove: null,
  afterRemoveCartItemCallback: null,
  searchSessionId: null
};

export const useTravelCartContext = create<ITravelCartContext>()(set => ({
  ...initialState,
  setSelectedCartItem: cartItem => {
    set({ selectedCartItem: cartItem });
  },
  closeRemoveCartItemModal: () => {
    set({
      isRemoveCartItemModalOpen: false,
      cartItemToRemove: null,
      afterRemoveCartItemCallback: null
    });
  },
  setCartItemToRemove: (cartItem, afterRemoveCallback) => {
    set({
      isRemoveCartItemModalOpen: true,
      cartItemToRemove: cartItem,
      afterRemoveCartItemCallback: afterRemoveCallback || null
    });
  },
  setSearchSessionId: searchSessionId => {
    set({ searchSessionId });
  }
}));
