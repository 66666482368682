import axios from 'axios';

import { ErrorHandle } from 'shared/utils/errors';

import { env } from './env.config';

// Access Token gerado pelo set-access-token.cjs
const accessToken = env.VITE_PERSONAL_TOKEN;

// Domínio do projeto do VExpenses no Laravel
export const vexDomain = env.VITE_VEX_BASE_DOMAIN;

// Instância do Axios para desenvolvimento local
const apiLocal = axios.create({
  baseURL: env.VITE_API_LOCALHOST,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    Accept: 'application/json',
    'X-Header-FrontLocalOrigin': `http://${window.location.host}`
  }
});

// Instância do Axios para desenvolvimento em prod, staging e dev
const apiProd = axios.create({
  baseURL: `${env.VITE_API_BASE_URL}/v3`,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
});

// Verifica qual o ambiente e exporta as instâncias baseado nisso
const api = process.env.NODE_ENV === 'development' ? apiLocal : apiProd;

api.interceptors.response.use(ErrorHandle.apiError, ErrorHandle.defaultError);

export default api;
