enum AuthQueryKeys {
  'AUTH_CARDS_COMPANY' = 'AUTH_CARDS_COMPANY',
  'AUTH_CARDS_USER' = 'AUTH_CARDS_USER',
  'AUTH_USER' = 'AUTH_USER',
  'AUTH_TRAVEL_USER' = 'AUTH_TRAVEL_USER',
  'GET_OUTSOURCED_USER' = 'GET_OUTSOURCED_USER',
  'GET_OUTSOURCED_USERS_AND_PERMISSIONS' = 'GET_OUTSOURCED_USERS_AND_PERMISSIONS'
}

enum AuthMutationKeys {
  'SEND_EMAIL_CONFIRMATION' = 'SEND_EMAIL_CONFIRMATION'
}

export { AuthMutationKeys, AuthQueryKeys };
