import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${tokens.spacingInsetXxs};
  user-select: none;

  svg {
    cursor: pointer;
    width: 2.2rem;
    height: 2.2rem;
  }
`;

export const CurrentPage = styled.span`
  cursor: pointer;
  min-height: 2.2rem;
  min-width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${tokens.neutralColorHighPure};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  border-radius: ${tokens.borderRadiusCircular};
  background-color: ${tokens.brandColorPrimaryPure};
`;

export const NumberPage = styled.span`
  cursor: pointer;
  min-height: 2.2rem;
  min-width: 2.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${tokens.neutralColorLowMedium};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
`;
