import { type IDefaultIconProps } from 'data/modules/global';

export function IndeterminateCircle({
  size,
  color
}: IDefaultIconProps): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path
        fill='none'
        d='M0 0h24v24H0z'
      ></path>
      <path d='M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11v2h10v-2H7z'></path>
    </svg>
  );
}
