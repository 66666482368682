import { tokens } from 'ds/tokens';
import { transitions } from 'ds/transitions';
import styled, { css, keyframes } from 'styled-components';

import { type MenuTabItemPropsStylesType } from './MenuTabItem.types';
import { type AddPrefixToType } from 'ds/types';

const widthAnimation = keyframes`
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
`;

export const Container = styled.li<AddPrefixToType<MenuTabItemPropsStylesType>>`
  display: flex;
  width: fit-content;
  flex-direction: row;
  gap: ${tokens.spacingInsetNano};
  cursor: pointer;
  position: relative;
  align-items: center;
  padding: ${tokens.spacingQuarck} ${tokens.spacingXs};

  ${({ $size }) =>
    $size === 'medium' &&
    css`
      height: 5.2rem;

      button,
      span {
        font-size: ${tokens.fontSizeXxs};
      }
    `}

  ${({ $size }) =>
    $size === 'small' &&
    css`
      button,
      span {
        font-size: ${tokens.fontSizeXxss};
      }
    `}

    ${({ $active }) =>
    $active &&
    css`
      &::before {
        content: '';
        position: absolute;
        bottom: -0.3rem;
        height: 0.4rem;
        left: 50%;
        width: 100%;
        background-color: ${tokens.brandColorPrimaryMedium};
        border-radius: ${tokens.borderRadiusXl};
        animation: ${widthAnimation} 200ms ease-in-out;
        transform: translate(-50%, 0);
      }

      button {
        color: ${tokens.brandColorPrimaryMedium} !important;
      }
    `};
`;

export const TabButton = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  font-size: ${tokens.fontSizeXs};
  font-weight: ${tokens.fontWeightMedium};
  font-family: ${tokens.fontFamilyPoppins};
  color: ${tokens.neutralColorLowMedium};
  transition: ${transitions.allEaseInOut};
  letter-spacing: 0.0075rem;
  line-height: 2.43rem;
  white-space: nowrap;

  &:hover {
    color: ${tokens.neutralColorLowDarkest};
  }
`;
