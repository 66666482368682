import { tokens } from 'ds';
import { transparentize } from 'polished';
import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;

    /* Width */
    &::-webkit-scrollbar {
        width: 0.6rem;
        height: 0.6rem;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: ${tokens.neutralColorHighDark};
      border-radius: 8px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: ${tokens.neutralColorLowLightest};
      border-radius: 8px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: ${tokens.neutralColorLowLight};
    }


    @media screen and (min-width: 1335px) {
      scroll-behavior: smooth;

      &::-webkit-scrollbar {
        width: 0.8rem;
        height: 0.8rem;
      }
    }
  }

  html, body, #root {
    height: 100vh;

    /* 1920px and UP - Full HD */
    /* stylelint-disable-next-line declaration-property-unit-allowed-list */
    font-size: 10px;

    /* 1680px */
    @media screen and (max-width: 1680px) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 9.5px;
    }

    /* 1366px and Below - HD */
    @media screen and (max-width: 1366px) {
      /* stylelint-disable-next-line declaration-property-unit-allowed-list */
      font-size: 9px;
    }
  }

  body, input, button, textarea {
    font-family: 'Inter', 'Ubuntu', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
  }

  a {
    text-decoration: none;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
  }

  table {
    border-collapse: collapse;
  }

  /* stylelint-disable-next-line at-rule-disallowed-list */
  @keyframes side-modal-right-opening {
    from {
      transform: translateX(100%)
    }

    to {
      transform: translateX(0)
    }
  }

  /* stylelint-disable-next-line at-rule-disallowed-list */
  @keyframes side-modal-left-opening {
    from {
      transform: translateX(-100%)
    }

    to {
      transform: translateX(0)
    }
  }

  /* stylelint-disable-next-line at-rule-disallowed-list */
  @keyframes side-modal-backdrop-opening {
    from {
      opacity: 0;
    }

    to {
      opacity: 1
    }
  }

  /* stylelint-disable-next-line at-rule-disallowed-list */
  @keyframes side-modal-right-closing {
    from {
      transform: translateX(0)
    }

    to {
      transform: translateX(100%)
    }
  }

  /* stylelint-disable-next-line at-rule-disallowed-list */
  @keyframes side-modal-left-closing {
    from {
      transform: translateX(0)
    }

    to {
      transform: translateX(-100%)
    }
  }

  /* stylelint-disable-next-line at-rule-disallowed-list */
  @keyframes side-modal-backdrop-closing {
    from {
      opacity: 1;
    }

    to {
      opacity: 0
    }
  }

  .default-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: ${({ theme }) => transparentize(0.5, theme.colors.black)};
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: ${({ theme }) => theme.zIndex.modal};
  }

  .default-modal-content {
    position: relative;
    min-height: 10rem;
    min-width: 27.5rem;
    max-width: 90%;
    max-height: 90%;
    overflow: visible auto;
    background: ${({ theme }) => theme.colors.white};
  }

  .ds-default-modal-content {
    position: relative;
    min-height: 10rem;
    min-width: 27.5rem;
    max-width: 90%;
    max-height: 90%;
    overflow: visible auto;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 16px;
  }

  .side-modal-backdrop-opening-animation {
    animation: side-modal-backdrop-opening 300ms ease-in-out;
  }

  .side-modal-backdrop-closing-animation {
    animation: side-modal-backdrop-closing 300ms ease-in-out;
  }

  .side-modal-content {
    position: absolute;
    height: 100%;
    background: ${({ theme }) => theme.colors.white};
  }

  .side-modal-right {
    right: 0;
    animation: side-modal-right-opening 300ms ease-in-out;
  }

  .side-modal-left {
    left: 0;
    animation: side-modal-left-opening 300ms ease-in-out;
  }

  .side-modal-right-closing-animation {
    animation: side-modal-right-closing 300ms ease-in-out;
  }

  .side-modal-left-closing-animation {
    animation: side-modal-left-closing 300ms ease-in-out;
  }

  .react-select-portal-with-menu-limited-height {
    .react-select-custom__menu-list {
      max-height: 20rem;
    }
  }
`;

export default GlobalStyles;
