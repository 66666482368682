import { Outlet } from 'react-router-dom';

import { useGetSessionUser, useLoadInitialData } from 'data/modules/auth';

import { SplashScreen } from 'presentation/components/global/SplashScreen';
import { FallbackPage } from 'presentation/pages/global';

export function AuthMiddleware(): JSX.Element {
  const { isLoading, hasError } = useLoadInitialData();
  const { user, outsourcedUserWithoutCard, cardsCompany, cardsUser } =
    useGetSessionUser();

  if (isLoading) {
    return <SplashScreen />;
  }

  if ((!isLoading && !user) || (!isLoading && hasError)) {
    return <FallbackPage error='DEFAULT' />;
  }

  if (
    outsourcedUserWithoutCard &&
    cardsCompany?.isOnboardingFinished &&
    cardsUser?.isOnboardingFinished
  ) {
    window.location.href = '/despesas';
  }

  return <Outlet />;
}
