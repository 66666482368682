import { tokens } from 'ds';
import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type DropdownStylesType } from './Dropdown.types';

export const Container = styled.div`
  height: fit-content;
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: center;
`;

export const Button = styled.button`
  cursor: pointer;
  width: fit-content;
  background-color: transparent;
  border: 0;
`;

export const Content = styled.div<AddPrefixToType<DropdownStylesType>>`
  width: max-content;
  background: ${({ theme }) => theme.colors.white};
  border: 2px solid ${tokens.neutralColorHighMedium};
  box-shadow: ${`${tokens.shadowLeve4.x} ${tokens.shadowLeve4.y} ${tokens.shadowLeve4.blur} ${tokens.shadowLeve4.spread} ${tokens.shadowLeve4.color}`};

  display: ${({ $opened }) => ($opened ? 'block' : 'none')};
  position: absolute;
  top: calc(100% + 0.1rem);
  z-index: ${({ theme }) => theme.zIndex.dropdown};
  padding: ${tokens.spacingNano};
  border-radius: ${tokens.borderRadiusMd};

  ${({ $direction }) =>
    $direction === 'center' &&
    css`
      margin: 0;
    `}

  ${({ $direction }) =>
    $direction === 'right' &&
    css`
      left: 0;
    `}

  ${({ $direction }) =>
    $direction === 'left' &&
    css`
      right: -1rem;
    `}
`;
