import { format } from 'date-fns';
import { ga4 } from 'ds/eventsDsGa4';
import ga from 'react-ga4';

import { CustomCookies } from 'shared/utils/global';

import {
  type GAEventsType,
  type GAParamsType,
  type GAScopeType,
  type IUseDsGa4
} from './useDsGa4.types';

export function useDsGa4(): IUseDsGa4 {
  function sendDsGaEvent<
    ScopeType extends GAScopeType,
    EventType extends GAEventsType<ScopeType>
  >(
    scope: ScopeType,
    event: EventType,
    params?: GAParamsType<ScopeType, EventType>,
    replace?: boolean
  ): void {
    const eventJson: GAParamsType<ScopeType, EventType> =
      ga4[scope]?.[event] ?? {};

    params = params
      ? replace
        ? params
        : {
            ...eventJson,
            ...params
          }
      : eventJson;

    if (params) {
      ga.event(event as string, {
        ...params,
        sessionUserId: CustomCookies.get('GAUID')?.replace('GAUID', ''),
        companyId: CustomCookies.get('GACID')?.replace('GACID', ''),
        eventProduct: '/' + window.location.pathname.split('/')[1],
        timeStamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
      });
    }
  }

  return {
    sendDsGaEvent
  };
}
