import { tokens } from 'ds/tokens';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { type ContainerStylesType } from './Tooltip.types';
import { type AddPrefixToType } from 'ds/types';

export const Arrow = styled.div`
  position: absolute;
  width: 1.6rem;
  height: 1.6rem;
  pointer-events: none;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 2;
    margin: auto 0;
    width: 1.6rem;
    height: 1.6rem;
    background-color: ${tokens.neutralColorLowDarkest};
    clip-path: polygon(70% 2%, 2% 70%, 100% 100%);
    border-radius: 0 0 5px;
  }
`;

export const Container = styled(motion.div)<
  AddPrefixToType<ContainerStylesType>
>`
  padding: ${tokens.spacingInsetNano};
  background-color: ${tokens.neutralColorLowDarkest};
  border-radius: ${tokens.borderRadiusNano};
  color: ${tokens.neutralColorHighPure} !important;
  font-family: ${tokens.fontFamiliesPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightMedium};
  line-height: 140%;
  ${({ $width }) => $width && `width: ${$width};`}
  max-height: ${({ $maxHeight }) => $maxHeight}rem;
  max-width: ${({ $maxWidth }) => $maxWidth}rem;

  ${({ $zIndex }) =>
    $zIndex !== undefined &&
    css`
      z-index: ${$zIndex};
    `}

  &[data-popper-interactive='false'] {
    pointer-events: none;
  }

  &[data-popper-placement*='bottom'] ${Arrow} {
    left: 0;
    margin-top: -0.4rem;
    top: 0;

    &::before,
    &::after {
      transform: rotate(225deg);
    }
  }

  &[data-popper-placement*='right'] ${Arrow} {
    left: 0;
    margin-left: -0.5rem;

    &::before,
    &::after {
      transform: rotate(135deg);
    }
  }

  &[data-popper-placement*='top'] ${Arrow} {
    bottom: 0;
    left: 0;
    margin-bottom: -0.5rem;

    &::before,
    &::after {
      transform: rotate(45deg);
    }
  }

  &[data-popper-placement*='left'] ${Arrow} {
    margin-right: -0.5rem;
    right: 0;

    &::before,
    &::after {
      transform: rotate(-45deg);
    }
  }
`;
