import { useDsGa4 } from 'ds/hooks/globals';

import { type IDefaultTabItemProps } from './DefaultTabItem.types';

import { Badge, Container, TabButton } from './DefaultTabItem.styles';

export function DefaultTabItem({
  active,
  children,
  badge,
  badgePosition = 'right',
  size = 'large',
  onClick,
  tabPosition,
  ...rest
}: IDefaultTabItemProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      $size={size}
      $badgePosition={badgePosition}
      $active={active}
      onClick={event => {
        if (onClick) {
          sendDsGaEvent('components', 'tabs', {
            eventLabel: children?.toString(),
            eventValue: String(tabPosition) ?? '0'
          });

          onClick(event);
        }
      }}
      {...rest}
      data-testid='defaultTabItemContainer'
    >
      <TabButton type='button'>{children}</TabButton>

      {typeof badge === 'number' && <Badge>{badge}</Badge>}
    </Container>
  );
}
