import { tokens } from 'ds/tokens';
import styled from 'styled-components';

import { type ContainerStylesType } from './CustomRadioItemControlledContainer.types';
import { type AddPrefixToType } from 'ds/types';

export const CheckIndicator = styled.div`
  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  width: 10px;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  height: 10px;
  border-radius: 50%;
  background-color: transparent;
`;

export const RadioComponent = styled.div`
  border-radius: 50%;
  border: solid 2px ${tokens.neutralColorLowLightest};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.8rem;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  width: 20px;

  /* stylelint-disable-next-line declaration-property-unit-allowed-list */
  height: 20px;
`;

export const LabelContainer = styled.div`
  background-color: ${tokens.deepColorWhite};
  border-radius: ${tokens.borderRadiusMd};
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${tokens.spacingNano};
  padding: ${tokens.spacingXxs} ${tokens.spacingNano};
`;

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  width: 100%;
  max-width: 94.2rem;
  height: 8.8rem;
  position: relative;

  > input:checked + ${LabelContainer} {
    background-color: ${tokens.brandColorSecondaryLightest};

    ${RadioComponent} {
      border-color: ${tokens.brandColorSecondaryPure};

      ${CheckIndicator} {
        background-color: ${tokens.brandColorSecondaryPure};
      }
    }
  }

  &:hover {
    ${LabelContainer} {
      background-color: ${tokens.neutralColorHighLight};
    }
  }

  ${({ $disabled }) =>
    $disabled &&
    `
    > input:checked + ${LabelContainer} {
      background-color: unset;
    }

    &:hover {
      > input {
        cursor: not-allowed;
      }

      ${LabelContainer} {
        background-color: unset;
      }
    }

    ${LabelContainer} {
      > div:first-child {
        border-color: #ddd;
      }

      ${Label} > * {
        color: ${tokens.neutralColorLowMediumLight};

        svg {
          color: #ddd;
        }
      }
    }

  `}
`;

export const InputComponent = styled.input`
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
`;

export const Label = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${tokens.spacingNano};
  padding: 0 ${tokens.spacingXs};
  flex-grow: 1;
`;
