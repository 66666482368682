import { type IDefaultIconProps } from 'data/modules/global';

export function Equalizer({ size, color }: IDefaultIconProps): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 20 22'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M4.17 17a3 3 0 015.66 0H20v2H9.83a3 3 0 01-5.66 0H0v-2h4.17zm6-7a3 3 0 015.66 0H20v2h-4.17a3 3 0 01-5.66 0H0v-2h10.17zm-6-7a3.001 3.001 0 015.66 0H20v2H9.83a3.001 3.001 0 01-5.66 0H0V3h4.17z'></path>
    </svg>
  );
}
