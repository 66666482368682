import { useDsGa4 } from 'ds/hooks/globals';

import { type IMenuTabItemProps } from './MenuTabItem.types';

import { Container, TabButton } from './MenuTabItem.styles';

export function MenuTabItem({
  active,
  children,
  size = 'medium',
  onClick,
  tabPosition,
  ...rest
}: IMenuTabItemProps): JSX.Element {
  const { sendDsGaEvent } = useDsGa4();

  return (
    <Container
      $size={size}
      $active={active}
      data-testid='menuTabItemContainer'
      onClick={event => {
        if (onClick) {
          sendDsGaEvent('components', 'tabs', {
            eventLabel: children?.toString(),
            eventValue: String(tabPosition) ?? '0'
          });

          onClick(event);
        }
      }}
      {...rest}
    >
      <TabButton type='button'>{children}</TabButton>
    </Container>
  );
}
