import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { type ContainerStyleType } from './CardInfoValue.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.span<AddPrefixToType<ContainerStyleType>>`
  color: ${tokens.neutralColorLowDarkest};
  font-family: ${tokens.fontFamilyPoppins};
  font-weight: ${tokens.fontWeightBold};
  letter-spacing: 0.1rem;
  width: fit-content;
  max-width: 25rem;
  overflow: auto;
  white-space: nowrap;

  ${({ $variant }) =>
    $variant === 'small'
      ? css`
          font-size: ${tokens.fontSizeXs};
          line-height: 3rem;
        `
      : $variant === 'medium'
        ? css`
            font-size: ${tokens.fontSizeMd};
          `
        : $variant === 'large' &&
          css`
            font-size: ${tokens.fontSizeLg};
          `}
`;
