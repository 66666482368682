import { type IDefaultIconProps } from 'data/modules/global';

export function EmotionSad({
  type,
  color,
  size
}: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M12 2C17.523 2 22 6.477 22 12C22 12.727 21.923 13.435 21.775 14.118L19.993 12.335C20.0664 10.5798 19.5599 8.84915 18.5519 7.41037C17.5438 5.97159 16.0902 4.90464 14.4154 4.37422C12.7406 3.84381 10.9377 3.87943 9.28521 4.47559C7.63267 5.07175 6.22231 6.19529 5.27187 7.67277C4.32143 9.15025 3.88375 10.8995 4.0264 12.6505C4.16905 14.4015 4.8841 16.0568 6.06116 17.361C7.23822 18.6651 8.81184 19.5455 10.5391 19.8664C12.2663 20.1872 14.0511 19.9305 15.618 19.136C15.9997 19.7409 16.5367 20.2323 17.173 20.559C15.6132 21.5041 13.8238 22.0025 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM19 14.172L20.414 15.586C20.6893 15.8612 20.8783 16.2107 20.9578 16.5916C21.0374 16.9726 21.004 17.3685 20.8619 17.7309C20.7198 18.0932 20.475 18.4062 20.1576 18.6314C19.8402 18.8567 19.464 18.9845 19.0751 18.9991C18.6861 19.0137 18.3014 18.9145 17.968 18.7136C17.6346 18.5128 17.3671 18.2191 17.1982 17.8684C17.0293 17.5178 16.9664 17.1255 17.0171 16.7396C17.0679 16.3537 17.2301 15.991 17.484 15.696L17.586 15.586L19 14.172ZM12 15C13.466 15 14.785 15.631 15.7 16.637L14.755 17.497C13.965 17.182 13.018 17 12 17C10.982 17 10.035 17.183 9.245 17.496L8.3 16.636C8.76821 16.1199 9.33937 15.7077 9.97666 15.426C10.6139 15.1442 11.3032 14.9991 12 15ZM8.5 10C8.89782 10 9.27936 10.158 9.56066 10.4393C9.84196 10.7206 10 11.1022 10 11.5C10 11.8978 9.84196 12.2794 9.56066 12.5607C9.27936 12.842 8.89782 13 8.5 13C8.10218 13 7.72064 12.842 7.43934 12.5607C7.15804 12.2794 7 11.8978 7 11.5C7 11.1022 7.15804 10.7206 7.43934 10.4393C7.72064 10.158 8.10218 10 8.5 10ZM15.5 10C15.8978 10 16.2794 10.158 16.5607 10.4393C16.842 10.7206 17 11.1022 17 11.5C17 11.8978 16.842 12.2794 16.5607 12.5607C16.2794 12.842 15.8978 13 15.5 13C15.1022 13 14.7206 12.842 14.4393 12.5607C14.158 12.2794 14 11.8978 14 11.5C14 11.1022 14.158 10.7206 14.4393 10.4393C14.7206 10.158 15.1022 10 15.5 10Z' />
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M12 2C17.523 2 22 6.477 22 12C22.0024 13.5614 21.6376 15.1016 20.935 16.496C20.8616 16.2113 20.7256 15.9465 20.537 15.721L20.414 15.586L19 14.172L17.586 15.586L17.469 15.713C17.2156 16.0142 17.0568 16.3834 17.0123 16.7745C16.9678 17.1655 17.0397 17.561 17.2189 17.9114C17.3982 18.2618 17.6768 18.5515 18.02 18.7443C18.3631 18.937 18.7555 19.0242 19.148 18.995C18.2175 19.9477 17.1056 20.7044 15.878 21.2206C14.6503 21.7367 13.3317 22.0017 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 15C10.62 15 9.37 15.56 8.466 16.463L8.3 16.637L9.245 17.497C10.035 17.182 10.982 17 12 17C12.905 17 13.754 17.144 14.486 17.396L14.755 17.496L15.7 16.636C15.2318 16.1199 14.6606 15.7077 14.0233 15.426C13.3861 15.1442 12.6968 14.9991 12 15ZM8.5 10C8.10218 10 7.72064 10.158 7.43934 10.4393C7.15804 10.7206 7 11.1022 7 11.5C7 11.8978 7.15804 12.2794 7.43934 12.5607C7.72064 12.842 8.10218 13 8.5 13C8.89782 13 9.27936 12.842 9.56066 12.5607C9.84196 12.2794 10 11.8978 10 11.5C10 11.1022 9.84196 10.7206 9.56066 10.4393C9.27936 10.158 8.89782 10 8.5 10ZM15.5 10C15.1022 10 14.7206 10.158 14.4393 10.4393C14.158 10.7206 14 11.1022 14 11.5C14 11.8978 14.158 12.2794 14.4393 12.5607C14.7206 12.842 15.1022 13 15.5 13C15.8978 13 16.2794 12.842 16.5607 12.5607C16.842 12.2794 17 11.8978 17 11.5C17 11.1022 16.842 10.7206 16.5607 10.4393C16.2794 10.158 15.8978 10 15.5 10Z' />
    </svg>
  );
}
