import { tokens } from 'ds/tokens';
import { ToastContainer as ToastifyContainer } from 'react-toastify';
import styled from 'styled-components';

export const Container = styled(ToastifyContainer)`
  .Toastify__toast {
    background-color: transparent;
    box-shadow: none;
  }

  .Toastify__toast-body {
    padding: 0;
    width: fit-content;
  }

  @media screen and (min-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    width: unset;
    max-width: 50%;

    .Toastify__toast-body {
      margin-right: ${tokens.spacingXs};

      > :not(:has(.notification)) {
        max-width: 32rem;
      }
    }
  }
`;
