import { Arrow, Content } from '@radix-ui/react-dropdown-menu';
import styled from 'styled-components';

export const Container = styled(Content)`
  background-color: #ffffff;
  padding: 2.4rem 0 0.1rem;
  border-radius: ${({ theme }) => theme.tokens.borderRadiusSm};
  z-index: 14;
  box-shadow: 0 6px 20px rgb(0 0 0 / 20%);
`;

export const StyledArrow = styled(Arrow)`
  fill: #fff;
`;
