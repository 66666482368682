import { useRef, useState } from 'react';

import { useOnClickOutside } from 'shared/hooks/global';

import { type IUseDropdownHookReturn } from './Dropdown.types';

export function useDropdown(): IUseDropdownHookReturn {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const toggleIsOpen = (): void => {
    setIsOpen(!isOpen);
  };

  useOnClickOutside(ref, () => {
    setIsOpen(false);
  });

  return { isOpen, setIsOpen, ref, toggleIsOpen };
}
