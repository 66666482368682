import { DSIcons } from 'ds';
import styled, { css, keyframes } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  DropdownContent,
  DropdownGroup,
  DropdownItem
} from 'presentation/ds/DsDropdown';

import { type ProfileWrapperStylesType } from './Profile.types';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.2rem;

  div[role='separator'] {
    margin-bottom: 0;
  }
`;

export const UserIcon = styled(DSIcons.UserIcon)`
  width: 2.4rem;
  height: 2.4rem;
`;

export const ProfileWrapper = styled.div<
  AddPrefixToType<ProfileWrapperStylesType>
>`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  max-width: 25.5rem;
  color: ${({ theme }) => theme.colors.adamantineBlue};

  p {
    font-weight: 400;
    font-size: 1.4rem;
    cursor: pointer;
    word-break: break-all;
    max-height: 2.6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 95%;
  }

  > svg:not(${UserIcon}) {
    transition: all 200ms ease-in-out;
  }

  ${({ $dropdownOpen }) =>
    $dropdownOpen &&
    css`
      > svg:not(${UserIcon}) {
        transform: rotateZ(3.14rad);
      }
    `}
`;

const slideUp = keyframes({
  from: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    transform: 'translateY(1rem)'
  },
  to: {
    height: '19rem',
    opacity: 1,
    overflow: 'hidden',
    transform: 'translateY(0)'
  }
});

const slideDown = keyframes({
  from: { height: '19rem', overflow: 'hidden', transform: 'translateY(0)' },
  to: {
    height: 0,
    opacity: 0,
    overflow: 'hidden',
    transform: 'translateY(0)'
  }
});

export const DropdownContentWrapper = styled(DropdownContent)`
  position: relative;
  max-height: 24rem;
  margin-top: 1rem;
  min-width: 17rem;
  margin-right: 1rem;

  &[data-state='open'] {
    animation: ${slideUp} 200ms ease-in-out;
    overflow: unset;
  }

  &[data-state='closed'] {
    animation: ${slideDown} 200ms ease-in-out;
  }

  & > div {
    padding-bottom: 0 !important;
  }

  & > div:last-child {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
  }

  span {
    left: unset;
    right: 2.4rem;

    svg {
      width: 2rem;
      height: auto;
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const DropdownProfileItem = styled(DropdownItem)`
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 200ms ease-in;
  margin: 0;
  padding: 0;

  &:hover {
    background-color: ${({ theme }) => theme.tokens.neutralColorLowDarkTwo};
    color: ${({ theme }) => theme.colors.independence};
  }

  a {
    all: unset;
    color: ${({ theme }) => theme.colors.independence};
    padding: 1.5rem;
    font-size: 1.4rem;
    width: 100%;
    font-weight: 500;
  }
`;

export const DropdownProfileGroup = styled(DropdownGroup)`
  padding: 0;
  margin: 0;
`;
