enum NotificationsMutationKeys {
  READ_NOTIFICATION = 'READ_NOTIFICATION',
  UNREAD_NOTIFICATION = 'UNREAD_NOTIFICATION',
  READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS'
}

enum NotificationsQueryKeys {
  GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
}

export { NotificationsMutationKeys, NotificationsQueryKeys };
