import { Children } from 'react';

import { Spinner } from 'ds';

import { Dropdown } from 'presentation/components/base/Dropdown';

import { Icons } from 'shared/constants/global';

import { type INotificationMenuProps } from './NotificationMenu.types';

import {
  Container,
  DropdownContainer,
  DropdownContent
} from './NotificationMenu.styles';

export function NotificationMenu({
  children,
  isLoading = false,
  ...props
}: INotificationMenuProps): JSX.Element {
  return (
    <Container
      {...props}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <Dropdown
          direction='left'
          closeOnContentClick
          className='notification-menu-dropdown'
          content={
            <DropdownContent>{Children.toArray(children)}</DropdownContent>
          }
        >
          <DropdownContainer>
            <Icons.DotsVerticalIcon />
          </DropdownContainer>
        </Dropdown>
      )}
    </Container>
  );
}
