import { useMutationCache } from 'data/cache';
import {
  AuthMutationKeys,
  AuthService,
  type IUseSendEmailConfirmation
} from 'data/modules/auth';

export function useSendEmailConfirmation(): IUseSendEmailConfirmation {
  const {
    mutateAsync: sendEmailConfirmationAsync,
    isLoading: isSendingEmailConfirmation
  } = useMutationCache({
    mutationKey: [AuthMutationKeys.SEND_EMAIL_CONFIRMATION],
    mutationFn: AuthService.sendEmailConfirmation
  });

  return {
    sendEmailConfirmationAsync,
    isSendingEmailConfirmation
  };
}
