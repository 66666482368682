import { tokens } from 'ds/tokens';
import { type CSSProperties } from 'styled-components';

const commonStyles: Highcharts.CSSObject = {
  fontWeight: String(tokens.fontWeightRegular),
  fontFamily: tokens.fontFamiliesPoppins,
  fontSize: tokens.fontSizeXxss,
  fontStyle: 'normal'
};

export const titleStyle: Highcharts.CSSObject = {
  ...commonStyles,
  fontSize: tokens.fontSizeXs,
  color: tokens.neutralColorLowPure,
  fontWeight: String(tokens.fontWeightBold)
};

export const subtitleStyle: Highcharts.CSSObject = {
  ...commonStyles,
  fontSize: tokens.fontSizeXxs,
  color: tokens.neutralColorLowMedium
};

export const tooltipStyle: Highcharts.CSSObject = {
  ...commonStyles,
  backgroundColor: 'black',
  padding: tokens.spacingInsetNano,
  color: tokens.neutralColorHighPure,
  fontWeight: String(tokens.fontWeightMedium)
};

export const captionStyle: Highcharts.CSSObject = {
  ...commonStyles,
  color: tokens.neutralColorLowMediumLight
};

export const legendStyle: Highcharts.CSSObject = {
  ...commonStyles,
  color: tokens.neutralColorLowDark
};

export const pieCharLabelStyle: Highcharts.CSSObject = {
  ...commonStyles,
  lineHeight: '2rem',
  letterSpacing: '0.025rem',
  color: tokens.neutralColorLowMedium,
  fontWeight: String(tokens.fontWeightSemibold)
};

export const pieChartPercentageStyle: Highcharts.CSSObject = {
  opacity: 0.7,
  ...commonStyles,
  textOutline: 'none'
};

export const axiosLabelsStyle: Highcharts.CSSObject = {
  ...commonStyles
};

export const axiosTitleStyle: Highcharts.CSSObject = {
  ...commonStyles,
  fontSize: tokens.fontSizeXxs
};

export const donutContentStyle: CSSProperties = {
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center'
};

export const dataLabelsStyle: Highcharts.CSSObject = {
  ...pieCharLabelStyle,
  textOutline: 'none',
  color: 'contrast'
};
