import { useQueryCache } from 'data/cache';
import {
  AuthQueryKeys,
  AuthService,
  type IUseGetAuthTravelUser
} from 'data/modules/auth';

export function useGetAuthTravelUser(enabled: boolean): IUseGetAuthTravelUser {
  const { data: travelUser, isFetching: isFetchingTravelUser } = useQueryCache({
    queryKey: [AuthQueryKeys.AUTH_TRAVEL_USER],
    queryFn: async () => await AuthService.getAuthTravelUser(),
    staleTime: Infinity,
    enabled
  });

  return {
    travelUser,
    isFetchingTravelUser
  };
}
