import { type INotificationBodyProps } from './NotificationBody.types';

import { Container } from './NotificationBody.styles';

export function NotificationBody({
  children,
  ...props
}: INotificationBodyProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
