import { create } from 'zustand';

import { type IUseTravelSettingsLayoutContext } from './useTravelSettingsLayoutContext.types';

export const useTravelSettingsLayoutContext =
  create<IUseTravelSettingsLayoutContext>()(set => ({
    pageName: '',
    breadcrumbs: [],
    hasBackButton: false,
    setLayoutState: ({ breadcrumbs, hasBackButton, pageName }) => {
      set({
        pageName: pageName || '',
        breadcrumbs: breadcrumbs || [],
        hasBackButton: hasBackButton || false
      });
    }
  }));
