import { createContext, type ReactElement, useContext } from 'react';

import { AccordionContainer } from './AccordionContainer.component';

import {
  type IAccordionContext,
  type IAccordionProviderProps
} from './AccordionContainer.types';

const AccordionContext = createContext<IAccordionContext | null>(null);

export function AccordionProvider({
  children,
  multiple
}: IAccordionProviderProps): ReactElement {
  return (
    <AccordionContext.Provider
      value={{
        multiple
      }}
    >
      <AccordionContainer>{children}</AccordionContainer>
    </AccordionContext.Provider>
  );
}

export function useAccordion(): IAccordionContext {
  const Accordion = useContext(AccordionContext);

  return Accordion as IAccordionContext;
}
