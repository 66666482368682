import { tokens } from 'ds';
import { transitions } from 'ds/transitions';
import styled, { css } from 'styled-components';

import {
  type CaptionStylesType,
  type ContainerStylesType,
  type InputContainerStylesType,
  type LabelStylesType
} from './CurrencyInputDefault.types';
import { type AddPrefixToType } from 'ds/types';

export const Label = styled.label<AddPrefixToType<LabelStylesType>>`
  padding: ${tokens.spacingXxs} ${tokens.spacingSm};
  background-color: ${tokens.neutralColorHighMedium};
  color: ${({ $operation }) =>
    $operation === 'CREDIT'
      ? `${tokens.feedbackColorSuccessPure} !important`
      : $operation === 'DEBIT'
        ? `${tokens.feedbackColorErrorPure} !important`
        : tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  letter-spacing: 0.75px;
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightSemibold};
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 2rem;
  width: 6.1rem;

  ${({ $hasLeftBorderRadius, $variant }) =>
    $hasLeftBorderRadius &&
    css`
      border-top-left-radius: ${$variant === 'large'
        ? tokens.borderRadiusMd
        : tokens.borderRadiusSm};
      border-bottom-left-radius: ${$variant === 'large'
        ? tokens.borderRadiusMd
        : tokens.borderRadiusSm};
    `}

  ${({ $hasRightBorderRadius, $variant }) =>
    $hasRightBorderRadius &&
    css`
      border-top-right-radius: ${$variant === 'large'
        ? tokens.borderRadiusMd
        : tokens.borderRadiusSm};
      border-bottom-right-radius: ${$variant === 'large'
        ? tokens.borderRadiusMd
        : tokens.borderRadiusSm};
    `}
`;

export const Input = styled.input`
  background-color: transparent;
  border: 0;
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  letter-spacing: 0.75px;
  font-weight: ${tokens.fontWeightRegular};
  border-radius: ${tokens.borderRadiusMd};
  text-align: center;
  width: 100%;
  padding: 1rem;
`;

export const Container = styled.div<AddPrefixToType<ContainerStylesType>>`
  display: flex;
  flex-direction: column;

  ${({ $variant }) =>
    $variant === 'small' &&
    css`
      max-width: 20.8rem;

      ${Input} {
        max-height: 4.1rem;
      }

      ${Label},
      ${Input} {
        font-size: ${tokens.fontSizeXxss};
      }
    `}

  ${({ $variant }) =>
    $variant === 'medium' &&
    css`
      max-width: 22.2rem;

      ${Input} {
        max-height: 4.8rem;
      }

      ${Label},
      ${Input} {
        font-size: ${tokens.fontSizeXxs};
      }
    `}

  ${({ $variant }) =>
    $variant === 'large' &&
    css`
      max-width: 24.8rem;

      ${Input} {
        max-height: 5.2rem;
      }

      ${Label},
      ${Input} {
        font-size: ${tokens.fontSizeXs};
      }
    `}
`;

export const InputContainer = styled.div<
  AddPrefixToType<InputContainerStylesType>
>`
  background-color: ${tokens.deepColorWhite};
  border: 2px solid ${tokens.neutralColorHighMedium};
  border-radius: ${tokens.borderRadiusMd};
  width: 100%;
  display: flex;
  overflow: hidden;
  transition: ${transitions.allEaseInOut};

  ${({ $filled }) =>
    $filled &&
    css`
      border-color: ${tokens.neutralColorHighDark};

      ${Label} {
        background-color: ${tokens.neutralColorHighDark};
        border-color: ${tokens.neutralColorHighDark};
        color: ${tokens.neutralColorLowDark};
      }

      ${Input} {
        color: ${tokens.neutralColorLowDark};
        font-weight: ${tokens.fontWeightMedium};
      }
    `};

  ${({ $hasError }) =>
    $hasError &&
    css`
      border-color: ${tokens.feedbackColorErrorLightest};

      ${Label} {
        background-color: ${tokens.feedbackColorErrorLightest};
        border-color: ${tokens.feedbackColorErrorLightest};
        color: ${tokens.feedbackColorErrorMedium};
      }
    `}

  ${({ $hasSuccess }) =>
    $hasSuccess &&
    css`
      border-color: ${tokens.feedbackColorSuccessLightest};

      ${Label} {
        background-color: ${tokens.feedbackColorSuccessLightest};
        border-color: ${tokens.feedbackColorSuccessLightest};
        color: ${tokens.feedbackColorSuccessMedium};
      }
    `}

  ${({ $disabled }) =>
    $disabled
      ? css`
          background-color: ${tokens.neutralColorHighLight};
          border-color: ${tokens.neutralColorHighDark};

          ${Label} {
            background-color: ${tokens.neutralColorHighDark};
            color: ${tokens.neutralColorLowMediumLight};
            border-color: ${tokens.neutralColorHighDark};
          }

          ${Input} {
            color: ${tokens.neutralColorLowMediumLight};
          }
        `
      : css`
          &:focus-within {
            border-color: ${tokens.brandColorPrimaryLight};

            ${Label} {
              background-color: ${tokens.brandColorPrimaryLight};
              color: ${tokens.brandColorSecondaryMedium};
              border-color: ${tokens.brandColorPrimaryLight};
            }
          }
        `}
`;

export const Caption = styled.p<AddPrefixToType<CaptionStylesType>>`
  display: inline-block;
  color: ${tokens.neutralColorLowMedium};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxss};
  font-weight: ${tokens.fontWeightRegular};
  letter-spacing: 0.75px;
  margin-top: ${tokens.spacingInsetNano};

  ${({ $hasError }) =>
    $hasError &&
    css`
      color: ${tokens.feedbackColorErrorPure};
    `}
`;
