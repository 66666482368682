import { AxiosError, type AxiosResponse } from 'axios';
import { toast } from 'ds/utils';

import { type IErrorDefault } from 'data/contexts/modal/useModalContext.types';
import {
  type IDefaultResponse,
  type IErrorResponse
} from 'data/modules/global';

import { ApiMessage } from 'shared/utils/global';

class ErrorHandle {
  apiError(
    response: AxiosResponse<IDefaultResponse<null>>
  ): AxiosResponse<IDefaultResponse<null>> {
    if (!response.data.success) {
      throw new Error(
        ApiMessage.error(response.data.errors?.[0].code?.toString() as string)
      );
    }

    return response;
  }

  apiWebError(
    response: AxiosResponse<IDefaultResponse<null>>
  ): AxiosResponse<IDefaultResponse<null>> {
    if (!response.data.success && !(response.data instanceof Blob)) {
      throw new Error(JSON.stringify(response.data.errors));
    }

    return response;
  }

  async defaultError(error: unknown): Promise<never> {
    if (error instanceof AxiosError && error.response?.data?.errors) {
      error.response.data.errors.forEach((err: AxiosError) => {
        toast.error(err.message);
      });
    } else {
      toast.error(ApiMessage.error('default'));
    }

    return await Promise.reject(error);
  }

  getErrorMessage(
    e: IErrorDefault | undefined,
    error?: IErrorResponse[]
  ): string | undefined {
    let parsedError: IErrorResponse[] | undefined;

    try {
      parsedError = e ? JSON.parse(e.message as string) : undefined;
    } catch (e) {
      parsedError = undefined;
    }

    const defaultError = parsedError ?? error;

    if (defaultError !== undefined && defaultError?.length > 0) {
      const code = defaultError[0].code;
      const message = defaultError[0].message;

      if (message) {
        return message;
      }

      return ApiMessage.error(code ? code.toString() : 'default');
    }

    return undefined;
  }
}

export default new ErrorHandle();
