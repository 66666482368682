import { type IDefaultIconProps } from 'data/modules/global';

export function ArrowGoBack({ color, size }: IDefaultIconProps): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 19 20'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M3.828 6l2.536 2.536L4.95 9.95 0 5 4.95.05l1.414 1.414L3.828 4H11a8 8 0 010 16H2v-2h9a6 6 0 100-12H3.828z'></path>
    </svg>
  );
}
