import { tokens } from 'ds';
import styled from 'styled-components';

export const Container = styled.span`
  padding: ${tokens.spacingNano} ${tokens.spacingXs};
  color: ${tokens.neutralColorLowDark};
  font-family: ${tokens.fontFamilyPoppins};
  font-size: ${tokens.fontSizeXxs};
  font-style: normal;
  font-weight: ${tokens.fontWeightSemibold};
  line-height: 2.4rem;
  letter-spacing: 0.75px;
`;
