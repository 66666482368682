import {
  type InfiniteData,
  type QueryKey,
  useInfiniteQuery,
  type UseInfiniteQueryOptions
} from '@tanstack/react-query';
import { type AxiosError } from 'axios';

import { type UseInfiniteQueryCacheType } from 'data/cache/cache.types';

export function useInfiniteQueryCache<
  T,
  K extends QueryKey = QueryKey,
  PageParam = unknown
>({
  ...rest
}: UseInfiniteQueryOptions<
  T,
  AxiosError,
  InfiniteData<T>,
  T,
  K,
  PageParam
>): UseInfiniteQueryCacheType<T> {
  const cache = useInfiniteQuery(rest);

  return {
    ...cache
  };
}
