import { type MotionProps } from 'framer-motion';

export const animations: Record<string, MotionProps> = {
  fadeInOut: {
    animate: { opacity: [0, 1] },
    exit: { opacity: [0] }
  },
  popFadeInOut: {
    animate: { opacity: [0, 1], scale: [0.7, 1] },
    exit: { opacity: [0], scale: [0] }
  },
  slideUpAndDown: {
    initial: { height: 0 },
    animate: { height: 'auto' },
    transition: { duration: 0.1 },
    exit: { height: 0 }
  }
};
