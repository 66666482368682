export const featureFlagsKeys = {
  cardGroupsFlag: 'card_groups',
  analyticsPageFlag: 'analytics_page',
  teamMembersFlag: 'team_members',
  redesignCompanyOnboarding: 'redesign_onboarding_kyb',
  redesignUserOnboarding: 'redesign_onboarding_kyc',
  spendingLimitsFlag: 'spending_limits',
  travelPolicyFlag: 'travel_policy',
  travelFlightSearchFlag: 'travel_flight_search',
  travelProductSearchFlag: 'travel_product_search',
  fuelDashboardCarbonFlag: 'fuel_dashboard_carbon',
  fuelNewPolicyPageFlag: 'fuel_new_policy_page'
};
