import axios from 'axios';

import { ErrorHandle } from 'shared/utils/errors';

import { env } from './env.config';

// Access Token gerado pelo set-access-token.cjs
const personalToken = env.VITE_PERSONAL_TOKEN;

// Instância do Axios para desenvolvimento local
const apiLocal = axios.create({
  baseURL: env.VITE_API_WEB_LOCALHOST,
  headers: {
    Authorization: `Bearer ${personalToken}`,
    Accept: 'application/json',
    'X-Header-FrontLocalOrigin': `http://${window.location.host}`
  }
});

// Instância do Axios para desenvolvimento em prod, staging e dev
const apiProd = axios.create({
  baseURL: `${env.VITE_API_BASE_URL}/web`,
  withCredentials: true,
  headers: {
    Accept: 'application/json'
  }
});

// Verifica qual o ambiente e exporta as instâncias baseado nisso
const apiWeb = process.env.NODE_ENV === 'development' ? apiLocal : apiProd;

apiWeb.interceptors.response.use(ErrorHandle.apiWebError);

export default apiWeb;
