import { type MouseEvent as MouseEventReact, useEffect, useRef } from 'react';

import { type IUseNavbar } from './Navbar.types';

export function useNavbar(): IUseNavbar {
  let pos = { top: 0, left: 0, x: 0, y: 0 };
  const grabAndScrollRef = useRef<HTMLDivElement>(null);

  /**
   * Atualiza as posições do scroll baseado no movimento do mnouse
   */
  function mouseMoveHandler(e: MouseEvent): void {
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    if (grabAndScrollRef.current) {
      grabAndScrollRef.current.scrollTop = pos.top - dy;
      grabAndScrollRef.current.scrollLeft = pos.left - dx;
    }
  }

  /**
   * Atualiza os listeners e atualiza o estado do cursor
   */
  function mouseUpHandler(): void {
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    if (grabAndScrollRef.current) {
      grabAndScrollRef.current.style.cursor = 'grab';
      grabAndScrollRef.current.style.removeProperty('user-select');
    }
  }

  /**
   * Lida com o evento de mouse down para o scroll vertical ou lateral
   */
  function handleMouseDown(e: MouseEventReact): void {
    if (grabAndScrollRef.current) {
      grabAndScrollRef.current.style.cursor = 'grabbing';
      grabAndScrollRef.current.style.userSelect = 'none';
      pos = {
        left: grabAndScrollRef.current.scrollLeft,
        top: grabAndScrollRef.current.scrollTop,
        x: e.clientX,
        y: e.clientY
      };

      document.addEventListener('mousemove', mouseMoveHandler);
      document.addEventListener('mouseup', mouseUpHandler);
    }
  }

  useEffect(() => {
    if (grabAndScrollRef.current) {
      grabAndScrollRef.current.scrollTop = 100;
      grabAndScrollRef.current.scrollLeft = 150;
    }
  }, [grabAndScrollRef]);

  return {
    handleMouseDown,
    grabAndScrollRef
  };
}
