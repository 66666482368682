import { type FC, type SVGProps } from 'react';

import { vexDomain } from 'data/config';
import { type IRouteNameKeyType } from 'data/modules/global';

import { Icons } from './icons.constant';

type SidebarRouteType = {
  route: string;
  isReactRoute: boolean;
  name: IRouteNameKeyType;
  icon: FC<SVGProps<SVGSVGElement>>;
};

type SidebarRouteWithNoIconType = Omit<SidebarRouteType, 'icon'>;

type SidebarRoutesType = {
  home: SidebarRouteType;
  reports: SidebarRouteType;
  approvals: SidebarRouteType;
  approvalsRequest: SidebarRouteType;
  travel: SidebarRouteType;
  cards: SidebarRouteType;
  conciliations: SidebarRouteType;
  management: SidebarRouteType;
  store: SidebarRouteType;
  support: SidebarRouteType;
  analytics: SidebarRouteType;
  withdraw: SidebarRouteType;
  settingsAdmin: SidebarRouteType;
  settingsUser: SidebarRouteType;
  adminGraphicsDashboard: SidebarRouteWithNoIconType;
  fuelDashboard: SidebarRouteWithNoIconType;
  travelReport: SidebarRouteWithNoIconType;
};

/**
 * Lista de rotas disponíveis para serem exibidos na Sidebar
 */
export const sidebarRoutes: SidebarRoutesType = {
  home: {
    name: 'home',
    route: `${vexDomain}/inicio`,
    icon: Icons.HomeIcon,
    isReactRoute: false
  },
  reports: {
    name: 'reports',
    route: `/relatorios`,
    icon: Icons.ReportsIcon,
    isReactRoute: false
  },
  approvals: {
    name: 'approvals',
    route: `${vexDomain}/aprovacoes`,
    icon: Icons.ApprovalsIcon,
    isReactRoute: false
  },
  approvalsRequest: {
    name: 'approvalsRequest',
    route: '/cartoes/solicitacoes-de-valores',
    icon: Icons.ApprovalsIcon,
    isReactRoute: true
  },
  travel: {
    name: 'travel',
    route: '/viagens/solicitacoes-de-viagens',
    icon: Icons.ApprovalsIcon,
    isReactRoute: true
  },
  cards: {
    name: 'cards',
    route: '/cartoes/inicio',
    icon: Icons.CardsIcon,
    isReactRoute: true
  },
  conciliations: {
    name: 'conciliations',
    route: `${vexDomain}/conciliacoes`,
    icon: Icons.ConciliationIcon,
    isReactRoute: false
  },
  withdraw: {
    name: 'withdrawalsAdvance',
    route: `${vexDomain}/saques`,
    icon: Icons.WithdrawIcon,
    isReactRoute: false
  },
  management: {
    name: 'management',
    route: `${vexDomain}/admin/dashboard-graficos`,
    icon: Icons.ManagementIcon,
    isReactRoute: false
  },
  settingsAdmin: {
    name: 'settings',
    route: `${vexDomain}/settings`,
    icon: Icons.SettingsIcon,
    isReactRoute: false
  },
  settingsUser: {
    name: 'settings',
    route: `${vexDomain}/settings/meus-dados`,
    icon: Icons.SettingsIcon,
    isReactRoute: false
  },
  store: {
    name: 'store',
    icon: Icons.StoreIcon,
    isReactRoute: true,
    route: '/store'
  },
  support: {
    name: 'support',
    icon: Icons.HelpCenterIcon,
    isReactRoute: true,
    route: '/suporte'
  },
  analytics: {
    name: 'analytics',
    icon: Icons.AnalyticsIcon,
    isReactRoute: true,
    route: '/analises'
  },
  adminGraphicsDashboard: {
    name: 'administrativeManagement',
    isReactRoute: false,
    route: `${vexDomain}/admin/dashboard-graficos`
  },
  fuelDashboard: {
    name: 'dashboardFuel',
    isReactRoute: true,
    route: '/admin/dashboard-combustivel'
  },
  travelReport: {
    name: 'travelReport',
    isReactRoute: true,
    route: '/admin/relatorio-de-viagem'
  }
};
