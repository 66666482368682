import { type IDefaultIconProps } from 'data/modules/global';

export function Pix({ size }: IDefaultIconProps): JSX.Element {
  return (
    <svg
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      viewBox='0 0 22 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
    >
      <rect
        width='22'
        height='23'
        fill='url(#pattern0)'
      />
      <defs>
        <pattern
          id='pattern0'
          patternContentUnits='objectBoundingBox'
          width='1'
          height='1'
        >
          <use
            xlinkHref='#image0_1_3'
            transform='scale(0.0454545 0.0434783)'
          />
        </pattern>
        <image
          id='image0_1_3'
          width='22'
          height='23'
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAXCAYAAAAP6L+eAAAAAXNSR0IArs4c6QAAAq9JREFUSA21lVFSGkEQhv9udglFKtYeIFbWG5gbhBMoJ4ieIPqWSkVdrDzkTTkBcgLxBJgThBtgKe+hLGMRdplO9SyzNcBCfMm+zDDT/fFvzz+9hH880dEwmr4JL0Cyb0OFeiZNW5PvO3ebUnnTZu3zMP6zFf4EyS6MHBqDps65GvZ1b1MurdvURKqGfYKMq5w1xsnOWGOjZBhNTdAHKDLTtLFOeSl4HdSJeAl8pRQeNBYxXafUQXXUNWNwDEi8riyr4BrGGaMJoEXEF/XThzMfqvPwy2iXGdcgujJGDms12DL5cUUpVClXg2sBRVpXEmlPKzwIjfQBXD6fb7ccNAzsWjvNqBdU5AJEseaYadZ0Nbdg7/XHRqTNJDHAqjRJmW4cPM3oxofqnAy6M5IBE33yD5R8qH/6tZP7AybqLMFVtFXq/uD5fDvRRf9Aq4/pe6qfjDrqTR/qarUMD2b4kM1wuwx18QVcaED104dfavzJt+1bF+CPBVzoCiR6245UtVPqx+q8/vV+H0wd6wpGFi0HuN8VYrFzG2ncYedrLsgbDcg6hAG5AQdn2hO8fTt9fTr6KJArtV6aok1CdylTQ1WX2VAZzFCX9LjKsyO12HQr6PvwBWhGPa2rEHVCI3tlcNustuZXndJWbrdkGJPJ+0JuN3qnbrBK51DrBqZeYT1nQ+12MD8Ku3HamCQ7d65mqCXDmE1+QWwdBO0yB4TJaNfBDWddkqADmV8QzpoK1fwC7Oqrr/S7HsZhIHvrHFDAVa1It/aUDcaXefdznJVegQgIA7EXg0SOy2yVJm8Hts4kB8zUmUSatfisKNbtwugAqo9ZY1nNwkHNa7qILSmFC1gHfwlUGaWKV+EUaXtkSASmM9ts1ih1uRvBGpQrr1wCpIcJIdy+ouyw7APgoP91/As+6LpOyfvGfwAAAABJRU5ErkJggg=='
        />
      </defs>
    </svg>
  );
}
