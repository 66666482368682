import { DefaultTabContainer } from './DefaultTab/DefaultTabContainer/DefaultTabContainer.component';
import { DefaultTabItem } from './DefaultTab/DefaultTabItem/DefaultTabItem.component';
import { MenuTabContainer } from './MenuTab/MenuTabContainer/MenuTabContainer.component';
import { MenuTabItem } from './MenuTab/MenuTabItem/MenuTabItem.component';

export const DefaultTabs = {
  Container: DefaultTabContainer,
  Item: DefaultTabItem
};

export const MenuTabs = {
  Container: MenuTabContainer,
  Item: MenuTabItem
};

export const SpacedTabs = {
  Container: DefaultTabContainer,
  Item: DefaultTabItem
};
