import { type IDropdownContentProps } from './DropdownContent.types';

import { Container, StyledArrow } from './DropdownContent.styles';

export function DropdownContent({
  children,
  withArrow,
  ...rest
}: IDropdownContentProps): JSX.Element {
  return (
    <Container {...rest}>
      {withArrow && <StyledArrow />}
      {children}
    </Container>
  );
}
