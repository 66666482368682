import { useMutationCache } from 'data/cache';
import {
  type IReadAllNotificationsPayload,
  type IUseReadAllNotifications,
  NotificationsMutationKeys,
  NotificationsService
} from 'data/modules/notifications';

export function useReadAllNotifications(): IUseReadAllNotifications {
  const {
    mutateAsync: readAllMutationAsync,
    isLoading: isReadingAllNotifications
  } = useMutationCache({
    mutationKey: [NotificationsMutationKeys.READ_ALL_NOTIFICATIONS],
    mutationFn: async (payload: IReadAllNotificationsPayload) =>
      await NotificationsService.readAllNotifications(payload)
  });

  return {
    readAllNotifications: readAllMutationAsync,
    isReadingAllNotifications
  };
}
