import accountHoldersJSON from './accountHolders.lang.json';
import businessCardsJSON from './businessCards.lang.json';
import ManageBusinessCardJSON from './manageBusinessCard.lang.json';
import tariffsJSON from './tariffs.lang.json';

export const settings = {
  tariffs: tariffsJSON,
  accountHolders: accountHoldersJSON,
  businessCards: businessCardsJSON,
  manageBusinessCard: ManageBusinessCardJSON
};
