import { type INotificationTitleProps } from './NotificationTitle.types';

import { Container } from './NotificationTitle.styles';

export function NotificationTitle({
  children,
  ...props
}: INotificationTitleProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
