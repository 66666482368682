import { Fragment, type MouseEvent } from 'react';

import { SkeletonLoader } from 'ds';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { vexDomain } from 'data/config';

import { BaseIcon } from 'presentation/components/base/Icon';
import { LanguageSelector } from 'presentation/components/global/LanguageSelector';
import { Outsourced } from 'presentation/components/global/OldOutsourced';
import { Profile } from 'presentation/components/global/OldProfile';
import { DropdownGroup, DropdownRoot } from 'presentation/ds/DsDropdown';

import { ReactComponent as CollapsedLogo } from 'shared/assets/images/global/logotipo-collapsado.svg';
import { ReactComponent as Logo } from 'shared/assets/images/global/logotipo-horizontal-branco.svg';
import { animations } from 'shared/constants/animations';
import { routesPathPrefix } from 'shared/constants/global';
import { Icons } from 'shared/constants/global/icons.constant';
import { sidebarRoutes } from 'shared/constants/global/sidebarRoutes.constant';

import { SidebarItem, SidebarSection, SidebarSubItem } from '..';

import { useSidebarContainer } from './useSidebarContainer';

import { type ISidebarContainerProps } from './SidebarContainer.types';

import {
  CollapseIcon,
  CompanyDropdownWrapper,
  CompanyInfo,
  Container,
  DropdownCompanyContent,
  DropdownCompanyItem,
  DropdownCompanyLabel,
  DropdownCompanyScroll,
  DropdownCompanyTrigger,
  LogoCompany,
  NavbarDataMobile,
  SidebarContent,
  SidebarHeader,
  SidebarSubItemsMotionContainer,
  SidebarSubSection,
  Tooltip,
  Wrapper
} from './SidebarContainer.styles';

export function SidebarContainer({
  isCollapsed,
  onSetIsCollapsed
}: ISidebarContainerProps): JSX.Element {
  const { t } = useTranslation(['sidebar', 'global']);

  const {
    sidebarRef,
    company,
    isFetchingData,
    user,
    availableRoutes,
    handleCollapseSidebar,
    route,
    subItemActive,
    setSubItemActive,
    isOutsourcing,
    handleCollapseSidebarMobile,
    isDropdownOpen,
    setIsDropdownOpen,
    firstRender,
    isAdmin,
    isUser,
    animatedMulticompanyMenuHeight,
    userHasCompanies,
    allowsManagementAccess
  } = useSidebarContainer({ isCollapsed, onSetIsCollapsed });

  return (
    <Container
      ref={sidebarRef}
      onClick={(e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        handleCollapseSidebarMobile(e.target);
      }}
      $isCollapsed={isCollapsed}
      $isOpen={window.innerWidth <= 1200 && !isCollapsed}
    >
      <Wrapper
        $outsourced={isOutsourcing}
        $isCollapsed={isCollapsed}
        data-testid='sidebar'
      >
        <SidebarHeader>
          <CollapsedLogo />

          <Logo />

          <CollapseIcon
            role='button'
            tabIndex={0}
            onClick={() => {
              handleCollapseSidebar();
            }}
            $isCollapsed={isCollapsed}
            $outsourced={isOutsourcing}
          >
            <Icons.ArrowLeftIcon />
            <Tooltip>{t('global:openClose')}</Tooltip>
          </CollapseIcon>

          {company?.logoUrl &&
            !isCollapsed &&
            company.parameters.usesLogoOnInterface && (
              <LogoCompany>
                <img src={company.logoUrl} />
              </LogoCompany>
            )}

          {user && (
            <NavbarDataMobile>
              <Profile user={user} />

              {/* Info de Terceirização */}
              {user.parameters.hasOutsourcingAccess && <Outsourced />}
              <LanguageSelector />
            </NavbarDataMobile>
          )}

          <DropdownRoot
            open={isDropdownOpen}
            onOpenChange={() => {
              setIsDropdownOpen(old => !old);
            }}
          >
            <CompanyDropdownWrapper $isCollapsed={isCollapsed}>
              <DropdownCompanyTrigger disabled={!userHasCompanies}>
                <CompanyInfo
                  $disabled={!userHasCompanies}
                  $isOpen={isDropdownOpen}
                  onClick={() => {
                    isCollapsed && onSetIsCollapsed(!isCollapsed);
                  }}
                  $isCollapsed={isCollapsed}
                  title={company?.name}
                  $outsourced={isOutsourcing}
                >
                  <div>
                    <Icons.CompanyIcon />
                    <p>{company?.name}</p>
                  </div>
                  {userHasCompanies && (
                    <BaseIcon
                      name='arrow-down-s'
                      size={1.8}
                      type='line'
                    />
                  )}
                </CompanyInfo>
              </DropdownCompanyTrigger>

              <DropdownCompanyContent height={animatedMulticompanyMenuHeight}>
                <DropdownGroup>
                  <DropdownCompanyLabel>
                    <BaseIcon
                      name='company'
                      color='black'
                      size={2}
                    />

                    <p>{company?.name}</p>
                  </DropdownCompanyLabel>
                </DropdownGroup>

                <DropdownCompanyScroll>
                  {userHasCompanies &&
                    user?.userCompanies?.map(
                      ({ companyId, companyName, userId }, index) => {
                        return (
                          <DropdownGroup
                            key={companyId}
                            lastGroup={
                              index + 1 === user?.userCompanies?.length
                            }
                          >
                            <DropdownCompanyItem asChild>
                              <a
                                href={`${vexDomain}/change-company-sidebar/${userId}`}
                              >
                                <BaseIcon
                                  name='company'
                                  size={2}
                                />
                                <p>{companyName}</p>
                              </a>
                            </DropdownCompanyItem>
                          </DropdownGroup>
                        );
                      }
                    )}
                </DropdownCompanyScroll>
              </DropdownCompanyContent>
            </CompanyDropdownWrapper>
          </DropdownRoot>
        </SidebarHeader>

        <SidebarContent>
          <SidebarSection isCollapsed={isCollapsed}>
            <SidebarSubSection $isCollapsed={isCollapsed}>
              {isFetchingData ? (
                <SkeletonLoader
                  height='4.6rem'
                  rows={3}
                  multipleLoadersContainerClassName='sidebar-loader-container'
                  loaderClassName='item-loader'
                />
              ) : (
                <>
                  {availableRoutes.map(page =>
                    page.subRouteCondition && page.subRoute ? (
                      <Fragment key={page.name}>
                        <SidebarItem
                          outsourced={isOutsourcing}
                          isCollapsed={isCollapsed}
                          icon={<page.icon />}
                          title={t(`routes.${page.name}`)}
                          isActive={page.subRoute?.some(
                            subRoute => subRoute.active
                          )}
                          isSubItemsActive={
                            subItemActive === page.subRouteActive
                          }
                          onToggleSubItemsActive={() => {
                            if (isCollapsed) {
                              handleCollapseSidebar();

                              if (subItemActive === page.subRouteActive) return;
                            }

                            setSubItemActive(
                              subItemActive === page.subRouteActive
                                ? ''
                                : page.subRouteActive || ''
                            );
                          }}
                          hasSubItems
                        />

                        <AnimatePresence>
                          {subItemActive === page.subRouteActive &&
                            !isCollapsed && (
                              <SidebarSubItemsMotionContainer
                                {...animations.slideUpAndDown}
                                initial={
                                  // se a página aberta for algum dos subitens do menu e for a primeira renderização, a animação deve ser false
                                  page.subRoute.some(item => item.active) &&
                                  firstRender
                                    ? false
                                    : animations.slideUpAndDown.initial
                                }
                              >
                                {page.subRoute.map(
                                  subRoute =>
                                    (subRoute.condition ?? true) && (
                                      <SidebarSubItem
                                        key={`sub-route-${subRoute.name}`}
                                        outsourced={isOutsourcing}
                                        href={subRoute.route}
                                        isReactRoute={
                                          subRoute.isReactRoute ?? false
                                        }
                                        isActive={subRoute.active}
                                      >
                                        <Icons.DotIcon />
                                        {t(`routes.${subRoute.name}`)}
                                      </SidebarSubItem>
                                    )
                                )}
                              </SidebarSubItemsMotionContainer>
                            )}
                        </AnimatePresence>
                      </Fragment>
                    ) : (
                      <SidebarItem
                        key={page.name}
                        outsourced={isOutsourcing}
                        isCollapsed={isCollapsed}
                        icon={<page.icon />}
                        title={t(`routes.${page.name}`)}
                        isActive={
                          page.route === sidebarRoutes.cards.route &&
                          route !== sidebarRoutes.approvalsRequest.route
                            ? route.startsWith(routesPathPrefix.cards)
                            : route.startsWith(page.route)
                        }
                        showPreviewIndicator={page.showPreviewIndicator}
                        link={page.route}
                        isReactRoute={page.isReactRoute}
                      />
                    )
                  )}

                  {(isAdmin || allowsManagementAccess) && (
                    <SidebarItem
                      isReactRoute
                      isCollapsed={isCollapsed}
                      outsourced={isOutsourcing}
                      title={t('routes.analytics')}
                      link={sidebarRoutes.analytics.route}
                      icon={<sidebarRoutes.analytics.icon />}
                      isActive={route.startsWith(routesPathPrefix.analytics)}
                    />
                  )}

                  {/* Itens alinhados à direita */}
                  {!isOutsourcing &&
                    (isUser ? (
                      <SidebarItem
                        isCollapsed={isCollapsed}
                        icon={<sidebarRoutes.settingsUser.icon />}
                        link={sidebarRoutes.settingsUser.route}
                        title={t(`routes.${sidebarRoutes.settingsUser.name}`)}
                        outsourced={isOutsourcing}
                      />
                    ) : (
                      <SidebarItem
                        outsourced={isOutsourcing}
                        isNew={company?.parameters.usesFuelsModule}
                        icon={<sidebarRoutes.settingsAdmin.icon />}
                        isCollapsed={isCollapsed}
                        link={sidebarRoutes.settingsAdmin.route}
                        title={t(`routes.${sidebarRoutes.settingsAdmin.name}`)}
                        isActive={route.startsWith(routesPathPrefix.settings)}
                      />
                    ))}
                </>
              )}
            </SidebarSubSection>

            <SidebarSubSection>
              {isFetchingData ? (
                <SkeletonLoader
                  height='4.6rem'
                  loaderClassName='item-loader'
                />
              ) : (
                <>
                  {isAdmin && (
                    <SidebarItem
                      isReactRoute
                      title={t('routes.store')}
                      isCollapsed={isCollapsed}
                      outsourced={isOutsourcing}
                      link={sidebarRoutes.store.route}
                      icon={<sidebarRoutes.store.icon />}
                      isActive={route.startsWith(routesPathPrefix.store)}
                    />
                  )}
                  <SidebarItem
                    isReactRoute
                    isCollapsed={isCollapsed}
                    outsourced={isOutsourcing}
                    title={t('routes.support')}
                    link={sidebarRoutes.support.route}
                    icon={<sidebarRoutes.support.icon />}
                    isActive={route.startsWith(routesPathPrefix.support)}
                  />
                </>
              )}
            </SidebarSubSection>
          </SidebarSection>
        </SidebarContent>
      </Wrapper>
    </Container>
  );
}
