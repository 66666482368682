import { type IDefaultIconProps } from 'data/modules/global';

export function Add({ size, color, type }: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z'></path>
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M11 11V5H13V11H19V13H13V19H11V13H5V11H11Z'></path>
    </svg>
  );
}
