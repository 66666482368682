import { create } from 'zustand';

import { type IUseTourContext } from './useTourContext.types';

export const useTourContext = create<IUseTourContext>()(set => ({
  reset: '',
  handleReset: (value: string) => {
    set({ reset: value });
  }
}));
