import { tokens } from 'ds';
import styled, { keyframes } from 'styled-components';

import { Container as TriggerContainer } from 'presentation/components/base/Notification/NotificationTrigger/NotificationTrigger.styles';

const notificationCounterFirstAnimation = keyframes`
  0% {
    left: 0;
    opacity: 0;
    visibility: hidden;
    min-width: 2.6rem;
    max-width: 2.6rem;
    min-height: 2.6rem;
    max-height: 2.6rem;
  }
  40% {
    opacity: 0;
    visibility: hidden;
  }
  60% {
    opacity: 1;
    visibility: visible;
  }
  90% {
    left: 0;
    min-width: 2.6rem;
    max-width: 2.6rem;
    min-height: 2.6rem;
    max-height: 2.6rem;
  }
  100% {
    left: 1.3rem;
    min-width: 2.1rem;
    min-height: 2.1rem;
    max-width: 2.1rem;
    max-height: 2.1rem;
    opacity: 1;
  }
`;

const notificationCounterAnimation = keyframes`
  0% {
    left: 1.3rem;
    min-width: 2.1rem;
    min-height: 2.1rem;
    max-width: 2.1rem;
    max-height: 2.1rem;
  }
  20% {
    left: 0;
    min-width: 2.6rem;
    min-height: 2.6rem;
    max-width: 2.6rem;
    max-height: 2.6rem;
  }
  80% {
    left: 0;
    min-width: 2.6rem;
    min-height: 2.6rem;
    max-width: 2.6rem;
    max-height: 2.6rem;
  }
  100% {
    left: 1.3rem;
    min-width: 2.1rem;
    min-height: 2.1rem;
    max-width: 2.1rem;
    max-height: 2.1rem;
  }
`;

const notificationCounterEmptyAnimation = keyframes`
  from {
    opacity: 1;
    visibility: visible;
  }
  to {
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
  }

`;

export const Container = styled.span`
  left: 1.3rem;
  display: flex;
  overflow: hidden;
  min-width: 2.1rem;
  max-width: 2.1rem;
  position: absolute;
  min-height: 2.1rem;
  max-height: 2.1rem;
  border-radius: 32px;
  align-items: center;
  justify-content: center;
  background: ${tokens.feedbackColorErrorPure};

  span {
    position: absolute;
    overflow: visible;
    font-size: 1rem;
    user-select: none;
    text-align: center;
    white-space: nowrap;
    transition: 0.5s ease;
    transition-property: opacity, max-heigh, max-width;
    font-weight: ${tokens.fontWeightBold};
    color: ${tokens.neutralColorHighPure};

    :first-of-type {
      opacity: 0;
      visibility: hidden;
    }
  }

  ${TriggerContainer}:hover > & {
    min-width: 2.3rem;
    max-width: 2.3rem;
    min-height: 2.3rem;
    max-height: 2.3rem;

    &:not(.new-arrived) span {
      font-size: 1.1rem;
    }
  }

  &.start-empty {
    opacity: 0;
    transform: scale(0);
    visibility: hidden;
  }

  &.empty {
    animation: ${notificationCounterEmptyAnimation} 0.2s forwards;
  }

  &.first {
    animation: ${notificationCounterFirstAnimation} 2s forwards;
  }

  &.new-arrived {
    &:not(.first) {
      animation: ${notificationCounterAnimation} 2s forwards;
    }

    span {
      font-size: 1.3rem;

      &:first-of-type {
        opacity: 1;
      }

      &:last-of-type {
        opacity: 0;
        visibility: hidden;
      }
    }
  }
`;
