import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getRemoteConfig } from 'firebase/remote-config';

import { env } from './env.config';

const firebaseConfig = {
  apiKey: env.VITE_FIREBASE_API_KEY,
  authDomain: env.VITE_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.VITE_FIREBASE_DATABASE_URL,
  projectId: env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.VITE_FIREBASE_APP_ID,
  measurementId: env.VITE_FIREBASE_MEASUREMENT_ID
};

const firebase = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(firebase);
const analytics = getAnalytics(firebase);

remoteConfig.settings.minimumFetchIntervalMillis = 10000;

export { analytics, firebase, remoteConfig };
