import { tokens } from 'ds/tokens';
import styled, { css } from 'styled-components';

import { avatarProps } from './constants/avatarProps';

import { type IAvatarStylesType } from './Avatar.types';
import { type AddPrefixToType } from 'ds/types';

export const Container = styled.div<AddPrefixToType<IAvatarStylesType>>`
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  img,
  svg {
    width: 100%;
    height: 100%;
  }

  ${({ $size }) =>
    $size &&
    css`
      border-radius: ${avatarProps[$size].border};
      height: ${avatarProps[$size].size};
      width: ${avatarProps[$size].size};

      img,
      svg {
        border-radius: ${avatarProps[$size].border};
      }
    `}

  ${({ $iconAsImage, $icon }) =>
    !$iconAsImage &&
    `
        background-color: ${
          $icon ? tokens.neutralColorHighMedium : 'transparent'
        };
        padding: ${$icon ? tokens.spacingNano : 0};
  `}
`;
