import { Alert } from 'ds';

import { useLangContext } from 'data/contexts';

import { BaseIcon } from 'presentation/components/base/Icon';
import { SkeletonLoader } from 'presentation/components/global/Loader';
import { DropdownRoot } from 'presentation/ds/DsDropdown';

import { useOutsourced } from './useOutsourced';

import {
  AlertContainer,
  DropdownOutsourcedContent,
  DropdownOutsourcedItem,
  DropdownOutsourcedScroll,
  OutsourcedDropdownWrapper,
  OutsourcedLabel,
  StyledDropdownTrigger
} from './Outsourced.styles';

export function Outsourced(): JSX.Element {
  const [currentLangKey, lang] = useLangContext(state => [
    state.currentLangKey,
    state.lang
  ]);

  const {
    isFetchingOutsourcingOptions,
    outsourcingOptions,
    handleChangeOutsourcingOption,
    nameOfTheUserWhoIsPerformingActions,
    isFetchingOutsourcedUser,
    outsourcedUser
  } = useOutsourced();

  return (
    <AlertContainer>
      <Alert type='outsourced'>
        {lang.global.carrying_out_actions_to[currentLangKey] +
          nameOfTheUserWhoIsPerformingActions}
        <DropdownRoot>
          {isFetchingOutsourcingOptions || isFetchingOutsourcedUser ? (
            <SkeletonLoader width='10rem' />
          ) : (
            <OutsourcedDropdownWrapper
              $outsourced={!!outsourcedUser}
              data-testid='outsourcedDropdown'
            >
              <StyledDropdownTrigger
                disabled={
                  !(outsourcingOptions && outsourcingOptions?.length > 0)
                }
              >
                <OutsourcedLabel>
                  {lang.global.change_user[currentLangKey]}
                </OutsourcedLabel>
              </StyledDropdownTrigger>

              <DropdownOutsourcedContent
                height={
                  // se a quantidade de opções x altura do elemento for maior que 21.3rem, ele vai ter 21.3rem de altura, porque é a altura máxima
                  (outsourcingOptions && outsourcingOptions.length * 4.7 > 21.3
                    ? 21.3
                    : outsourcingOptions.length * 4.7) || 0
                }
                withArrow
              >
                <DropdownOutsourcedScroll>
                  {outsourcingOptions.length &&
                    outsourcingOptions.map(option => (
                      <DropdownOutsourcedItem
                        onClick={() => {
                          handleChangeOutsourcingOption(option.uuid);
                        }}
                        key={option.uuid}
                      >
                        <BaseIcon
                          name='user-3'
                          size={1.7}
                          color='black'
                          type='line'
                        />

                        <p>{option.name}</p>
                      </DropdownOutsourcedItem>
                    ))}
                </DropdownOutsourcedScroll>
              </DropdownOutsourcedContent>
            </OutsourcedDropdownWrapper>
          )}
        </DropdownRoot>
      </Alert>
    </AlertContainer>
  );
}
