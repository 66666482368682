import { tokens } from 'ds/tokens';
import styled from 'styled-components';

export const Container = styled.thead`
  tr th {
    &:first-of-type {
      border-top-left-radius: ${tokens.borderRadiusXs};
    }

    &:last-of-type {
      border-top-right-radius: ${tokens.borderRadiusXs};
    }
  }
`;
