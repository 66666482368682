import { create } from 'zustand';

import { type IUseMyCardContext } from './useMyCardContext.types';

export const useMyValueSolicitationContext = create<IUseMyCardContext>()(
  (set, get) => ({
    sortByDate: 'default',
    myValueSolicitationsSearchInput: '',
    myValueSolicitationsStatus: 'ALL',
    paginationMyValueSolicitations: {
      currentPage: 1,
      pageSize: 10,
      totalPages: 1,
      totalItems: 0
    },
    selectedSolicitation: null,

    handleSortByDate: () => {
      if (get().sortByDate === 'asc') {
        set({ sortByDate: 'desc' });
      } else if (get().sortByDate === 'desc') {
        set({ sortByDate: 'default' });
      } else {
        set({ sortByDate: 'asc' });
      }
    },
    handleChangeMyValueSolicitationsSearchInput: value => {
      set({ myValueSolicitationsSearchInput: value });
    },
    handleChangeMyValueSolicitationsStatus: value => {
      set({ myValueSolicitationsStatus: value });
    },
    handleChangeMyValueSolicitationPagination: ({
      currentPage,
      pageSize,
      totalPages,
      totalItems
    }) => {
      set(state => ({
        paginationMyValueSolicitations: {
          ...state.paginationMyValueSolicitations,
          ...(totalItems && { totalItems }),
          ...(totalPages && { totalPages }),
          ...(currentPage && { currentPage }),
          ...(pageSize && { pageSize })
        }
      }));
    },
    handleResetMyValueSolicitationPagination: () => {
      set(state => ({
        paginationMyValueSolicitations: {
          ...state.paginationMyValueSolicitations,
          currentPage: 1,
          pageSize: 10
        }
      }));
    },
    handleSetSelectedSolicitation: solicitation => {
      set({ selectedSolicitation: solicitation });
    }
  })
);
