import styled, { keyframes } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import { type SpinnerStylesType } from './Spinner.types';

const rotate = keyframes`
    to {
    transform: rotate(360deg);
  }
`;

export const StyledSpinner = styled.div<AddPrefixToType<SpinnerStylesType>>`
  border: 3px solid ${({ $color, theme }) => theme.colors[$color]};
  border-radius: 50%;
  border-top: 3px solid transparent;
  width: ${({ $size }) => `${$size}rem`};
  height: ${({ $size }) => `${$size}rem`};
  animation: ${rotate} 1s linear infinite;
`;
