import { Link } from 'react-router-dom';

import { useLangContext } from 'data/contexts';

import { Icons } from 'shared/constants/global/icons.constant';

import { type ISidebarItemProps } from './SidebarItem.types';

import {
  Container,
  ExtraIconsWrapper,
  NewTag,
  PreviewIcon,
  Tooltip
} from './SidebarItem.styles';

export function SidebarItem({
  icon,
  target = '_self',
  title,
  isActive = false,
  hasSubItems = false,
  isCollapsed,
  outsourced,
  link,
  isNew = false,
  isSubItemsActive,
  onToggleSubItemsActive,
  isReactRoute,
  showPreviewIndicator
}: ISidebarItemProps): JSX.Element {
  const Component = isReactRoute ? Link : 'a';
  const {
    currentLangKey,
    lang: { global }
  } = useLangContext();

  return (
    <Container
      $outsourced={outsourced}
      $isActive={isActive}
      $isCollapsed={isCollapsed}
      onClick={onToggleSubItemsActive || undefined}
    >
      <Component
        to={link ?? ''}
        href={isReactRoute ? undefined : link}
        target={target}
        rel='noreferrer'
      >
        {!!icon && icon}

        <p>{title}</p>

        {
          // só vai mostrar o elemento se é pra mostrar o indicador de preview ou se tem subitems e não está colapsado
        }
        {(showPreviewIndicator || (hasSubItems && !isCollapsed)) && (
          <ExtraIconsWrapper $isSubItemsActive={isSubItemsActive}>
            {showPreviewIndicator && <PreviewIcon />}

            {hasSubItems && !isCollapsed && (
              <Icons.ArrowDownIcon
                color={
                  outsourced
                    ? 'neutralColorLowDark'
                    : isActive
                      ? 'primary'
                      : 'white'
                }
              />
            )}
          </ExtraIconsWrapper>
        )}

        {isNew && (
          <NewTag
            $isActive={isActive}
            $isCollapsed={isCollapsed}
          >
            {global.new[currentLangKey]}
          </NewTag>
        )}
      </Component>

      <Tooltip>{title}</Tooltip>
    </Container>
  );
}
