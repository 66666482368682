import styled, { css } from 'styled-components';

import { type AddPrefixToType } from 'data/modules/global';

import {
  type ContentStylesType,
  type ExpandButtonStylesType,
  type LeftContentStylesType,
  type RightContentStylesType
} from './TripleColumn.types';

export const Container = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

export const ContentTitle = styled.div`
  z-index: 1;
  grid-column: 1 / -1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    grid-column: unset;
  }
`;

export const CenterContent = styled.div`
  overflow: auto;
  z-index: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-grow: 1;
  }
`;

export const Content = styled.div<AddPrefixToType<ContentStylesType>>`
  display: grid;
  grid-template-columns: ${({ $lgGridTemplateColumns }) =>
    $lgGridTemplateColumns};
  grid-template-rows: ${({ $hasTitle }) => ($hasTitle ? '5rem 1fr' : '1fr')};
  min-height: 12rem;
  background: ${({ theme }) => theme.colors.gallery};
  row-gap: 0.1rem;
  height: 100%;
  overflow: hidden;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: flex;
    flex-direction: column;
    row-gap: 0;

    ${({ $hasTitle, $hasLeftElement, $hasRightElement }) =>
      !$hasTitle &&
      $hasLeftElement &&
      $hasRightElement &&
      css`
        ${CenterContent} {
          padding-top: 4.5rem;
        }
      `}

    ${({ $hasLeftElement, $hasRightElement }) =>
      ($hasLeftElement || $hasRightElement) &&
      css`
        ${ContentTitle} > div {
          padding-left: 5rem;
          padding-right: 5rem;
        }
      `}
  }
`;

export const LeftContent = styled.div<AddPrefixToType<LeftContentStylesType>>`
  background: inherit;
  height: 100%;
  overflow: auto;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    position: absolute;
    width: ${({ $isExpandedOnMobile }) => ($isExpandedOnMobile ? '70%' : '0')};
    overflow: visible;
    left: 0;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }

  > div {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      overflow: ${({ $isExpandedOnMobile }) =>
        $isExpandedOnMobile ? 'auto' : 'hidden'};
    }
  }
`;

export const RightContent = styled.div<AddPrefixToType<RightContentStylesType>>`
  background: inherit;
  overflow: auto;
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    position: absolute;
    width: ${({ $isExpandedOnMobile }) => ($isExpandedOnMobile ? '70%' : '0')};
    overflow: visible;
    right: 0;
    transition: all 0.3s ease-in-out;
    z-index: 1;
  }

  > div {
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      overflow: ${({ $isExpandedOnMobile }) =>
        $isExpandedOnMobile ? 'auto' : 'hidden'};
    }
  }
`;

export const ExpandButton = styled.button<
  AddPrefixToType<ExpandButtonStylesType>
>`
  display: none;
  position: absolute;
  top: 0;
  ${({ $side }) => `${$side}: 100%`};
  border: 0;
  width: 3.6rem;
  height: 3.6rem;
  background: ${({ theme }) => theme.colors.primary};
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }

  &:focus {
    outline: none;
    box-shadow: 1px 1px 1px ${({ theme }) => theme.colors.primary};
  }
`;
