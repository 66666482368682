import { useEffect, useState, type WheelEvent } from 'react';

import { localStorageKeys } from 'shared/constants/global';

import { useZoomTour } from './tours';

import {
  type IUseImageFullScreen,
  type IUseImageFullScreenParams
} from './ImageFullScreen.types';

export function useImageFullScreen({
  closeOnClickOutside,
  onClose,
  showZoomTour,
  hasZoomControl
}: IUseImageFullScreenParams): IUseImageFullScreen {
  const [imageZoom, setImageZoom] = useState(1);

  const { startTour } = useZoomTour();

  const showExpensesZoomTour =
    localStorage.getItem(localStorageKeys.zoomTour) !== 'true';

  function handleClickOutsideImage(): void {
    if (closeOnClickOutside) {
      onClose();
    }
  }

  function handleWheelZoom(event: WheelEvent<HTMLDivElement>): void {
    const delta = event.deltaY;

    if (delta < 0) {
      plusZoom();
      return;
    }

    minusZoom();
  }

  function minusZoom(): void {
    if (imageZoom - 0.25 < 1) {
      return;
    }

    setImageZoom(imageZoom - 0.25);
  }

  function plusZoom(): void {
    if (imageZoom + 0.25 > 3) {
      return;
    }

    setImageZoom(imageZoom + 0.25);
  }

  useEffect(() => {
    if (hasZoomControl && showZoomTour && showExpensesZoomTour) {
      startTour();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasZoomControl, showZoomTour, showExpensesZoomTour]);

  return {
    handleClickOutsideImage,
    handleWheelZoom,
    minusZoom,
    plusZoom,
    imageZoom
  };
}
