import {
  type FullLanguageType,
  type LanguageKeyType
} from 'data/contexts/lang/useLangContext.types';

import { langJSON } from 'shared/lang';

import { CustomCookies } from '.';

class ApiMessage {
  error(code: string): string {
    const codeKey = code as keyof typeof langJSON.api.errors;

    /* Primeiro, tenta pegar a chave do idioma salvo no storage
     caso não exista, retorna a chave padrão */
    const currentLangKey = (
      (CustomCookies.get('language') as FullLanguageType) ?? 'pt-BR'
    ).substring(0, 2) as LanguageKeyType;

    // Caso a chave não exista, retorna a mensagem padrão
    return langJSON.api.errors[codeKey]
      ? langJSON.api.errors[codeKey][currentLangKey]
      : langJSON.api.errors.default[currentLangKey];
  }
}

export default new ApiMessage();
