import { create } from 'zustand';

import { type IUseSpendingLimitsContext } from './useSpendingLimitsContext.types';

export const useSpendingLimitsContext = create<IUseSpendingLimitsContext>()(
  (set, get) => ({
    creationStep: 1,
    cardsToBeAddedOnPolicy: [],
    selectedPolicy: null,
    policyType: 'DEFAULT',
    validPolicyMembersPagination: {
      page: 1,
      limit: 10
    },
    validPolicyMembersSearchTerm: '',
    cardsInOtherPolicy: [],
    cardToBeRemoved: null,

    handleChangeCreationStep: step => {
      set({ creationStep: step });
    },

    handleSelectCardsToBeAddedOnPolicy: item => {
      if (!item) {
        set({ cardsToBeAddedOnPolicy: [] });

        return;
      }

      const cardAlreadySelected = get().cardsToBeAddedOnPolicy.some(
        card => card.id === item.id
      );

      if (cardAlreadySelected) {
        set({
          cardsToBeAddedOnPolicy: get().cardsToBeAddedOnPolicy.filter(
            card => card.id !== item.id
          )
        });

        return;
      }

      set({ cardsToBeAddedOnPolicy: [...get().cardsToBeAddedOnPolicy, item] });
    },

    handleSelectPolicy: policy => {
      set({
        selectedPolicy: policy
      });
    },

    handleChangePolicyType: type => {
      set({
        policyType: type
      });
    },

    handleChangeValidPolicyMembersSearchTerm: value => {
      set({
        validPolicyMembersSearchTerm: value
      });
    },

    handleSetCardsInOtherPolicy: cards => {
      set({
        cardsInOtherPolicy: cards
      });
    },

    handleSetCardToBeRemoved: card => {
      set({
        cardToBeRemoved: card
      });
    }
  })
);
