import { type IDefaultIconProps } from 'data/modules/global';

export function EmotionHappy({
  type,
  color,
  size
}: IDefaultIconProps): JSX.Element {
  if (type === 'line') {
    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 24 24'
        width={size ? `${size}rem` : '1em'}
        height={size ? `${size}rem` : '1em'}
        fill={
          color === undefined || color === 'inherit' ? 'currentColor' : color
        }
      >
        <path d='M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 14.1217 4.84285 16.1566 6.34315 17.6569C7.84344 19.1571 9.87827 20 12 20C14.1217 20 16.1566 19.1571 17.6569 17.6569C19.1571 16.1566 20 14.1217 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM12 11C14 11 15.667 11.333 17 12C17 13.3261 16.4732 14.5979 15.5355 15.5355C14.5979 16.4732 13.3261 17 12 17C10.6739 17 9.40215 16.4732 8.46447 15.5355C7.52678 14.5979 7 13.3261 7 12C8.333 11.333 10 11 12 11ZM8.5 7C9.07633 6.99988 9.63499 7.19889 10.0815 7.56335C10.5279 7.9278 10.8347 8.43532 10.95 9H6.05C6.16527 8.43532 6.47209 7.9278 6.91855 7.56335C7.36501 7.19889 7.92367 6.99988 8.5 7ZM15.5 7C16.0763 6.99988 16.635 7.19889 17.0815 7.56335C17.5279 7.9278 17.8347 8.43532 17.95 9H13.05C13.1653 8.43532 13.4721 7.9278 13.9185 7.56335C14.365 7.19889 14.9237 6.99988 15.5 7Z' />
      </svg>
    );
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      width={size ? `${size}rem` : '1em'}
      height={size ? `${size}rem` : '1em'}
      fill={color === undefined || color === 'inherit' ? 'currentColor' : color}
    >
      <path d='M12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2ZM12 11C10 11 8.333 11.333 7 12C7 13.3261 7.52678 14.5979 8.46447 15.5355C9.40215 16.4732 10.6739 17 12 17C13.3261 17 14.5979 16.4732 15.5355 15.5355C16.4732 14.5979 17 13.3261 17 12C15.667 11.333 14 11 12 11ZM8.5 7C7.348 7 6.378 7.78 6.088 8.84L6.05 9H10.95C10.8347 8.43532 10.5279 7.9278 10.0815 7.56335C9.63499 7.19889 9.07633 6.99988 8.5 7ZM15.5 7C14.348 7 13.378 7.78 13.088 8.84L13.05 9H17.95C17.8347 8.43532 17.5279 7.9278 17.0815 7.56335C16.635 7.19889 16.0763 6.99988 15.5 7Z' />
    </svg>
  );
}
