import { NotificationAction } from './NotificationAction/NotificationAction.component';
import { NotificationBody } from './NotificationBody/NotificationBody.component';
import { NotificationCard } from './NotificationCard/NotificationCard.component';
import { NotificationContainer } from './NotificationContainer/NotificationContainer.component';
import { NotificationCounter } from './NotificationCounter/NotificationCounter.component';
import { NotificationDate } from './NotificationDate/NotificationDate.component';
import { NotificationDropdown } from './NotificationDropdown/NotificationDropdown.component';
import { NotificationGroupTitle } from './NotificationGroupTitle/NotificationGroupTitle.component';
import { NotificationHeader } from './NotificationHeader/NotificationHeader.component';
import { NotificationIcon } from './NotificationIcon/NotificationIcon.component';
import { NotificationList } from './NotificationList/NotificationList.component';
import { NotificationMenu } from './NotificationMenu/NotificationMenu.component';
import { NotificationTitle } from './NotificationTitle/NotificationTitle.component';
import { NotificationTrigger } from './NotificationTrigger/NotificationTrigger.component';

export const Notification = {
  List: NotificationList,
  Icon: NotificationIcon,
  Date: NotificationDate,
  Card: NotificationCard,
  Body: NotificationBody,
  Menu: NotificationMenu,
  Title: NotificationTitle,
  Action: NotificationAction,
  Header: NotificationHeader,
  Trigger: NotificationTrigger,
  Counter: NotificationCounter,
  Dropdown: NotificationDropdown,
  Container: NotificationContainer,
  GroupTitle: NotificationGroupTitle
};
