import { DSIcons } from 'ds/icons';
import { tokens } from 'ds/tokens';

import {
  type IToastVariants,
  type ToastType
} from 'ds/components/Toast/Toast.types';

export const toastVariants: Record<ToastType, IToastVariants> = {
  default: {
    background: tokens.brandColorSecondaryLightest
  },
  info: {
    background: tokens.brandColorSecondaryLightest,
    icon: DSIcons.InfoIcon
  },
  success: {
    background: tokens.feedbackColorSuccessLightest,
    icon: DSIcons.SuccessIcon
  },
  warning: {
    background: tokens.feedbackColorWarningLightest,
    icon: DSIcons.WarningIcon
  },
  error: {
    background: tokens.feedbackColorErrorLightest,
    icon: DSIcons.ErrorIcon
  },
  notification: {
    background: tokens.brandColorSecondaryLightest,
    icon: DSIcons.NotificationBellIcon
  }
};
