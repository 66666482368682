import {
  type IOutsourcedUserPermission,
  type IPersistenceOutsourcedUserPermission
} from 'data/modules/auth';

class GetOutsourcedUsersAndPermissionsMapper {
  toDomain(
    persistenceUserAndPermission: IPersistenceOutsourcedUserPermission
  ): IOutsourcedUserPermission {
    return {
      directorId: persistenceUserAndPermission.director_id,
      directorUuid: persistenceUserAndPermission.director_uuid,
      directorName: persistenceUserAndPermission.director_name,
      permissions: persistenceUserAndPermission.permissions
    };
  }
}

export default new GetOutsourcedUsersAndPermissionsMapper();
