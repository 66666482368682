import {
  Add,
  Alert,
  ArrowDown,
  ArrowDownS,
  ArrowGoBack,
  ArrowLeftDouble,
  ArrowLeftRight,
  ArrowLeftS,
  ArrowRightS,
  ArrowUp,
  ArrowUpS,
  Attachment,
  BankCard,
  Bell,
  BuildingBusinessOffice,
  ChatSmile3,
  Check,
  CheckboxBlankCircle,
  CheckboxCircle,
  Clock,
  Close,
  CloseCircle,
  Coins,
  Company,
  DeleteBin,
  DragMove2,
  Earth,
  Edit,
  Emotion1,
  Emotion2,
  EmotionHappy,
  EmotionNormal,
  EmotionSad,
  Equalizer,
  ErrorWarning,
  ExpandUpDown,
  Eye,
  EyeOff,
  File3,
  FileAdd,
  FileCopy,
  FileEdit,
  FileText,
  FileUser,
  FileWarning,
  Filter,
  FolderOpen,
  IndeterminateCircle,
  Information,
  LineChart,
  Lock,
  MailSend,
  MoneyDollarCircle,
  Pencil,
  Pix,
  Profile,
  QrScan2,
  Question,
  Save3,
  Search,
  SecurePayment,
  Settings,
  SkipBack,
  SkipForward,
  Subtract,
  Swap,
  Trophy,
  Unlock,
  User3,
  UserAdd,
  UserSearch,
  UserSettings,
  UserShared,
  UserStar
} from 'presentation/components/base/Icon/Vector';

export const icons = {
  information: Information,
  'arrow-up': ArrowUp,
  close: Close,
  'indeterminate-circle': IndeterminateCircle,
  'arrow-go-back': ArrowGoBack,
  add: Add,
  subtract: Subtract,
  'bank-card': BankCard,
  equalizer: Equalizer,
  'arrow-left-s': ArrowLeftS,
  'arrow-right-s': ArrowRightS,
  'skip-back': SkipBack,
  'skip-forward': SkipForward,
  question: Question,
  pix: Pix,
  'file-copy': FileCopy,
  check: Check,
  'arrow-down-s': ArrowDownS,
  coins: Coins,
  'money-dollar-circle': MoneyDollarCircle,
  'error-warning': ErrorWarning,
  lock: Lock,
  unlock: Unlock,
  settings: Settings,
  clock: Clock,
  search: Search,
  'arrow-down': ArrowDown,
  alert: Alert,
  'file-3': File3,
  'drag-move-2': DragMove2,
  pencil: Pencil,
  'close-circle': CloseCircle,
  eye: Eye,
  'arrow-left-right': ArrowLeftRight,
  'arrow-up-s': ArrowUpS,
  'expand-up-down': ExpandUpDown,
  'file-edit': FileEdit,
  'checkbox-blank-circle': CheckboxBlankCircle,
  'user-3': User3,
  'user-settings': UserSettings,
  'qr-scan-2': QrScan2,
  'secure-payment': SecurePayment,
  'line-chart': LineChart,
  'delete-bin': DeleteBin,
  'file-warning': FileWarning,
  'arrow-left-double': ArrowLeftDouble,
  profile: Profile,
  'checkbox-circle': CheckboxCircle,
  'folder-open': FolderOpen,
  'user-star': UserStar,
  'mail-send': MailSend,
  'file-text': FileText,
  'building-business-office': BuildingBusinessOffice,
  edit: Edit,
  swap: Swap,
  earth: Earth,
  attachment: Attachment,
  filter: Filter,
  'user-add': UserAdd,
  'save-3': Save3,
  bell: Bell,
  trophy: Trophy,
  'chat-smile-3': ChatSmile3,
  'emotion-1': Emotion1,
  'emotion-2': Emotion2,
  'emotion-happy': EmotionHappy,
  'emotion-normal': EmotionNormal,
  'emotion-sad': EmotionSad,
  company: Company,
  'eye-off': EyeOff,
  'file-user': FileUser,
  'file-add': FileAdd,
  'user-search': UserSearch,
  'user-shared': UserShared
};
