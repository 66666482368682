import { DSIcons } from 'ds';

import { useZendeskCloseAction } from './useZendeskCloseAction';

import { Close, Container } from './ZendeskCloseAction.styles';

export function ZendeskCloseAction(): JSX.Element {
  const { handleCloseZendesk, isOpenCloseActionVisible } =
    useZendeskCloseAction();

  return (
    <>
      {isOpenCloseActionVisible && (
        <Container id='zendesk-close-button'>
          <Close onClick={handleCloseZendesk}>
            <DSIcons.CloseIcon />
          </Close>
        </Container>
      )}
    </>
  );
}
