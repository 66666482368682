import expenseViewModalJson from './expenseViewModal.lang.json';
import manualExpenseModalJson from './manualExpenseModal.lang.json';
import pageJson from './page.lang.json';
import reportSelectionJson from './reportSelection.lang.json';
import routeByMapModalJson from './routeByMapModal.lang.json';

export const tours = {
  page: pageJson,
  manualExpenseModal: manualExpenseModalJson,
  routeByMapModal: routeByMapModalJson,
  reportSelection: reportSelectionJson,
  expenseViewModal: expenseViewModalJson
};
