import { type INotificationContainerProps } from './NotificationContainer.types';

import { Container } from './NotificationContainer.styles';

export function NotificationContainer({
  children,
  ...props
}: INotificationContainerProps): JSX.Element {
  return <Container {...props}>{children}</Container>;
}
