import { useTranslation } from 'react-i18next';

import { type INotificationListProps } from './NotificationList.types';

import { Container, LoadMoreButton } from './NotificationList.styles';

export function NotificationList({
  children,
  onLoadMoreClick,
  loadingStatus = 'default',
  ...props
}: INotificationListProps): JSX.Element {
  const { t } = useTranslation('notifications');

  return (
    <Container {...props}>
      {children}
      <LoadMoreButton
        onClick={onLoadMoreClick}
        $loadingStatus={loadingStatus}
      >
        {loadingStatus === 'default'
          ? t('loadingStatusDefault')
          : loadingStatus === 'loading'
            ? t('loadingStatusLoading')
            : t('loadingStatusLoaded')}
      </LoadMoreButton>
    </Container>
  );
}
