import { lazy } from 'react';

const ArrowRightCircleIcon = lazy(
  async () =>
    await import('./arrow_right_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthHomeWorldIcon = lazy(
  async () =>
    await import('./earth_home_world.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationLocatorIcon = lazy(
  async () =>
    await import('./pin_location_locator.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LocationPinRotateIcon = lazy(
  async () =>
    await import('./location_pin_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationDirectionIcon = lazy(
  async () =>
    await import('./pin_location_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const RouteDirectionPinIcon = lazy(
  async () =>
    await import('./route_direction_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationIcon = lazy(
  async () =>
    await import('./pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationHandSelectIcon = lazy(
  async () =>
    await import('./pin_location_hand_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowLocationMapDirectionIcon = lazy(
  async () =>
    await import('./arrow_location_map_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationReloadIcon = lazy(
  async () =>
    await import('./pin_location_reload.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapRadarLocatorIcon = lazy(
  async () =>
    await import('./map_radar_locator.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationMapIcon = lazy(
  async () =>
    await import('./pin_location_map.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapNavigationIcon = lazy(
  async () =>
    await import('./map_navigation.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeEarthSimpleCheckmarkIcon = lazy(
  async () =>
    await import('./globe_earth_simple_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationDirectionPlusIcon = lazy(
  async () =>
    await import('./pin_location_direction_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationDirectionRefreshIcon = lazy(
  async () =>
    await import('./pin_location_direction_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinIcon = lazy(
  async () =>
    await import('./pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PointDirectionArrowIcon = lazy(
  async () =>
    await import('./point_direction_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ProtectionTargetIcon = lazy(
  async () =>
    await import('./protection_target.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthPinLocationDirectionIcon = lazy(
  async () =>
    await import('./earth_pin_location_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MessageChatGlobeEarthIcon = lazy(
  async () =>
    await import('./message_chat_globe_earth.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagPinLocationIcon = lazy(
  async () =>
    await import('./flag_pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LocationSearchRadarIcon = lazy(
  async () =>
    await import('./location_search_radar.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EyePinLocationIcon = lazy(
  async () =>
    await import('./eye_pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinCheckmarkCircleIcon = lazy(
  async () =>
    await import('./pin_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationAddPlusCircleIcon = lazy(
  async () =>
    await import('./pin_location_add_plus_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowLocationDirectionIcon = lazy(
  async () =>
    await import('./arrow_location_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapPinSquareIcon = lazy(
  async () =>
    await import('./map_pin_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationLockIcon = lazy(
  async () =>
    await import('./pin_location_lock.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapOpenPinIcon = lazy(
  async () =>
    await import('./map_open_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowIcon = lazy(
  async () =>
    await import('./direction_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapIcon = lazy(
  async () =>
    await import('./map.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationDeleteSquareIcon = lazy(
  async () =>
    await import('./pin_location_delete_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationMessageChatIcon = lazy(
  async () =>
    await import('./pin_location_message_chat.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationCheckmarkCircleIcon = lazy(
  async () =>
    await import('./pin_location_checkmark_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeArrowDirectionIcon = lazy(
  async () =>
    await import('./globe_arrow_direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowSmallCircleSquareIcon = lazy(
  async () =>
    await import('./direction_arrow_small_circle_square.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapPinLocationCircleIcon = lazy(
  async () =>
    await import('./map_pin_location_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinDeleteDisableIcon = lazy(
  async () =>
    await import('./pin_delete_disable.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapDirectionLocationSelectIcon = lazy(
  async () =>
    await import('./map_direction_location_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapLocationSearchIcon = lazy(
  async () =>
    await import('./map_location_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapLoactionAddPlusIcon = lazy(
  async () =>
    await import('./map_loaction_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ChecklistPinLocationIcon = lazy(
  async () =>
    await import('./checklist_pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionCheckmarkIcon = lazy(
  async () =>
    await import('./arrow_direction_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const FlagPinIcon = lazy(
  async () =>
    await import('./flag_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeCheckmarkIcon = lazy(
  async () =>
    await import('./globe_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapDPinIcon = lazy(
  async () =>
    await import('./map_d_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinRotateIcon = lazy(
  async () =>
    await import('./pin_rotate.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionsIcon = lazy(
  async () =>
    await import('./directions.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const CompassMapIcon = lazy(
  async () =>
    await import('./compass_map.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeEarthSimpleCursorIcon = lazy(
  async () =>
    await import('./globe_earth_simple_cursor.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapLineSmallSqaureIcon = lazy(
  async () =>
    await import('./map_line_small_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionLocationSignalIcon = lazy(
  async () =>
    await import('./arrow_direction_location_signal.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionIcon = lazy(
  async () =>
    await import('./direction.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionAddPlusIcon = lazy(
  async () =>
    await import('./arrow_direction_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const TargetDeleteDisableIcon = lazy(
  async () =>
    await import('./target_delete_disable.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ArrowDirectionCircleIcon = lazy(
  async () =>
    await import('./arrow_direction_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const VoicePinLocationSelectIcon = lazy(
  async () =>
    await import('./voice_pin_location_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthPinIcon = lazy(
  async () =>
    await import('./earth_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionArrowRoadIcon = lazy(
  async () =>
    await import('./direction_arrow_road.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapLocationCheckmarkIcon = lazy(
  async () =>
    await import('./map_location_checkmark.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const ItemPinAddPlusIcon = lazy(
  async () =>
    await import('./item_pin_add_plus.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapPinLocationIcon = lazy(
  async () =>
    await import('./map_pin_location.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapPinIcon = lazy(
  async () =>
    await import('./map_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationNotificationCircleIcon = lazy(
  async () =>
    await import('./pin_location_notification_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinModernCircleIcon = lazy(
  async () =>
    await import('./pin_modern_circle.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const LocationPinSearchIcon = lazy(
  async () =>
    await import('./location_pin_search.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const EarthCursorSelectIcon = lazy(
  async () =>
    await import('./earth_cursor_select.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const GlobeEarthModernIcon = lazy(
  async () =>
    await import('./globe_earth_modern.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const PinLocationRefreshIcon = lazy(
  async () =>
    await import('./pin_location_refresh.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const DirectionPinArrowIcon = lazy(
  async () =>
    await import('./direction_pin_arrow.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapLocationSearchSqaureIcon = lazy(
  async () =>
    await import('./map_location_search_sqaure.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapBigOpenIcon = lazy(
  async () =>
    await import('./map_big_open.svg').then(module => ({
      default: module.ReactComponent
    }))
);
const MapLocationPinIcon = lazy(
  async () =>
    await import('./map_location_pin.svg').then(module => ({
      default: module.ReactComponent
    }))
);

export const NavigationMaps = {
  ArrowRightCircleIcon,
  EarthHomeWorldIcon,
  PinLocationLocatorIcon,
  LocationPinRotateIcon,
  PinLocationDirectionIcon,
  RouteDirectionPinIcon,
  PinLocationIcon,
  PinLocationHandSelectIcon,
  ArrowLocationMapDirectionIcon,
  PinLocationReloadIcon,
  MapRadarLocatorIcon,
  PinLocationMapIcon,
  MapNavigationIcon,
  GlobeEarthSimpleCheckmarkIcon,
  PinLocationDirectionPlusIcon,
  PinLocationDirectionRefreshIcon,
  PinIcon,
  PointDirectionArrowIcon,
  ProtectionTargetIcon,
  EarthPinLocationDirectionIcon,
  MessageChatGlobeEarthIcon,
  FlagPinLocationIcon,
  LocationSearchRadarIcon,
  EyePinLocationIcon,
  PinCheckmarkCircleIcon,
  PinLocationAddPlusCircleIcon,
  ArrowLocationDirectionIcon,
  MapPinSquareIcon,
  PinLocationLockIcon,
  MapOpenPinIcon,
  DirectionArrowIcon,
  MapIcon,
  PinLocationDeleteSquareIcon,
  PinLocationMessageChatIcon,
  PinLocationCheckmarkCircleIcon,
  GlobeArrowDirectionIcon,
  DirectionArrowSmallCircleSquareIcon,
  MapPinLocationCircleIcon,
  PinDeleteDisableIcon,
  MapDirectionLocationSelectIcon,
  MapLocationSearchIcon,
  MapLoactionAddPlusIcon,
  ChecklistPinLocationIcon,
  ArrowDirectionCheckmarkIcon,
  FlagPinIcon,
  GlobeCheckmarkIcon,
  MapDPinIcon,
  PinRotateIcon,
  DirectionsIcon,
  CompassMapIcon,
  GlobeEarthSimpleCursorIcon,
  MapLineSmallSqaureIcon,
  ArrowDirectionLocationSignalIcon,
  DirectionIcon,
  ArrowDirectionAddPlusIcon,
  TargetDeleteDisableIcon,
  ArrowDirectionCircleIcon,
  VoicePinLocationSelectIcon,
  EarthPinIcon,
  DirectionArrowRoadIcon,
  MapLocationCheckmarkIcon,
  ItemPinAddPlusIcon,
  MapPinLocationIcon,
  MapPinIcon,
  PinLocationNotificationCircleIcon,
  PinModernCircleIcon,
  LocationPinSearchIcon,
  EarthCursorSelectIcon,
  GlobeEarthModernIcon,
  PinLocationRefreshIcon,
  DirectionPinArrowIcon,
  MapLocationSearchSqaureIcon,
  MapBigOpenIcon,
  MapLocationPinIcon
};
