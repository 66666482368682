import { tokens } from 'ds/tokens';

import {
  type AvatarSizeType,
  type IAvatarVariants
} from 'ds/components/Avatar/Avatar.types';

export const avatarProps: Record<AvatarSizeType, IAvatarVariants> = {
  '20': {
    border: tokens.borderRadiusNano,
    size: '2rem'
  },
  '24': {
    border: tokens.borderRadiusNano,
    size: '2.4rem'
  },
  '32': {
    border: tokens.borderRadiusNano,
    size: '3.2rem'
  },
  '40': {
    border: tokens.borderRadiusXs,
    size: '4rem'
  },
  '44': {
    border: tokens.borderRadiusXs,
    size: '4.4rem'
  },
  '56': {
    border: tokens.borderRadiusSm,
    size: '5.6rem'
  },
  '60': {
    border: tokens.borderRadiusSm,
    size: '6rem'
  }
};
