import { useEffect } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import {
  AuthQueryKeys,
  type IAuthUser,
  type IUseLoadInitialData,
  type IUser,
  useGetOutsourcedUsersAndPermissions
} from 'data/modules/auth';
import { useGetSessionUser } from 'data/modules/auth/useCases/get-session-user/useGetSessionUser';

import { useGa4 } from 'shared/hooks/global';

export function useLoadInitialData(): IUseLoadInitialData {
  const {
    user,
    company,
    isFetchingAuthUser,
    outsourcedUser,
    isGetOutsourcedUserRequestEnabled,
    isFetchingOutsourcedUser,
    isFetchingCardsCompany,
    isFetchingCardsUser,
    isFetchingTravelUser,
    cardsUserError,
    cardsCompanyError
  } = useGetSessionUser();

  const { isFetchingOutsourcedUsersAndPermissions } =
    useGetOutsourcedUsersAndPermissions({
      enabled:
        (!isFetchingAuthUser && user?.parameters.hasOutsourcingAccess) ?? false
    });

  const queryClient = useQueryClient();

  const { sendUser } = useGa4();

  /* Engajamento do usuario set datalayer */
  if (user && company) {
    sendUser({
      userId: user.id,
      companyId: company.id,
      companyUuId: company.uuid
    });
  }

  useEffect(() => {
    if (
      !isFetchingAuthUser &&
      isGetOutsourcedUserRequestEnabled &&
      !isFetchingOutsourcedUser &&
      outsourcedUser
    ) {
      const newOutsourcingParameters: Partial<IUser['parameters']> = {
        usesCards:
          outsourcedUser.user.parameters.usesCards ??
          outsourcedUser.user.parameters.sharedAccountsCards ??
          false
      };

      queryClient.setQueryData<IAuthUser>(
        [AuthQueryKeys.AUTH_USER],
        oldData => {
          if (!oldData) return;

          return {
            ...oldData,
            user: {
              ...oldData.user,
              parameters: {
                ...oldData.user.parameters,
                ...newOutsourcingParameters
              }
            }
          };
        }
      );
    }
  }, [
    isFetchingOutsourcedUser,
    isGetOutsourcedUserRequestEnabled,
    outsourcedUser,
    isFetchingAuthUser,
    queryClient
  ]);

  return {
    isLoading:
      isFetchingAuthUser ||
      isFetchingCardsUser ||
      isFetchingCardsCompany ||
      isFetchingOutsourcedUser ||
      isFetchingOutsourcedUsersAndPermissions ||
      isFetchingTravelUser,
    hasError: cardsUserError || cardsCompanyError
  };
}
